import axios from "axios";
import {fork,all, call, takeLatest, put} from "redux-saga/effects";
import {LOADING_GALLERY_DATA,LOAD_GALLERY_DATA_SUCCESS,LOAD_GALLERY_DATA_FAILURE,
    UPLOADING_IMAGE_FILES_INIT,
    UPLOADING_IMAGE_FILES_REQUEST,
    UPLOADING_IMAGE_FILES_SUCCESS,
    UPLOADING_IMAGE_FILES_FAILURE,
    UPLOADING_DATA_INIT,
    UPLOADING_DATA_REQUEST,
    UPLOADING_DATA_SUCCESS,
    UPLOADING_DATA_FAILURE,
    GET_COUNT_GALLERY_REQUEST,
    GET_COUNT_GALLERY_SUCCESS,
    GET_COUNT_GALLERY_FAILURE,
    DELETE_GALLERY_PRODUCT_REQUEST,
    DELETE_GALLERY_PRODUCT_SUCCESS,
    DELETE_GALLERY_PRODUCT_FAILURE
} from '../reducers/paintings';
import {GalleryIndexLoading,GalleryProductUpload,UserProfileModifyPage} from "../constants/applicationMessage";
/* import { create } from 'ipfs-http-client';
import { IPFS_API_CLIENT_URL } from "../constants/urls"; */

const callAPI = (data)=>{
    const {apiType} = data;
    
    let url = '';
    console.log(`api type : ${apiType}`);
    switch(apiType){
        case 'gallery_with_user' :{
            const {limit,offset,userid,withWaterMark}=data;
            url=withWaterMark?
            `https://api.collery.io/getauthorgalleryimageslimitprivate/${limit},${offset},${userid}`
            :`https://api.collery.io/getauthorgalleryimageslimit/${limit},${offset},${userid}`;
            break;
        }
        case 'gallery_with_category' :{
            const {cat,limit,offset}=data;
            url=`https://api.collery.io/getgallerycat/${cat},${limit},${offset}`;
            break;
        }
        case 'gallery_with_nothing' :{
            const {limit,offset}=data;
            url=`https://api.collery.io/getallgalleryimageslimit/${Number(limit)},${Number(offset)}`;
            break;
        }
        case 'gallery_with_user_and_category':{
            const {limit,offset,userid,cat}=data;
            console.log(data);
            url=`https://api.collery.io/getgallerycatuser/${cat},${userid},${limit},${offset}`
            break;
        }
    }

    return axios.get(url)
}

function* loadPaintingsData(action){
    try{
        let response=null;
        let countGallery = -1;
        if(action?.isFirst&&!action.data?.userid){
            const countGalleryRequest=yield call(sendGetCountRequest);
            countGallery=countGalleryRequest.data[0].totalimages;
        }
        response = yield call(callAPI,action.data);
        if(response.data.response==="Error while processing"){
            console.error(response.data.response);
            yield put({type:LOAD_GALLERY_DATA_FAILURE,message:GalleryIndexLoading.loadErrorMessage,errorData:GalleryIndexLoading.loadErrorMessage});
        }else{
            if(!!action.data.isCate){
                yield put({type:LOAD_GALLERY_DATA_SUCCESS,galleryData:response.data});
            }else{
                action?.isFirst&&!action.data?.userid?
                yield put({type:LOAD_GALLERY_DATA_SUCCESS,galleryData:response.data,countGallery})
                :yield put ({type:LOAD_GALLERY_DATA_SUCCESS,galleryData:response.data})
            }
        }
        
        
        
    }catch(err){
        console.log(err);;
        if(err.response){
            const httpStatus = err.response.status;
            let message = '';
            if(httpStatus>=400 && httpStatus<500){
                message = GalleryIndexLoading.loadErrorMessage;
            }else if(httpStatus>=500){
                message = GalleryIndexLoading.serverErrorMessage;
            }
            yield put({type:LOAD_GALLERY_DATA_FAILURE,message,errorData:message});
        }else{
            yield put({type:LOAD_GALLERY_DATA_FAILURE,message:GalleryIndexLoading.loadErrorMessage,errorData:GalleryIndexLoading.loadErrorMessage});
        }
    }
}

function* createPaintingsSaga(){
    yield takeLatest(LOADING_GALLERY_DATA,loadPaintingsData);
}

//서버로 이미지 파일들 전송

const callUploadingImageAPI = async(image)=>{
    const formData = new FormData();
    formData.append("file",image);
    return await axios.post('https://api.collery.io/uploadgallery',
        formData
    ); {/**  */}
}

//작품들 main server 에 data 전송
const callMainServerAPI=async(data)=>{
    return await axios.post('https://api.collery.io/savegalleryimage',data);
}

const callUploadingImages=async (data)=>{
    try{
        await Promise.all(data.image.map(async(v)=>{
            const respObj = await callUploadingImageAPI(v);
            const strToBeHashed = `${respObj.data.url}_${data.userid}`;
            const encodedText = new TextEncoder().encode(strToBeHashed);
            let imagehash='';
            if(window.crypto.subtle){
                const tempImagehash = await window.crypto.subtle.digest('SHA-256',encodedText);
                const hashArray = Array.from(new Uint8Array(tempImagehash));
                imagehash = hashArray.map(e=>e.toString(16).padStart(2,'0')).join('');
            }else{
                imagehash=encodeURIComponent(strToBeHashed);
                console.log(imagehash);
            }
            console.log(imagehash);
            console.log(respObj.data);
            const respFromMainServer = await callMainServerAPI({
                ...data,
                image:(respObj.data.url),
                watermarkurl:(respObj.data.watermarkurl),
                imagename:(respObj.data.imagename),
                resizedurl:(respObj.data.resizedurl),
                imagehash
            });
            return true;
        }));
        return true;
    }catch(err){
        if(err.response){
            const httpStatus = err.response.status;
            let message = '';
            if(httpStatus>=400 && httpStatus<500){
                message=GalleryProductUpload.uploadImagesErrorMessage;
            }else{
                message=GalleryProductUpload.uploadImagesserverErrorMessage;
            }
            throw new Error(message);
        }else{
            console.error(err);
            throw new Error(GalleryProductUpload.uploadImagesErrorMessage)
        }
        
    }
}

function* uploadingImageFiles(action){
    try{
        yield call(callUploadingImages,action.data);
        console.log("load data success!");
        yield put({type:UPLOADING_IMAGE_FILES_SUCCESS});
    }catch(err){
        yield put({type:UPLOADING_IMAGE_FILES_FAILURE,data:err.message});
    }
}

function* uploadImagesToIPFS(){
    yield takeLatest(UPLOADING_IMAGE_FILES_REQUEST,uploadingImageFiles)
}

const sendGetCountRequest = ()=>{
    return axios.get('https://api.collery.io/counttotalgalleryimages');
}

function* sendGetCountUtil(action){
    try{
        const countGallery = yield call(sendGetCountRequest);
        console.log(countGallery.data[0].totalimages)
        yield put({type:GET_COUNT_GALLERY_SUCCESS,data:countGallery.data[0].totalimages});
    }catch(err){
        const httpStatus = err.response.status;
        let message = '';
        if(httpStatus>=400&&httpStatus<500){
            message="데이터 전송 오류";
        }else if(httpStatus>=500){
            message="서버 점검중...";
        }
        yield put({type:GET_COUNT_GALLERY_FAILURE,data:message});
    }
}

function* getCountGallery(){
    yield takeLatest(GET_COUNT_GALLERY_REQUEST,sendGetCountUtil);
}

//제품 삭제
const deleteGalleryAPI = (data)=>{
    console.log(data);
    return axios.post(`https://api.collery.io/deletegalleryimage`,
        JSON.stringify(data),
        {headers:{'Content-Type':'application/json'}});
}

const callDeleteGalleryAPI = async(deleteIdList)=>{
    console.log(deleteIdList);
    try{
        await Promise.all(deleteIdList.map(async (v)=>{
            return await deleteGalleryAPI(v);
        }))
        return true;
    }catch(err){
        if(err.response){
            const httpStatus = err.response.status;
            let message = '';
            if(httpStatus>=400 && httpStatus<500){
                message=UserProfileModifyPage.errorDeletingGalleryProductMessage;
            }else{
                message=UserProfileModifyPage.serverErrorDeletingGalleryProductMessage;
            }
            throw new Error(message);
        }else{
            throw new Error(UserProfileModifyPage.errorDeletingGalleryProductMessage);
        }
        
    }
}

function* deleteGalleryUtil(action){
    try{
        yield call(callDeleteGalleryAPI,action.data);
        yield put({type:DELETE_GALLERY_PRODUCT_SUCCESS});
    }catch(err){
        yield put({type:DELETE_GALLERY_PRODUCT_FAILURE,data:err.message});
    }
}

function* deleteGallery(){
    yield takeLatest(DELETE_GALLERY_PRODUCT_REQUEST,deleteGalleryUtil);
}

export default function* paintingsSaga(){
    yield all([
        fork(createPaintingsSaga),
        fork(uploadImagesToIPFS),
        fork(deleteGallery),
        //fork(getCountGallery)
        //fork(uploadDataToMainServer)
    ]);
}