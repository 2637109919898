import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {USER_SIGNUP_CERTIFICATION,HOMEPAGE} from '../../constants/applicationUrls';
import { useAuth } from '../../contexts/AuthContext';

const RouteSignUp = (ChildrenComponent)=>{
    function RenderComponent(props){
        const {currentUser} =useAuth();
        const {certify_sign_up} = useSelector(state=>state.users);
        return (
            <>
                {
                    currentUser?
                    (<Redirect to={HOMEPAGE}/>):
                    (certify_sign_up?<ChildrenComponent/>:<Redirect to={USER_SIGNUP_CERTIFICATION}/>)
                
                }
            </>
        )
    }
    return RenderComponent;
}

export default RouteSignUp;