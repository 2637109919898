import produce from 'immer';

export const INIT_NFT_STATE = 'INIT_NFT_STATE';

export const INIT_MINTING_STATE = 'INIT_MINTING_STATE';
export const LOADING_MINTING = 'LOADING_MINTING';
export const LOAD_MINTING_SUCCESS = 'LOAD_MINTING_SUCCESS';
export const LOAD_MINTING_FAILURE = 'LOAD_MINTING_FAILURE';
export const LOADING_CHANGE_PRICE_INFO ='LOADING_CHANGE_PRICE_INFO';
export const LOAD_CHANGE_PRICE_INFO_SUCCESS = 'LOAD_CHANGE_PRICE_INFO_SUCCESS';
export const LOAD_CHANGE_PRICE_INFO_FAILURE = 'LOAD_CHANGE_PRICE_INFO_FAILURE';
export const INIT_LOAD_CHANGE_PRICE_INFO_STATE = 'INIT_LOAD_CHANGE_PRICE_INFO_STATE';
export const LOADING_NFT_PRODUCTS ='LOADING_NFT_PRODUCTS';
export const LOAD_NFT_PRODUCTS_SUCCESS = 'LOAD_NFT_PRODUCTS_SUCCESS';
export const LOAD_NFT_PRODUCTS_FAILURE = 'LOAD_NFT_PRODUCTS_FAILURE';
export const INIT_LOAD_NFT_PRODUCTS_STATE = 'INIT_LOAD_NFT_PRODUCTS_STATE';
export const LOADING_MY_NFT_PRODUCTS ='LOADING_MY_NFT_PRODUCTS';
export const LOAD_MY_NFT_PRODUCTS_SUCCESS = 'LOAD_MY_NFT_PRODUCTS_SUCCESS';
export const LOAD_MY_NFT_PRODUCTS_FAILURE = 'LOAD_MY_NFT_PRODUCTS_FAILURE';
export const INIT_LOAD_MY_NFT_PRODUCTS_STATE = 'INIT_LOAD_MY_NFT_PRODUCTS_STATE';

export const LOAD_MY_MINTED_PRODUCTS ='LOAD_MY_MINTED_PRODUCTS';
export const LOAD_MY_MINTED_PRODUCTS_SUCCESS = 'LOAD_MY_MINTED_PRODUCTS_SUCCESS';
export const LOAD_MY_MINTED_PRODUCTS_FAILURE = 'LOAD_MY_MINTED_PRODUCTS_FAILURE';
export const INIT_LOAD_MY_MINTED_PRODUCTS_STATE = 'INIT_LOAD_MY_MINTED_PRODUCTS_STATE';

export const LOAD_BOUGHT_PRODUCTS ='LOAD_BOUGHT_PRODUCTS';
export const LOAD_BOUGHT_PRODUCTS_SUCCESS = 'LOAD_BOUGHT_PRODUCTS_SUCCESS';
export const LOAD_BOUGHT_PRODUCTS_FAILURE = 'LOAD_BOUGHT_PRODUCTS_FAILURE';
export const INIT_LOAD_BOUGHT_PRODUCTS_STATE = 'INIT_LOAD_BOUGHT_PRODUCTS_STATE';

export const LOAD_RENT_PRODUCTS ='LOAD_RENT_PRODUCTS';
export const LOAD_RENT_PRODUCTS_SUCCESS = 'LOAD_RENT_PRODUCTS_SUCCESS';
export const LOAD_RENT_PRODUCTS_FAILURE = 'LOAD_RENT_PRODUCTS_FAILURE';
export const INIT_LOAD_RENT_PRODUCTS_STATE = 'INIT_LOAD_RENT_PRODUCTS_STATE';


const initialState = {
    loadingMinting : false,
    mintingSuccess:false,
    mintingData:null,
    mintingFailure:null,
    loadingChangePriceInfo:false,
    loadingChangePriceInfoSuccess:false,
    loadingChangePriceInfoData:null,
    loadingChangePriceInfoDataId:null,
    loadingChangePriceInfoFailure:null,
    loadingChangePriceInfoType:null,
    loadingNftProducts:false,
    loadingNftProductsSuccess:false,
    loadingNftProductsData:null,
    loadingNftProductsFailure:null,

    loadingMyNftProducts:false,
    loadingMyNftProductsSuccess:false,
    loadingMyNftProductsData:null,
    loadingMyNftProductsFailure:null,

    loadingMyMintedNftProductsFailure:null,
    loadingMyMintedNftProducts:false,
    loadingMyMintedNftProductsSuccess:false,
    loadingMyMintedNftProductsData:null,

    loadingBoughtNftProductsFailure:null,
    loadingBoughtNftProducts:false,
    loadingBoughtNftProductsSuccess:false,
    loadingBoughtNftProductsData:null,

    loadingRentNftProductsFailure:null,
    loadingRentNftProducts:false,
    loadingRentNftProductsSuccess:false,
    loadingRentNftProductsData:null,
};

const reducer = (state=initialState,action)=>{
    return produce(state,(draft)=>{
        switch(action.type){
            case LOAD_RENT_PRODUCTS:{
                draft.loadingRentNftProductsFailure=null;
                draft.loadingRentNftProducts=true;
                draft.loadingRentNftProductsSuccess=false;
                draft.loadingRentNftProductsData=null;
                break;
            }
            case LOAD_RENT_PRODUCTS_SUCCESS:{
                draft.loadingRentNftProducts=false;
                draft.loadingRentNftProductsSuccess=true;
                draft.loadingRentNftProductsData=action.data;
                break;
            }
            case LOAD_RENT_PRODUCTS_FAILURE:{
                draft.loadingRentNftProductsFailure=action.data;
                draft.loadingRentNftProducts=false;
                break;
            }
            case INIT_LOAD_RENT_PRODUCTS_STATE:{
                draft.loadingRentNftProductsFailure=null;
                draft.loadingRentNftProducts=false;
                draft.loadingRentNftProductsSuccess=false;
                draft.loadingRentNftProductsData=null;
                break;
            }
            case LOAD_BOUGHT_PRODUCTS:{
                draft.loadingBoughtNftProductsFailure=null;
                draft.loadingBoughtNftProducts=true;
                draft.loadingBoughtNftProductsSuccess=false
                draft.loadingBoughtNftProductsData=null;
                break;
            }
            case LOAD_BOUGHT_PRODUCTS_SUCCESS:{
                draft.loadingBoughtNftProducts=false;
                draft.loadingBoughtNftProductsSuccess=true;
                draft.loadingBoughtNftProductsData=action.data
                break;
            }
            case LOAD_BOUGHT_PRODUCTS_FAILURE:{
                draft.loadingBoughtNftProductsFailure=action.data;
                draft.loadingBoughtNftProducts=false;
                break;
            }
            case INIT_LOAD_BOUGHT_PRODUCTS_STATE:{
                draft.loadingBoughtNftProductsFailure=null;
                draft.loadingBoughtNftProducts=false;
                draft.loadingBoughtNftProductsSuccess=false;
                draft.loadingBoughtNftProductsData=null;
                break;
            }
            case LOAD_MY_MINTED_PRODUCTS:{
                draft.loadingMyMintedNftProductsFailure=null;
                draft.loadingMyMintedNftProducts=true;
                draft.loadingMyMintedNftProductsSuccess=false;
                draft.loadingMyMintedNftProductsData=null;
                break;
            }
            case LOAD_MY_MINTED_PRODUCTS_SUCCESS:{
                draft.loadingMyMintedNftProducts=false;
                draft.loadingMyMintedNftProductsSuccess=true;
                draft.loadingMyMintedNftProductsData=action.data;
                break;
            }
            case LOAD_MY_MINTED_PRODUCTS_FAILURE:{
                draft.loadingMyMintedNftProducts=false;
                draft.loadingMyMintedNftProductsFailure=action.data;
                break;
            }
            case INIT_LOAD_MY_MINTED_PRODUCTS_STATE:{
                draft.loadingMyMintedNftProducts=false;
                draft.loadingMyMintedNftProductsSuccess=false;
                draft.loadingMyMintedNftProductsFailure=null;
                draft.loadingMyMintedNftProductsData=null;
                break;
            }
            case LOADING_MY_NFT_PRODUCTS:{
                draft.loadingMyNftProducts=true;
                draft.loadingMyNftProductsSuccess=false;
                draft.loadingMyNftProductsData=null;
                draft.loadingMyNftProductsFailure=null;
                //draft.loadingMyNftProductsAPIType=action.apiType;
                break;
            }
            case LOAD_MY_NFT_PRODUCTS_SUCCESS:{
                draft.loadingMyNftProducts=false;
                draft.loadingMyNftProductsSuccess=true;
                draft.loadingMyNftProductsData=action.data.myMintedResp;
                break;
            }
            case LOAD_MY_NFT_PRODUCTS_FAILURE:{
                draft.loadingMyNftProducts=false;
                draft.loadingMyNftProductsFailure=action.data;
                break;
            }
            case INIT_LOAD_MY_NFT_PRODUCTS_STATE:{
                draft.loadingMyNftProducts=false;
                draft.loadingMyNftProductsSuccess=false;
                draft.loadingMyNftProductsData=null;
                draft.loadingMyNftProductsFailure=null;
                draft.loadingMyNftProductsAPIType=null;
                break;
            }
            case LOADING_NFT_PRODUCTS:{
                draft.loadingNftProducts=true;
                draft.loadingNftProductsSuccess=false;
                draft.loadingNftProductsData=null;
                draft.loadingNftProductsFailure=null;
                break;
            }
            case LOAD_NFT_PRODUCTS_SUCCESS:{
                draft.loadingNftProducts=false;
                draft.loadingNftProductsSuccess=true;
                draft.loadingNftProductsData=action.data;
                break;
            }
            case LOAD_NFT_PRODUCTS_FAILURE:{
                draft.loadingNftProducts=false;
                draft.loadingNftProductsFailure=action.data;
                break;
            }
            case INIT_LOAD_NFT_PRODUCTS_STATE:{
                draft.loadingNftProducts=false;
                draft.loadingNftProductsSuccess=false;
                draft.loadingNftProductsData=null;
                draft.loadingNftProductsFailure=null;
                break;
            }
            case INIT_LOAD_CHANGE_PRICE_INFO_STATE:{
                draft.loadingChangePriceInfo=false;
                draft.loadingChangePriceInfoSuccess=false;
                draft.loadingChangePriceInfoData=null;
                draft.loadingChangePriceInfoDataId=null;
                draft.loadingChangePriceInfoFailure=null;
                draft.loadingChangePriceInfoType=null;
                break;
            }
            case LOADING_CHANGE_PRICE_INFO:{
                draft.loadingChangePriceInfo=true;
                draft.loadingChangePriceInfoSuccess=false;
                draft.loadingChangePriceInfoData=null;
                draft.loadingChangePriceInfoDataId=null;
                draft.loadingChangePriceInfoFailure=null;
                draft.loadingChangePriceInfoType=action.send_type;
                break;
            }
            case LOAD_CHANGE_PRICE_INFO_SUCCESS:{
                draft.loadingChangePriceInfo=false;
                draft.loadingChangePriceInfoSuccess=true;
                draft.loadingChangePriceInfoData=action.data;
                draft.loadingChangePriceInfoDataId=action.id;
                break;
            }
            case LOAD_CHANGE_PRICE_INFO_FAILURE:{
                draft.loadingChangePriceInfo=false;
                draft.loadingChangePriceInfoFailure=action.data;
                break;
            }
            
            case INIT_MINTING_STATE:{
                draft.loadingMinting=false;
                draft.mintingData=null;
                draft.mintingSuccess=false;
                draft.mintingFailure=null;
                break;
            }
            case LOADING_MINTING:{
                draft.loadingMinting=true;
                draft.mintingData=null;
                draft.mintingSuccess=false;
                draft.mintingFailure=null;
                break;
            }
            case LOAD_MINTING_SUCCESS:{
                draft.loadingMinting=false;
                draft.mintingData=action.data;
                draft.mintingSuccess=true;
                break;
            }
            case LOAD_MINTING_FAILURE:{
                draft.loadingMinting = false;
                draft.mintingFailure = action.data;
                break;
            }
            default:break;
        }
    })
}

export default reducer;
