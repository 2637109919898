import React from 'react'
//import Footer from '../Footer';
import Header from '../Header';

import SponsoredNFTs from './SponsoredNFTs';
import TopAuthors from './TopAuthors';
import FeaturedNFTs from './FeaturedNFTs';
//import HowItWorks from '../HowItWorks';

import './style.css';
import Section3 from './Section3';
import Section1 from './Section1';
import Section4 from './Section4';
import Slider from './Slide/Slider';
import Carou from './Carou';
import Slider2 from './Slide/Slider2';
import Section2 from './Section2';
import Section5 from './Section5';

import { Collabo, Footer, Hero, HowItWorks, Nav, SectionLend, SectionWorks } from "./components"




function Home(){
  
    return (
        <>
            {/* previous design <Header />

            <Section1 />

            <Section2 />

            <Section3 />

            <HowItWorks />

          <Footer />*/} 

            <Nav />
            <Hero />
            <SectionWorks />
            <SectionLend />
            <HowItWorks />
            <Collabo />
            <Footer />



        

        </>
    )
}

export default Home;

