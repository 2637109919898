
import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';
import { HOMEPAGE, USER_DASHBOARD } from '../../constants/applicationUrls';
import { useAuth } from '../../contexts/AuthContext';

function NotFound(){
    const { currentUser } = useAuth();
  
    return (
        <>
            <Header />

            <div className="error-area ptb-100">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="error-content">
                            <h1>4 <span>0</span> 4</h1>
                            <h3>Oops! Page Not Found</h3>
                            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                            {currentUser ? (
                                <Link to={USER_DASHBOARD} className="default-btn">
                                    Return To Dashboard
                                </Link>
                            ) : (
                                <Link to={HOMEPAGE} className="default-btn">
                                    Return To Home Page
                                </Link>
                            )}
                            
                            
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default NotFound;

