import React, { useEffect, useRef } from 'react';
import first from '../../assets/top2/first.png';
import second from '../../assets/top2/second.png';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import styled,{keyframes} from 'styled-components';
import bg1 from '../../assets/top2/bg1_2.png';
import bg2 from '../../assets/top2/bg2_2.png';



const bannerTextAnimation = keyframes`
    from{
        color:#fff;
    }
    to{
        color:#000;
    }
`;

const BannerContainer = styled.div`
    width:100%;height: 544px;
    background-image:url(${bg1});
    background-repeat:no-repeat;
    background-size:cover;
    @media screen and (max-width:998px){
        height:538px;
    }
    @media screen and (max-width:1070px){
        height:333px;
    }
    @media screen and (max-width:578px){
        height: 233px;
    }
`;

const BannerWrapper = styled.div`
    width:100%;
    height:100%;
    position:relative;
`;

const BannerRow = styled.div`
    width:100%;
    height:620px;
    margin:0 auto;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    position:absolute;
    top:50%;
    left:50%;
    transform:translateY(-50%) translateX(-60%);
    
    .text{
        text-align:center;
        margin:0;
        font-weight:bold;
    }
    .main-title-text{
        width:100%;height:83px;
        line-height:83px;
        font-weight:500;
        font-size:66px;
        color:#fff;
        font-weight:bold;
    }
    .sub-title-text{
        width: 100%;
        height: 56px;
        line-height: 56px;
        font-size: 51.5px;
    }
    .service-explain-text{
        width:100%;
        height:36px;
        line-height:36px;
        font-size:24px;
        color:#fff;
    }
    @media screen and (max-width:1070px){
        width: 620px;
        height: 370px;
        .text{
            text-align:center;
            margin:0;
        }
        .main-title-text{
            width: 100%;
            height: 51.5px;
            line-height: 51.5px;
            font-size: var(--tablet-page-title);
            .main-title-non-heightlight{
                font-weight: bold;
            }
        }
        .sub-title-text{
            width: 100%;
            height: 38px;
            line-height: 38px;
            font-size: var(--tablet-subtitle);
        }
        .service-explain-text{
            width:100%;
            height:18px;
            line-height:18px;
            font-size:var(--tablet-service-explain-text);
        }
    }
    @media screen and (max-width:578px){
        width: 100%;
        height: 100%;
        .text{
            text-align:center;
            margin:0;
        }
        .main-title-text{
            height: 38px;
            line-height: 38px;
            font-size: var(--mobile-page-title);
        }
        .sub-title-text{
            height: 28px;
            line-height: 28px;
            font-size: var(--mobile-subtitle);
        }
        .service-explain-text{
            width:100%;
            height:18px;
            line-height:18px;
            font-size:var(--mobile-service-explain-text);
        }
    }
`;

export default function Section1() {
    const { currentUser, logout } = useAuth();

    return (

        <BannerContainer>
            <BannerWrapper>
                
                <BannerRow >
                    <h1 className="text main-title-text">
                        <span className="main-title-non-heightlight">
                            Create and Make < br /> 
                            &nbsp;your dreams with &nbsp;
                        </span> 
                        <span style={{ color: 'red' }}>
                            AI
                        </span>
                    </h1>
                    <h2 className="text sub-title-text"  style={{ color: 'white' }}>
                        <span  style={{ color: 'red' }}>Co</span>
                        <span className="white" style={{ color: 'white' }}>llabo Ga</span>
                        <span style={{ color: 'red' }}>llery</span>
                    </h2> 
                    
                    <h2 className="text service-explain-text" >AI와 함께하는 갤러리</h2>


                </BannerRow>
            </BannerWrapper>
        </BannerContainer>

    )

}