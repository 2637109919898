import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from 'react-query';

import Header from '../../Header';
import Footer from '../../Footer';
import WalletTransfer from './Transfer';
import WalletTransactions from './Transactions';

import ethLogo from '../../../assets/images/crypto-logo/eth.svg';
import maticLogo from '../../../assets/images/crypto-logo/matic.svg';
import celoLogo from '../../../assets/images/crypto-logo/celo.svg';
import bnbLogo from '../../../assets/images/crypto-logo/bnb.svg';

import { AVAILABLE_BLOCKCHAINS } from '../../../constants/application';
import { USER_WALLET } from '../../../constants/applicationUrls';
import { getUserWallets } from '../../../api/wallet';

import './style.css';

function WalletDetails({ currentUser }){

    const { wallet } = useParams();
    const routeHistory = useHistory();
    const userId = currentUser.uid;
    let userWallets = [];
    let currentWallet = null;
    const walletOptions = ['transfer', 'transactions'];
    const [currentWalletOption, setWalletOption] = useState(walletOptions[0]);

    const { isLoading, error, data } = useQuery(['getUserWalletDetails', userId], () => getUserWallets(userId));

    if(!error && !isLoading)
    {
        const tempWallet = data[0];

        userWallets = [
            {
                name: 'eth',
                address: tempWallet.eth,
                balance: tempWallet.beth,
                logo: ethLogo
            },
            {
                name: 'matic',
                address: tempWallet.matic,
                balance: tempWallet.bmatic,
                logo: maticLogo
            },
            {
                name: 'celo',
                address: tempWallet.celo,
                balance: tempWallet.bcelo,
                logo: celoLogo
            },
            {
                name: 'bnb',
                address: tempWallet.bnb,
                balance: tempWallet.bbnb,
                logo: bnbLogo
            }
        ];

        currentWallet = userWallets.find(w => w.name.toLowerCase() === wallet.toLowerCase());
    }

    const changeWalletOption = (event, wallOpt) => {
        event.preventDefault();
        setWalletOption(wallOpt);
    }

    if(!AVAILABLE_BLOCKCHAINS.find(wal => wal.value.toLowerCase() === wallet.toLowerCase()))
    {
        routeHistory.push(USER_WALLET);
    }
  
    return (
        <>
            <Header />
                <div className="container pt-50">

                    {isLoading && (<h5 className="text-center text-warning pt-100 pb-100">Please wait while we load your {wallet.toUpperCase()} wallet</h5>)}

                    {currentWallet && (<div className="row">

                        <div className="col-lg-12">
                            <div className="activity-card">
                                <div className="activity-img text-center">
                                    <img src={currentWallet.logo} style={{maxHeight: '100px'}} alt="Images" />
                                </div>
                                <div className="activity-content">
                                    <p><i className="ri-wallet-3-fill"></i>{currentWallet.name.toUpperCase()}</p>
                                    {/* <span><i className="ri-time-line"></i> 11:49 AM</span> */}
                                </div>
                                <div className="activity-into">
                                    <h3><b>Balance:</b> {currentWallet.balance}</h3>
                                    <h6><b>Address:</b> {currentWallet.address}</h6>
                                </div>
                                <div className="activity-btn">
                                    {/* <i className="ri-delete-bin-4-line"></i> */}
                                </div>
                            </div>
                        </div>

                    </div>)}

                    {currentWallet && (<div className="tab featured-tab-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-4">
                                <div className="section-title">
                                    <h4>Wallet Options</h4>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8">
                                {walletOptions && (<ul className="tabs active">
                                    {walletOptions.slice(0, walletOptions.length < 4 ? walletOptions.length : 4 ).map((option, index) => {
                                        return (<li key={`fnft-cat-${index}`} className={`${walletOptions.indexOf(currentWalletOption) == index ? 'current' : ''}`} >
                                            <a href="" onClick={(e) => changeWalletOption(e, option.toLowerCase())}>{option.charAt(0).toUpperCase() + option.slice(1)}</a>
                                        </li>)
                                    })}
                                </ul>)}
                            </div>

                        </div>
                    </div>)}

                    {currentWallet && currentWalletOption === 'transfer' && <WalletTransfer currentUser={currentUser} currentWallet={currentWallet} />}

                    {currentWallet && currentWalletOption === 'transactions' && <WalletTransactions currentUser={currentUser} currentWallet={currentWallet} />}

                </div>
            <Footer />
        </>
    )
}

export default WalletDetails;

