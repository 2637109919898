import { faiverAPI } from '../config/axios';

export const createUserWallets = async (userId = null, userEmail = null) => {
    const params = {}

    if(userId != null)
    {
        params.UID = userId.toString();
    }

    if(userEmail != null)
    {
        params.Email = userEmail.toString();
    }

    const { data } = await faiverAPI.post(`/newuser`, params);

    return data;
}

export const getUserWallets = async (userId = null) => {
    const params = {}

    if(userId != null)
    {
        params.userId = userId.toString();
    }

    const { data } = await faiverAPI.get(`/getwallets/${userId}`, params);

    return data;
}

export const sendCryptoFromWallet = async (userId, chain, senderAddress, receiverAddress, transferAmount) => {
    const params = {}

    if(userId != null)
    {
        params.uid = userId.toString();
    }

    if(chain != null)
    {
        params.chain = chain.toString().toUpperCase();
    }

    if(senderAddress != null)
    {
        params.from = senderAddress.toString();
    }

    if(receiverAddress != null)
    {
        params.to = receiverAddress.toString();
    }

    if(transferAmount != null)
    {
        params.amount = transferAmount.toString();
    }

    const { data } = await faiverAPI.post(`/sendcoin`, params);

    return data;
}