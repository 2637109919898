
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import Web3Modal from "web3modal";
import { ethers } from 'ethers';
import {useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { 
    nftaddress, nftmarketaddress 
  } from '../../config';
  
  import NFT from '../../artifacts/contracts/NFT.sol/NFT.json';
  import Market from '../../artifacts/contracts/Market.sol/Market.json';

import { getFeaturedNFTs } from '../../api/nft';
import { FEATURED_NFT_CATEGORIES_ON_HOME, MAX_FEATURED_ON_HOME } from '../../constants/application';
import { USER_NFT } from '../../constants/applicationUrls';
import Loader from '../Loader';


function FeaturedNFTs(){
    let featuredNFTs = [];
    const limit = MAX_FEATURED_ON_HOME;

    const [currentCategory, setCategory] = useState(null);

    const [nfts, setNfts] = useState([]);
    const [loadingState, setLoadingState]=useState(false);
    const history = useHistory();

    const { isLoading, error, data } = useQuery(['getFeaturedNFTsHome', limit, currentCategory], () => getFeaturedNFTs(limit, currentCategory));
    
    if(!error && !isLoading)
    {
        featuredNFTs = data.data;
    }
    
    const categories = FEATURED_NFT_CATEGORIES_ON_HOME;

    const changeCategory = (event, category) => {
        event.preventDefault();
        setCategory(category);
    }

    useEffect(() => {
        loadNFTs()
        //withD() // test withdraw 
      },[]) ;


    async function loadNFTs(){ 
    
        const provider = new ethers.providers.JsonRpcProvider("https://polygon-mumbai.infura.io/v3/faaee569843d4f89b03ea2feb433c094")
        const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
        const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, provider)
        const data = await marketContract.fetchMarketItems()
        
        const items = await Promise.all(data.map(async i => {
          const tokenUri = await tokenContract.tokenURI(i.tokenId)
          const meta = await axios.get(tokenUri)
          let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
          let item = {
            price,
            itemId: i.itemId.toNumber(),
            seller: i.seller,
            owner: i.owner,
            image: meta.data.image,
            title: meta.data.title,
            description: meta.data.description,
            chain:meta.data.chain,
            date:meta.data.date,
            contract:meta.data.contract,
          }
          return item
        }))
        setNfts(items)
        setLoadingState(true) 
       }

       async function withD() {
     
        const web3Modal = new Web3Modal()
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()
        const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
        const p =0.000001
        const price = ethers.utils.parseUnits(p.toString(), 'ether')
        const transaction = await contract.withDrawEth("0x324a3D5951F94b40C566B180066D6B2ab7BE8D54",price)
        await transaction.wait().then(()=>{
            loadNFTs();
            toast.success("withdraw successfully",{position:toast.POSITION.BOTTOM_RIGHT})
            history.push(USER_NFT);

        }).catch((err) => {
            toast.error(`Failed to withdraw ${err.message}`,{position:toast.POSITION.BOTTOM_RIGHT})
        })
    }  

    async function buyNft(nft) {
        try {
            const web3Modal = new Web3Modal({
                cacheProvider: true,
              })
              
            const connection = await web3Modal.connect()
            const provider = new ethers.providers.Web3Provider(connection)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)

            const price = ethers.utils.parseUnits(nft.price.toString(), 'ether')
           
                const transaction = await contract.buyNFT(nftaddress, nft.itemId, {
                    value: price
                    })
                    await transaction.wait().then(()=>{
                        loadNFTs();
                        toast.success("Successfully purchased",{position:toast.POSITION.BOTTOM_RIGHT})
                        history.push(USER_NFT);
        
                    }).catch((error) => {
                        toast.error(`Failed to buy ${error.message}`,{position:toast.POSITION.BOTTOM_RIGHT})
                    })
                
            } catch (error) {
                const res= error.code 
                if (res==-32603){

                    toast.error(`You don't have enough funds to proceed`,{position:toast.POSITION.BOTTOM_RIGHT})

                } else if (res==4001) {
                    toast.error(`request rejected`,{position:toast.POSITION.BOTTOM_RIGHT})

                }
                else if (res==4901) {
                    toast.error(`you are not connected to the wallet`,{position:toast.POSITION.BOTTOM_RIGHT})

                } else if (res==4900) {
                    toast.error(`you are disconnected to the blockchain`,{position:toast.POSITION.BOTTOM_RIGHT})

                }else if (res==4901) {
                    toast.error(`you are disconnected to the blockchain`,{position:toast.POSITION.BOTTOM_RIGHT})

                }else if (res==-32000) {
                    toast.error(`You are using the wrong network ${error}`,{position:toast.POSITION.BOTTOM_RIGHT})

                } 
             
            }
           
          
    }  

    return (
        <>
      

            <div className="featured-nfts pt-100 pb-70">
                <div className="container">
                    <div className="tab featured-tab-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-4">
                                <div className="section-title">
                                    <h2>Featured NFTs</h2>
                                </div>
                            </div>
                            {/*<div className="col-lg-6 col-md-8">
                                {categories && (<ul className="tabs active">
                                    <li className={`${categories.indexOf(currentCategory) == -1 ? 'current' : ''}`} ><a  href="" onClick={(e) => changeCategory(e, null)}>All</a></li>
                                    {categories.slice(0, categories.length < 4 ? categories.length : 4 ).map((category, index) => {
                                        return (<li key={`fnft-cat-${index}`} className={`${categories.indexOf(currentCategory) == index ? 'current' : ''}`} ><a href="" onClick={(e) => changeCategory(e, category.toLowerCase())}>{category.charAt(0).toUpperCase() + category.slice(1)}</a></li>)
                                    })}
                                </ul>)} 
                            </div>*/}

                        </div>

                        {/* 

                        {isLoading && (<Skeleton />)}
                        {error && (<Skeleton />)}

                        {featuredNFTs && (<div className="row align-items-center pt-50">

                            {featuredNFTs.map((NFT, index) => {
                                return (
                                <div className="col-lg-3 col-md-6" key={`fe-${NFT.tokenid}`}>
                                    <NFTCard data={NFT} />
                                </div>)
                            })}

                        </div>)}
                        
                        */}

                       
                        <div className="row pt-20">

                          {!loadingState&&

                            <div className="container d-flex justify-content-center mx-auto" >
                                                    
                                <div className="">
                                <Loader size={10} />

                                </div>   

                            </div> 
                            }
                     
                            {
                            nfts.map((nft, i) => (
                            <div className="col-lg-3 col-md-4 col-6" key={i}  onClick={() => buyNft(nft)}>
                                
                                    <div className="nft-option-card">
                                        <img src={nft.image} alt="Create Buy NFT" />
                                        <h3><b >{nft.title}</b></h3>
                                        <p>Price: {nft.price} {nft.chain}</p>
                                        <p>Chain: {nft.chain} </p>
                                        <p>Minter: {nft.seller.slice(0,7)+'...'+nft.seller.slice(36,42)} </p> 
                                        <p>date: {nft.date} </p>
                                        <p>Smart contract: <a href={`https://mumbai.polygonscan.com/address/${nftaddress}`} target="_blank"> {nft.contract.slice(0,7)+'...'+nft.contract.slice(20,25)} </a></p>
                                      
                                        <div className="top-btn- text-uppercase btn-dark btn"  onClick={() => buyNft(nft)}>Buy</div>
                                    </div>
                    
                        
                            </div>

                            ))}
                   
                   </div>




                    </div>
                </div>
            </div>

        </>
    )
}

export default FeaturedNFTs;
