import React,{useState,useCallback,useRef, useEffect,memo} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
    LOAD_USER_LIST_REQUEST,
    LOAD_USER_LIST_SUCCESS,
    LOAD_USER_LIST_INIT,
} from '../../reducers/users';
import SingleAuthorComponent from './SingleAuthorComponent';

const StyledAuthorListContainer = styled.div`
    width:100%;
    height:auto;
    overflow:hidden;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 5.5px;
    margin-top: 50px;
`;

const StyledAuthorListUl = styled.ul`
    display:flex;
    flex-direction:row;
    justify-content: ${props=>props.dataLength<=4?`flex-start`:`space-evenly`};
    align-items:center;
    flex-wrap: wrap;
    width:100%;
    height:auto;
    margin:0;
    padding 0 2.5px;
    user-select: none;
    @media screen and (max-width:768px){
        justify-content:center;
    }
`;

const StyledShowMoreButtonWrap = styled.div`
    width : 100%;
    height : 105.5px;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
        display:block;
        width:60%;
        height:50%;
    }
`;

const StyledShowMoreButton = styled.button`
    display:block;
    width:200px;
    height:48px;
    border:1px solid #313131;
    border-radius: 9.5px;
    color:white;
    font-size:19.5px;
    padding:0;
    background: #fff;
    color:#313131;
    &:active{
        background:#313131;
        color:#fff;
    }
    &:hover{
        background:#313131;
        color:#fff;
    }
    @media screen and (max-width:855px){
        width: 150px;
        height: 28px;
        font-size:15.5px;
    }
`

const AuthorList =memo( ()=>{
    const ulRef = useRef(null);
    const pageRef = useRef(1);
    const maxrecords= 8;
    const [moreData,setMoreData] = useState(false);
    const [authorList,setAuthorList] = useState([]);
    const dispatch = useDispatch();
    /* const dummyUserData = {
        authorid: "xDn2CcA0rVWno1LCbUaw73zOSfv1",
        userid: "xDn2CcA0rVWno1LCbUaw73zOSfv1",
        userthumbnail: "https://api.collery.io/userprofile/1652771578603-Eu01EwbUUAMF5VA.jpg",
        firstname: "jack",
        lastname: "davidyyy"
    }; */
    const {
        loadUserListLoading,
        loadUserListSuccess,
        loadUserListFailure,
        loadUserListData,
    } = useSelector(state=>state.users);

    useEffect(()=>{
        const offset = (pageRef.current-1)*maxrecords;
        dispatch({type:LOAD_USER_LIST_REQUEST,data:{offset,limit:maxrecords}});
    },[])

    useEffect(()=>{
        console.log(loadUserListData);
        if(!loadUserListLoading&&loadUserListSuccess){
            if(loadUserListData){
                authorList.length===0?setAuthorList([...loadUserListData]):setAuthorList(prev=>[...prev,...loadUserListData]);
                pageRef.current=pageRef.current+1;
                const flag = loadUserListData.length%maxrecords!==0;
                setMoreData(!flag);
            }
            dispatch({type:LOAD_USER_LIST_INIT});
        }else if(loadUserListFailure){
            dispatch({type:LOAD_USER_LIST_INIT});
        }
    },[loadUserListLoading,loadUserListSuccess,loadUserListData,loadUserListFailure,pageRef.current])

    const onClickLoadMoreAuthors = useCallback(()=>{
        const offset = (pageRef.current-1)*maxrecords;
        dispatch({type:LOAD_USER_LIST_REQUEST,data:{offset,limit:maxrecords}})
    },[pageRef.current])
    return (
        <StyledAuthorListContainer>
            <StyledAuthorListUl dataLength={authorList.length} ref={ulRef}>
                {authorList&&authorList.map((v,idx)=>(
                    <SingleAuthorComponent key={`${v.userid}`} personalInfo={v}/>
                ))}
                {/* <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>
                <SingleAuthorComponent personalInfo={dummyUserData}/>*/}
                
            </StyledAuthorListUl>
            {moreData&&(
                <StyledShowMoreButtonWrap>
                    <StyledShowMoreButton onClick={onClickLoadMoreAuthors}>
                        작가 더 보기
                    </StyledShowMoreButton>
                </StyledShowMoreButtonWrap>
            )}
        </StyledAuthorListContainer>
    )
})

export default AuthorList;