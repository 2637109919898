import React from 'react';
import person from '../assets/newhomepage/7.png';
import house from '../assets/newhomepage/9.png';
import nft from '../assets/newhomepage/12.png';
import printer from '../assets/newhomepage/14.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();
export default function () {
  return (
    <div className="mb-10 sm:mt-20"> {/**  sm:flex sm:flex-col sm:container sm:items-center sm:justify-center */}
        <div className="flex items-center justify-center font-bold text-gray-600 mt-5 sm:text-4xl sm:mt-10">
            <p>HOW IT WORKS</p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-0 mt-8 ml-2 mr-2 sm:mt-14 sm:mr-[100px] sm:ml-[100px] ">

            <div className="p-4 border-gray-800 h-[250px]" style={{border: "0.1px solid #EDEDED"}}>

                <p className="font-bold text-sm sm:text-xl md:text-2xl mt-2 text-gray-600 ">계정만들기</p>
                <p className="text-gray-500 text-[10px]">SETUP YOUR ACCOUNT</p>
                <p className="mt-4 text-[10px]  mr-10 w-auto h-16 no-scrollbar overflow-y-auto sm:text-[10px] md:text-[14px] leading-normal">SIGNUP 버튼을 클릭해서 계정을 만들어보세요. 더 많은 혜택이 기다리고 있습니다.</p>
                <p className="mt-6 mb-4"><img src={person} className="h-[40px] w-[40px]"/></p>

            </div>

            <div className="p-4 border-gray-800 h-[250px]" style={{border: "0.1px solid #EDEDED"}}>

                <p className="font-bold text-sm mt-2 sm:text-xl md:text-2xl  text-gray-600">전시공간 신청하기</p>
                <p className="text-gray-500 text-[10px]">CREATE YOUR COLLECTION</p>
                <p className="mt-4 text-[10px] w-auto mr-10 h-16 no-scrollbar overflow-y-auto sm:text-[10px] md:text-[14px] leading-normal">작품들을 전시할 수 있는 가상공간을 만들어 드립니다. 개인, 단체, 동호회 등 활동을 지원합니다.</p>
                <p className="mt-6 mb-4"><img src={house} className="h-[40px] w-[40px]"/></p>

            </div>

            <div className="p-4 border-gray-800 h-[250px]" style={{border: "0.1px solid #EDEDED"}}>

                <p className="font-bold text-sm mt-2 sm:text-xl md:text-2xl text-gray-600">NFT 발행하기</p>
                <p className="text-gray-500 text-[10px]">ADD YOUR NFTS</p>
                <p className="mt-4 text-[10px] w-auto mr-10 h-16 no-scrollbar overflow-y-auto sm:text-[10px] md:text-[14px] leading-normal">작품을 NFT로 발행해드립니다. 이미지, 동영상, 오디오 등의 파일을 작가명, 제목, 작품설명 등 정보를 추가해서 NFT를 발행합니다.</p>
                <p className="mt-6 mb-4"><img src={nft} className="h-[40px] w-[40px]"/></p>

            </div>

            <div className="p-4 border-gray-800 h-[250px]" style={{border: "0.1px solid #EDEDED"}}>

                <p className="font-bold text-sm sm:text-xl md:text-2xl mt-2 text-gray-600">인증서 발급하기</p>
                <p className="text-gray-500 text-[10px]">CERTIFY YOUR WORKS</p>
                <p className="mt-4 text-[10px] w-auto mr-10 h-16 no-scrollbar overflow-y-auto sm:text-[10px] md:text-[14px] leading-normal">작품의 저작권을 보호하기 위한 방법으로 사설 저작권 인증서를 발급해드립니다. (* 인증서가 저작권을 법적으로 인정받을 수는 없습니다.)</p>
                <p className="mt-6 mb-4"><img src={printer} className="h-[40px] w-[40px]"/></p>

            </div>

        </div>
    </div>
  )
}
