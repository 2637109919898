import firebase from "firebase/app"
import "firebase/auth"


const app = firebase.initializeApp({
/*  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
 // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
  apiKey: "AIzaSyAkufIZnqpXsvcdwR3W13ckrC98cGXzvcQ",
  authDomain: "faiver-auth-dev.firebaseapp.com",
  projectId: "faiver-auth-dev",
  storageBucket: "faiver-auth-dev.appspot.com",
  messagingSenderId: "82009369157",
  appId: "1:82009369157:web:8c32509791bfa22f45512c"*/

  // production
  apiKey: "AIzaSyBqYSc_NCdz2w0Ns4axXHrrnvTh0KhcVmY",
  authDomain: "nestyle-4954b.firebaseapp.com",
  projectId: "nestyle-4954b",
  storageBucket: "nestyle-4954b.appspot.com",
  messagingSenderId: "107029973204",
  appId: "1:107029973204:web:aad32f3525dd8a2ce3d341",
  measurementId: "G-LEFQX1EK7H"


  
})

export const auth = app.auth()
export default app
