
import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

import { useAuth } from '../../contexts/AuthContext';

import comingSoonPic from '../../assets/images/coming-soon.jpg';
import { HOMEPAGE, USER_DASHBOARD } from '../../constants/applicationUrls';

function ComingSoon(){
    const { currentUser } = useAuth();
  
    return (
        <>
            <Header />

            <div className="coming-soon-area ptb-100">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="coming-soon-content">
                                <img src={comingSoonPic} style={{ height: '250px' }} />
                                <h1>Coming Soon</h1>
                                <p>We are working are hard to bring this feature to you, that is why we are testing it thoroughly and will be available soon.</p>
                                <div className="pt-50">
                                    {currentUser ? (
                                        <Link to={USER_DASHBOARD} className="default-btn">
                                            Return To Dashboard
                                        </Link>
                                    ) : (
                                        <Link to={HOMEPAGE} className="default-btn">
                                            Return To Home Page
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ComingSoon;

