import React,{memo,useContext} from 'react';
import {RentNFTDataContext} from './index';
import SingleNFTProductComponent from './SingleNFTProductComponent';

const RentedNFTDataComponent = memo(({userId})=>{
    const {RentedNFTDataList} = useContext(RentNFTDataContext);
    return (
        <>
        {
            RentedNFTDataList.length>0&&
            RentedNFTDataList.map((nft,i)=>(
                <SingleNFTProductComponent userId={userId} key={nft.id}  value={{...nft,type:'rented'}}/>
            ))
        }
        </>
    )
});

export default RentedNFTDataComponent;