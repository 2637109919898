
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import circleShapeBlack from '../../assets/images/shapes/circle1.png';
import circleShapeGrey from '../../assets/images/shapes/circle2.png';
import bgShape from '../../assets/images/shapes/bg-shape.png';
import userProfilePic from '../../assets/images/userProfilePic.svg';
import first from '../../assets/top2/first.png';
import second from '../../assets/top2/second.png';


import { MAX_SPONSORED_ON_HOME, SPONSORED_NFT_CATEGORIES_ON_HOME } from '../../constants/application';
import { MARKETPLACE, USER_LOGIN } from '../../constants/applicationUrls';
import { getSponsoredNFTs } from '../../api/nft';

import './style.css';


function HotCollections(){

    const [currentCategory, setCategory] = useState(SPONSORED_NFT_CATEGORIES_ON_HOME[Math.floor(Math.random() * SPONSORED_NFT_CATEGORIES_ON_HOME.length)]);
    const limit = MAX_SPONSORED_ON_HOME;

    const { isLoading, error, data } = useQuery(['getSponsoredNFTsHome', limit, currentCategory], () => getSponsoredNFTs(limit, currentCategory));    

    if(error || isLoading)
    {
        return <></>;
    }

    const sponsoredNFTs = data.data;
  
    return (
        <>

            <div className="banner-area bg-white" >
                <div className="container-fluid" style={{marginTop:'-10em'}}>
                    <div className="row align-items-center">
                        
                        <div className="col-lg-6">
                            <div className="banner-content">
                               
                                <h1 className='text-dark'>Create and Make your idea</h1>
                                <span className='text-dark'>We always ready for  dreams.</span>
                                <p className='text-dark'> 나만의  아이디어로 작품들을 만들어보세요. <br /> 여러분의 꿈을 이룰수 있도록 후원합니다! </p>
                                <div className="banner-btn">
                                    <Link to={MARKETPLACE} className="default-btn border-radius-5" style={{backgroundColor:'green'}}>작품등록하기</Link>
                                    <Link to={USER_LOGIN} className="default-btn two border-radius-5" style={{backgroundColor:'green'}}>갤러리 보러가기</Link>
                                </div>
                            </div>
                        </div>

                        

                        <div className="col-lg-6">
                             <div className="banner-card-area">
                                <div className="row">
                                    
                                    <div className="col-lg-2 col-sm-0">
                                        <div className="banner-card">
                                            <div className="banner-card-img">
                                               
                                            </div>
                                          
                                        </div>
                                    </div>

                                     <div className="col-lg-10 col-sm-10">
                                        <div className="banner-card banner-card-mt">
                                            <div className="banner-card-img">
                                                <Link to={MARKETPLACE} >

                                                    <img src={second} alt="Images" style={{height:'80vh', width:'50em'}}/>
                                                  
                                                </Link>
                                            </div>
                                           
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
              
            </div>

        </>
    )
}

export default HotCollections;
