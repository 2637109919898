import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Slider } from 'antd';
import { CaretRightOutlined, SoundOutlined, PauseOutlined, CloseOutlined } from "@ant-design/icons";

const MusicPlayerContainer = styled.div`
    height:65px;width:100%;
    z-index:1000;
    background:rgba(45,45,45,0.67);
`;

const MusicPlayerWrapper = styled.div`
    width:100%;height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
    .duration-bar-component{
        width:90%;
        height:25px;
        .audio-duration-wrap{
            margin:0;
            width:100%;
            height:100%;
            .ant-slider{
                margin:0;
                height:100%;
                display:flex;
                align-items:center;
                .ant-slider-handle{
                    top: calc(50% - 2.5px);
                }
            }
        }
    }
    .media-controller-component{
        width:90%;
        height:30px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        .play-controller-wrapper{
            width:auto;
            display:flex;
            flex-direction:row;
            .play-button{
                height:25.5px;width:25.5px;
                box-sizing:border-box;
                text-align:center;
                cursor:pointer;
                span{
                    color:#fff;
                    font-size:25.5px;
                    text-align:center;
                    &:hover{
                        color:#f14d5c;
                    }
                }
            }
            .audio-volumn-controller-wrap{
                width:auto;height:25px;
                margin:0;
                cursor:pointer;
                text-align:center;
                line-height:25px;
                color:#fff;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                .audio-volumn-controller-box{
                    width:90px;
                    height:25px;
                    margin-left: 5.5px;
                    .ant-slider{
                        margin:0;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        .ant-slider-handle{
                            top: calc(50% - 2.5px);
                            right: auto;
                        }
                    }
                }
                .sound-icon{
                    font-size:23.5px;
                    color:${props => props.mute ? '#fff' : '#f14d5d'};
                }
                .mute-sound-icon{
                    font-size:12.5px;
                    color:#fff;
                }
            }
        }
        
        
        .audio-duration-text{
            margin:0;
            width:30%;
            height:25px;
            text-align:right;
            line-height:25px;
            color:#fff;
        }
    }
    
`;


const MediaPlayerComponent = ({ media }) => {
    const [play, setPlay] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [mute, setMute] = useState(false);
    const [disableVolumnController, setDisableVolumnController] = useState(false);
    const [volume, setVolume] = useState(0.3);
    const [minute, setMinute] = useState(0);
    const [second, setSecond] = useState(0);
    const audioObject = useRef(new Audio(media));
    const volumnContorllerRef = useRef(null);
    const durationControllerRef = useRef(null);

    const mediaEndCallback = useCallback(() => {
        setPlay(false);
        audioObject.current.load();
    }, [play])

    const mediaCanPlay = useCallback(() => {
        if(audioObject.current){
            play&&audioObject.play();
        }
    }, [play,audioObject.current]);

    const mediaPlaying = useCallback(() => {
        setSecond(parseInt(audioObject.current.currentTime % 60));
        setMinute(parseInt(audioObject.current.currentTime / 60));
        if (durationControllerRef.current) {
            const ratio = parseFloat(audioObject.current.currentTime / audioObject.current.duration);
            isNaN(ratio) ? durationControllerRef.current.state.value = 0.0 : durationControllerRef.current.state.value = ratio * 100;
        }
    }, [durationControllerRef.current]);

    useEffect(() => {
        audioObject.current.autoplay = false;
        audioObject.current.currentTime = 0;
        audioObject.current.addEventListener('ended', mediaEndCallback)
        audioObject.current.addEventListener('canplay', mediaCanPlay);
        audioObject.current.addEventListener('timeupdate', mediaPlaying)
        audioObject.current.load();
        return () => {
            //audioObject.current.stop();
            audioObject.current.pause();
            audioObject.current.removeEventListener('ended', mediaEndCallback);
            audioObject.current.removeEventListener('canplay', mediaCanPlay);
            audioObject.current.removeEventListener('timeupdate', mediaPlaying);
            audioObject.current = null;
        }
    }, [])

    const onClickStartPlay = useCallback(() => {
        if (audioObject.current) {
            play ? audioObject.current.pause() : audioObject.current.play();
            setPlay(prev => !prev);
        }
    }, [play, audioObject.current]);

    const onClickSoundController = useCallback((evt) => {
        //evt.stopPropagation();
        if (!mute) {
            audioObject.current.volume = 0;
        } else {
            audioObject.current.volume = volume;
        }
        mute ? setDisableVolumnController(false) : setDisableVolumnController(true);
        setMute(prev => !prev);
    }, [mute])

    const onChangeVolume = useCallback((value) => {
        setVolume(value / 100);
    }, [])

    const onChangeCurrentPlayTime = useCallback((value) => {
        audioObject.current.currentTime = (value / 100) * audioObject.current.duration;
        audioObject.current.play();
        if(!play){
            setPlay(true);
        }
    }, [durationControllerRef.current,play])

    useEffect(() => {
        if (audioObject.current) {
            audioObject.current.volume = volume;
            if (volume === 0.0) {
                setMute(true);
            }
        }
    }, [volume, audioObject.current])

    const onMouseDownDurationComponent = useCallback((evt) => {
        audioObject.current.pause();
    }, [])

    /* const onMouseUpDurationComponent = useCallback((evt)=>{
        audioObject.current.play();
    },[]) */

    return (
        <MusicPlayerContainer>
            <MusicPlayerWrapper mute={mute}>
                <div className="duration-bar-component">
                    <div
                        onMouseDown={onMouseDownDurationComponent}
                        className="audio-duration-wrap">
                        <Slider onAfterChange={onChangeCurrentPlayTime} ref={durationControllerRef} step={0.1} defaultValue={0.0} />
                    </div>
                </div>
                <div className="media-controller-component">
                    <div className="play-controller-wrapper">
                        <div className="play-button" onClick={onClickStartPlay}>
                            {play ? <PauseOutlined /> : <CaretRightOutlined />}
                        </div>
                        <div className="audio-volumn-controller-wrap">
                            <SoundOutlined onClick={onClickSoundController} className="sound-icon" />
                            {mute && <CloseOutlined className="mute-sound-icon" />}
                            {
                                <div className="audio-volumn-controller-box">
                                    <Slider onAfterChange={onChangeVolume} ref={volumnContorllerRef} disabled={disableVolumnController} step={0.1} defaultValue={volume * 100} />
                                </div>
                            }

                        </div>
                    </div>
                    <div className="audio-duration-text">
                        <span><span>{minute / 10 < 1 ? `0${minute}` : minute}</span>:
                            <span>{second / 10 < 1 ? `0${second}` : second}</span></span>
                    </div>
                </div>


            </MusicPlayerWrapper>
        </MusicPlayerContainer>
    )
}

export default MediaPlayerComponent;