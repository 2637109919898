
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { HOMEPAGE } from '../../constants/applicationUrls';
import { useAuth } from '../../contexts/AuthContext';

import Footer from '../Footer';
import Header from '../Header';

import './style.css';
import styled from 'styled-components';
import {PlusOutlined} from '@ant-design/icons';
import {useDispatch,useSelector} from "react-redux";
import MessageComponent from '../MessageCommponent';
import LoadingComponent from '../LoadingComponent';
import {MODIFY_USER_PROFILE_IMG_REQUEST,
    MODIFY_USER_PROFILE_IMG_INIT,
    ADD_USER_REQUEST,
    ADD_USER_INIT,
    INIT_CERTIFY_SIGN_UP_USER
} from '../../reducers/users';
import {UserProfileModifyPage,SignupPage} from '../../constants/applicationMessage';
import Helmet from 'react-helmet';
import ColleryLogo3 from '../../assets/Collary_logo_03.jpg';
import UserProfilePiceture from '../../assets/images/userProfilePic.svg';

const LoginFormContainer = styled.div`
    width:100%;height:auto;
    min-height:${window.innerHeight-84-192}px;
    @media screen and (max-width:855px){
        min-height:${window.outerHeight}px;
    }
`;

const LogInFormWrappper = styled.div`
    width:75%;height:auto;
    margin: 0 auto;
    display:flex;
    align-items:center;
    @media screen and (max-width:550px){
        width:90.5%
    }
    @media screen and (max-width:550px){
        width:96.5%
    }
`;

const LogInFormComponent = styled.div`
    width:100%;height:100%;
    padding:50px 0 100px 0;
    form{
        height:100%;
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        
        .form_row{
            width:85%;
            height:100%;
            display:flex;
            flex-direction:column;
            justify-content:center;
            .header-wrap{
                width:100%;height:auto;
                margin: 12.5px auto 35.5px;
                h1{
                    height:auto;
                    font-size: var(--page-title);
                    font-weight:bold;
                    text-align:left;
                    margin:0;
                }
            }
            .form_inner_row{
                padding-top: 20px;
                padding-bottom: 20px;
                width:100%;
                height:auto;
                box-shadow: 0px 0px 2.5px #454545;
                border-radius: 4.5px;
                .link_to_new_passport{
                    width: 100%;
                    height: auto;
                    margin: 10px 0;
                }
                .form_component{
                    width:60%;
                    height:84px;
                    margin : 0 auto;
                    &.form_error_alert{
                        text-align:left;
                        height:32px;
                        line-height:24px;
                        font-size:var(--dashboard-page-info);
                        color:red;
                    }
                    .form-group{
                        margin-bottom:15.5px;
                        label{
                            font-weight:bold;
                            margin-bottom:10.5px;
                            font-size:var(--dashboard-page-title);
                        }
                        input{
                            font-size:var(--dashboard-page-info);
                        }
                    }
                }
                .form_btn_component{
                    width:60%;
                    height:34px;
                    margin:15.5px auto;
                    button{
                        font-size:var(--dashboard-page-button);
                        width:100%;
                        height:32px;
                        border:none;
                        border-radius:4.5px;
                        background-color:#ff4d2c;
                        color:#fff;
                        &:hover{
                            background-color:#000;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:855px){
        form{
            .form_row{
                .header-wrap{
                    h1{
                        font-size: var(--tablet-page-title);
                    }
                }
                .form_inner_row{
                    .form_component{
                        width:84.5%;
                        &.form_error_alert{
                            height:auto;
                            font-size:var(--tablet-dashboard-page-info);
                        }
                        .form-group{
                            label{
                                font-size:var(--tablet-dashboard-page-title);
                            }
                            input{
                                font-size:var(--tablet-dashboard-page-info);
                            }
                        }
                    }
                    .form_btn_component{
                        button{
                            font-size:var(--tablet-dashboard-page-button);
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:578px){
        form{
            .form_row{
                .header-wrap{
                    h1{
                        font-size: var(--mobile-page-title);
                    }
                }
                .form_inner_row{
                    .form_component{
                        width:84.5%;
                        &.form_error_alert{
                            height:auto;
                            font-size:var(--mobile-dashboard-page-info);
                        }
                        .form-group{
                            label{
                                font-size:var(--mobile-dashboard-page-title);
                            }
                            input{
                                font-size:var(--mobile-dashboard-page-info);
                            }
                        }
                    }
                    .form_btn_component{
                        button{
                            font-size:var(--mobile-dashboard-page-button);
                        }
                    }
                }
            }
        }
    }
`;

const ProfileImageContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:150px;
`;

const ProfileImageWrap = styled.div`
    width: 150px; height:150px;
    position:relative;
    
    input{display:none;}
    .upload-profile-img-btn{
        display: flex;
        justify-content: center;
        align-items:center;
        width:100%;height:100%;
        cursor:pointer;
        box-shadow:0 0 3.5px #454545;
        span{
            display:block;
            width:32.5px;height:32.5px;
            font-size:32.5px;
        }
        .profile_image_frmae{
            width:100%;height:100%;
            img{
                display:block;
                width:100%;height:100%;
                object-fit:cover;
            }
        }
    }
`;

function Signup(){
    const { currentUser, signup } = useAuth();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');    
    const [signupError, setSignupError] = useState("");
    const [profileImgFile, setProfileImgFile] = useState(null);
    const [profileImgBase64,setProfileImgBase64] = useState(null);
    const [lastname,setLastname] = useState("");
    const [firstname,setFirstname] = useState("");
    const [profileImageUrl,setProfileImageUrl]=useState(UserProfilePiceture);
    const dispatch = useDispatch();
    const [showMessageComponent,setShowMessageComponent] = useState(false);
    const [message,setMessage] = useState('');
    
    const [loadingMessage,setLoadingMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const {
        addUserInfoLoading,
        addUserInfoSuccess,
        addUserInfoFailure,
        addUserInfoData,
        modifyUserProfileImgLoading,
        modifyUserProfileImgSuccess,
        modifyUserProfileImgFailure,
        modifyUserProfileImgData
    } = useSelector(state=>state.users);

   /*  const onChangeProfileImage = useCallback((evt)=>{
        const {target:{files}}=evt;
        const targetFile = files[0];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (event)=>{
            console.log(event.target.result);
            setProfileImgFile(targetFile);
            setProfileImgBase64(event.target.result);
            dispatch({type:MODIFY_USER_PROFILE_IMG_REQUEST,data:{file:targetFile},notModify:true});
        }
    },[]); */

    useEffect(()=>{
        return()=>{
            dispatch({type:INIT_CERTIFY_SIGN_UP_USER})
        }
    },[]);

    useEffect(()=>{
        if(!modifyUserProfileImgLoading
            &&modifyUserProfileImgSuccess){
                console.log(modifyUserProfileImgData.url);
                setProfileImageUrl(modifyUserProfileImgData.url);
                setLoading(false);
                setShowMessageComponent(true);
                setMessage(modifyUserProfileImgData.message);
                dispatch({type:MODIFY_USER_PROFILE_IMG_INIT});
        }else if(!modifyUserProfileImgLoading
            &&!!modifyUserProfileImgFailure){
            setLoading(false);
            setShowMessageComponent(true);
            setMessage(modifyUserProfileImgFailure);
            setProfileImgBase64(null);
            dispatch({type:MODIFY_USER_PROFILE_IMG_INIT});
        }else if(modifyUserProfileImgLoading){
            setLoading(true);
            setLoadingMessage(UserProfileModifyPage.modifingUserProfileMessage)
        }
    },[
        modifyUserProfileImgLoading,
        modifyUserProfileImgSuccess,
        modifyUserProfileImgFailure,
        modifyUserProfileImgData
    ]);

    useEffect(()=>{
        if(!addUserInfoLoading&&addUserInfoSuccess){
            setLoading(false);
            setShowMessageComponent(true);
            setMessage(addUserInfoData);
            console.log(addUserInfoData);
        }else if(!addUserInfoLoading&&!!addUserInfoFailure){
            setLoading(false);
            setShowMessageComponent(true);
            setMessage(addUserInfoFailure);
            dispatch({type:ADD_USER_INIT});
        }else if(addUserInfoLoading){
            setLoading(true);
            setLoadingMessage(SignupPage.UserProfileModifyPage);
        }
    },[
        addUserInfoLoading,
        addUserInfoSuccess,
        addUserInfoFailure,
        addUserInfoData,
    ])

    const cancelFunction=useCallback(()=>{
        if(addUserInfoSuccess){
            dispatch({type:ADD_USER_INIT});
            history.replace(HOMEPAGE);
        }
        setShowMessageComponent(false);
    },[addUserInfoSuccess])

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();

        setSignupError('');

        if (password !== confirmPassword)
        {
            setShowMessageComponent(true);
            setMessage(SignupPage.passwordNotMatch);
            return false;
        }

        /* if(profileImageUrl===''){
            setShowMessageComponent(true);
            setMessage(SignupPage.didNotUploadProfileImg);
            return false;
        } */

        if(email===''){
            setShowMessageComponent(true);
            setMessage(SignupPage.didNotUploadProfileImg);
            return false;
        }

        if(firstname===''){
            setShowMessageComponent(true);
            setMessage(SignupPage.didNotWriteFirstName);
            return false;
        }

        /*if(lastname===''){
            setShowMessageComponent(true);
            setMessage(SignupPage.didNotWriteLastName);
            return false;
        }*/
        setLoading(true);
        setLoadingMessage(SignupPage.message);
        console.log(`profile Image Url : ${profileImageUrl}`);
        signup(email, password).then(async (response) => {
            const {uid:userid,email} = response.user;
            const data = {
                userid,password,userthumbnail:profileImageUrl,
                firstname,lastname:email
            }
            dispatch({type:ADD_USER_REQUEST,data});

        }).catch((error) => {
            console.error(error);
            setLoading(false);
            setShowMessageComponent(true);
            setMessage(SignupPage.errorUploadingMessage);
        });

    },[email,password,confirmPassword,profileImageUrl,firstname]);

  
    return (
        <>
            <Helmet>
                <link rel="icon" href={ColleryLogo3}></link>
            </Helmet>
            {
                showMessageComponent
                &&
                <MessageComponent 
                    message={message}
                    cancelFunction={cancelFunction}
                />
            }
            {
                loading
                &&
                <LoadingComponent message={loadingMessage}/>
            }
            <Header />
            <LoginFormContainer>
                <LogInFormWrappper>
                    <LogInFormComponent>
                        <form onSubmit={handleSubmit}>
                            <div className="form_row">
                                <div className="header-wrap">
                                    <h1>Sign Up</h1>
                                </div>
                                <div className="form_inner_row">
                                    {/* <ProfileImageContainer>
                                        <ProfileImageWrap>
                                            <label className="upload-profile-img-btn" htmlFor="profileImageInput">
                                                
                                                {
                                                    profileImgBase64?
                                                    (
                                                        <div className="profile_image_frmae">
                                                            <img src={profileImgBase64} alt="profile Image"/>
                                                        </div>
                                                    )
                                                    :(
                                                        <>
                                                            <PlusOutlined />
                                                        </>
                                                    )
                                                }
                                            </label>
                                            <input onChange={onChangeProfileImage} type="file" id="profileImageInput"/>
                                           
                                        </ProfileImageWrap>
                                    </ProfileImageContainer> */}
                                    <div className="form_component">
                                        <div className="form-group">
                                            <label>아이디 (이메일)</label>
                                            <input type="text" value={email} className="form-control" placeholder="example@gmail.com" onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                    </div>

                                    {/*<div className="form_component">
                                        <div className="form-group">
                                            <label>성 : Last Name</label>
                                            <input type="text" value={lastname} className="form-control" placeholder="성을 입력 해주세요" onChange={(e) => setLastname(e.target.value)} required />
                                        </div>
                                     </div>*/}

                                    <div className="form_component">
                                        <div className="form-group">
                                            <label>이름 : (Name)</label>
                                            <input type="text" value={firstname} className="form-control" placeholder="이름을 입력 해주세요" onChange={(e) => setFirstname(e.target.value)} required />
                                        </div>
                                    </div>

                                    <div className="form_component">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" value={password} className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                                        </div>
                                    </div>

                                    <div className="form_component">
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" value={confirmPassword} className="form-control" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} required />
                                        </div>
                                    </div>

                                    {
                                        (signupError.length > 0) 
                                        && 
                                        (
                                            <div className="form_component form_error_alert">
                                                <div role="alert">{signupError}</div>
                                            </div>
                                        )
                                    }

                                    <div className="form_btn_component">
                                        <button type="submit" disabled={loading}>Sign Up</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                        
                    </LogInFormComponent>
                </LogInFormWrappper>
            </LoginFormContainer>
            <Footer />
        </>
    )
}

export default Signup;

