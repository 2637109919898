import React, { useCallback, useEffect, useState, useRef } from 'react';
/* import { useQuery } from 'react-query';
import Web3Modal from "web3modal"; */
import Footer from '../Footer';
import Header from '../Header';
/* import Filters from './Filters';
import Pagination from './Pagination'; */
import NFTCard from '../NFTCard';
/* import Skeleton from '../Skeleton/marketplaceNFTs';
import { ethers } from 'ethers'; */
/* import axios from 'axios'; */
import { Link } from 'react-router-dom';
/* import { toast } from 'react-toastify';
import web3 from 'web3';
import { 
    nftaddress, nftmarketaddress 
  } from '../../config';
  
import NFT from '../../artifacts/contracts/NFT.sol/NFT.json';
import Market from '../../artifacts/contracts/Market.sol/Market.json';

 */
import { AVAILABLE_NFT_TYPES,AVAILABLE_CATEGORY_FILTER, AVAILABLE_SORT_FILTER, MAX_NFTS_ON_MARKETPLACE ,AVAILABLE_CATEGORIES,AVAILABLE_AUDIO_CATEGORIES} from '../../constants/application';
import { getMarketplaceCount, getMarketplaceNFTs } from '../../api/marketplace';
import {CaretUpOutlined,CaretDownOutlined} from '@ant-design/icons';

import './style.css';
import { USER_NFT, USER_NFT_MINTING } from '../../constants/applicationUrls';
import styled from 'styled-components';

import {LOADING_NFT_PRODUCTS,
    INIT_LOAD_NFT_PRODUCTS_STATE} from "../../reducers/nftTokens";
import {useDispatch,useSelector}from "react-redux";
import LoadingComponent from '../LoadingComponent';
import MessageComponent from '../MessageCommponent';
import {NFTMarketPlaceLoading} from '../../constants/applicationMessage';

const StyledH1 = styled.h1`
    margin-top:25px;
    font-size: var(--page-title);
    font-weight:bold;
    @media screen and (max-width:855px){
        font-size: var(--tablet-page-title);
        margin-top:20.5px;
    }
    @media screen and (max-width:587px){
        font-size: var(--mobile-page-title);
        margin-top:18.5px;
    }
`;

const StyledChooseCategoryContainer = styled.div`
    width:100%;height:35.5px;
    margin-top:85.5px;
    border-radius: 4.5px 4.5px;
    .choose-category-wrapper{
        width:100%;height:100%;
        ul{
            width:100%;height:100%;
            display:flex;
            align-items:center;
            justify-content:space-between;
            li{
                width: 32.5%;
                height: 100%;
                padding: 4.5px 3.5px;
                box-sizing: border-box;
                text-align: center;
                line-height: 31px;
                font-size: var(--explain-text);
                cursor:pointer;
                border-radius:4.5px;
                box-shadow: 0px 0px 2.5px #454545;
                &.on{
                    background-color:rgb(24,104,183);
                    box-shadow: 0px 0px 2.5px rgb(24,104,183);
                    color:#fff;
                }
            }
        }
    }
    @media screen and (max-width:855px){
        height:30.5px;
        margin-top:45.5px;
        .choose-category-wrapper{
            width:100%;height:100%;
            ul{
                li{
                    line-height: 21.5px;
                    font-size: var(--tablet-explain-text);
                    box-shadow: 0px 0px 2.5px #454545;
                }
            }
        }
    }
    @media screen and (max-width:578px){
        height:25.5px;
        margin-top:35.5px;
        .choose-category-wrapper{
            width:100%;height:100%;
            ul{
                li{
                    line-height: 16.6px;
                    font-size: var(--mobile-explain-text);
                    box-shadow: 0px 0px 2.5px #454545;
                }
            }
        }
    }
`;

const StyledCategoryContainer = styled.div`
    width:100%;
    height:35.5px;
    margin: 15.5px 0 35.5px 0;
    display: flex;
    .category_wrapper{
        position: relative;
        border-radius: 3.5px;
        color: #313131;
        background-color: #fff;
        width: 150px;
        border-radius: 4.5px;
        box-shadow: 0px 0px 2.5px #454545;
        .selected-category-text{
            display:flex;
            justify-content: space-around;
            height:100%;
            text-align:center;
            span{
                color:#000;
                display:inline-block;
                width:auto;
                height:100%;
                line-height: 35.5px;
                font-weight: 100;
                font-size:var(--explain-text);
            }
        }
        ul{
            box-shadow: 0 0 2.5px #454545;
            width: 100%;
            position: absolute;
            top: 105%;
            left: 0;
            z-index: 1000;
            background: #fff;
            height: auto;
            flex-direction: column;
            border-radius: 2.5px;
            justify-content: space-evenly;
            li{
                cursor:pointer;
                width: 150px;
                height: 32.5px;
                border-radius: 0px;
                line-height: 32.5px;
                text-align:center;
                color:#000;
                font-size:var(--explain-text);
                &:hover{
                    color:#fff;
                    background-color:rgb(24,104,183);
                }
                &.on{
                    color:#fff;
                    background-color:rgb(24,104,183);
                }
            }
        }
    }
    @media screen and (max-width:855px){
        height:30.5px;
        .category_wrapper{
            width: 125px;
            .selected-category-text{
                display:flex;
                justify-content: space-around;
                height:100%;
                text-align:center;
                span{
                    line-height: 30.5px;
                    font-size:var(--tablet-explain-text);
                }
            }
            ul{
                box-shadow: 0 0 2.5px #454545;
                width: 100%;
                z-index: 1000;
                li{
                    width: 100%;
                    height: 28.5px;
                    line-height: 28.5px;
                    font-size:var(--tablet-explain-text);
                }
            }
        }
    }
    @media screen and (max-width:578px){
        height:25.5px;
        .category_wrapper{
            .selected-category-text{
                span{
                    line-height: 25.5px;
                    font-size:var(--mobile-explain-text);
                }
            }
            ul{
                box-shadow: 0 0 2.5px #454545;
                width: 100%;
                z-index: 1000;
                li{
                    width: 100%;
                    height: 23px;
                    line-height: 23px;
                    font-size:var(--mobile-explain-text);
                }
            }
        }
    }
    
`;

const EntireContainer = styled.div`
    width:100%;
    height:auto;
    min-height:${window.innerHeight}px;
    padding:0 0 95.5px 0;
`;

const StyledShowMoreButtonWrap = styled.div`
    width : 100%;
    height : 105.5px;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
        display:block;
        width:60%;
        height:50%;
    }
`;

const StyledShowMoreButton = styled.button`
    display:block;
    width:200px;
    height:48px;
    border:1px solid #313131;
    border-radius: 9.5px;
    color:white;
    font-size:var(--list-subtitle);
    padding:0;
    background: #fff;
    color:#313131;
    &:active{
        background:#313131;
        color:#fff;
    }
    &:hover{
        background:#313131;
        color:#fff;
    }
    @media screen and (max-width:855px){
        width: 150px;
        height: 28px;
        font-size:var(--tablet-list-subtitle);
    }
    @media screen and (max-width:578px){
        font-size:var(--mobile-list-subtitle);
    }
`


const findElement = (element,findCondition,endCondition)=>{
    if(!element)return false;
    const flag = element.classList.contains(findCondition)
    ||element.tagName===findCondition;
    if(flag){
        return true;
    }else{
        const endFlag = element.classList.contains(endCondition);
        if(endFlag){return false;}
        else{
            return findElement(element.parentElement,findCondition,endCondition);
        }
    }
}

function Marketplace() {

    const [nfts, setNfts] = useState([]);
    const [loadingState, setLoadingState]=useState(false);
    const [categoryType,setCategoryType]=useState('Category');
    const [categoryTypeArr,setCategoryTypeArr] = useState(['Category','NFT-Type','Price Order']);
    const [currentCategory, setCurrentCategory] = useState( AVAILABLE_CATEGORY_FILTER ? AVAILABLE_CATEGORY_FILTER[0].value : '');
    //const [categoryArr,setCategoryArr] = useState([...AVAILABLE_CATEGORY_FILTER,...AVAILABLE_SORT_FILTER,...AVAILABLE_AUDIO_CATEGORIES,...AVAILABLE_CATEGORIES]);
    const [categoryArr,setCategoryArr] = useState([...AVAILABLE_CATEGORY_FILTER,...AVAILABLE_AUDIO_CATEGORIES,...AVAILABLE_CATEGORIES]);
    const [showCateList,setShowCateList] = useState(false);
    const [currentSort, setCurrentSort] = useState( AVAILABLE_SORT_FILTER ? AVAILABLE_SORT_FILTER[0].value : 'latest');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [noMoreData,setNomoreData] = useState(false);
    const maxrecord= 20;
    const pageRef = useRef(1);
    const [showMessageComponet,setShowMessageComponent]=useState(false);
    //const [marketplaceData, setMarketplaceData] = useState([]);
    //const { isLoading: countLoading, error: countError, data: countData } = useQuery(['getMarketplaceCount', currentCategory], () => getMarketplaceCount(currentCategory));
    //const { isLoading, error, data: nftData } = useQuery(['getMarketplaceNFTs', currentPage, currentSort, currentCategory], () => getMarketplaceNFTs(currentPage, currentSort, currentCategory));
    const dispatch = useDispatch();
    const {
        loadingNftProducts,
        loadingNftProductsSuccess,
        loadingNftProductsData,
        loadingNftProductsFailure,
    } = useSelector(state=>state.nftTokens);

    useEffect(()=>{
        console.log(loadingNftProducts);
        if(!loadingNftProducts&&loadingNftProductsSuccess){
            if(!!loadingNftProductsData&&loadingNftProductsData.length!==0){
                pageRef.current=pageRef.current+1;
                setNfts([...nfts,...loadingNftProductsData]);
                /* nfts.length===0
                    ?setNfts([...loadingNftProductsData])
                    :setNfts([...nfts,...loadingNftProducts]) */
                    
                loadingNftProductsData.length%maxrecord===0
                ?setNomoreData(false):setNomoreData(true)
            }else if(!loadingNftProductsData||loadingNftProductsData.length!==0){
                setNomoreData(true);
            }
            //setShowMessageComponent(true);
            dispatch({type:INIT_LOAD_NFT_PRODUCTS_STATE});
        }else if(!loadingNftProducts&&loadingNftProductsFailure){
            setShowMessageComponent(true);
            //dispatch({type:INIT_LOAD_NFT_PRODUCTS_STATE});
        }
    },[loadingNftProducts,
        loadingNftProductsSuccess,
        loadingNftProductsData,
        loadingNftProductsFailure,nfts])

    const cancelFunction=useCallback((evt)=>{
        setShowMessageComponent(false);
        dispatch({type:INIT_LOAD_NFT_PRODUCTS_STATE});
    },[]);

    /* useEffect(() => {
        if(!countLoading && !countError)
        {
            const totalCount = parseInt(countData.data);
            const noOfpages = Math.ceil(totalCount/MAX_NFTS_ON_MARKETPLACE);

            if(totalCount < MAX_NFTS_ON_MARKETPLACE)
            {
                setTotalPage(1);
            }
            else if(totalPage != noOfpages)
            {
                setTotalPage(noOfpages);
            }
        }
    }, [countData, currentCategory]); */

    /* useEffect(() => {
        if(!isLoading && !error)
        {
            if(!nftData.data)
            {
                setMarketplaceData([]);
            }
            else
            {
                setMarketplaceData(nftData.data);
            }
        }
    }, [nftData]); */

    useEffect(() => {

        dispatch({type:LOADING_NFT_PRODUCTS,
            data:{limit:maxrecord,offset:(pageRef.current-1)*maxrecord},
            send_type:'all_data'});
      },[]);

    /* const onCategoryChage = category => {
        setCurrentPage(1);
        setCurrentCategory(category);
    }

    const onSortChange = sortOrder => {
        setCurrentPage(1);
        setCurrentSort(sortOrder);
    } */

    /* async function loadNFTs(){ 
        setLoadingState(false) 
    
        await axios.get(`https://api.collery.io/getallnfts`).then((res)=>{
            setNfts(res.data)
            console.log(res.data)
            setLoadingState(true) 
        }).catch((err)=>{
            console.log(err)
            setLoadingState(true) 
        })
    } */

    const onClickChooseCategoryContainer = useCallback((evt)=>{
        const {target:thisElement} = evt;
        const tagType = thisElement.tagName;
        let classToFind = '';
        const endCondition = 'choose-category-container';
        switch(tagType){
            case 'LI':{
                classToFind='outer-category';
                break;
            }
            default:{
                classToFind='choose-category-container';
                break;
            }
        }

        const flag = findElement(thisElement,classToFind,endCondition);
        if(flag){
            const {value} = thisElement.dataset;
            switch(value){
                case 'Category':{
                    setCategoryArr([
                        ...AVAILABLE_CATEGORY_FILTER,
                        ...AVAILABLE_CATEGORIES,
                        ...AVAILABLE_AUDIO_CATEGORIES
                    ]);
                    break;
                }
                case 'NFT-Type':{
                    setCategoryArr([
                        ...AVAILABLE_CATEGORY_FILTER,
                        ...AVAILABLE_NFT_TYPES
                    ]);
                    break;
                }
                case 'Price Order':{
                    setCategoryArr([
                        ...AVAILABLE_CATEGORY_FILTER,
                        ...AVAILABLE_SORT_FILTER
                    ]);
                    break;
                }
                default:{
                    setCategoryArr([
                        ...AVAILABLE_CATEGORY_FILTER,
                        ...AVAILABLE_CATEGORIES,
                        ...AVAILABLE_AUDIO_CATEGORIES
                    ]);
                    break;
                }
            }
            setCategoryType(value);
        }
    },[])    

    const onClickCategoryContainer = useCallback((evt)=>{
        const {target:thisElement} = evt;
        const tagType = thisElement.tagName;
        const endPointClassName='category_container';
        let nameOfClass = '';
        switch (tagType){
            case 'LI':{
                nameOfClass='category-list';
                break;
            }
            default:{
                nameOfClass='selected-category-text';
                break;
            };
        }
        const flag = findElement(thisElement,nameOfClass,endPointClassName);
        if(flag){
            if(nameOfClass==='selected-category-text'){
                setShowCateList(prev=>!prev);
            }else if(nameOfClass==='category-list'){
                if(showCateList){
                    setShowCateList(false);
                    if(thisElement.dataset.value===currentCategory){
                        return false;
                    }
                    pageRef.current=1;
                    setCurrentCategory(thisElement.dataset.value);
                    setNfts([]);
                    if(thisElement.dataset.value==='All'){
                        dispatch({
                            type:LOADING_NFT_PRODUCTS,
                            data:{
                                limit:maxrecord,
                                offset:0
                            }
                            ,send_type:'all_data'
                        });
                    }else{
                        let data=null;
                        const limit = maxrecord; const offset = 0;
                        switch(categoryType){
                            case 'Category':{
                                data={
                                    limit,offset,
                                    category:thisElement.dataset.value
                                };
                                break;
                            }
                            case 'NFT-Type':{
                                data={
                                    limit,offset,
                                    type:thisElement.dataset.value
                                };
                                break;
                            }
                            case 'Price Order':{
                                data={
                                    limit,offset,
                                    category:thisElement.dataset.value
                                };
                                break;
                            }
                            default:{
                                data={};
                                break;
                            }
                        }
                        dispatch({type:LOADING_NFT_PRODUCTS,data,send_type:categoryType});
                    }
                }
            }
        }
    },[showCateList,categoryType])
    const onClickShowMoreButton = useCallback((evt) => {
        if(noMoreData){return false;}
        let limit = maxrecord;
        let offset = (pageRef.current-1)*maxrecord;
        let data={limit,offset};
        let send_type=null;
        switch(categoryType){
            case 'Category':{
                data=currentCategory!=='All'?
                {...data,category:currentCategory}
                :{...data};
                send_type=currentCategory!=='All'?
                categoryType:'all_data';
                break;
            }
            case 'NFT-Type':{
                data=currentCategory!=='All'?
                {...data,type:currentCategory}
                :{...data};
                send_type=currentCategory!=='All'?
                categoryType:'all_data';
                break;
            }
            case 'Price Order':{
                data={...data,category:currentCategory}
                send_type=currentCategory!=='All'?
                categoryType:'all_data';
                break;
            }
        }
        console.log(data);
        console.log(send_type);
        dispatch({
            type:LOADING_NFT_PRODUCTS,
            data,send_type
        })
    },[noMoreData,categoryType,currentCategory,pageRef.current])
    return (
        <>
            <Header />
            <EntireContainer className="container-fluid">
                
                <div className="row pt-25">
                    {loadingNftProducts&&<LoadingComponent message={NFTMarketPlaceLoading.message}/>}
                    {
                        !loadingNftProducts
                        &&loadingNftProductsFailure
                        &&showMessageComponet
                        &&(
                            <MessageComponent 
                                message={loadingNftProductsFailure}
                                cancelFunction={cancelFunction}
                            />
                        )
                    }
                    <div className="col-lg-12">
                        <StyledH1>NFTs</StyledH1>
                        <StyledChooseCategoryContainer 
                            onClick={onClickChooseCategoryContainer} 
                            className="choose-category-container"
                        >
                            <div className="choose-category-wrapper">
                                <ul>
                                    {
                                        categoryTypeArr.map((v,i)=>(
                                            <li 
                                                className={v===categoryType?"outer-category on":"outer-category"} 
                                                data-value={v} 
                                                key={v}
                                            >
                                                {v}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </StyledChooseCategoryContainer>
                        <StyledCategoryContainer className="category_container" onClick={(evt)=>onClickCategoryContainer(evt)}>
                            <div className="category_wrapper">
                                <span className="selected-category-text">
                                    <span>{currentCategory}</span>
                                    {showCateList ? <CaretUpOutlined /> : <CaretDownOutlined />}
                                </span>
                                <ul>
                                    {
                                        showCateList &&
                                        categoryArr.map((elem,idx)=>(
                                            <li className="category-list" data-value={elem.value} key={`${elem.value}_${idx}`}>{elem.value}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </StyledCategoryContainer>
                    
                        {nfts && (<div className="row align-items-center">

                            {nfts?.map((NFT, index) => {
                                return (
                                <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                                    <NFTCard data={{...NFT}} />
                                </div>)
                            })}

                        </div>)}
                    
                        </div>
                    {loadingState && !nfts.length &&<div style={{marginBottom:'10rem'}} className="container d-flex justify-content-center">
       
                            <div className="alert alert-light container" role="alert" >
                                There is no available NFT, you can create your own.
                         
                                <div class="d-grid gap-2 others-options" >

                                <Link to={USER_NFT_MINTING} className="btn btn-dark">I want to create some NFTs</Link>
                                    
                                </div>
                                    
                            </div>
                        </div>

                    }

                    {/* {!loadingState&&
                    <div className="container d-flex justify-content-center mx-auto" style={{marginTop:'-1rem', marginBottom:'40rem'}}>
                                            
                        <div className="" style={{marginBottom:'20rem'}}>
                        <Loader size={10} />
                        </div>   
                    
                    </div> 
                    } */}

     

                </div>

                {
                        !loadingNftProducts && !noMoreData &&
                        <StyledShowMoreButtonWrap>
                            <StyledShowMoreButton onClick={onClickShowMoreButton}>
                                <span>
                                    NFT 더 보기
                                </span>
                            </StyledShowMoreButton>
                        </StyledShowMoreButtonWrap>
                    }
                
            </EntireContainer>

            <Footer />
        </>
    )
}

export default Marketplace;