import React, {  useState,useCallback,useRef, useEffect } from "react";
import styled from "styled-components";
import certificationImage from '../../assets/images/certification.jpg';
import certificationImage_Kor_ver from '../../assets/images/certification_Kor_ver.jpg';
import logoImage from '../../assets/images/Collary_logo.jpg';
import {PDFDownloadLink,PDFViewer, Document, Page, View, Image, Text, Font, StyleSheet } from "@react-pdf/renderer";
import DancingScript from '../../assets/fonts/DancingScript-Bold.ttf';
import materialDesignIconsWebfont from '../../assets/fonts/materialdesignicons-webfont.ttf'
import {BrowserView,MobileView,isBrowser,isMobile,} from 'react-device-detect';
import {CloseOutlined,DownloadOutlined} from '@ant-design/icons';
import axios from 'axios';

const PDFComponent = styled.div`
    width:100%;height:100%;
    .cancel-Btn-wrapper{
        width:100%;height:40px;
        .cancel-Btn{
            float:right;
            display:flex;
            width:35px;
            height:35px;
            justify-content:center;
            align-items:center;
            border-radius:100%;
            span{color:#fff; font-size:24px;}
            cursor:pointer;
        }
    }

    @media screen and (max-width:855px){
        height:auto;
        .cancel-Btn-wrapper{
            position: absolute;
            top: 0;
            height:${isMobile?'auto':'100%'};
        }
    }
`

const SelectButtonWrapper = styled.div`
    width:100%;
    height:45px;
    display:flex;
    justify-content:space-evenly;
    flex-direction:row;
    align-items:center;
    margin: 15.5px 0;
    position:relative;
    .cancel-Btn-wrapper{
        position:absolute;
        top:0;right:0;
        width:35px;
        height:35px;
        .cancel-Btn{
            display:flex;
            width:100%;
            height:100%;
            justify-content:center;
            align-items:center;
            border-radius:100%;
            span{color:#fff; font-size:24px;}
            cursor:pointer;
        }
    }
    button{
        display:block;
        height:35px;
        box-shadow:2.5px 2.5px 2.5px #454545;
        width:125px;
        border-radius:8.5px;
        line-height:35px;
        color:#fff;
        text-align:center;
        background-color:#000;   
        border:none;     
        font-size:var(--dashboard-page-button);    
        &:hover{
            box-shadow:none;
        }
        &.chosen{
            background-color:#ff4d2c;
        }
        &.download-pdf-link{
            width:auto;height:auto;
        }
    }
    @media screen and (max-width:855px){
        height:${isMobile?'355.5px':'100%'};
        display:${isMobile?'flex':'block'};
        ${isMobile&&`flex-direction:column;`}
        button{
            font-size:var(--tablet-dashboard-page-button); 
            &.download-pdf-link{
                width:auto;height:auto;
                a{
                    font-size:var(--tablet-dashboard-page-button)
                } 
            }
        }
    }
    @media screen and (max-width:578px){
        button{
            font-size:var(--mobile-detail-page-button);
            &.download-pdf-link{
                width:auto;height:auto;
                a{
                    font-size:var(--mobile-dashboard-page-button)
                } 
            }
        }
    }
`;

Font.register({
    family: 'SpoqaHanSans',
    src: 'https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf',
});

Font.register({
    family:'materialDesignIconsWebfont',
    format:"truetype",
    src:materialDesignIconsWebfont
})

Font.register({
    family: "Dancing Script",
    format: "truetype",
    src: DancingScript
})

const pdfStyle = StyleSheet.create({
    pdfViewer: {
        width: '100%',
        height: '100%',
    },
    page: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        backgroundColor: '#e4e4e4',
        
    },
    section: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    certifyNumberView:{
        position: 'absolute',
        top: 30,
        right: 30,
        width:'auto',height:25.5,
    },
    certifyNumber:{
        fontSize:'12.5px'
    },
    LogoImageView: {
        width: 130, height: 130,
        overflow: 'hidden',
        position: 'absolute',
        top: 57.50,
        right: 30
    },
    LogoImageComponent: {
        borderRadius: '100%',
        width: 130, height: 130
    },
    BasicInfoView:{
        width:'100%',height:'auto',
        position:'absolute',
        top:'28.5%',left:'0'
    },
    BasicInfoWrap:{
        width:'100%',height:'auto',
        display:'flex',flexDirection:'column',
        alignItems:'center',justifyContent:'flex-start'
    },
    BasicInfoComponent:{
        fontSize:'22.5px',
        fontWeight:'700'
    },
    BasicInfoTitle:{
        fontWeight:'700'
    }, 
    DetailInfoView:{
        width:'100%',height:'auto',
        position:'absolute',
        top:'55.5%',left:'0'
    },
    DetailInfoWrap :{
        width:'100%',height:'auto',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center'
    },
    DetailImageInfoComponent:{
        width:150,height:150,
    },
    DetailTextInfoComponent:{
        width:'auto',height:'200px',
        display:'flex',flexDirection:'column',
        justifyContent:'space-evenly',
        alignItems:'center'
    },
    DetailTextSmallInfo:{
        width:'auto',height:'50px',
        display:'flex',flexDirection:'column',
        justifyContent:'space-evenly',
        alignItems:'center'
    },
    DetailTextSmallInfoText:{
        fontSize:11.5
    },  
    DetailTextSmallInfoTextHash:{
        fontSize:11.5,
    },  
    DetailTextBigInfo:{
        width:'auto',height:'130px',
        display:'flex',flexDirection:'column',
        justifyContent:'space-evenly',
        alignItems:'center'
    },  
    DetailTextBigInfoText:{
        fontSize:19.5,
        lineHeight:22.5,
    },  
    DetailTextBigInfoTextGallerySign:{
        fontSize:45.5,
        fontFamily:'Dancing Script'
    },
    StampComponent:{
        width:150,height:150,
        position:'absolute',
        bottom:175,right:200,
    },
    PdfDownloadLink:{
        width: 'auto',
        height: 35,
        boxShadow: '2.5px 2.5px 2.5px #454545',
        zIndex: 1000,
        backgroundColor: '#ff4d2c',
        borderRadius: '8.5px',
        color: '#fff',
        textAlign: 'center',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        fontSize:'var(--dashboard-page-button)',
        padding:'0 5.5px',
    }
})


const ShowPDFDocument = ({certificationData,onClickCancelShowPDF}) => {
    const { 
        userid,
        certifiedimageurl,
        authorname,
        imagetitle,
        issueddate,
        imgid,
        imagehash,
        certificatenumber,
     }=certificationData;
    const [languageType, setLanguageType] = useState('KOREAN');
    //const imageUrlRef = useRef('https://images.unsplash.com/photo-1545291730-faff8ca1d4b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGZhc2hpb258ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60');
    const onClickSelectLanguageType = useCallback((evt) => {
        evt.preventDefault();
        const { language } = evt.target.dataset;
        setLanguageType(language);
    }, [languageType]);

    /* useEffect(async()=>{
        console.log(certifiedimageurl);
    },[]) */
    
    const PDFDocument = ()=>(
        <Document>
            <Page orientation="landscape" size="A4" style={{...pdfStyle.page, fontFamily: languageType==='ENGLISH'?'materialDesignIconsWebfont':'SpoqaHanSans'}}>
                <View style={pdfStyle.section}>
                    <Image src={languageType==='ENGLISH'?certificationImage:certificationImage_Kor_ver} style={pdfStyle.CertificationImage} />
                    {/*<View style={pdfStyle.certifyNumberView}>
                        <Text style={pdfStyle.certifyNumber}>
                            {languageType==='ENGLISH'?'Certification Number':'인증번호'}  
                            : {certificatenumber}
                        </Text>
                    </View>*/}
                    <View style={pdfStyle.LogoImageView}>
                        <Image src={logoImage} style={pdfStyle.LogoImageComponent} />
                    </View>

                    <View style={pdfStyle.BasicInfoView}>
                        <View style={pdfStyle.BasicInfoWrap}>
                            <Text style={pdfStyle.BasicInfoComponent}>
                                {languageType==='ENGLISH'?'Author':'작가명'} : {authorname}
                            </Text>
                            <Text style={pdfStyle.BasicInfoComponent}>
                                    {languageType==='ENGLISH'?'Title':'제목'} : {imagetitle}
                            </Text>
                            <Text style={pdfStyle.BasicInfoComponent}>
                                {languageType==='ENGLISH'?'Date of Registration':'등록일'} :
                                {   languageType==='ENGLISH'?
                                    `${(new Date(issueddate)).getFullYear()}.${(new Date(issueddate)).getMonth() + 1}.${(new Date(issueddate)).getDate()}`
                                    :`${(new Date(issueddate)).getFullYear()}년 ${(new Date(issueddate)).getMonth() + 1}월 ${(new Date(issueddate)).getDate()}일`
                                }
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyle.DetailInfoView}>
                        <View style={pdfStyle.DetailInfoWrap}>
                            <View style={pdfStyle.DetailImageInfoComponent}>
                                <Image src={certifiedimageurl}/>
                            </View>
                            <View style={pdfStyle.DetailTextInfoComponent}>
                                <View style={pdfStyle.DetailTextSmallInfo}>
                                    <Text style={pdfStyle.DetailTextSmallInfoText}>
                                        {
                                            languageType==='ENGLISH'?
                                            'This image certifies that it is a work registered by the above artist.'
                                            :'이 이미지는 상기 작가가 등록한 작품임을 인증합니다.'
                                        } 
                                    </Text>
                                    <Text style={pdfStyle.DetailTextSmallInfoText}>
                                        {languageType==='ENGLISH'?'Hash Value':'해쉬값'} :
                                        <Text style={pdfStyle.DetailTextSmallInfoTextHash}>
                                            {` ${imagehash}`}
                                        </Text>
                                    </Text>
                                </View>
                                <View style={pdfStyle.DetailTextBigInfo}>
                                    <Text>
                                        {   languageType==='ENGLISH'?
                                            `${(new Date()).getFullYear()}.${(new Date()).getMonth() + 1}.${(new Date()).getDate()}`
                                            :`${(new Date()).getFullYear()}년 ${(new Date()).getMonth() + 1}월 ${(new Date()).getDate()}일`
                                        }
                                    </Text>
                                    <Text style={pdfStyle.DetailTextBigInfoTextGallerySign}>
                                        Collery Gallery
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    {/* <View style={pdfStyle.DetailTextBigInfoText}>
                        
                    </View> */}
                </View>
            </Page>
        </Document>
    )
    return (
        <PDFComponent>
            {
                isMobile&&
                <div className="cancel-Btn-wrapper">
                    <p className='cancel-Btn' onClick={onClickCancelShowPDF}>
                        <CloseOutlined />
                    </p>
                </div>
            }
            <SelectButtonWrapper>
                <button className={languageType === `KOREAN` ? 'chosen' : ''} data-language='KOREAN' onClick={onClickSelectLanguageType}>
                    한글(KOREAN)
                </button>
                <button className="download-pdf-link">
                    <PDFDownloadLink
                        style={pdfStyle.PdfDownloadLink}
                        document={<PDFDocument/>}
                        fileName={`${authorname}_${imagetitle}.pdf`}
                    >
                        {(blob,url,loading,error)=>{
                            if(!loading){
                                console.log(blob);
                            }
                        }}
                        <DownloadOutlined />
                        Download PDF
                    </PDFDownloadLink>
                </button>
                <button className={languageType === `ENGLISH` ? 'chosen' : ''} data-language='ENGLISH' onClick={onClickSelectLanguageType}>
                    영문(ENGLISH)
                </button>
                {
                    isBrowser&&
                    <div className="cancel-Btn-wrapper">
                        <p className='cancel-Btn' onClick={onClickCancelShowPDF}>
                            <CloseOutlined />
                        </p>
                    </div>
                }
                
            </SelectButtonWrapper>
            
            {
                isBrowser
                &&
                (
                    <BrowserView style={{width:'100%',height:'100%'}}>
                        <PDFViewer style={pdfStyle.pdfViewer}>
                            <PDFDocument />
                        </PDFViewer>
                    </BrowserView>
                )
            }
        </PDFComponent>
    )
}

export default ShowPDFDocument;