import React,{useEffect,useState,memo,useRef, useCallback,useContext} from "react";
import styled from "styled-components";
/* import Clock from '../../Countdown/ClassTimer';
import moment from "moment"; */
import axios from 'axios'
import ShowSingleTimeComponent from "./ShowSingleTimeComponent";
import {RentNFTDataContext,DELETE_RENTED_NFT_DATA_STATE} from './index';

const ShowRentTimerContainer = styled.div`
    width:100%;height:auto;
    .showRentTimerWrap{
        width:100%;height:100%;
        display:flex;
        align-items:center;
        flex-direction:row;
        justify-content:space-between;
        
    }
`;

function leading0(num) {
    return num < 10 ? "0" + num : num;
  }
function leading00(num){
    if(num<10){
        return "00"+num;
    }else if(num<100){
        return "0"+num;
    }
    return num;
}
const ShowRentInfoComponent = memo(({rentData})=>{
    const countClockRef = useRef(null);
    const [daysStr,setDays]=useState('00');
    const [hoursStr,setHours] = useState('00');
    const [minutesStr,setMinutes] = useState('00');
    const [secondsStr,setSeconds] = useState('00');
    const dueDateRef = useRef(null);
    const [isDueTimeEnd,setIsDueTimeEnd]=useState(false);
    const {RentedNFTDataDispatch} = useContext(RentNFTDataContext);

    const countDeadLineFunction = useCallback(()=>{
        if(dueDateRef.current){
            const dateNow = Date.now();
            const deadLineLeft = dueDateRef.current-dateNow;
            if(deadLineLeft<=0){
                setIsDueTimeEnd(true);
                window.clearInterval(countClockRef.current);
            }else{
                const seconds = Math.floor((deadLineLeft / 1000) % 60);
                setSeconds(`${leading0(seconds)}`);
                const minutes = Math.floor((deadLineLeft / 1000 / 60) % 60);
                setMinutes(`${leading0(minutes)}`);
                const hours = Math.floor((deadLineLeft / (1000 * 60 * 60)) % 24);
                setHours(`${leading0(hours)}`);
                const days = Math.floor(deadLineLeft / (1000 * 60 * 60 * 24));
                setDays(`${leading00(days)}`);
            }
        }
    },[]);

    useEffect(()=>{
        console.log(rentData);
        const addedDate = Number(rentData.rentalperiod)*24*60*60*1000;
        //console.log(`addedDate : ${addedDate}`);
        const dateRented =new Date(Number(rentData.renteddate));
        const deadLine = new Date(dateRented.getTime()+addedDate);
        dueDateRef.current = deadLine.getTime();
        countClockRef.current = window.setInterval(countDeadLineFunction,1000);
        return ()=>{
            window.clearInterval(countClockRef.currnet);
        }
    },[]);

    useEffect(async()=>{
        if(isDueTimeEnd){
            try{
                const resp = await axios.post(`https://api.collery.io/deleterentednft/${rentData.id}`);
                console.log(resp);
                RentedNFTDataDispatch({type:DELETE_RENTED_NFT_DATA_STATE,deleteRentNFTId:rentData.id})
            }catch(error){
                console.log(error);
            }
        }
    },[isDueTimeEnd])

    return (
        <>
            <div className='show-rent-dueDate-wrap'>
                <p className="countdown-header">임대 잔여 시간</p>
                <ShowRentTimerContainer>
                    <div className="showRentTimerWrap">
                        <ShowSingleTimeComponent dateVal={daysStr} dateType={"DAY"}/>
                        <ShowSingleTimeComponent dateVal={hoursStr} dateType={"HOUR"}/>
                        <ShowSingleTimeComponent dateVal={minutesStr} dateType={"MIN"}/>
                        <ShowSingleTimeComponent dateVal={secondsStr} dateType={"SEC"}/>  
                    </div>
                </ShowRentTimerContainer>   
            </div>
        </>
    )
})

export default ShowRentInfoComponent;