import { combineReducers } from "redux";
import users from './users';
import nftTokens from './nftTokens';
import paintings from './paintings';

const rootReducers = combineReducers({
    users,nftTokens,paintings
})

export default rootReducers;

