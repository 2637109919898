import React from 'react'

export default function Collabo() {
  return (
    <div className="mb-4 pl-2 pr-2 sm:mr-[90px] sm:ml-[90px]">
        <div className="bg-gray-100 p-3 items-center justify-center flex rounded-full">
            <p className="text-[10px] font-bold sm:text-2xl sm:text-gray-600">COLLABO GALLERY</p>
        </div>
    </div>
  )
}
