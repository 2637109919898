import React, { memo } from "react";
import Header from "../Header";
import Footer from "../Footer";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import PersonalInfo from "./PersonalInfo";
import ServicePolicy from "./ServicePolicy";

const EntireContainer = styled.div`
    width:100%;height:auto;
    min-height:${window.innerHeight}px;
`;

const Banner = styled.div`
    width:100%;
    height:230px;
    background-color:#000;
    display:flex;
    align-items:center;
    margin-bottom:30px;
    h1{
        margin:0 auto 85.5px auto;
        width:87.5%;
        font-weight:bold;
        font-size:var(--page-title);
        color:#ff4d2c;
    }
    @media screen and (max-width:855px){
        height: 150px;
        h1{
            margin: 0 auto 15.5px auto;
            font-size:var(--tablet-page-title);
        }
    }

    @media screen and (max-width:578px){
        height: 120px;
        h1{
            margin: 0 auto 15.5px auto;
            font-size:var(--mobile-page-title);
        }
    }
`;

const PolicyPage = memo(() => {
    const ServicePolicyMatch = useRouteMatch('/policy/service-policy');
    const PersonalInfoMatch = useRouteMatch('/policy/personal-info');

    return (
        <>
            <Header />
            <EntireContainer>
                <Banner>
                    <h1>
                        {
                            PersonalInfoMatch ?
                                '개인 정보 처리 방침'
                                : 'COLLERY 이용 약관'
                        }
                    </h1>
                </Banner>
                {
                    PersonalInfoMatch
                    &&
                    <PersonalInfo />
                }
                {
                    ServicePolicyMatch
                    &&
                    <ServicePolicy />
                }
            </EntireContainer>
            <Footer />
        </>
    )
})

export default PolicyPage;