import React,{useState,useCallback,useRef, useEffect} from 'react';
import styled from 'styled-components';

const StyledEntrieWrap = styled.div`
    width:100%;
    height:auto;
    transition:all 0.5s;
    .imageWrap{
        position:relative;
        >img{
            object-fit:contain;
            display:block;
            width:100%;height:auto;
        }
    }
`;



const SingleThumbNailImage = ({image})=>{
    return(
        <StyledEntrieWrap >
            <div >
                <div className="imageWrap">
                    <img src={image} alt="thumbnail image"/>
                </div>
            </div>
        </StyledEntrieWrap>
    );
}

export default SingleThumbNailImage;