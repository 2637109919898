import React from 'react';

function WalletTransactions({ currentWallet }){
  
    return (
        <>
            <div className="item-details-dsce collection-form wallet-transactions-list">
                <h4 className="text-center text-danger">Wallet Transactions Coming Soon</h4>
            </div>
        </>
    )
}

export default WalletTransactions;

