import axios from "axios";
import {fork,all,put,call, takeLatest} from "redux-saga/effects";
import {MODIFY_USER_INFO_REQUEST,
    MODIFY_USER_INFO_SUCCESS,
    MODIFY_USER_INFO_FAILURE,
    LOAD_USER_LIST_REQUEST,
    LOAD_USER_LIST_SUCCESS,
    LOAD_USER_LIST_FAILURE,
    LOAD_USER_PROFILE_IMG_REQUEST,
    LOAD_USER_PROFILE_IMG_SUCCESS,
    LOAD_USER_PROFILE_IMG_FAILURE,
    MODIFY_USER_PROFILE_IMG_REQUEST,
    MODIFY_USER_PROFILE_IMG_SUCCESS,
    MODIFY_USER_PROFILE_IMG_FAILURE,
    MODIFY_USER_PROFILE_IMG_INIT,
    REQUEST_CERTIFICATION_REQUEST,
    REQUEST_CERTIFICATION_SUCCESS,
    REQUEST_CERTIFICATION_FAILURE,
    REQUEST_CERTIFICATION_INIT,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,
} from "../reducers/users";

import {SignupPage,CertifyPageLoading,UserProfileModifyPage,GalleryIndexLoading,LoginPage} from '../constants/applicationMessage';

const returnHttpErrorMsg = (response)=>{
    const {status}=response;
    switch(status){
        case status>=400&&status<500:{
            return "데이터 전송 오류";
        }
        case status>=500:{
            return "서버 점검중.."
        }
    }
}

//유저 프로필 이미지 업로드 로직.
const uploadUserProfileImage = (file)=>{
    console.log(file);
    const formData = new FormData();
    formData.append('file',file);
    return axios.post('https://api.collery.io/uploaduserprofile',formData);
}


const saveUserPic = (data)=>{
    return axios.post('https://nestyle-korea.herokuapp.com/api/saveauthorpic',data);
}

function* modifyUserProfileImg(action){
    try{
        const uploadProfileImgResp = yield call(uploadUserProfileImage,action.data.file);
        const {url}=uploadProfileImgResp.data;
        /* if(!action.notModify){
            yield call(saveUserPic,{userid:action.data.userid,firstname:action.data.firstname,
                lastname:action.data.lastname,image:url});
        } */
        yield put({type:MODIFY_USER_PROFILE_IMG_SUCCESS,data:{url,message:UserProfileModifyPage.SuccessModifyUserProfileImageMessage}});
        
    }catch(err){
        console.error(err.response);
        if(err.response){
            let message = '';
            if(err.response.status>=400&&err.response.status<500){
                message= UserProfileModifyPage.ErrorModifingUserInfoMessage;
            }else if(err.response.status>=500){
                message= UserProfileModifyPage.serverErrorModifingUserInfoMessage;
            }
            //const httpRespMessage = returnHttpErrorMsg(err.response);
            yield put({type:MODIFY_USER_PROFILE_IMG_FAILURE,data:message})
        }else{
            yield put({type:MODIFY_USER_PROFILE_IMG_FAILURE,data:UserProfileModifyPage.ErrorModifingUserInfoMessage});
        }
    }
}

function* modifyUserProfileImgSaga(){
    yield takeLatest(MODIFY_USER_PROFILE_IMG_REQUEST,modifyUserProfileImg);
}

//user list 호출 api
const loadUserListAPI = (data)=>{
    const {offset,limit} = data;
    return axios.get(`https://api.collery.io/getallauthorslimit/${limit},${offset}`)
}

function* loadUserList(action){
    try{
        const authorData = yield call(loadUserListAPI,action.data);
        
        if(authorData.data.response==="Error while processing"){
            throw new Error(GalleryIndexLoading.errorloadUsersDataMessage);
        }else{
            yield put({type:LOAD_USER_LIST_SUCCESS,data:authorData.data});
        }
    }catch(err){
        if(err?.response){
            const {status}=err.response;
            let message='';
            switch(status){
                case status>=400&&status<500:{
                    message=GalleryIndexLoading.errorloadUsersDataMessage;
                    break;
                }
                case status>=500:{
                    message=GalleryIndexLoading.serverloadUsersDataErrorMessage
                    break;
                }
            }
            yield put({type:LOAD_USER_LIST_FAILURE,data:message})
        }else{
            console.log(err);
            yield put({type:LOAD_USER_LIST_FAILURE,data:err.message});
        }
    }
}

function* loadUserListSaga(){
    yield takeLatest(LOAD_USER_LIST_REQUEST,loadUserList);
}

//user profile image 호출
const loadUserProfileImgAPI = (userid)=>{
    return axios.get(`https://api.collery.io/getuserinfo/${userid} `)
}

function* loadUserProfileImg(action){
    try{
        const {userid} = action.data;
        const resp= yield call(loadUserProfileImgAPI,userid);
        console.log(resp.data);
        if(resp.data.response=="Error while processing"||resp.data.length===0){
            
            throw new Error(
                resp.data.length===0?
                LoginPage.errorObjectNotExist
                :GalleryIndexLoading.errorloadUsersDataMessage
            );
        }
        console.log(resp.data);

        yield put({type:LOAD_USER_PROFILE_IMG_SUCCESS,data:resp.data?resp.data[0]:null});
    }catch(error){
        let message='';
        console.log(error.message);
        if(error.response){
            if(error.response.status>=400&&error.response.status<500){
                message=GalleryIndexLoading.errorloadUsersDataMessage;
            }else if(error.response.status>=500){
                message=GalleryIndexLoading.serverloadUsersDataErrorMessage;
            }
        }else{
            message=!!error.message?error.message:GalleryIndexLoading.errorloadUsersDataMessage;
        }
        yield put({type:LOAD_USER_PROFILE_IMG_FAILURE,data:message})
        
    }
}

function* loadUserProfileImgSaga(){
    yield takeLatest(LOAD_USER_PROFILE_IMG_REQUEST,loadUserProfileImg);
}


//user profile 수정
const modifyUserInfoAPI=(data)=>{
    return axios.post('https://nestyle-korea.herokuapp.com/api/updateauthor',data);
}

function* modifyUserInfo(action){
    try{
        yield call(modifyUserInfoAPI,action.data);
        yield put({type:MODIFY_USER_INFO_SUCCESS});
    }catch(err){
        if(err?.response){
            const httpRespMessage = returnHttpErrorMsg(err.response);   
            yield put({type:MODIFY_USER_INFO_FAILURE,data:httpRespMessage})
        }else{
            console.error(err.message);
            
            yield put({type:MODIFY_USER_INFO_FAILURE,data:'internal error'});
        }
    }
}

function* modifyUserInfoSaga(){
    yield takeLatest(MODIFY_USER_INFO_REQUEST,modifyUserInfo);
}

const requestCertificationAPI=(data)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const resp = await axios.post(
                'https://api.collery.io/newcertificate',
                data
            )
            if(resp.data.response==="Error while processing"){
                throw new Error(CertifyPageLoading.publishingLoadErrorMessage);
            }else{
                resolve(data);
            }
        }catch(error){
            reject(error);
        }
    }) 
    //return axios.post('/api/certification',data);
}

function* requestCertification(action){
    try{
        const answerData = yield call(requestCertificationAPI,action.data); 
        console.log(answerData);
        yield put({type:REQUEST_CERTIFICATION_SUCCESS,
            data:answerData
        });
    }catch(error){
        console.log(error);
        let message='';
        if(error.response){
            if(error.response.status>=400&&error.response.status<500){
                message=CertifyPageLoading.publishingLoadErrorMessage;
            }else if(error.response.status>=500){
                message=CertifyPageLoading.publishingLoadserverErrorMessage;
            }
        }else{
            message=CertifyPageLoading.publishingLoadErrorMessage;
        }
        yield put({type:REQUEST_CERTIFICATION_FAILURE,data:message})
    }
}

function* requestCertificationSaGa(){
    yield takeLatest(REQUEST_CERTIFICATION_REQUEST,requestCertification);
}

/* ADD_USER_REQUEST
ADD_USER_SUCCESS
ADD_USER_FAILURE */

const requestAddUserAPI = (data)=>{
    return axios.post('https://api.collery.io/adduserinfo',data);
}

function* requestAddUser(action){
    try{
        //const {uid:userid,email} =  yield call(signUpToFirebase,{passwod:action.data.password,email:action.data.email});
        const respData = yield call(requestAddUserAPI,action.data);
        console.log(respData);
        if(respData.data.response==="Error while processing"){
            throw new Error(SignupPage.errorUploadingMessage);
        }else{
            yield put({type:ADD_USER_SUCCESS,data:SignupPage.successMessage});
        }
    }catch(error){
        let message ='';
        if(error.response){
            if(error.response.status>=400&&error.response.status<500){
                message=SignupPage.errorUploadingMessage;
            }else if(error.response.status>=500){
                message=SignupPage.serverErrorUploadingMessage;
            }
            yield put({type:ADD_USER_FAILURE,data:message});
        }else{
            yield put({type:ADD_USER_FAILURE,data:SignupPage.errorUploadingMessage});
        }
    }
}

function* requestAddUserSaga(){
    yield takeLatest(ADD_USER_REQUEST,requestAddUser);
}

const callUploadingImageAPI = async(image)=>{
    const formData = new FormData();
    formData.append("file",image);
    return await axios.post('https://api.collery.io/uploadgallery',
        formData
    );
}



export default function* usersSaga(){
    yield all([
        fork(modifyUserProfileImgSaga),
        fork(loadUserListSaga),
        fork(loadUserProfileImgSaga),
        fork(modifyUserInfoSaga),
        fork(requestCertificationSaGa),
        fork(requestAddUserSaga)
    ]);
}