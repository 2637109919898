import React, { useState } from 'react';
import { sendCryptoFromWallet } from '../../../api/wallet';
import Loader from '../../Loader';

function WalletTransfer({ currentWallet, currentUser }){

    const [receiverAddress, setReceiverAddress] = useState('');
    const [transferAmount, setTransferAmount] = useState('');
    const [loadingTransfer, setLoadingTransfer] = useState(false);
    const [transferDone, setTransferDone] = useState(false);
    const [transferError, setTransferError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingTransfer(true);
        setTransferError(false);
        setTransferDone(false);

        sendCryptoFromWallet(currentUser.uid, currentWallet.name, currentWallet.address, receiverAddress, transferAmount).then((response) => {
            console.log('TRANSFER RESULT', response);
            setTransferDone(true);
        }).catch((error) => {
            console.log('TRANSFER ERROR', error);
            setTransferError(true);
        }).finally(() => {
            setLoadingTransfer(false);
        });
    }
  
    return (
        <>
            <div className="item-details-dsce collection-form wallet-transfer-form">

                <form onSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12 ">
                            <div className="form-group">
                                <label>Your {currentWallet.name.toUpperCase()} Address</label>
                                <input type="text" value={currentWallet.address} className="form-control" placeholder="" required disabled/>
                            </div>
                        </div>

                        <div className="col-lg-12 ">
                            <div className="form-group">
                                <label>Receiver's {currentWallet.name.toUpperCase()} Address</label>
                                <input type="text" value={receiverAddress} className="form-control" placeholder="" onChange={(e) => setReceiverAddress(e.target.value)} required />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>Amount</label>
                                <input type="number" value={transferAmount} min={0.000001} step={0.000001} className="form-control" placeholder="0" onChange={(e) => setTransferAmount(e.target.value)} required />
                            </div>
                        </div>
                    
                        <div className="col-lg-12 col-md-12 pt-50">
                            <div className="text-center">
                                {currentWallet.balance == 0 && (<h5 className="text-warning">You do not have sufficient funds to make a transfer</h5>)}
                                {!loadingTransfer && currentWallet.balance != 0 && (<button type="submit" className="default-btn" >Transfer</button>)}
                                {loadingTransfer && <Loader size={10}/>}
                            </div>

                            {transferDone && (
                                <div className="involved-card pt-20">
                                    <div className="icon">
                                        <i className="ri-checkbox-circle-line text-success"></i>
                                    </div>
                                    <h3 className="text-success">Transfer Successful</h3>
                                </div>
                            )}
                            {transferError && (
                                <div className="involved-card pt-20">
                                    <div className="icon">
                                        <i className="ri-close-circle-line text-danger"></i>
                                    </div>
                                    <h3 className="text-danger">Oops! Something Happened.</h3>
                                    <h6 className="text-danger">Please Try Again After Sometime.</h6>
                                </div>
                            )}
                        </div>                                                

                    </div>
                </form>

            </div>
        </>
    )
}

export default WalletTransfer;

