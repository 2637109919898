import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import loader from './assets/loader/loader.gif'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App';
import { createGlobalStyle } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'flag-icon-css/css/flag-icon.min.css';

const GlobalStyle = createGlobalStyle`
:root{
  --service-explain-text:18.5px;
  --page-title:53px;
  --subtitle:32px;
  --list-title:22.5px;
  --list-subtitle:17.5px;
  --detail-page-title:53px;
  --detail-page-subtitle:53px;
  --detail-page-subtext:32.5px;
  --detail-page-button:16.5px;
  --explain-text:18.5px;
  --line-height-exp:22.5px;
  --dashboard-page-title:22.5px;
  --dashboard-page-subtitle:18.5px;
  --dashboard-page-info:18.5px;
  --dashboard-page-button:16.5px;
  --footer-title:35px;
  --footer-info:15.5px;
  --user-sign-up-certificate-checkbox:22.5px;
  --user-sign-up-certificate-header:15.5px;
  --user-sign-up-certificate-explain-text:13.5px;
  --user-sign-up-certificate-btn-text:18.5px;

  --tablet-service-explain-text:15.5px;
  --tablet-page-title:38px;
  --tablet-subtitle:27.5px;
  --tablet-list-title:22.5px;
  --tablet-list-subtitle:15.5px;
  --tablet-detail-page-title:38px;
  --tablet-detail-page-subtitle:35.5px;
  --tablet-detail-page-subtext:22.5px;
  --tablet-detail-page-button:15.5px;
  --tablet-explain-text:15.5px;
  --tablet-line-height-exp:22.5px;
  --tablet-dashboard-page-title:22.5px;
  --tablet-dashboard-page-subtitle:18.5px;
  --tablet-dashboard-page-info:15.5px;
  --tablet-dashboard-page-button:13.5px;
  --tablet-footer-title:28px;
  --tablet-footer-info:13.5px;
  --tablet-user-sign-up-certificate-checkbox:18.5px;
  --tablet-user-sign-up-certificate-header:13.5px;
  --tablet-user-sign-up-certificate-explain-text:11.5px;
  --tablet-user-sign-up-certificate-btn-text:16.5px;

  --mobile-service-explain-text:13.5px;
  --mobile-page-title:28px;
  --mobile-subtitle:21.5px;
  --mobile-list-title:17.5px;
  --mobile-list-subtitle:13.5px;
  --mobile-detail-page-title:28px;
  --mobile-detail-page-subtitle:15.5px;
  --mobile-detail-page-subtext:15.5px;
  --mobile-detail-page-button:13.5px;
  --mobile-explain-text:13.5px;
  --mobile-line-height-exp:22.5px;
  --mobile-dashboard-page-title:18.5px;
  --mobile-dashboard-page-subtitle:17.5px;
  --mobile-dashboard-page-info:13.5px;
  --mobile-dashboard-page-button:13.5px;
  --mobile-footer-title:35px;
  --mobile-footer-info:15.5px;
  --mobile-user-sign-up-certificate-checkbox:14.5px;
  --mobile-user-sign-up-certificate-header:12.5px;
  --mobile-user-sign-up-certificate-explain-text:12.5px;
  --mobile-user-sign-up-certificate-btn-text:14.5px;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
#root{
  overflow:hidden;
}
`;

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'fr', 'kr'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath:'../assets/locales/{{lng}}/translation.json',
    },
  })

const loadingMarkup = (
    <div>
      <img src={loader} width="200px" height="200px" alt="loading" style={{marginLeft:"40%", marginTop:"20%"}}/>
    </div>
)



const queryClient = new QueryClient();

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <GlobalStyle/>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


  {/*<div className="d-flex justify-content-center" style={{marginTop:"20%"}}>
    <div className="spinner-border text-danger" role="status" style={{width:'8rem', height:'8rem'}}>
  
    </div>
  </div>*/}
