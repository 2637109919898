import React from "react";
import styled from "styled-components";
import loaderImage from '../../assets/images/loader.gif';

const LoadingComponentContainer = styled.div`
    position:fixed;
    top:0;left:0;
    width:100%;height:100%;
    background-color:rgba(45,45,45,0.65);
    z-index:10000;
`;

const LoadingComponentWrapper=styled.div`
    position:absolute;
    top:50%;left:50%;
    transform:translateX(-50%) translateY(-50%);
    width:auto;height:auto;
    >p{
        margin-top: 10.5px;
        color:#fff;
        text-align:center;
        font-size:var(--explain-text);
    }
    @media screen and (max-width:855px){
        >p{
            font-size:var(--tablet-explain-text);
        }
    }
    @media screen and (max-width:578px){
        >p{
            font-size:var(--mobile-explain-text);
        }
    }
`;

const LoadingImageWrapper = styled.div`
    
    width:125px;height:125px;
    border-radius:100%;
    overflow:hidden;
    margin: 0 auto;
    >img{
        display:block;
        width:100%;height:100%;
        object-fit:cover;
    }
    @media screen and (max-width:855px){
        width:85px;height:85px;
    }
    @media screen and (max-width:578px){
        width:55px;height:55px;
    }
`;

const LoadingComponent = ({message})=>{
    return(
        <LoadingComponentContainer>
            <LoadingComponentWrapper>
                <LoadingImageWrapper>
                    <img src={loaderImage}/>
                </LoadingImageWrapper>
                <p>{message}</p>
            </LoadingComponentWrapper>
        </LoadingComponentContainer>
    )
}

export default LoadingComponent;