import React, { memo, useState, useCallback } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import ColleryLogo3 from '../../assets/Collary_logo_03.jpg';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HOMEPAGE, USER_SIGNUP, USER_SIGNUP_CERTIFICATION } from '../../constants/applicationUrls';
import { Link, Switch } from 'react-router-dom';
import MessageComponent from '../MessageCommponent';
import { SignUpCertificateMessage } from '../../constants/applicationMessage';
import { useHistory } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { useDispatch } from 'react-redux';
import { CERTIFY_SIGN_UP_USER, } from '../../reducers/users'

const SignUpCertificateContainer = styled.div`
    width:100%;height:auto;
`;

const SignUpCertificateWrapper = styled.div`
    width:100%;height:auto;
    min-height:${window.innerHeight}px;
    .main-contents{
        max-width:768px;height:100%;
        margin:0 auto;
        .main-logo-box{
            width:100%;height:158px;
            box-sizing:border-box;
            padding: 30px 0 30px 0;
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:center;
            .logo-img{
                display:inline-block;
                width:64px;height:64px;
                object-fit:cover;
                border-radius:100%;
            }
            .service-name{
                font-size:var(--page-title);
                font-weight:bold;
                color:#ff4d2c;
                margin-left:12.5px;
            }
        }
        .agree-entire-item{
            width:100%;height:auto;
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:space-between;
            .check-box{
                width:35.5px;height:35.5px;
                border:2.5px solid #454545;
                border-radius:100%;
                font-size:var(--user-sign-up-certificate-checkbox);
                display: flex;
                align-items: center;
                justify-content:center;
                cursor:pointer;
                &:hover{
                    ${isMobile ?
        'color:#454545;border:2.5px solid #454545;'
        :
        'color:#ff4d2c;border:2.5px solid #ff4d2c;'
    }
                }
                
                &.on{
                    color:#ff4d2c;
                    border:2.5px solid #ff4d2c;
                }
            }
            .explain-text{
                width:calc(100% - 45.5px);height:auto;
                line-height:24.5px;
                font-size:var(--user-sign-up-certificate-header);
                font-weight:bold;
                white-space:pre-wrap;
                text-decoration:underline;
            }
        }
        .agree-item{
            margin:15.5px 10.5px 15.5px auto;
            width:100%;height:auto;
            .agree-item-header{
                width:100%;height:auto;
                display:flex;
                flex-direction:row;
                align-items:center;
                justify-content:space-between;
                margin-bottom: 10.5px;
                .check-box{
                    width:33.5px;height:33.5px;
                    border:2.5px solid #454545;
                    border-radius:100%;
                    font-size:var(--user-sign-up-certificate-checkbox);
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    cursor:pointer;
                    &:hover{
                        ${isMobile ?
        'color:#454545;border:2.5px solid #454545;'
        :
        'color:#ff4d2c;border:2.5px solid #ff4d2c;'
    }
                    }
                    &.on{
                        color:#ff4d2c;
                        border:2.5px solid #ff4d2c;
                    }
                }
                .explain-text{
                    width:calc(100% - 45.5px);height:auto;
                    line-height:22.5px;
                    font-weight:bold;
                    font-size:var(--user-sign-up-certificate-header);
                    white-space:pre-wrap;
                }
            }
            .agree-item-explain{
                width:100%;height:185.5px;
                font-size:var(--user-sign-up-certificate-explain-text);
                overflow-x:hidden;
                overflow-y:scroll;
                border:1px solid rgba(45,45,45,0.65);
                background-color:#fff;
                color:#000;
                box-sizing:border-box;
                padding:15.5px;
                line-height: 19.4px;
            }
        }
        .button-list-box{
            width:100%;height:auto;
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:space-between;
            padding:30px 0;
            .btn{
                display: flex;
                align-items: center;
                justify-content: center;
                width:45%;
                height:51px;
                font-size:var(--user-sign-up-certificate-btn-text);
                color:#fff;
                border-radius:4.5px;
                &.cancel{
                    background-color:#454545;
                }
                &.confirm{
                    background-color:#ff4d2c;
                }
            }
        }
    }  
    @media screen and (max-width:855px){
        .main-contents{
            max-width:568px;
            .main-logo-box{
                width:100%;height:128px;
                padding: 15px 0 15px 0;
                .logo-img{
                    width:54px;height:54px;
                }
                .service-name{
                    font-size:var(--tablet-page-title);
                }
            }
            .agree-entire-item{
                width:100%;height:auto;
                display:flex;
                flex-direction:row;
                align-items:center;
                justify-content:space-between;
                .check-box{
                    width:32.5px;height:32.5px;
                    font-size:var(--tablet-user-sign-up-certificate-checkbox);
                    &.on{
                        color:#ff4d2c;
                        border:2.5px solid #ff4d2c;
                    }
                }
                .explain-text{
                    width:calc(100% - 45.5px);height:auto;
                    line-height:18.5px;
                    font-size:var(--tablet-user-sign-up-certificate-header);
                }
            }
            .agree-item{
                margin:13.5px 8.5px 13.5px auto;
                .agree-item-header{
                    margin-bottom: 8.5px;
                    .check-box{
                        width:30.5px;height:30.5px;
                        border:2.5px solid #454545;
                        border-radius:100%;
                        font-size:var(--tablet-user-sign-up-certificate-checkbox);
                        &.on{
                            color:#ff4d2c;
                            border:2.5px solid #ff4d2c;
                        }
                    }
                    .explain-text{
                        width:calc(100% - 42.5px);height:auto;
                        line-height:20.5px;
                        font-size:var(--tablet-user-sign-up-certificate-header);
                    }
                }
                .agree-item-explain{
                    width:100%;height:175.5px;
                    font-size:var(--tablet-user-sign-up-certificate-explain-text);
                    box-sizing:border-box;
                    padding:12.5px;
                    line-height: 17.4px;
                }
            }
            .button-list-box{
                padding:25px 0;
                .btn{
                    height:41px;
                    font-size:var(--tablet-user-sign-up-certificate-btn-text);
                    color:#fff;
                }
            }
        } 
    }

    @media screen and (max-width:578px){
        min-height:auto;
        .main-contents{
            max-width:348px;
            .main-logo-box{
                height: 78px;
                padding: 10px 0 10px 0;
                .logo-img{
                    width:44px;height:44px;
                }
                .service-name{
                    font-size:var(--mobile-page-title);
                }
            }
            .agree-entire-item{
                width:100%;height:auto;
                display:flex;
                flex-direction:row;
                align-items:center;
                justify-content:space-between;
                .check-box{
                    width:28.5px;height:28.5px;
                    font-size:var(--mobile-user-sign-up-certificate-checkbox);
                }
                .explain-text{
                    width:calc(100% - 40.5px);height:auto;
                    line-height:18.5px;
                    font-size:var(--mobile-user-sign-up-certificate-header);
                }
            }
            .agree-item{
                margin:13.5px 8.5px 13.5px auto;
                .agree-item-header{
                    margin-bottom: 8.5px;
                    .check-box{
                        width:26.5px;height:26.5px;
                        font-size:var(--mobile-user-sign-up-certificate-checkbox);
                        &.on{
                            color:#ff4d2c;
                            border:2.5px solid #ff4d2c;
                        }
                    }
                    .explain-text{
                        width:calc(100% - 40.5px);height:auto;
                        line-height:20.5px;
                        font-size:var(--mobile-user-sign-up-certificate-header);
                    }
                }
                .agree-item-explain{
                    width:100%;height:175.5px;
                    font-size:var(--mobile-user-sign-up-certificate-explain-text);
                    box-sizing:border-box;
                    padding:12.5px;
                    line-height: 17.4px;
                }
            }
            .button-list-box{
                padding:25px 0;
                .btn{
                    height:31px;
                    font-size:var(--mobile-user-sign-up-certificate-btn-text);
                    color:#fff;
                }
            }
        } 
    }
    @media screen and (max-width:378px){
        .main-contents{
            max-width:328px;
        }
    }
`;

const SignUpCertificate = memo(() => {
    const [agreeEntire, setAgreeEntire] = useState(false);
    const [personalInfoAgree, setPersonalInfoAgree] = useState(false);
    const [useOfServiceAgree, setUserOfServiceAgree] = useState(false);
    const [geolocationInfoAgree, setGetlocationInfoAgree] = useState(false);
    const [showMessageComponent, setShowMessageComponent] = useState(false);
    const [message, setMessage] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();
    /* const matchesSignUpCerti = useRouteMatch(USER_SIGNUP_CERTIFICATION);
    console.log(matchesSignUpCerti); */

    const onClickAgreeAll = useCallback(() => {
        if (agreeEntire) {
            setAgreeEntire(false);
            setPersonalInfoAgree(false);
            setUserOfServiceAgree(false);
            setGetlocationInfoAgree(false);
        } else {
            setAgreeEntire(true);
            setPersonalInfoAgree(true);
            setUserOfServiceAgree(true);
            setGetlocationInfoAgree(true);
        }

    }, [agreeEntire])

    const onClickConfirm = useCallback(() => {
        if (!personalInfoAgree) {
            setShowMessageComponent(true);
            setMessage(SignUpCertificateMessage.agreePersonalInfoMessage);
        } else if (!useOfServiceAgree) {
            setShowMessageComponent(true);
            setMessage(SignUpCertificateMessage.agreeUseOfServiceMessage);
        } else {
            dispatch({
                type: CERTIFY_SIGN_UP_USER,
                data: {
                    personalInfoAgree,
                    useOfServiceAgree,
                    geolocationInfoAgree
                }
            })
            history.push(USER_SIGNUP);
        }
    }, [
        personalInfoAgree,
        useOfServiceAgree,
        geolocationInfoAgree
    ])

    const cancelFunction = useCallback(() => {
        setShowMessageComponent(false);
        setMessage('');
    });

    const onClickUseOfService = useCallback(()=>{
        if(useOfServiceAgree&&agreeEntire){
            setAgreeEntire(false);
        }
        setUserOfServiceAgree(prev => !prev);
    },[
        agreeEntire,
        useOfServiceAgree
    ])

    const onClickPersonalInfoAgree=useCallback(()=>{
        if(personalInfoAgree&&agreeEntire){
            setAgreeEntire(false);
        }
        setPersonalInfoAgree(prev => !prev);
    },[
        personalInfoAgree,
        agreeEntire
    ])

    return (
        <>
            <Helmet>
                <link rel="icon" href={ColleryLogo3}></link>
            </Helmet>
            <Header />
            {
                showMessageComponent &&
                <MessageComponent message={message} cancelFunction={cancelFunction} />
            }
            <SignUpCertificateContainer>
                <SignUpCertificateWrapper>
                    <div className="main-contents">
                        <div className="main-logo-box">
                            <img className="logo-img" src={ColleryLogo3} />
                            <p className="service-name">
                                COLLERY
                            </p>
                        </div>
                        <div className="agree-entire-item">
                            <p onClick={onClickAgreeAll} className={`check-box ${agreeEntire ? 'on' : ''}`}>
                                <FontAwesomeIcon icon={faCheck} />
                            </p>
                            <div className="explain-text">
                                꼴레리(Collery) 서비스에 대하여 다음의  이용약관, 개인정보처리방침 (필수)에 모두 동의합니다.
                            </div>
                        </div>
                        <div className="agree-item">
                            <div className="agree-item-header">
                                <p onClick={(evt) => onClickUseOfService(evt)} className={`check-box ${useOfServiceAgree ? 'on' : ''}`}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </p>
                                <div className="explain-text">
                                    이용약관 동의<span>(필수)</span>
                                </div>
                            </div>
                            <div className="agree-item-explain">
                                제1조 (목적)<br />

                                본 이용약관은 주식회사 내스타일(NESTYLE)(이하 회사) 이 제공하는 꼴레리(Collery) 웹사이트 서비스(www.collery.io)의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

                                <br />
                                <br />
                                제2조 (정의)<br />

                                1. 콘텐츠는 Collery 내에 전시되는 사진, 그림, 캐릭터, 음악 등을 말합니다.<br />
                                2. Collery 내의 콘텐츠는 상업적 용도로 사용할 수 없는 비 상업적 용도의 이미지를 말합니다.<br />
                                3. 회원이란 Collery에 가입한 회원을 말하며, 회원의 역할에 따라 일반회원과 작가회원으로 구분할 수 있습니다.<br />
                                4. 작가 회원이란 Collery에 콘텐츠를 업로드하여 판매나 임대하기 위해 가입한 회원을 말합니다. 콘텐츠를 하나 이상 업로드하게 되면 자동으로 작가회원 대우를 받게됩니다.<br />
                                5. 아이디(ID)는 회원 식별과 서비스 이용을 위하여 회원이 신청하고 회사가 승인한 문자와 숫자의 조합을 말하며 보통 가입할 때 입력하는 이메일 주소를 말합니다.<br />
                                6. 비밀번호는 회원이 부여받은 아이디와 일치된 회원임을 확인하고, 비밀 보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 말합니다.<br />
                                7. 서비스란 회원이 회사 사이트 내에서 콘텐츠를 무료 또는 유료로 이용하는 행위를 말합니다.<br />
                                8. 해지는 회사 또는 회원이 서비스 계약을 해지하는 것을 말합니다.<br />
                                9. 포인트는 작가 회원의 콘텐츠 판매 시 제공되는 현금화 가능한 전자화폐를 의미합니다.(포인트제 적용은 추후 제공할 예정입니다)<br /><br />


                                제3조 (약관의 명시 및 효력, 개정)<br />

                                1. 이 약관은 회원이 쉽게 알 수 있도록, 서비스 초기화면에 게시합니다.<br />
                                2. 회사는 약관의  규제에관한법률, 정보통신망이용촉진및정보보호등에관한법 률(이하 정보통신망법) 등 관련법을 위배하지 않는 범위 내에서 이 약관을 개정할 수 있습니다.<br />
                                3. 회사가 약관을 개정하는 경우 적용 일자 및 개정사항을 명시하여 적용일 7일 전에 서비스 공지사항 게시판 또는 이메일을 통해 공지합니다.
                                다만, 회원에게 불리한 약관 개정의 경우에는 공지 외에 일정 기간 서비스 내 전자우편, 로그인 시 동의 창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.<br />
                                4. 회사가 제3조 3항에 따라 개정약관을 공지 또는 통지한 공지일로부터 7일 이내에 회원이 명시적으로 거부하지 않는 경우 개정약관에 동의한 것으로 봅니다.<br />
                                5. 회원이 개정에 동의하지 않는다는 의사를 표시한 경우 회사는 개정된 약관을 적용할 수 없으며, 회원은 개정 약관의 효력 발생일부터 서비스를 이용할 수 없습니다.<br />
                                6. 약관을 개정할 경우 그 개정약관은 그 적용 일자 이후에 체결되는 계약에만 적용됩니다. 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다.<br />

                                <br />
                                제4조 (약관의 해석)<br />

                                1. 이 약관에서 정하지 않은 사항이나 해석은 관련 법령 또는 상 관례에 따릅니다.<br />
                                2. 서비스를 이용 중인 회원들의 분쟁 발생 시 약관을 우선으로 적용하고, 해석이 애매하거나 이 약관에서 정하지 않은 사항은 관련 법령 또는 상 관례에 따라 분쟁의 당사자가 해결해야 합니다.<br />

                                <br />
                                제5조 (이용계약 체결)<br />

                                1. 서비스 이용계약은 회원이 되고자 하는 자(이하 가입 신청자)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이를 수락함으로써 체결됩니다.<br />
                                2. 제1항 신청에 있어 회사는 필요한 경우 본인확인 기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.<br />
                                3. 가입 신청자는 가입신청 시 진실한 정보를 기재해야 하며, 허위의 정보를 기재함으로 인한 불이익 및 법적 책임은 가입 신청자에게 있습니다.<br />
                                4. 회사는 다음 각호에 해당하는 신청에 대하여는 승낙을 하지 않습니다.<br />
                                ① 만 14세 미만의 경우<br />
                                ② 실명이 아니거나 타인의 명의를 이용한 경우<br />
                                ③ 이용자의 귀책사유로 인하여 승인할 수 없거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br />
                                5. 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입 신청자에게 알리도록 합니다.<br />
                                6. 회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.<br /><br />


                                제6조 (회원 정보의 변경)<br />

                                1. 회원은 서비스 내 회원 메뉴를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 단, 서비스 관리를 위해 필요한 아이디 및 이메일 주소 등은 수정할 수 없습니다.<br />
                                2. 제1항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br /><br />


                                제7조 (개인정보의 보호 및 사용)<br />

                                회사는 관계 법령에 따라 회원 등록정보를 포함한 이용자의 개인정보를 보호하기 위해 노력하며, 이용자 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보 취급방침이 적용됩니다. 그러나 이용 회원의 귀책사유로 인해 노출된 정보에 대해서는 일체의 책임을 지지 않습니다.

                                <br />
                                제8조 (회원의 아이디 및 비밀번호의 관리에 대한 의무)<br />

                                1. 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.<br />
                                2. 회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인한 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.<br />
                                3. 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.<br />
                                4. 제3항의 상황에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br /><br />


                                제9조 (회원에 대한 통지)<br />
                                회사가 회원에 대해 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 공지사항, 전자우편주소, 전자쪽지 등으로 할 수 있습니다.<br /><br />


                                제10조 (회사의 의무)<br />

                                1. 회사는 관련 법령 또는 이 약관을 위반하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다합니다.<br />
                                2. 회사는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호 정책을 공시하고 준수합니다.<br />
                                3. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만 즉시 처리가 곤란한 경우는 회원에게 그 사유와 처리 일정을 통보하여야 합니다.<br />
                                <br />

                                제11조 (회원의 의무)<br />

                                1. 회원은 가입 신청 또는 회원 정보 변경 시 모든 사항을 사실대로 작성하여야 합니다. 만일 허위의 사실이나 타인의 정보를 이용하여 등록한 경우에는 일체의 권리를 주장하거나 보호받을 수 없습니다.<br />
                                2. 회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 이용약관 및 회사가 공지하는 사항을 준수하여야 합니다.<br />
                                3. 회원은 서비스의 이용 권한, 계약상의 지위 등을 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.<br />
                                4. 회원은 아래와 같은 행위를 하여서는 안 되며, 이러한 행위를 하는 경우에 회사는 회원 서비스 이용 제한 및 적법 조치를 포함한 제재를 가할 수 있습니다.<br />
                                ① 서비스를 통해 얻은 콘텐츠를 서비스 이용 외의 목적으로 사용하거나 복제하는 행위, 제3자에게 제공하는 행위<br />
                                ② 제16조 (콘텐츠 이용제한)에 해당하는 행위<br />
                                ③ 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용조건을 위반하는 행위<br />
                                ④ 서비스에 위해를 가하거나 고의로 방해하는 행위<br />
                                ⑤ 서비스 내 기능을 임의대로 조작하거나 고의적인 부정거래를 하는 행위<br />
                                (예 : 무단으로 갤러리내 이미지를 다운로드하여 유출하는 경우 등)
                                ⑥ 기타 관계 법령을 위반하는 행위<br /><br />

                                제12조 (회원의 계약해지)<br />

                                1. 회원은 언제든지 서비스 내 회원 정보 메뉴를 통하여 서비스 이용계약 해지를 신청할 수 있으며 회사는 이를 즉시 처리합니다.<br />
                                2. 회원이 계약을 해지(회원탈퇴)할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원 정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸합니다.
                                단, 작가회원의 경우 회원탈퇴 신청을 한 시점에서 14일 이후에 모든 데이터가 소멸합니다. 이것은 탈퇴하려는 작가회원의 콘텐츠를 기존에 구매한 회원의 재다운로드를 보장하기 위함입니다.
                                또한, 탈퇴신청을 한 순간부터 해당 작가의 콘텐츠는 검색에서 노출되지 않으며, 새로운 구매가 불가능합니다.<br />
                                3. 따라서 회원탈퇴 신청 후 같은 이메일 주소로 재가입을 원하는 작가회원의 경우 14일 이후에 가능합니다.<br />
                                4. 해지로 인하여 콘텐츠 이용 및 현금 전환 신청과 관련한 정보가 삭제됨에 따라 회원에게 발생하는 손해에 대해 회사는 책임지지 않습니다.<br /><br />


                                제13조 (서비스의 제공)<br />

                                1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.<br />
                                ① 콘텐츠 제공: 작가 회원이 업로드 한 콘텐츠를 제공, 판매, 중개<br />
                                ② 콘텐츠 이용: 작가 회원 및 회사가 제공하는 콘텐츠를 이용, 구매, 중개<br />
                                ③ 기타 회사가 추가 개발하여 회원에게 제공하는 일체의 서비스<br />
                                2. 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.<br />
                                3. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절, 천재지변 또는 운영상 타당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.
                                다만 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.<br />
                                4. 회사는 서비스의 제공에 필요한 경우 정기점검을 할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.<br /><br />


                                제14조 (콘텐츠의 관리)<br />

                                1. 회사에서 제공하는 콘텐츠는 회사의 독자적 판단에 따라 임의로 삭제 및 변경할 수 있습니다.<br />
                                2. 저작권, 재산권, 초상권 문제 등을 포함한 이유로 콘텐츠에 문제가 발생했을 때 회사는 이를 공지함(사이트 내 공지사항 게재 또는 전자메일 통지)으로써 회원들에 대한 알릴 의무를 다하는 것으로 하며,
                                회원은 그 사용을 중지해야 합니다. 공지 후 해당 콘텐츠 사용으로 인한 모든 법적 책임은 회사에 있지 않고 사용한 회원에게 있습니다.<br /><br />


                                제15조 (콘텐츠 저작권)<br />

                                1. 회사의 서비스에서 제공되는 콘텐츠의 저작권은 그 적법한 저작자 혹은 작가 회원 및 관련 권리자에게 있습니다.
                                회원은 회원 종류의 구분 및 지불한 금액에 따라 허용되는 목적의 범위 내에서만 사용 허락을 받는 것이며, 회사가 콘텐츠의 저작권 자체를 판매하는 것은 아닙니다.<br />
                                2. 회사의 사이트에서 제공되는 콘텐츠의 무단복제, 무단사용 또는 회사와 사전협의 없이 사이트에 게시된 이용약관을 벗어나는 행위에 대해서는 저작권법 및 관련 법률에 의한 권리 침해로 간주합니다.
                                따라서 회원은 이 약관에서 명시적으로 허용하는 사항 이외의 이미지 사용으로 발생하는 모든 종류의 손해 또는 책임에 대해 회사와 제3자에게 손해를 배상하여야 합니다.
                                또한, 제3자의 청구에 대해서는 자신의 비용으로 회사를 면책시켜야 합니다.<br />
                                3. 사이트에서 제공되는 콘텐츠의 피사체(인물, 건물, 장소 등)에 대한 초상권, 재산권, 저작권, 상표권, 특허권, 디자인권(이하 ‘저작권 등’)은 회사가 보유하지 않은 것이 있을 수 있습니다.
                                (예: 군중 속 인물의 초상권, 배경 건물의 재산권 등). 따라서 콘텐츠의 일부 피사체는 사용 형태에 따라서는 회원이 사용 전에 직접 해당 권리를 취득해야 하는 경우가 있을 수 있으므로,
                                사용 시 저작권 등에 대한 권리의 존재가 의심되는 일부 피사체에 대하여는 사전에 회사에 문의하셔야 합니다.
                                만일 회사와 사전협의 없이 사용하여 제3자와 이들 권리에 대한 분쟁이 발생할 경우 회사는 일체의 책임을 지지 않으며, 모든 책임은 회원에게 있습니다.
                                회원은 이러한 사용으로 인한 제3자의 청구에 대해 회사를 면책시켜야 합니다.<br />
                                4. 콘텐츠에 일부 포함될 수 있는 로고, 심벌, 캐릭터, 트레이드마크, 서비스마크 등에 대한 권리는 회사가 아닌 해당 권리자에게 있으므로 해당 권리의 침해가 되는 형태로 사용하려 할 경우
                                권리자로부터 사전에 승인을 받아야 합니다.<br />
                                5. 전항의 경우 회원이 사용 중지 및 삭제조치 처리에 협조하지 않아 발생하는 저작권 분쟁이나 이로 인해 확대된 손해에 대한 책임은 회원에게 있으며,
                                회사는 해당 분쟁이나 확대된 손해에 대하여 책임지지 않습니다.<br />
                                6. 위 각 항 중, 회사와 회원의 책임이 문제 되는 제3자와의 분쟁이 있을 때,
                                회원은 회사의 동의 없이 그 제3자에 대한 책임을 인정하거나 조정, 합의를 할 수 없으며, 만일 회원이 회사의 동의 없이 제3자에 대한 책임인정,
                                조정, 기타 여하한 합의를 하더라도 이는 회사에 대해 효력을 미칠 수 없습니다.<br />
                                7. 콘텐츠를 업로드하거나 구매, 사용함으로써 발생하는 모든 법적 분쟁에 대해 회사는 책임지지 않습니다. 해당 분쟁에 대해서는 당사자 간의 합의 및 분쟁을 통해 해결해야 합니다.
                                회사는 당사자들 간의 원만한 합의를 위해 사실관계를 당사자에게 통지할 수는 있습니다.<br />
                                8. 회원이 저작권을 보유한 콘텐츠가 아닌 제3자의 콘텐츠를 도용하거나 무단으로 판매 및 거래하는 경우 발생하는 모든 법적 분쟁에 대해 회사는 책임지지 않으며
                                해당 콘텐츠를 판매한 회원이 모든 책임을 집니다. 회사는 불관용 원칙을 적용, 해당 회원을 영구적으로 서비스에서 활동하지 못하도록 조치할 수 있습니다.<br /><br />


                                제16조 (콘텐츠 사용제한)<br />

                                1. 모든 콘텐츠는 하위 라이선스를 발행하거나 재배포, 재분배, 재판매(배급, 양도, 대여, 임대, 임차)를 할 수 없습니다.<br />

                                2. 모든 콘텐츠는 아래와 같은 용도로 사용할 수 없습니다.<br />
                                ① 음란물 및 성인 광고, 유흥업소, 고리대금업, 기타 매춘업, 비뇨기과, 성형외과 등 사회 미풍양속과 일반 정서에 반하는 용도<br />
                                ② 특정 제품을 모델이 보증하는 형식의 과대광고<br />
                                A. 예) 성형외과 비교 사진 (before & after)<br />
                                B. 모델에 대한 허위사실을 기재하여 사용한 경우<br />
                                예) 이미지의 모델이 특정 서비스를 이용하는 것처럼 사용된 경우<br />
                                예) 이미지의 모델에 가명, 허위 신상 등을 표시하여 사용된 경우<br />
                                C. 기타 과대광고 및 허위 광고 등<br />
                                ③ 모델의 인격권을 훼손하는 용도<br />
                                A. 타인을 비방하거나 외설적으로 사용하는 등 명예를 훼손하는 용도<br />
                                B. 신체 및 얼굴 등과 제3자의 이미지를 합성 혹은 재가공하는 행위<br />
                                C. 기타 모델의 인격권을 훼손하는 용도 등<br />
                                예) 소개팅, 금융, 병원 등 그 외 기타 서비스에서 모델의 인격권을 훼손할 수 있다고 판단되는 경우 인물 콘텐츠 사용이 불가합니다.<br />
                                해당 제한 사항과 관련하여 자세한 설명이 필요하시면 회사로 문의 바랍니다.<br />
                                ④ 기타 불법적인 사용<br />
                                ⑤ 콘텐츠를 전시회/공모전, 로고, 심벌, 트레이드마크, 서비스마크, 상표 등으로 사용할 수 없습니다<br />

                                3. 콘텐츠 사용 시 이용제한에 위반될 수 있다고 우려되거나 판단이 어려운 경우 회사 혹은 콘텐츠의 저작권자(작가 회원)에게 미리 문의 후 사용해야 합니다.
                                특히 인물 콘텐츠 사용의 경우 콘텐츠 이용제한에 있어 좀 더 민감하게 적용될 수 있으므로 중요한 인물 콘텐츠의 사용에 있어 회사 혹은 콘텐츠의 저작권자(작가 회원)에게 미리 문의 후 사용해야 합니다.<br />
                                4. 모든 콘텐츠는 비독점적 사용을 전제로 제공되는 것이며, 독점사용을 위하여는 별도협의가 필요합니다.<br />
                                5. 구매한 콘텐츠를 원본 형태 그대로 웹하드, 블로그, 카페, SNS(페이스북, 인스타그램 등) 등에 재배포, 재분배 목적으로 업로드하거나 스크랩하는 것을 금합니다.<br />
                                6. 다운로드 받은 콘텐츠 및 2차 저작물에 대하여 지적 재산권의 권리등록을 금합니다.<br />
                                7. 2차 저작물을 만들어 판매 및 배포를 해야 하는 경우 회사에 미리 문의하시기 바랍니다.<br />
                                (예 : 구매한 이미지로 제품패키지, 엽서 및 액자를 만들어 판매할 경우 일정수량(1,000부)이 넘는다면 따로 추가 계약이 필요할 수 있습니다.)<br />
                                8. 저작물을 납품(제2차 사용자에게 제공) 시 납품된 저작물의 원본 소스는 동시에 납품할 수 없습니다. 만일 원본 저작물을 납품하여 클라이언트가 원본 콘텐츠를 이용하여 2차 제작물을 제작할 경우, 그로 인한 모든 책임은 납품한 회원에게 있습니다.
                                <br />
                                *이미지 사용제한에 위반되는 사용이 발견되었을 시 해당 이미지 사용 고객은 회사의 안내에 따라 신속하게 문제가 되는 이미지 사용을 중지해야 합니다.
                                또한, 사용제한에 위반되는 사용으로 인해 회사 및 이미지의 저작권자에게 손해 및 피해가 발생한 경우 해당 이미지 사용 고객은 그 손해와 피해를 보상해야 합니다.<br /><br />


                                제17조 (콘텐츠의 환불)<br />

                                1. 디지털 콘텐츠의 특성상 구매한 콘텐츠에 대해서는 환불이 불가능합니다.<br />
                                2. 환불이 필요하다고 인정되는 사유에 대해서는 회사와 협의할 수 있습니다.<br />
                                4. 환불시에는 결제할 때 발생하였던 수수료를 제외한 금액이 환불됩니다.<br /><br />


                                제18조 (손해배상)<br />

                                서비스에서 제공되는 콘텐츠의 무단 복제, 무단 사용 또는 본 약관에 규정된 내용을 벗어나는 행위(사용범위 초과 사용 또는 콘텐츠 이용 시 제한 사항의 위반 사용 등)는 저작권법 및 관련 법률에 의한 저작권 침해에 해당합니다. 이 경우 회사에 발생한 손해를 배상하여야 하며, 회사가 이를 초과하는 손해를 입은 경우 그 초과 손해도 배상해야 합니다. 회원의 위와 같은 행위로 인하여 제3자가 회사에 대해 손해배상을 청구할 경우, 자신의 비용으로 회사를 면책시켜야 합니다.
                                <br /><br />

                                제19조 (면책조항)<br />

                                1. 회사는 천재지변, 전쟁 및 기타 이에 따르는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.<br />
                                2. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.<br />
                                3. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.<br />
                                4. 회원들의 접속 폭주로 인한 서비스 속도의 지체나 일시적 오류 그리고 회사의 서비스개선을 위한 정비 등으로 인한 서비스의 일시 중단에 대하여는 환불 또는 배상하지 않습니다.<br /><br />


                                제20조 (서비스 관련 분쟁 해결)<br />

                                1. 회사는 서비스 이용과 관련한 회원의 의견이나 불만 사항을 신속하게 처리합니다. 단, 신속한 처리가 곤란한 경우에는 그 사유와 처리 일정을 통보하여 드립니다.<br />
                                2. 회사와 회원 간에 발생한 분쟁은 전자거래기본법에 의해 설치된 전자거래 분쟁 조정위원회의 조정절차에 의해 해결할 수 있습니다.<br /><br />


                                제21조 (서비스의 종료)<br />

                                1. 회사는 서비스를 종료하고자 하는 날로부터 3개월 이전에 서비스 내 공지사항 및 전자우편 등의 방법으로 회원에게 알립니다.<br />
                                2. 서비스 종료 통지일 현재 이미 적립된 포인트는 서비스 종료일까지 본 약관이 정하는 바에 따라 소진하셔야 하며, 서비스 종료일 이후로는
                                자동 소멸하여 회원은 포인트에 관한 권리를 주장할 수 없습니다.<br />
                                3. 종료 통지일로부터 서비스 종료일까지 서비스 일부가 제한될 수 있습니다.<br /><br />


                                제22조 (준거법 및 합의관할)<br />

                                1. 회사와 회원 간에 제기된 법적 분쟁은 대한민국 법을 준거법으로 합니다.<br />
                                2. 서비스 이용으로 발생한 분쟁에 대하여 소송이 제기될 경우 회사의 소재지를 담당하는 법원을 관할 법원으로 합니다.<br /><br />

                            </div>
                        </div>
                        <div className="agree-item">
                            <div className="agree-item-header">
                                <p onClick={(evt) => onClickPersonalInfoAgree(evt)} className={`check-box ${personalInfoAgree ? 'on' : ''}`}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </p>
                                <div className="explain-text">
                                    개인정보 수집 및 이용 동의<span>(필수)</span>
                                </div>
                            </div>
                            <div className="agree-item-explain">
                                COLLERY의 회원님께서 COLLERY(https://www.collery.io)의 콘텐츠를 주문하거나 각종 서비스를 이용하는 데 있어, 원활한 주문 및 서비스 접수, 대금 결제 및 회원님에게 편리하고 유익한 맞춤 정보를 제공하기 위한 최소한의 정보를 필수 사항으로 수집합니다. 회원님께서 제공하신 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며, 수집 정보의 범위나 사용 목적, 용도가 변경될 시에는 반드시 회원님께 사전 동의를 구할 것입니다.
                                개인정보 보호정책은 정보통신망이용촉진등에관한법률(이하 '법률'이라 합니다) 제16조(개인정보의 수집 및 취급) 내지 18조(이용자의 권리)를 기본으로 하여 회원 여러분이 신뢰를 바탕으로 더욱 안전하고 편안하게 COLLERY을 이용하게 하는 데 그 목적이 있습니다.
                                개인정보의 처리 목적
                                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                                <br /><br />
                                1. 홈페이지 회원 가입 및 관리<br />
                                회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별ㆍ인증, 회원자격 유지ㆍ관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지ㆍ통지, 고충 처리 등을 목적으로 개인정보를 처리합니다.<br />
                                2. 재화 또는 서비스 제공<br />
                                서비스 제공, 계약서ㆍ청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제ㆍ정산, 채권추심 등을 목적으로 개인정보를 처리합니다.<br />
                                3. 고충 처리<br />
                                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락ㆍ통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.<br />
                                회원 정보의 수집 및 이용에 대한 안내<br /><br />
                                1. 개인정보의 수집항목 및 수집방법<br />
                                다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.<br />
                                - 성명 (또는 닉네임)<br />
                                - 현재 사용하는 E-mail 주소<br /><br />

                                판매 수익금 출금 및 세금 신고를 위해 다음의 정보들이 추가로 수집될 수 있습니다.<br />
                                - 예금주 성명<br />
                                - 은행명<br />
                                - 계좌번호<br />
                                - 작가회원 실명 및 휴대폰 번호 (본인인증을 위한 정보)<br />
                                - 주민등록번호 (1회 세금 신고 후 삭제 및 파기됨)
                                소셜 계정을 통해 회원가입 시 다음의 정보들이 추가로 수집될 수 있습니다.<br />
                                - 네이버 : 아이디, 닉네임, 성별, 연령대, 생일, 프로필 이미지<br />
                                COLLERY은 다음과 같은 방법으로 개인정보를 수집합니다.<br />
                                - 홈페이지를 통한 회원가입, 문의 이메일, 경품 행사 응모<br /><br />
                                2. 회원 정보의 수집종류 및 이용범위<br />
                                회원님이 제공한 개인정보는 오직 회원님에게 최적화된 서비스(신규 서비스의 개발 및 마케팅 광고에 활용)를 제공하기 위해서만 수집, 이용되고 있습니다.
                                우선 COLLERY의 이용을 위해서는 기본적으로 회원님의 실명, E-mail 주소, 사용자 암호 등의 기본 정보가 필요합니다. 나아가 새롭고 발전된 특정 서비스의 제공을 위해 회원님의 자발적인 참여와 동의를 조건으로 개별 및 집단적 설문조사 등을 통해 추가적인 정보를 수집하거나, 변경된 서비스 내용을 E-mail로 알려드릴 수 있습니다. 회원 가입과 동시에 해당 정보를 E-mail을 통해 수신받는 것을 동의한 것으로 간주합니다. 회원은 언제든지 E-mail 수신 동의를 거절할 수 있습니다.
                                위의 방법을 통해 수집된 개인정보는 원칙적으로 해당 서비스나 기타 조사 당시 고지 또는 명시된 목적 이외의 용도로는 사용되지 않습니다.
                                단, 법률 제17조에 의거 회원님이 동의하거나 다음의 경우에는 예외로 합니다.<br />
                                - 서비스의 제공에 따른 요금 정산을 위하여 필요한 경우<br />
                                - 통계작성ㆍ학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br /><br />
                                개인정보의 보유 기간<br /><br />
                                1. 개인정보의 보유 기간<br />
                                회원님의 개인정보는 COLLERY의 회원으로서 서비스를 이용하는 동안 COLLERY에 의해 보호되며, 앞서 언급된 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에(탈퇴 시에) 
                                회원 권익을 보호하기 위해 다음과 같이 회원 정보가 일정 기간 보존됩니다. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br />
                                A. 홈페이지 회원 가입 및 관리: 회원 탈퇴 시까지. 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지<br />
                                - 관계 법령 위반에 따른 수사ㆍ조사 등이 진행 중인 경우에는 해당 수사ㆍ조사 종료 시까지<br />
                                - 홈페이지 이용에 따른 채권ㆍ채무 관계 잔존 시에는 해당 채권ㆍ채무 관계 정산 시까지.<br />
                                B. 재화 또는 서비스 제공: 재화ㆍ서비스 공급 완료 및 요금결제ㆍ정산 완료 시까지. 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지.<br />
                                - 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 표시ㆍ광고, 계약 내용 및 이행 등 거래에 관한 기록<br />
                                →표시ㆍ광고에 관한 기록: 6월<br />
                                →계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년<br />
                                →소비자 불만 또는 분쟁처리에 관한 기록 : 3년<br />
                                - 통신비밀 보호법 제41조에 따른 통신 사실확인자료 보관<br />
                                →가입자 전기통신일시, 개시ㆍ종료 시간, 상대방 가입자 번호, 사용도수, 발신기지국 위치추적자료: 1년<br />
                                →컴퓨터 통신, 인터넷 로그 기록자료, 접속지 추적자료:3개월<br />
                                - 정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행령 제29조에 따른 본인확인 정보 보관<br />
                                →게시판에 정보 게시가 종료된 후 6개월<br />
                                C. 1회 사용 후 즉시 삭제 및 파기되는 정보<br />
                                - 사업 소득세 신고를 위해 출금 신청시 주민등록번호 수집. 익월 10일 전 사업 소득세 신고 후 즉시 삭제 및 파기<br /><br />
                                2. 개인정보의 파기절차<br />
                                COLLERY는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 바로 파기합니다. 파기절차 및 방법은 다음과 같습니다.<br />
                                - 파기절차 : 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용 기간 참조) 
                                일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br />
                                - 파기방법 : 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br /><br />
                                3. 개인정보의 제3자 제공<br />
                                COLLERY는 회원님의 개인정보를 위 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 회원님의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조, 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                                회사는 결제 서비스 및 세금 신고를 위하여 아래와 같이 외부 전문업체에 수집한 개인정보를 위탁하여 운영하고 있습니다.<br />
                                - 위탁대상자 및 위탁업무 내용  ① NICE페이먼츠 (결제 및 에스크로 서비스)  ② 페이레터 (결제 서비스)  ③ 페이플/주식회사 케이에스넷 (결제 서비스)  ④ 회계법인/세무법인 (세금 신고)<br /><br />
                                4. 개인정보 유출방지를 위한 기술적, 관리적 보호조치<br />
                                COLLERY상의 모든 정보는 구글 웹서버(Google)에서 전송, 처리 및 저장이 이루어지며 안전하게 보관되어 처리됩니다. 그러나, 다른 사람과 컴퓨터를 공유하여 사용하거나 
                                공공장소에서 서비스를 이용하는 경우 반드시 로그아웃(Log-out)을 정상적으로 하시고 브라우저를 종료하셔서, 공공장소에서의 개인정보 유출 방지에 주의하셔야 합니다.<br /><br />
                                5. 개인정보의 열람, 정정, 삭제 및 동의철회의 방법<br />
                                회원님은 언제든지 제공된 개인 정보를 열람, 정정, 삭제를 요청하실 수 있습니다. 개인 정보 열람 및 정정, 삭제는 COLLERY의 "회원정보" 메뉴를 선택한 다음 스스로 정정이 가능합니다. 
                                개인정보 동의 철회는 COLLERY 운영자 메일(contact@nestyle.ai)로 연락을 주시면 본인 확인절차를 통하여 즉시 삭제되며, 회원님의 개인정보는 어떤 이유나 방법으로도 재생되거나 
                                이용할 수 없도록 처리됩니다. 개인 정보와 관련하여 불만이나 의견이 있으신 분은 COLLERY 운영자 메일(contact@nestyle.ai)로 의견을 주시면 접수 즉시 조치하고 처리 결과를 통보해 드리겠습니다<br /><br />
                                6. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항<br />
                                쿠키란?<br />
                                - COLLERY은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.<br />
                                - 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 
                                이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장된 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.<br />
                                - 쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.<br />
                                회사의 쿠키 사용 목적<br />
                                - COLLERY은 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 더욱 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다.<br />
                                - COLLERY은 쿠키를 통해 서비스의 접속 빈도, 방문 시간, 방문 횟수 등을 분석하여 이용자의 취향과 관심 분야를 파악하여 서비스 제공에 활용합니다.<br />
                                쿠키의 설치/운영 및 거부<br />
                                - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 
                                쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br />
                                - 다만, 쿠키의 저장을 거부할 경우에는 감사 추적 인증서 등 온라인 계약을 진행하면서 COLLERY의 서비스의 기능이 제대로 동작하지 않을 수 있으며, 이와 관련하여 회사는 책임지지 않습니다.<br />
                                - 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.<br />
                                ① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.<br />
                                ② [개인정보 탭]을 클릭합니다.<br />
                                ③ [개인정보 취급 수준]을 설정하시면 됩니다.<br /><br />
                                7. 개인 아이디와 비밀번호 관리<br />
                                회원님이 사용하고 계시는 ID와 비밀번호는 원칙적으로 회원님만이 사용하게 되어 있습니다. COLLERY 의 고의 또는 과실이 없는 경우에, 
                                회원님의 ID와 비밀번호 도용 또는 기타 타인의 사용으로 의해 발생한 문제에 대하여 COLLERY이 책임지지 않습니다. 
                                어떤 상황에서도 비밀번호는 타인에게 알려 주지 마시고 로그온 상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록 특별한 주의를 기울여 주시기 바랍니다.<br /><br />
                                8. 고객의 개인정보 관리 책임자<br />
                                회사는 귀하가 회사의 서비스를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 귀하께서는 회사의 서비스를 이용하시며 발생하는 개인정보보호 
                                관련 민원을 개인정보관리책임자 혹은 담당자로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하고 성실하게 답변해드릴 것입니다.<br />
                                개인정보관리책임자<br />
                                성명 : 이용균<br />
                                연락처 : 02-2155-2040<br />
                                전자우편 : contact@nestyle.ai<br />
                                COLLERY 이용 중 피해 사항 신고는 '한국소비자보호원' (http://www.cpb.or.kr) 에서 하실 수 있으며, 
                                개인정보침해에 관련된 신고는 개인정보침해신고센터 (http://www.cyberprivacy.or.kr / 국번 없이 118), 대검찰청 사이버범죄수사단(www.spo.go.kr / 02-3480-3571), 
                                경찰청 사이버안전국 (www.ctrc.go.kr / 국번 없이 182)에서 하실 수 있습니다.<br />
                                개인정보의 보유 기간<br />
                                본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있으면 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.<br />
                                다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때는 최소 30일 전에 공지하며, 필요하면 이용자 동의를 다시 받을 수도 있습니다.<br />


                            </div>
                        </div>
                        {/* <div className="agree-item">
                            <div className="agree-item-header">
                                <p onClick={()=>setGetlocationInfoAgree(prev=>!prev)} className={`check-box ${geolocationInfoAgree?'on' :''}`}>
                                    <FontAwesomeIcon icon={faCheck}/>
                                </p>
                                <div className="explain-text">
                                    위치기반 서비스 이용약관 동의<span>(선택)</span>
                                </div>
                            </div>
                            <div className="agree-item-explain">
                                네이버 서비스 및 제품(이하 ‘서비스’)을 이용해 주셔서 감사합니다. 본 약관은 다양한 네이버 서비스의 이용과 관련하여 네이버 서비스를 제공하는 네이버 주식회사(이하 ‘네이버’)와 이를 이용하는 네이버 서비스 회원(이하 ‘회원’) 또는 비회원과의 관계를 설명하며, 아울러 여러분의 네이버 서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고 있습니다.

                                네이버 서비스를 이용하시거나 네이버 서비스 회원으로 가입하실 경우 여러분은 본 약관 및 관련 운영 정책을 확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기 바랍니다.

                                    다양한 네이버 서비스를 즐겨보세요.
                                    네이버는 www.naver.com을 비롯한 네이버 도메인의 웹사이트 및 응용프로그램(어플리케이션, 앱)을 통해 정보 검색, 다른 이용자와의 커뮤니케이션, 콘텐츠 제공, 상품 쇼핑 등 여러분의 생활에 편리함을 더할 수 있는 다양한 서비스를 제공하고 있습니다.
                                    여러분은 PC, 휴대폰 등 인터넷 이용이 가능한 각종 단말기를 통해 각양각색의 네이버 서비스를 자유롭게 이용하실 수 있으며, 개별 서비스들의 구체적인 내용은 각 서비스 상의 안내, 공지사항, 네이버 웹고객센터(이하 ‘고객센터’) 도움말 등에서 쉽게 확인하실 수 있습니다.

                                    네이버는 기본적으로 여러분 모두에게 동일한 내용의 서비스를 제공합니다. 다만, '청소년보호법' 등 관련 법령이나 기타 개별 서비스 제공에서의 특별한 필요에 의해서 연령 또는 일정한 등급을 기준으로 이용자를 구분하여 제공하는 서비스의 내용, 이용 시간, 이용 횟수 등을 다르게 하는 등 일부 이용을 제한하는 경우가 있습니다. 자세한 내용은 역시 각 서비스 상의 안내, 공지사항, 고객센터 도움말 등에서 확인하실 수 있습니다.

                                    네이버 서비스에는 기본적으로 본 약관이 적용됩니다만 네이버가 다양한 서비스를 제공하는 과정에서 부득이 본 약관 외 별도의 약관, 운영정책 등을 적용하는 경우(예, 네이버페이, V LIVE 등)가 있습니다. 그리고 네이버 계열사가 제공하는 특정 서비스의 경우에도(예, LINE, SNOW등) 해당 운영 회사가 정한 고유의 약관, 운영정책 등이 적용될 수 있습니다. 이러한 내용은 각각의 해당 서비스 초기 화면에서 확인해 주시기 바랍니다.

                                    회원으로 가입하시면 네이버 서비스를 보다 편리하게 이용할 수 있습니다.
                                    여러분은 본 약관을 읽고 동의하신 후 회원 가입을 신청하실 수 있으며, 네이버는 이에 대한 승낙을 통해 회원 가입 절차를 완료하고 여러분께 네이버 서비스 이용 계정(이하 ‘계정’)을 부여합니다. 계정이란 회원이 네이버 서비스에 로그인한 이후 이용하는 각종 서비스 이용 이력을 회원 별로 관리하기 위해 설정한 회원 식별 단위를 말합니다. 회원은 자신의 계정을 통해 좀더 다양한 네이버 서비스를 보다 편리하게 이용할 수 있습니다. 이와 관련한 상세한 내용은 계정 운영정책 및 고객센터 내 네이버 회원가입 방법 등에서 확인해 주세요.

                                    네이버는 단체에 속한 여러 구성원들이 공동의 계정으로 네이버 서비스를 함께 이용할 수 있도록 단체회원 계정도 부여하고 있습니다. 단체회원 구성원들은 하나의 계정 및 아이디(ID)를 공유하되 각자 개별적으로 설정한 비밀번호를 입력하여 계정에 로그인하고 각종 서비스를 이용하게 됩니다. 단체회원은 관리자와 멤버로 구성되며, 관리자는 구성원 전부로부터 권한을 위임 받아 단체회원을 대표하고 단체회원 계정을 운용합니다.
                                    따라서 관리자는 단체회원 계정을 통해 별도 약관 또는 기존 약관 개정에 대해 동의하거나 단체회원에서 탈퇴할 수 있고, 멤버들의 단체회원 계정 로그인 방법 및 이를 통한 게시물 게재 등 네이버 서비스 이용을 관리(게시물 삭제 포함)할 수 있습니다. 본 약관에서 규정한 사항은 원칙적으로 구성원 모두에게 적용되며, 각각의 구성원은 다른 구성원들의 단체회원 계정 및 아이디(ID)를 통한 서비스 이용에 관해 연대책임을 부담합니다.

                                    단체회원 계정 사용에서의 관리자, 멤버 등의 권한 및 (공동)책임에 관한 사항 등은 계정 운영정책 및 고객센터 내 네이버 단체회원(단체 아이디) 소개 등에서 확인해 주시기 바랍니다.

                                    여러분이 제공한 콘텐츠를 소중히 다룰 것입니다.
                                    네이버는 여러분이 게재한 게시물이 네이버 서비스를 통해 다른 이용자들에게 전달되어 우리 모두의 삶을 더욱 풍요롭게 해줄 것을 기대합니다. 게시물은 여러분이 타인 또는 자신이 보게 할 목적으로 네이버 서비스 상에 게재한 부호, 문자, 음성, 음향, 그림, 사진, 동영상, 링크 등으로 구성된 각종 콘텐츠 자체 또는 파일을 말합니다.

                                    네이버는 여러분의 생각과 감정이 표현된 콘텐츠를 소중히 보호할 것을 약속 드립니다. 여러분이 제작하여 게재한 게시물에 대한 지식재산권 등의 권리는 당연히 여러분에게 있습니다.

                                    한편, 네이버 서비스를 통해 여러분이 게재한 게시물을 적법하게 제공하려면 해당 콘텐츠에 대한 저장, 복제, 수정, 공중 송신, 전시, 배포, 2차적 저작물 작성(단, 번역에 한함) 등의 이용 권한(기한과 지역 제한에 정함이 없으며, 별도 대가 지급이 없는 라이선스)이 필요합니다.
                                    게시물 게재로 여러분은 네이버에게 그러한 권한을 부여하게 되므로, 여러분은 이에 필요한 권리를 보유하고 있어야 합니다.

                                    네이버는 여러분이 부여해 주신 콘텐츠 이용 권한을 저작권법 등 관련 법령에서 정하는 바에 따라 네이버 서비스 내 노출, 서비스 홍보를 위한 활용, 서비스 운영, 개선 및 새로운 서비스 개발을 위한 연구, 웹 접근성 등 법률상 의무 준수, 외부 사이트에서의 검색, 수집 및 링크 허용을 위해서만 제한적으로 행사할 것입니다.
                                    만약, 그 밖의 목적을 위해 부득이 여러분의 콘텐츠를 이용하고자 할 경우엔 사전에 여러분께 설명을 드리고 동의를 받도록 하겠습니다.

                                    또한 여러분이 제공한 소중한 콘텐츠는 네이버 서비스를 개선하고 새로운 네이버 서비스를 제공하기 위해 인공지능 분야 기술 등의 연구 개발 목적으로 네이버 및 네이버 계열사에서 사용될 수 있습니다. 네이버는 지속적인 연구 개발을 통해 여러분께 좀 더 편리하고 유용한 서비스를 제공해 드릴 수 있도록 최선을 다하겠습니다.

                                    네이버는 여러분이 자신이 제공한 콘텐츠에 대한 네이버 또는 다른 이용자들의 이용 또는 접근을 보다 쉽게 관리할 수 있도록 다양한 수단을 제공하기 위해 노력하고 있습니다. 여러분은 네이버 서비스 내에 콘텐츠 삭제, 비공개 등의 관리기능이 제공되는 경우 이를 통해 직접 타인의 이용 또는 접근을 통제할 수 있고, 고객센터를 통해서도 콘텐츠의 삭제, 비공개, 검색결과 제외 등의 조치를 요청할 수 있습니다.
                                    다만, 일부 네이버 서비스의 경우 삭제, 비공개 등의 처리가 어려울 수 있으며, 이러한 내용은 각 서비스 상의 안내, 공지사항, 고객센터 도움말 등에서 확인해 주시길 부탁 드립니다.

                                    여러분의 개인정보를 소중히 보호합니다.
                                    네이버는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 개인정보를 수집∙이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. 네이버가 이용자 및 회원에 대해 관련 개인정보를 안전하게 처리하기 위하여 기울이는 노력이나 기타 상세한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.

                                    네이버는 여러분이 서비스를 이용하기 위해 일정 기간 동안 로그인 혹은 접속한 기록이 없는 경우, 전자메일, 서비스 내 알림 또는 기타 적절한 전자적 수단을 통해 사전에 안내해 드린 후 여러분의 정보를 파기하거나 분리 보관할 수 있으며, 만약 이로 인해 서비스 제공을 위해 필수적인 정보가 부족해질 경우 부득이 관련 서비스 이용계약을 해지할 수 있습니다.

                                    타인의 권리를 존중해 주세요.
                                    여러분이 무심코 게재한 게시물로 인해 타인의 저작권이 침해되거나 명예훼손 등 권리 침해가 발생할 수 있습니다. 네이버는 이에 대한 문제 해결을 위해 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 및 ‘저작권법’ 등을 근거로 권리침해 주장자의 요청에 따른 게시물 게시중단, 원 게시자의 이의신청에 따른 해당 게시물 게시 재개 등을 내용으로 하는 게시중단요청서비스를 운영하고 있습니다. 보다 상세한 내용 및 절차는 고객센터 내 게시중단요청서비스 소개를 참고해 주세요.

                                    한편, 네이버 서비스를 통해 타인의 콘텐츠를 이용한다고 하여 여러분이 해당 콘텐츠에 대한 지식재산권을 보유하게 되는 것은 아닙니다. 여러분이 해당 콘텐츠를 자유롭게 이용하기 위해서는 그 이용이 저작권법 등 관련 법률에 따라 허용되는 범위 내에 있거나, 해당 콘텐츠의 지식재산권자로부터 별도의 이용 허락을 받아야 하므로 각별한 주의가 필요합니다.

                                    네이버는 여러분이 네이버 서비스를 마음껏 이용할 수 있도록 여러분께 네이버 서비스에 수반되는 관련 소프트웨어 사용에 관한 이용 권한을 부여합니다. 이 경우 여러분의 자유로운 이용은 네이버가 제시하는 이용 조건에 부합하는 범위 내에서만 허용되고, 이러한 권한은 양도가 불가능하며, 비독점적 조건 및 법적고지가 적용된다는 점을 유의해 주세요.

                                    네이버에서 제공하는 다양한 포인트를 요긴하게 활용해 보세요.
                                    네이버는 여러분이 네이버 서비스를 효율적으로 이용할 수 있도록 포인트를 부여하고 있습니다. 포인트는 여러분의 일정한 네이버 서비스 이용과 연동하여 네이버가 임의로 책정하거나 조정하여 지급하는 일정한 계산 단위를 갖는 서비스 상의 가상 데이터를 말합니다. 포인트는 재산적 가치가 없기 때문에 금전으로 환불 또는 전환할 수 없지만, 포인트의 많고 적음에 따라 여러분의 네이버 서비스 이용에 영향을 주는 경우가 있으므로 경우에 따라 적절히 활용해 보세요.

                                    네이버는 네이버 서비스의 효율적 이용을 지원하거나 서비스 운영을 개선하기 위해 필요한 경우 사전에 여러분께 안내 드린 후 부득이 포인트의 일부 또는 전부를 조정할 수 있습니다. 그리고 포인트는 네이버가 정한 기간에 따라 주기적으로 소멸할 수도 있으니 포인트가 부여되는 네이버 서비스 이용 시 유의해 주시기 바랍니다.

                                    네이버 서비스 이용과 관련하여 몇 가지 주의사항이 있습니다.
                                    네이버는 여러분이 네이버 서비스를 자유롭고 편리하게 이용할 수 있도록 최선을 다하고 있습니다. 다만, 여러분이 네이버 서비스를 보다 안전하게 이용하고 네이버 서비스에서 여러분과 타인의 권리가 서로 존중되고 보호받으려면 여러분의 도움과 협조가 필요합니다. 여러분의 안전한 서비스 이용과 권리 보호를 위해 부득이 아래와 같은 경우 여러분의 게시물 게재나 네이버 서비스 이용이 제한될 수 있으므로, 이에 대한 확인 및 준수를 요청 드립니다.

                                    회원 가입 시 이름, 생년월일, 휴대전화번호 등의 정보를 허위로 기재해서는 안 됩니다. 회원 계정에 등록된 정보는 항상 정확한 최신 정보가 유지될 수 있도록 관리해 주세요. 자신의 계정을 다른 사람에게 판매, 양도, 대여 또는 담보로 제공하거나 다른 사람에게 그 사용을 허락해서는 안 됩니다. 아울러 자신의 계정이 아닌 타인의 계정을 무단으로 사용해서는 안 됩니다. 이에 관한 상세한 내용은 계정 운영정책을 참고해 주시기 바랍니다.
                                    타인에 대해 직접적이고 명백한 신체적 위협을 가하는 내용의 게시물, 타인의 자해 행위 또는 자살을 부추기거나 권장하는 내용의 게시물, 타인의 신상정보, 사생활 등 비공개 개인정보를 드러내는 내용의 게시물, 타인을 지속적으로 따돌리거나 괴롭히는 내용의 게시물, 성매매를 제안, 알선, 유인 또는 강요하는 내용의 게시물, 공공 안전에 대해 직접적이고 심각한 위협을 가하는 내용의 게시물은 제한될 수 있습니다.
                                    관련 법령상 금지되거나 형사처벌의 대상이 되는 행위를 수행하거나 이를 교사 또는 방조하는 등의 범죄 관련 직접적인 위험이 확인된 게시물, 관련 법령에서 홍보, 광고, 판매 등을 금지하고 있는 물건 또는 서비스를 홍보, 광고, 판매하는 내용의 게시물, 타인의 지식재산권 등을 침해하거나 모욕, 사생활 침해 또는 명예훼손 등 타인의 권리를 침해하는 내용이 확인된 게시물은 제한될 수 있습니다.
                                    자극적이고 노골적인 성행위를 묘사하는 등 타인에게 성적 수치심을 유발시키거나 왜곡된 성 의식 등을 야기할 수 있는 내용의 게시물, 타인에게 잔혹감 또는 혐오감을 일으킬 수 있는 폭력적이고 자극적인 내용의 게시물, 본인 이외의 자를 사칭하거나 허위사실을 주장하는 등 타인을 기만하는 내용의 게시물, 과도한 욕설, 비속어 등을 계속하여 반복적으로 사용하여 심한 혐오감 또는 불쾌감을 일으키는 내용의 게시물은 제한될 수 있습니다.
                                    자동화된 수단을 활용하는 등 네이버 서비스의 기능을 비정상적으로 이용하여 게재된 게시물, 네이버 각 개별 서비스의 제공 취지와 부합하지 않는 내용의 게시물은 다른 이용자들의 정상적인 네이버 서비스 이용에 불편을 초래하고 더 나아가 네이버의 원활한 서비스 제공을 방해하므로 역시 제한될 수 있습니다. 기타 제한되는 게시물에 관한 상세한 내용은 게시물 운영정책 및 각 개별 서비스에서의 약관, 운영정책 등을 참고해 주시기 바랍니다.
                                    네이버의 사전 허락 없이 자동화된 수단(예: 매크로 프로그램, 로봇(봇), 스파이더, 스크래퍼 등)을 이용하여 네이버 서비스 회원으로 가입을 시도 또는 가입하거나, 네이버 서비스에 로그인을 시도 또는 로그인하거나, 네이버 서비스 상에 게시물을 게재하거나, 네이버 서비스를 통해 커뮤니케이션하거나(예: 전자메일, 쪽지 등), 네이버 서비스에 게재된 회원의 아이디(ID), 게시물 등을 수집하거나, 네이버 검색 서비스에서 특정 질의어로 검색하거나 혹은 그 검색결과에서 특정 검색결과를 선택(이른바 ‘클릭’)하는 등 이용자(사람)의 실제 이용을 전제로 하는 네이버 서비스의 제공 취지에 부합하지 않는 방식으로 네이버 서비스를 이용하거나 이와 같은 네이버 서비스에 대한 어뷰징(남용) 행위를 막기 위한 네이버의 기술적 조치를 무력화하려는 일체의 행위(예: IP를 지속적으로 바꿔가며 접속하는 행위, Captcha를 외부 솔루션 등을 통해 우회하거나 무력화 하는 행위 등)를 시도해서는 안 됩니다.
                                    네이버의 동의 없이 자동화된 수단에 의해 네이버 서비스 상에 광고가 게재되는 영역 또는 그 밖의 영역에 부호, 문자, 음성, 음향, 그림, 사진, 동영상, 링크 등으로 구성된 각종 콘텐츠 자체 또는 파일을 삽입해서는 안 됩니다. 또한, 네이버 서비스 또는 이에 포함된 소프트웨어를 복사, 수정할 수 없으며, 이를 판매, 양도, 대여 또는 담보로 제공하거나 타인에게 그 이용을 허락해서는 안 됩니다. 네이버 서비스에 포함된 소프트웨어를 역 설계, 소스코드 추출 시도, 복제, 분해, 모방, 기타 변형하는 등의 행위도 금지됩니다(다만, 오픈소스에 해당되는 경우 그 자체 조건에 따릅니다). 그 밖에 바이러스나 기타 악성 코드를 업로드하거나 네이버 서비스의 원활한 운영을 방해할 목적으로 서비스 기능을 비정상적으로 이용하는 행위 역시 금지됩니다.
                                    네이버는 본 약관의 범위 내에서 게시물 운영정책, 각 개별 서비스에서의 약관 또는 운영정책, 각 서비스 상의 안내, 공지사항, 고객센터 도움말 등을 두어, 여러분에게 안정적이고 원활한 서비스 이용이 가능하도록 지원하고 있습니다. 각 세부 정책에는 여러분이 참고할 수 있도록 보다 구체적인 유의사항을 포함하고 있으니, 본 약관 본문 및 구성 페이지 상의 링크 등을 통해 이를 확인해 주시기 바랍니다.

                                    부득이 서비스 이용을 제한할 경우 합리적인 절차를 준수합니다.
                                    네이버는 다양한 정보와 의견이 담긴 여러분의 콘텐츠를 소중히 다룰 것을 약속 드립니다만, 여러분이 게재한 게시물이 관련 법령, 본 약관, 게시물 운영정책, 각 개별 서비스에서의 약관, 운영정책 등에 위배되는 경우, 부득이 이를 비공개 또는 삭제 처리하거나 게재를 거부할 수 있습니다. 다만, 이것이 네이버가 모든 콘텐츠를 검토할 의무가 있다는 것을 의미하지는 않습니다.

                                    또한 여러분이 관련 법령, 본 약관, 계정 및 게시물 운영정책, 각 개별 서비스에서의 약관, 운영정책 등을 준수하지 않을 경우, 네이버는 여러분의 관련 행위 내용을 확인할 수 있으며, 그 확인 결과에 따라 네이버 서비스 이용에 대한 주의를 당부하거나, 네이버 서비스 이용을 일부 또는 전부, 일시 또는 영구히 정지시키는 등 그 이용을 제한할 수 있습니다. 한편, 이러한 이용 제한에도 불구하고 더 이상 네이버 서비스 이용계약의 온전한 유지를 기대하기 어려운 경우엔 부득이 여러분과의 이용계약을 해지할 수 있습니다.

                                    부득이 여러분의 서비스 이용을 제한해야 할 경우 명백한 법령 위반이나 타인의 권리침해로서 긴급한 위험 또는 피해 차단이 요구되는 사안 외에는 위와 같은 단계적 서비스 이용제한 원칙을 준수 하겠습니다. 명백한 법령 위반 등을 이유로 부득이 서비스 이용을 즉시 영구 정지시키는 경우 서비스 이용을 통해 획득한 포인트 및 기타 혜택 등은 모두 소멸되고 이에 대해 별도로 보상하지 않으므로 유의해 주시기 바랍니다. 서비스 이용 제한의 조건, 세부 내용 등은 계정 운영정책 및 각 개별 서비스에서의 운영정책을 참고하시기 바랍니다.

                                    네이버의 잘못은 네이버가 책임집니다.
                                    네이버는 여러분이 네이버 서비스를 이용함에 있어 네이버의 고의 또는 과실로 인하여 손해를 입게 될 경우 관련 법령에 따라 여러분의 손해를 배상합니다. 다만, 천재지변 또는 이에 준하는 불가항력으로 인하여 네이버가 서비스를 제공할 수 없거나 이용자의 고의 또는 과실로 인하여 서비스를 이용할 수 없어 발생한 손해에 대해서 네이버는 책임을 부담하지 않습니다.

                                    그리고 네이버가 손해배상책임을 부담하는 경우에도 통상적으로 예견이 불가능하거나 특별한 사정으로 인한 특별 손해 또는 간접 손해, 기타 징벌적 손해에 대해서는 관련 법령에 특별한 규정이 없는 한 책임을 부담하지 않습니다.

                                    한편, 네이버 서비스를 매개로 한 여러분과 다른 회원 간 또는 여러분과 비회원 간의 의견 교환, 거래 등에서 발생한 손해나 여러분이 서비스 상에 게재된 타인의 게시물 등의 콘텐츠를 신뢰함으로써 발생한 손해에 대해서도 네이버는 특별한 사정이 없는 한 이에 대해 책임을 부담하지 않습니다.

                                    언제든지 네이버 서비스 이용계약을 해지하실 수 있습니다.
                                    네이버에게는 참 안타까운 일입니다만, 회원은 언제든지 네이버 서비스 이용계약 해지를 신청하여 회원에서 탈퇴할 수 있으며, 이 경우 네이버는 관련 법령 등이 정하는 바에 따라 이를 지체 없이 처리하겠습니다.

                                    네이버 서비스 이용계약이 해지되면, 관련 법령 및 개인정보처리방침에 따라 네이버가 해당 회원의 정보를 보유할 수 있는 경우를 제외하고, 해당 회원 계정에 부속된 게시물 일체를 포함한 회원의 모든 데이터는 소멸됨과 동시에 복구할 수 없게 됩니다. 다만, 이 경우에도 다른 회원이 별도로 담아갔거나 스크랩한 게시물과 공용 게시판에 등록한 댓글 등의 게시물은 삭제되지 않으므로 반드시 해지 신청 이전에 삭제하신 후 탈퇴하시기 바랍니다.

                                    일부 네이버 서비스에는 광고가 포함되어 있습니다.
                                    여러분이 다양한 네이버 서비스를 이용하다 보면 간혹 일부 개별 서비스에 광고가 포함된 경우가 있습니다. 네이버 서비스를 이용하면서 발생할 수 있는 데이터 통신요금은 가입하신 통신사업자와의 이용계약에 따라 여러분이 부담하며, 포함된 광고 열람으로 인해 추가적으로 발생하는 비용 역시 여러분이 부담합니다.

                                    원하는 네이버 서비스를 이용하기 위해 원하지 않는 광고를 봐야 하는 경우가 있습니다. 이는 여러분에게 제공하는 다양한 네이버 서비스를 원칙적으로 무료로 제공할 수 있게 해주는 데 기여하며, 더 나아가 네이버가 연구 개발에 투자하여 더 나은 서비스를 제공할 수 있는 기반이 됩니다. 최근 타사의 일부 서비스들이 광고 없는 서비스 이용을 강조하며 주된 서비스를 유료로 제공하고 있는 관행이 이를 뒷받침합니다.

                                    네이버는 여러분의 본의 아닌 불편이나 부담이 최소화될 수 있는 방법에 대해 항상 고민하고 개선해 나가겠습니다.

                                    서비스 중단 또는 변경 시 꼭 알려드리겠습니다.
                                    네이버는 연중 무휴, 1일 24시간 안정적으로 서비스를 제공하기 위해 최선을 다하고 있습니다만, 컴퓨터, 서버 등 정보통신설비의 보수점검, 교체 또는 고장, 통신두절 등 운영상 상당한 이유가 있는 경우 부득이 서비스의 전부 또는 일부를 중단할 수 있습니다.

                                    한편, 네이버는 서비스 운영 또는 개선을 위해 상당한 필요성이 있는 경우 서비스의 전부 또는 일부를 수정, 변경 또는 종료할 수 있습니다. 무료로 제공되는 서비스의 전부 또는 일부를 수정, 변경 또는 종료하게 된 경우 관련 법령에 특별한 규정이 없는 한 별도의 보상을 하지 않습니다.

                                    이 경우 네이버는 예측 가능한 경우 상당기간 전에 이를 안내하며, 만약 예측 불가능한 경우라면 사후 지체 없이 상세히 설명하고 안내 드리겠습니다. 또한 서비스 중단의 경우에는 여러분 자신의 콘텐츠를 백업할 수 있도록 합리적이고 충분한 기회를 제공하도록 하겠습니다.

                                    주요 사항을 잘 안내하고 여러분의 소중한 의견에 귀 기울이겠습니다.
                                    네이버는 서비스 이용에 필요한 주요사항을 적시에 잘 안내해 드릴 수 있도록 힘쓰겠습니다. 회원에게 통지를 하는 경우 전자메일, 서비스 내 알림 또는 기타 적절한 전자적 수단을 통해 개별적으로 알려 드릴 것이며, 다만 회원 전체에 대한 통지가 필요할 경우엔 7일 이상 www.naver.com을 비롯한 네이버 도메인의 웹사이트 및 응용프로그램(어플리케이션, 앱) 초기 화면 또는 공지사항 등에 관련 내용을 게시하도록 하겠습니다.

                                    네이버는 여러분의 소중한 의견에 귀 기울이겠습니다. 여러분은 언제든지 고객센터를 통해 서비스 이용과 관련된 의견이나 개선사항을 전달할 수 있으며, 네이버는 합리적 범위 내에서 가능한 그 처리과정 및 결과를 여러분께 전달할 수 있도록 하겠습니다.

                                    여러분이 쉽게 알 수 있도록 약관 및 운영정책을 게시하며 사전 공지 후 개정합니다.
                                    네이버는 본 약관의 내용을 여러분이 쉽게 확인할 수 있도록 서비스 초기 화면에 게시하고 있습니다.

                                    네이버는 수시로 본 약관, 계정 및 게시물 운영정책을 개정할 수 있습니다만, 관련 법령을 위배하지 않는 범위 내에서 개정할 것이며, 사전에 그 개정 이유와 적용 일자를 서비스 내에 알리도록 하겠습니다. 또한 여러분에게 불리할 수 있는 중대한 내용의 약관 변경의 경우에는 최소 30일 이전에 해당 서비스 내 공지하고 별도의 전자적 수단(전자메일, 서비스 내 알림 등)을 통해 개별적으로 알릴 것입니다.

                                    네이버는 변경된 약관을 게시한 날로부터 효력이 발생되는 날까지 약관 변경에 대한 여러분의 의견을 기다립니다. 위 기간이 지나도록 여러분의 의견이 네이버에 접수되지 않으면, 여러분이 변경된 약관에 따라 서비스를 이용하는 데에 동의하는 것으로 간주됩니다. 네이버로서는 매우 안타까운 일이지만, 여러분이 변경된 약관에 동의하지 않는 경우 변경된 약관의 적용을 받는 해당 서비스의 제공이 더 이상 불가능하게 될 수 있습니다.

                                    네이버 서비스에는 기본적으로 본 약관이 적용됩니다만, 부득이 각 개별 서비스의 고유한 특성을 반영하기 위해 본 약관 외 별도의 약관, 운영정책이 추가로 적용될 때가 있습니다. 따라서 별도의 약관, 운영정책에서 그 개별 서비스 제공에 관하여 본 약관, 계정 및 게시물 운영정책과 다르게 정한 경우에는 별도의 약관, 운영정책이 우선하여 적용됩니다. 이러한 내용은 각각의 개별 서비스 초기 화면에서 확인해 주시기 바랍니다.

                                    본 약관은 한국어를 정본으로 합니다. 본 약관 또는 네이버 서비스와 관련된 여러분과 네이버와의 관계에는 대한민국의 법령이 적용됩니다. 그리고 본 약관 또는 네이버 서비스와 관련하여 여러분과 네이버 사이에 분쟁이 발생할 경우, 그 분쟁의 처리는 대한민국 '민사소송법'에서 정한 절차를 따릅니다.

                                    공지 일자: 2018년 3월 30일
                                    적용 일자: 2018년 5월 1일
                                    네이버 서비스와 관련하여 궁금하신 사항이 있으시면 고객센터(대표번호: 1588 – 3820/ 평일 09:00~18:00)로 문의 주시기 바랍니다.
                            </div>
                        </div> */}
                        <div className="button-list-box">
                            <Link to={HOMEPAGE} className="btn cancel">
                                취소
                            </Link>
                            <p onClick={onClickConfirm} className="btn confirm">
                                확인
                            </p>
                        </div>
                    </div>
                </SignUpCertificateWrapper>
            </SignUpCertificateContainer>
            <Footer />
        </>
    )
})

export default SignUpCertificate;