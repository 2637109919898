import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

const StyledAuthorListLi = styled.li`
    height : auto;
    padding : 5.5px;
    box-sizing: border-box; 
    overflow:hidden;
    border-radius:9.5px;
    flex:0 0 auto;
    width:auto;
    a{width:100%;}
    @mdeia screen and (max-width:992px){
        flex:0 0 auto;
    }
`;

const StyledAuthorProfileImageContainer = styled.div`
    width:30.4vh;
    height:30.4vh;
    display:flex;
    justify-content:center;
    box-sizing:border-box;
`;

const StyledAuthorProfileImageWrap = styled.p`
    width:100%;
    height:100%;
    margin:0;
    overflow:hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledAuthorProfileImage = styled.img`
    display:block;
    height: 92.5%;
    width: 92.5%;
    border-radius: 100%;
    object-fit:cover;
`;

const StyledAuthorProfileContainer = styled.div`
    width:100%;
    height:auto;
    padding:5.25px;
    line-height:24.5px;
`;

const StyledAuthorProfileName = styled.p`
    margin:0px;
    padding:0px;
    text-align:center;
    text-overflow:ellipsis;
    white-space:normal;
    font-size: var(--list-title);
    @media screen and (max-width:855px){
        font-size: var(--tablet-list-title);
    }
    @media screen and (max-width:578px){
        font-size: var(--mobile-list-title);
    }
`;

const StyledAuthorProfileProductNumber = styled.p`
    margin-top:7.5px;
    padding:0px;
    text-align:center;
    font-size:var(--list-subtitle);
    @media screen and (max-width:855px){
        font-size: var(--tablet-list-subtitle);
    }
    @media screen and (max-width:578px){
        font-size: var(--mobile-list-subtitle);
    }
`;
const StyledAuthorProfileButtonContainer = styled.p`
    padding:0px;
    display:flex;
    justify-content:center;
    margin-top:7.5px;
`;
const StyledAuthorProfileButton = styled.button`
    display:block;
    width:68.5%;
    height:33.5px;
    border-radius:9.5px;
    text-align:center;
    line-height:33.5px;
    border:1px solid #313131;
    padding: 0;
    color:#313131;
    background:#fff;
    font-size:var(--list-subtitle);
    &:hover{
        background:#313131;
        color:#fff;
    }
    @media screen and (max-width:855px){
        font-size: var(--tablet-list-subtitle);
    }
    @media screen and (max-width:578px){
        font-size: var(--mobile-list-subtitle);
    }
`

const SingleAuthorComponent = memo(({ personalInfo }) => {
    const [numberOfProducts, setNumberOfProducts] = useState(-1);
    const {isLoading:loadingNumOfProducts,data:numOfProductsCalled}=useQuery(`${personalInfo.userid}_numOfProducts`,()=>{
        return axios.get(`https://api.collery.io/countauthorgalleryimages/${personalInfo.userid}`).then(resp=>resp.data[0].totalimages+'')
    },{
        cacheTime:1000*60*2
    })
    useEffect(() => {

        if(!loadingNumOfProducts){
            if (numOfProductsCalled.length >= 4) {
                setNumberOfProducts(`${numOfProductsCalled[0]}K`);
            } else {
                setNumberOfProducts(numOfProductsCalled.match(/.{1,3}(?=(.{3})*$)/g).join(','));
            }
        }
    }, [loadingNumOfProducts])

    return (
        <StyledAuthorListLi>
            <Link to={{
                pathname: `/gallery/${personalInfo.userid}`,
                state: {numberOfProducts:numberOfProducts ,userthumbnail: personalInfo.userthumbnail, authorName: `${personalInfo.firstname}` }
            }}>
                <StyledAuthorProfileImageContainer>
                    <StyledAuthorProfileImageWrap>
                        <StyledAuthorProfileImage
                            src={personalInfo.userthumbnail ? personalInfo.userthumbnail : "/static/media/userProfilePic.a16c0a5a.spersonalInfog"}
                            alt="user-profile"
                        />
                    </StyledAuthorProfileImageWrap>
                </StyledAuthorProfileImageContainer>
                <StyledAuthorProfileContainer>
                    <StyledAuthorProfileName>
                        {`${personalInfo.firstname}`}
                    </StyledAuthorProfileName>
                    <StyledAuthorProfileProductNumber>
                        <span>작품 수 :</span>{numberOfProducts}
                    </StyledAuthorProfileProductNumber>
                    <StyledAuthorProfileButtonContainer>
                        <StyledAuthorProfileButton>
                            작품보기
                        </StyledAuthorProfileButton>
                    </StyledAuthorProfileButtonContainer>
                </StyledAuthorProfileContainer>
            </Link>
        </StyledAuthorListLi>
    )
})

export default SingleAuthorComponent;