import React,{useState,useEffect} from 'react'
import logo from '../assets/newhomepage/logo.png';
import {AiOutlineMenu,AiOutlineClose} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { USER_LOGIN } from '../../../constants/applicationUrls';
export default function Nav() {

    const [menu,setMenu]=useState('none')
    const [isOpen,setIsopen]=useState(false)

    const ShowMenu = () =>{
        if (menu==='none'){
            setMenu('flex');
            setIsopen(true);
        }else{
            setMenu('none');
            setIsopen(false)
        }
        
    }

  return (
    <div>
         <div className="flex justify-between pt-4 sm:pl-20 sm:pr-20 pl-6 pr-6" >
                <div className="flex items-center justify-center cursor-pointer"><img src={logo} className="h-6 w-6 mr-2 sm:h-8 sm:w-8"/><p className="font-bold text-red-700 text-md sm:text-3xl"><span>COLLERY</span></p></div>
                <div className="text-white flex">
                    <div className="md:flex md:items-center md:justify-center">
                        {/*<p className="mr-6 text-lg text-gray-300 cursor-pointer">Home</p>
                        <p className="mr-6 text-lg text-gray-300 cursor-pointer">Gallery</p>
                        <p className="mr-10 text-lg text-gray-300 cursor-pointer">NFTs</p>
                        <Link to={USER_LOGIN} ><p className="mr-4 text-lg text-gray-200 font-bold p-1 text-center w-20 bg-red-700 rounded-lg cursor-pointer">Login</p></Link>*/}
                        <Link to='/gallery' ><p className="mr-0 sm:-mr-10 md:-mr-5 text-xs sm:text-[20px] text-white font-bold p-2 text-center w-[8rem] sm:w-[10rem] bg-gradient-to-r from-pink-600 to-yellow-600 hover:from-pink-500 hover:to-yellow-500 rounded-lg cursor-pointer"><span>Launch App</span></p></Link>
                    </div>
                    {/*!isOpen&&<div className="sm:text-3xl sm:-mr-10 lg:mr-0 text-xl md:hidden cursor-pointer" onClick={()=>ShowMenu()}><AiOutlineMenu /></div>}
                    {isOpen&&<div className="sm:text-3xl sm:-mr-10 lg:mr-0 text-xl md:hidden cursor-pointer" onClick={()=>ShowMenu()}><AiOutlineClose /></div>*/}
                    
                </div>
            </div>

            {/*<div className={`${isOpen? 'flex md:hidden':'hidden'} absolute sm:top-14 top-8 sidebar right-0 items-center bg-black h-[20rem] w-[15rem] ml-4  mr-3 mt-4 rounded-lg`} style={{zIndex:'100'}}>

                <div className="pl-2 pr-2 -mt-10 w-full">
                <p className="text-md mb-2 text-gray-300 cursor-pointer p-2 rounded-md hover:bg-slate-700 text-center" onClick={()=>ShowMenu()}>Home</p>
                <p className="text-md mb-2 text-gray-300 cursor-pointer p-2 rounded-md hover:bg-slate-700 text-center" onClick={()=>ShowMenu()}>Gallery</p>
                <p className="text-md mb-4 text-gray-300 cursor-pointer p-2 rounded-md hover:bg-slate-700 text-center" onClick={()=>ShowMenu()}>NFTs</p>
                <p className="text-md mb-4 text-gray-200 font-bold p-1 text-center  bg-red-700 rounded-lg cursor-pointer" onClick={()=>ShowMenu()}>Login</p>
                <p className="text-md mb-4 text-gray-300 font-bold  p-1 text-center bg-slate-500 rounded-lg cursor-pointer" onClick={()=>ShowMenu()}><span>Sign up</span></p>
                </div>
  

            </div>*/}
    </div>
  )
}
