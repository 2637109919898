import React from 'react';
import pic4 from '../../assets/top2/new_banner4.jpg';
import pic1 from '../../assets/top2/new_banner1.jpg';
import pic2 from '../../assets/top2/new_banner2.jpg';
import pic3 from '../../assets/top2/new_banner3.jpg';
import pic5 from '../../assets/top2/new_banner5.jfif';
import styled,{keyframes} from 'styled-components';
import {isMobile,MobileView} from "react-device-detect";

const BannerAnimation1 = keyframes`
    0%{
      opacity:1;
    }
    33.3%{
      opacity:0.35;
    }
    66.6%{
      opacity:0.35;
    }
    100%{
      opacity:0.35;
    }
`;

const BannerAnimation2 = keyframes`
    0%{
      opacity:0.35;
    }
    33.3%{
      opacity:1;
    }
    66.6%{
      opacity:0.35;
    }
    100%{
      opacity:0.35;
    }
`;

const BannerAnimation3 = keyframes`
    0%{
      opacity:0.35;
    }
    33.3%{
      opacity:0.35;
    }
    66.6%{
      opacity:1;
    }
    100%{
      opacity:0.35;
    }
`;

const BannerAnimation4 = keyframes`
    0%{
      opacity:0.35;
    }
    33.3%{
      opacity:0.35;
    }
    66.6%{
      opacity:0.35;
    }
    100%{
      opacity:1;
    }
`;

const Section3Container = styled.div`
    width:100%;
    height:auto;
    margin:50px 0;
    h2{
      width:100%;
      height:83px;
      text-align:center;
      font-size:var(--subtitle);
      line-height:83px;
      color: #000;
      font-weight:bold;
    }
    @media screen and (max-width:1070px){
      h2{
        height:42px;
        line-height:42px;
        font-size:var(--tablet-subtitle);
      }
    }
    @media screen and (max-width:570px){
      h2{
        font-size:var(--mobile-subtitle);
      }
    }
`;

const Section3Wrapper = styled.div`
  margin: 50px auto;
  width: 95.5%;
  height: 481px;
  .banner-image-wrap{
    width:auto;
    height:100%;
    overflow:hidden;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    img{
      display:block;
      object-fit:contain;
      height:100%;
      &:nth-child(1){
        animation-name:${BannerAnimation1};
        animation-duration:5.5s;
        animation-iteration-count:infinite;
        animation-direction:alternate;
      }
      &:nth-child(2){
        animation-name:${BannerAnimation2};
        animation-duration:5.5s;
        animation-iteration-count:infinite;
        animation-direction:alternate;
      }
      &:nth-child(3){
        animation-name:${BannerAnimation3};
        animation-duration:5.5s;
        animation-iteration-count:infinite;
        animation-direction:alternate;
      }
      &:nth-child(4){
        animation-name:${BannerAnimation4};
        animation-duration:5.5s;
        animation-iteration-count:infinite;
        animation-direction:alternate;
      }
    }
  }
  position:relative;
  @media screen and (max-width:1675px){
    width:85.5%;
  }
  @media screen and (max-width:1405px){
    height:321.5px;
  }
  @media screen and (max-width:960px){
    height: 313px;
    .banner-image-wrap{
      img{
        object-fit:cover;
        &:nth-child(1){
          animation:none;
        }
        &:nth-child(2){
          animation:none;
        }
        &:nth-child(3){
          animation:none;
        }
        &:nth-child(4){
          animation:none;
        }
      }
    }
  }
  @media screen and (max-width:578px){
    margin: 15px auto;
  }
`;

const TextArea = styled.div`
  position:absolute;
  top:50%;left:50%;
  transform:translateX(-50%) translateY(-50%);
  width:760px;
  height:249px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-evenly;
  .button-wrap{
    width:200px;height:55.5px;
    button{
      display:block;
      line-height:22.5px;
      padding:.5px 7.5px;
      width:auto;height:100%;
      border-radius:4.5px;
      border:none;
      background-color:#ff4d2c;
      color:#fff;
      font-size:var(--list-subtitle);
      &:hover{
        background-color:#fff;
        color:#ff4d2c;
        border:1px solid #ff4d2c;
        box-sizing:border-box;
      }
    }
  }
  @media screen and (max-width:1070px){
    .button-wrap{
      height:48px;
      button{
        padding:0 7.5px;
        font-size:var(--tablet-list-subtitle);
      }
    }
  }
  @media screen and (max-width:578px){
    width:100%;height:100%;
    .button-wrap{
      width:140px;height:48px;
      button{
        font-size:var(--mobile-list-subtitle);
      }
    }
  }
`

export default function Section3 (){

    return(
      <Section3Container>
      <h2>가상의 전시 공간을 빌려드립니다. </h2>
        <Section3Wrapper >
        <div className='banner-image-wrap'>
          {!isMobile?(
            <>
              <img src={pic2} alt="banner-image"/>
              <img src={pic3} alt="banner-image"/>
              <img src={pic1} alt="banner-image"/>
              <img src={pic4} alt="banner-image"/>
            </>
          ):(
            <>
              <img src={pic5} alt="banner-image"/>
            </>
          )
          }
        </div>
            
            <TextArea>
               
              <div className='button-wrap'>
                <button type="button" >
                  전시공간 신청하기 (Comming Soon)
                ​</button>
              </div>
            {/* <p className='d-flex justify-content-center'>Collection에 등록된 작품들을 공유해주시면 바로 전시 공간이 만들어집니다. </p> < br /> */}
            </TextArea>
    
          {/* <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
    
            <div class="col">
              <div class="card shadow-sm">
                  <img src={pic1} width="" alt="" />
    
                
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                  <img src={pic4} width="" alt="" />
    
                
              </div>
            </div>
            <div class="col">
              <div class="card shadow-sm">
                  <img src={pic5} width="" alt="" />
    
                
              </div>
            </div>
         
          </div> */}

        </Section3Wrapper>
        </Section3Container>
    )

}