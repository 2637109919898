import produce from "immer";

const initialState = {
    modifyUserInfoLoading:false,
    modifyUserInfoSuccess:false,
    modifyUserInfoFailure:null,
    loadUserListLoading:false,
    loadUserListSuccess:false,
    loadUserListFailure:null,
    loadUserListData:null,
    loadUserProfileImgLoading:false,
    loadUserProfileImgSuccess:false,
    loadUserProfileImgFailure:null,
    loadUserProfileImgData:null,
    modifyUserProfileImgLoading:false,
    modifyUserProfileImgSuccess:false,
    modifyUserProfileImgFailure:null,
    modifyUserProfileImgData:null,
    requestCertificationLoading:false,
    requestCertificationSuccess:false,
    requestCertificationFailure:null,
    requestCertificationData:null,
    addUserInfoLoading:false,
    addUserInfoSuccess:false,
    addUserInfoFailure:null,
    addUserInfoData:null,
    certify_sign_up:false,
    certify_data_agreed_sign_up:null
};

export const INIT_USERS_STATE = 'INIT_USERS_STATE';
export const MODIFY_USER_INFO_REQUEST = 'MODIFY_USER_INFO_REQUEST';
export const MODIFY_USER_INFO_SUCCESS = 'MODIFY_USER_INFO_SUCCESS';
export const MODIFY_USER_INFO_FAILURE = 'MODIFY_USER_INFO_FAILURE';
export const MODIFY_USER_INFO_INIT = 'MODIFY_USER_INFO_INIT';

export const LOAD_USER_LIST_REQUEST = 'LOAD_USER_LIST_REQUEST';
export const LOAD_USER_LIST_SUCCESS = 'LOAD_USER_LIST_SUCCESS';
export const LOAD_USER_LIST_FAILURE = 'LOAD_USER_LIST_FAILURE';
export const LOAD_USER_LIST_INIT = 'LOAD_USER_LIST_INIT';

export const LOAD_USER_PROFILE_IMG_REQUEST= 'LOAD_USER_PROFILE_IMG_REQUEST';
export const LOAD_USER_PROFILE_IMG_SUCCESS = 'LOAD_USER_PROFILE_IMG_SUCCESS';
export const LOAD_USER_PROFILE_IMG_FAILURE = 'LOAD_USER_PROFILE_IMG_FAILURE';
export const LOAD_USER_PROFILE_IMG_INIT = 'LOAD_USER_PROFILE_IMG_INIT';

export const MODIFY_USER_PROFILE_IMG_REQUEST = 'MODIFY_USER_PROFILE_IMG_REQUEST';
export const MODIFY_USER_PROFILE_IMG_SUCCESS = 'MODIFY_USER_PROFILE_IMG_SUCCESS';
export const MODIFY_USER_PROFILE_IMG_FAILURE = 'MODIFY_USER_PROFILE_IMG_FAILURE';
export const MODIFY_USER_PROFILE_IMG_INIT = 'MODIFY_USER_PROFILE_IMG_INIT';

export const REQUEST_CERTIFICATION_REQUEST = 'REQUEST_CERTIFICATION_REQUEST';
export const REQUEST_CERTIFICATION_SUCCESS = 'REQUEST_CERTIFICATION_SUCCESS';
export const REQUEST_CERTIFICATION_FAILURE = 'REQUEST_CERTIFICATION_FAILURE';
export const REQUEST_CERTIFICATION_INIT = 'REQUEST_CERTIFICATION_INIT';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const ADD_USER_INIT = 'ADD_USER_INIT';

export const CERTIFY_SIGN_UP_USER = 'CERTIFY_SIGN_UP_USER';
export const INIT_CERTIFY_SIGN_UP_USER = 'INIT_CERTIFY_SIGN_UP_USER';

const usersReducer = (state=initialState,action)=>{
    return produce(state,(draft)=>{
        switch(action.type){
            case INIT_CERTIFY_SIGN_UP_USER:{
                draft.certify_sign_up=false;
                draft.certify_data_agreed_sign_up=null;
                break;
            }
            case CERTIFY_SIGN_UP_USER:{
                draft.certify_sign_up=true;
                draft.certify_data_agreed_sign_up=action.data;
                break;
            }
            case ADD_USER_REQUEST:{
                draft.addUserInfoLoading=false;
                draft.addUserInfoSuccess=false;
                draft.addUserInfoFailure=null;
                draft.addUserInfoData=null;
                break;
            }
            case ADD_USER_SUCCESS:{
                draft.addUserInfoLoading=false;
                draft.addUserInfoSuccess=true;
                draft.addUserInfoData=action.data;
                break;
            }
            case ADD_USER_FAILURE:{
                draft.addUserInfoLoading=false;
                draft.addUserInfoFailure=action.data;
                break;
            }
            case ADD_USER_INIT:{
                draft.addUserInfoLoading=false;
                draft.addUserInfoSuccess=false;
                draft.addUserInfoFailure=null;
                draft.addUserInfoData=null;
                break;
            }

            case REQUEST_CERTIFICATION_REQUEST:{
                draft.requestCertificationLoading=true;
                draft.requestCertificationSuccess=false;
                draft.requestCertificationFailure=null;
                break;
            }
            case REQUEST_CERTIFICATION_SUCCESS:{
                draft.requestCertificationLoading=false;
                draft.requestCertificationSuccess=true;
                draft.requestCertificationData=action.data;
                break;
            }
            case REQUEST_CERTIFICATION_FAILURE:{
                draft.requestCertificationLoading=false;
                draft.requestCertificationFailure=action.data;
                break;
            }
            case REQUEST_CERTIFICATION_INIT:{
                draft.requestCertificationLoading=false;
                draft.requestCertificationSuccess=false;
                draft.requestCertificationFailure=null
                draft.requestCertificationData=null;
                break;
            }
            case MODIFY_USER_PROFILE_IMG_REQUEST:{
                draft.modifyUserProfileImgLoading=true;
                draft.modifyUserProfileImgSuccess=false;
                draft.modifyUserProfileImgFailure=null;
                break;
            }
            case MODIFY_USER_PROFILE_IMG_SUCCESS:{
                draft.modifyUserProfileImgLoading=false;
                draft.modifyUserProfileImgSuccess=true;
                draft.modifyUserProfileImgData=action.data;
                break;
            }
            case MODIFY_USER_PROFILE_IMG_FAILURE:{
                draft.modifyUserProfileImgLoading=false;
                draft.modifyUserProfileImgFailure=action.data;
                break;
            }
            case MODIFY_USER_PROFILE_IMG_INIT:{
                draft.modifyUserProfileImgLoading=false;
                draft.modifyUserProfileImgSuccess=false;
                draft.modifyUserProfileImgFailure=null;
                draft.modifyUserProfileImgData=null;
                break;
            }
            case LOAD_USER_PROFILE_IMG_REQUEST:{
                console.log(LOAD_USER_PROFILE_IMG_REQUEST);
                draft.loadUserProfileImgLoading=true;
                draft.loadUserProfileImgSuccess=false;
                draft.loadUserProfileImgFailure=null;
                break;
            }
            case LOAD_USER_PROFILE_IMG_SUCCESS:{
                console.log(LOAD_USER_PROFILE_IMG_SUCCESS);
                draft.loadUserProfileImgLoading=false;
                draft.loadUserProfileImgSuccess=true;
                draft.loadUserProfileImgData=action.data;
                break;
            }
            case LOAD_USER_PROFILE_IMG_FAILURE:{
                draft.loadUserProfileImgLoading = false;
                draft.loadUserProfileImgFailure = action.data;
                break;
            }
            case LOAD_USER_PROFILE_IMG_INIT :{
                draft.loadUserProfileImgLoading=false;
                draft.loadUserProfileImgSuccess=false;
                draft.loadUserProfileImgFailure=null;
                draft.loadUserProfileImgData=null;
                break;
            }
            case LOAD_USER_LIST_REQUEST:{
                draft.loadUserListLoading=true;
                draft.loadUserListSuccess=false;
                draft.loadUserListFailure=null;
                break;
            }
            case LOAD_USER_LIST_SUCCESS:{
                console.log(action.data);
                draft.loadUserListLoading=false;
                draft.loadUserListSuccess=true;
                draft.loadUserListData=action.data;
                break;
            }
            case LOAD_USER_LIST_FAILURE:{
                draft.loadUserListLoading=false;
                draft.loadUserListFailure=action.data;
                break;
            }
            case LOAD_USER_LIST_INIT:{
                draft.loadUserListLoading=false;
                draft.loadUserListSuccess=false;
                draft.loadUserListFailure=null;
                draft.loadUserListData=null;
                break;
            }
            case MODIFY_USER_INFO_REQUEST:{
                draft.modifyUserInfoLoading=true;
                draft.modifyUserInfoSuccess=false;
                draft.modifyUserInfoFailure=null;
                break;
            }
            case MODIFY_USER_INFO_SUCCESS:{
                draft.modifyUserInfoLoading=false;
                draft.modifyUserInfoSuccess=true;
                break;
            }
            case MODIFY_USER_INFO_FAILURE:{
                draft.modifyUserInfoLoading=false;
                draft.modifyUserInfoFailure=action.data;
                break;
            }
            case MODIFY_USER_INFO_INIT: {
                draft.modifyUserInfoLoading=false;
                draft.modifyUserInfoSuccess=false;
                draft.modifyUserInfoFailure=null;
                break;
            }     
            case INIT_USERS_STATE:{
                break;
            }
            default:break;
        }
    });
}

export default usersReducer;