import React,{useState,useEffect,useRef,useCallback} from 'react';
import styled from 'styled-components';
import {CloseOutlined,LeftOutlined,RightOutlined,HeartOutlined
    ,CaretLeftOutlined,PlusOutlined,CaretRightOutlined,RotateRightOutlined,RotateLeftOutlined
} from '@ant-design/icons';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassPlus,faMagnifyingGlassMinus, faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import BackgroundImage1 from '../../assets/images/product_detail_page/1.jpg';
import BackgroundImage6 from '../../assets/images/product_detail_page/6.jpg';
import BackgroundImage5 from '../../assets/images/product_detail_page/5.jpg';
import BackgroundImage4 from '../../assets/images/product_detail_page/4.jpg';
import BackgroundImage3 from '../../assets/images/product_detail_page/3.jpg';
import BackgroundImage2 from '../../assets/images/product_detail_page/2.jpg'; 

const FullImageWrap = styled.div`
    position:fixed;
    top:0%;left:0%;
    z-index:10000;
    display:flex;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.85); 
`;

const FullImageContainer = styled.div`
    display:flex;
    width:100%;height:100%;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    position:relative;
`;

const ToolBarComponentWrap = styled.div`
    width:100%;height:5.5%;
    background:#000;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    
`;

const ToolBarComponent = styled.div`
    width:75%;height:100%;
    display:flex;
    align-items:center;
    justify-content: space-evenly;
    div{
        width:5vh;height:5vh;
        color:#fff;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 27px;
        cursor:pointer;
    }
    @media screen and (max-height:900px){
        div{
            font-size:22.5px;
        }
    }
    @media screen and (max-height:600px){
        div{
            font-size:17.5px;
        }
    }
`;

const StyledEntireContentsWrap = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    width:100%;
    height:94.5%;
    background:#fff;
`;

const StyledProfileWrap = styled.div`
    width:100%;
    height:85.5px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:10.5px;
    box-sizing:border-box;
    
    .title-compo{
        height:100%;
        width:auto;
        line-height:60px;
        font-size:34.5px;
        text-align:center;
    }
`;

const StyledProfileComponent = styled.div`
    width:auto;
    height:100%;
    display:flex;
    align-items:center;
    >p{
        margin:0;
    }
    .author-profile-pic{
        width:60px;height:60px;
        border-radius:100%;
        border:none;
        overflow:hidden;    
    }
    .author-profile-name{
        width:auto;
        height:60px;
        line-height:60px;
        font-align:center;
        margin-left: 10px;
        font-size:21px;
    }
    @media screen and (max-width:650px){
        .author-profile-name{display:none;}
    }
`;

const StyledButtonComponent = styled.div`
    width:180px;
    height:100%;
    display:flex;
    align-items:center;
    justify-content: space-around;
    button{
        display:flex;
        justify-content: center;
        align-items:center;
        width:72px;height:54px;
        border-radius:15px;
        border:none;
        background:#f14d5d;
        color:#fff;
        font-size:24.5px;
        transition:background 0.6s;
        &:hover{
            background:#14233D;
        }
        span{margin-bottom:0;}
    }
    @media screen and (max-width:650px){
        width:140px;
        button{
            width:40px;height:30px;
            border-radius:5.5px;
            font-size:19.5px;
        }
    }
`

const StyledImageContainer  = styled.div`
    position: relative;
    width:100%;height:100%;
    box-sizing:border-box;
    padding:20px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
`;

const StyledCloseImageListWrapBtn = styled.div`
    position:absolute;
    cursor:pointer;
    top:50%;right:-30px;
    margin-top:-75px;
    width:30px;height:150px;
    background:#f14d5d;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 0 15.5px 15.5px 0;
    span{
        font-size:22.5px;
        color:#fff;
    }
    @media screen and (max-width:650px){
        border-radius: 0 7.5px 7.5px 0;
        width:15px;height:75px;
        margin-top:-32.5px;
        right:-15px;
        span{
            font-size:12.5px;
        }
    }
`;

const StyledImageListWrap = styled.div`
    width:385px;
    height:100%;
    background-color: #fff;
    box-shadow: 0 0 2.5px #454545;
    border-radius: 15.5px;
    z-index:1000;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #2f3542;
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: grey;
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
    padding:12.5px;
    position:absolute;
    top:0px;left:0px;
    transition : all 0.4s ease-in;
    &:.on{
        transform:tranlateX(-100%);
    }
    @media screen and (max-width:650px){
        width:385px;
        border-radius:5.5px;
    }
    @media screen and (max-width:390px){
        width:285px;
        border-radius:5.5px;
    }
`;

const StyledImageListComponent = styled.div`
    position:absolute;
    top:0;left:0;
    width: calc(100% - 12.5px);
    height:auto;
    .img-thumbnail-wrap{
        transition:border 0.3s ease-in;
        cursor:pointer;
        box-shadow:0 0 2.5px #454545;
        border-radius:15.5px;
        overflow:hidden;
        width:275px;height:275px;
        margin:10px auto;
        img{
            display:block;
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
    .img-thumbnail-wrap.on{
        border: 8.5px solid #f14d5d;
    }
    @media screen and (max-width:650px){
        .img-thumbnail-wrap{
            box-shadow:0 0 0.5px #454545;
            border-radius:5.5px;
            overflow:hidden;
            width:145px;height:145px;
            margin:5px auto;
            
        }
        .img-thumbnail-wrap.on{
            border: 7.5px solid #f14d5d;
        }
    }
`;

const StyledImgWrap = styled.div`
    width:100%;height:100%;
    overflow:hidden;
    display:flex;justify-content:center;
    align-items:center;
`;

const StyledBackgroundImgComponent1 = styled.div`
    width:100%;height:100%;
    position:relative;
    background-image:url(${BackgroundImage1});
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
    .mainImageContainer{
        position:absolute;
        top:50%;left:50%;
        transform:translateX(-50%) translateY(-50%);
        height:100%;width: 26.89%;
        .mainImageWrapper{
            position:absolute;
            left:0;top:10%;
            background-color:#fff;
            height: 34.5%;
            box-sizing: border-box;
            left: 0;
            top: 21.1%;
            width: 100%;
        }
    }
`;

const StyledMainImgComponent = styled.div`
    width:100%;height:100%;
    border-radius:15.5px;
    display:flex;
    align-items:center;justify-content:center;
    img{
        object-fit:contain;
        transition:transform 0.5s, height 0.6s;
        object-position:50% 50%;
        transform:rotate(${props=>props.angle}deg);
        width:${props=>(props.mainImageLength!==-1&&props.standardAxis==='width')?`${props.mainImageLength}px`: 'auto'};
        height: ${props=>(props.mainImageLength!==-1&&props.standardAxis==='height')?`${props.mainImageLength}px`:'auto'};
        -webkit-box-reflect: below 35px linear-gradient(transparent 45%, rgba(255, 255, 255, 0.25));
        -webkit-user-select:none;
    }
`;

const StyledCloseButton = styled.p`
    display: flex;
    width : 30px; height : 30px;
    @media screen and (max-height:900px){
        width:22.5px; height:22.5px;
        span{
            font-size:22.5px;
        }
    }
    @media screen and (max-height:600px){
        width:17.5px; height:17.5px;
        span{
            font-size:17.5px;
        }
    }
`;

const StyledCloseOutlined = styled(CloseOutlined)`
    width:100%;
    height: 100%;
    color:white;
    font-size:30px;
    cursor:pointer;
`;

const findElement = (elem,findCondition,endCondition)=>{
    const flag = elem.tagName ===findCondition.toUpperCase()||elem.classList.contains(findCondition);
    if(flag){
        return elem;
    }else{
        const endFlag = elem.tagName ===endCondition.toUpperCase()||elem.classList.contains(endCondition);
        if(endFlag){
            return null;
        }else{
            return findElement(elem.parentElement,findCondition,endCondition);
        }
    }
}

const FullImage = ({title,author,image,setShowView})=>{
    const [showImgSlider,setShowImgSlider] = useState(false);
    const imageContainerRef = useRef(null);
    const mainImageRef = useRef(null); 
    const imageListWrapRef = useRef(null);
    const basicAngle = useRef(90);
    const standardAxis = useRef('width');
    const [rotateIdx,setRotateIdx] = useState(0);
    const [loading,setLoading]=useState(true);

    const onClickCloseListWrapBtn = useCallback(()=>{
        setShowImgSlider(prev=>!prev);
    },[]);
    const [mainImageLength,setMainImageLength] = useState(-1);

    useEffect(()=>{
        const imgListStyle = document.defaultView.getComputedStyle(imageListWrapRef.current);
        const width = parseFloat(imgListStyle.width);
        imageListWrapRef.current.style.transform = showImgSlider?`translateX(0px)`:`translateX(-${width}px)`;
        
    },[showImgSlider]);

    //초기 설정 side effect
    /* useEffect(()=>{
        const imageContainerStyle = document.defaultView.getComputedStyle(imageContainerRef.current);
        const imageContainerHeight = parseFloat(imageContainerStyle.height);
        const imageContainerWidth = parseFloat(imageContainerStyle.width);
        const ratio = parseFloat(imageContainerWidth/imageContainerHeight);
        ratio>1? setMainImageLength(imageContainerHeight):setMainImageLength(imageContainerWidth);
        standardAxis.current = ratio>1?'height':'width';
        setLoading(false);
    },[]); */

    //이미지 확대
    const onClickZoomIn = useCallback((evt)=>{
        if(standardAxis.current==='height'&&mainImageLength/window.innerHeight>2){
            const imageContainerStyle = document.defaultView.getComputedStyle(imageContainerRef.current);
            const imageContainerHeight = parseFloat(imageContainerStyle.height);
            setMainImageLength(imageContainerHeight);
            return false;
        }else if(standardAxis.current==='width'&&mainImageLength/window.innerWidth>2){
            const imageContainerStyle = document.defaultView.getComputedStyle(imageContainerRef.current);
            const imageContainerWidth = parseFloat(imageContainerStyle.width);
            setMainImageLength(imageContainerWidth);
            return false;
        }else{
            setMainImageLength(prev=>prev*1.25);
        }
    },[standardAxis.current,mainImageLength]);

    const onClickZoomOut = useCallback((evt)=>{
        setMainImageLength(prev=>prev*0.8);
    },[]);

    const onClickRotateRight = useCallback((evt)=>{
        if(rotateIdx*basicAngle.current>=360){
            setRotateIdx(0);
        }else{
            setRotateIdx(prev=>prev+1);
        }
    },[rotateIdx]);

    const onClickRotateLeft = useCallback((evt)=>{
        if(rotateIdx*basicAngle.current<=0){
            setRotateIdx(parseInt(360/basicAngle.current));
        }else{
            setRotateIdx(prev=>prev-1);
        }
    },[rotateIdx]);  

    const onClickClose = useCallback(()=>{
        setShowView(false);
    },[]);

    return(
        <FullImageWrap height={window.innerHeight}>
            <FullImageContainer>
                <ToolBarComponentWrap>
                    <ToolBarComponent className="imageControllerIconsComponent">
                        <div onClick={onClickZoomIn} className="imageControllerIcon plus">
                            <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                        </div>
                        <div onClick={onClickZoomOut} className="imageControllerIcon minus">
                            <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
                        </div>
                        <div onClick={onClickRotateRight} className="imageControllerIcon rotate-right">
                            <RotateRightOutlined />
                        </div>
                        <div onClick={onClickRotateLeft} className="imageControllerIcon rotate-right">
                            <RotateLeftOutlined />
                        </div>
                    </ToolBarComponent>
                    <StyledCloseButton>
                        <StyledCloseOutlined onClick={onClickClose}/>
                    </StyledCloseButton>
                </ToolBarComponentWrap>
                <StyledEntireContentsWrap>
                    <StyledImageContainer>
                        <StyledImageListWrap ref={imageListWrapRef}>
                            <StyledCloseImageListWrapBtn onClick={onClickCloseListWrapBtn}>
                                {
                                    showImgSlider?
                                    <CaretLeftOutlined />
                                    :<CaretRightOutlined />
                                }
                            </StyledCloseImageListWrapBtn>
                        </StyledImageListWrap>
                        <StyledImgWrap>
                            <StyledBackgroundImgComponent1>
                                <div className="mainImageContainer">
                                    <div className="mainImageWrapper">
                                    <img ref={mainImageRef} src={image} alt="product Original Image"/>
                                    </div>
                                </div>
                            </StyledBackgroundImgComponent1>
                            {/*<StyledMainImgComponent angle={rotateIdx*basicAngle.current} standardAxis={standardAxis.current} mainImageLength={mainImageLength} ref={imageContainerRef}>
                                {   loading?
                                    <img src="/static/media/loader.58607b10.gif" alt="loading icon"/>:
                                    <img ref={mainImageRef} src={image} alt="product Original Image"/>}
                                </StyledMainImgComponent>*/}
                        </StyledImgWrap>
                    </StyledImageContainer>
                </StyledEntireContentsWrap>
            </FullImageContainer>
        </FullImageWrap>
    )
}

export default FullImage;