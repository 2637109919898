
import React from 'react';
import { Link } from 'react-router-dom';

import faiverLogo from '../../assets/logo.svg';
import shapeBg from '../../assets/images/shapes/shape-bg.png';

import { HOMEPAGE, MARKETPLACE, USER_PROFILE, USER_DASHBOARD, USER_COLLECTION } from '../../constants/applicationUrls';
import styled from 'styled-components';
import sns1 from '../../assets/images/sns_icon/3032.png';
import sns2 from '../../assets/images/sns_icon/blog.png';
import sns3 from '../../assets/images/sns_icon/linked_in.png';
import sns4 from '../../assets/images/sns_icon/twitter.png';

const FooterContainer = styled.footer`
    height:192px;
    width:100%;
    background-color:#000;
    @media screen and (max-width:855px){
        padding-top:20px;
        padding-bottom:20px;
        height:auto;
    }
`;

const FooterWrapper = styled.div`
    height:100%;width:75%;
    margin:0 auto;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    @media screen and (max-width:940px){
        width:90.5%;
    }
    @media screen and (max-width:855px){
        flex-direction:column;
        width:100%;
    }
    @media screen and (max-width:785px){
        width:96.5%;
    }
    
`;

const TitleComponent = styled.div`
    width:190px;
    height:65px;
    padding :1.5px 2.5px;
    p{
        width:185px;
        height:63px;
        margin:0;padding:0;
        font-weight:400;
        color: #ff4d5c;
        text-align: center;
        font-size: var(--footer-title);
    
    }
    @media screen and (max-width:855px){
        height:35px;
        margin-bottom: 15px;
        p{
            font-size: var(--tablet-footer-title);
            height:32.5px;
        }
    }
`;

const ServiceInfoContainer = styled.div`
    width:calc(90% - 190px);
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    @media screen and (max-width:1046px){
        width:calc(100% - 190px);
    }
    @media screen and (max-width:855px){
        width:100%;
    }
`;

const ServiceInfoWrapper = styled.div`
    width:calc(100% - 15px);
    height:calc(100% - 15px);
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    .main-info-wrap{
        width:100%;
        height:112px;
        box-sizing:border-box;
        border-bottom:2px solid #454545;
        display:flex;
        align-items:center;
        justify-content:space-between;
        .addr-info{
            width:auto;
            height:82.5px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            p{
                color:#fff;
                width:100%;
                height:20px;
                font-size:var(--footer-info);
                margin:0;
                line-height:20px;
                &.contact-info{
                    width:85.5%;
                    >span{
                        font-size:var(--footer-info);
                    }
                    span+span{
                        margin-left:10px;
                    }
                }
            }
        }
        .sns-info{
            width:auto;
            height:62.5px;
            display:flex;
            flex-direction:column;
            justify-content:flex-end;
            p{
                width:100%;
                height:21px;
                font-size:font-size:var(--footer-info);
                line-height:30px;
                color:#fff;
                span{
                    display:inline-block;
                    width:21px;height:21px;
                    margin-right:10px;
                    img{
                        display:block;
                        width:100%;height:100%;
                    }
                }
            }
        }
        
    }
    .sub-info-wrap{
        width:100%;
        height:38px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        p{
            width:auto;
            height:28px;
            line-height:28px;
            color:#fff;
            font-size:var(--footer-info);
            span{
                a{
                    color:#fff;
                    font-size:var(--footer-info);
                }
            }
            span+span{
                margin-left:5.5px;
            }
        }
    }
    @media screen and (max-width:855px){
        width:100%;
        .main-info-wrap{
            width:100%;
            height:auto;
            border-bottom:none;
            flex-direction:column;
            .addr-info{
                height:auto;
                margin-bottom:12.5px;
                p{
                    width:285.5px;
                    height:auto;
                    text-align:center;
                    font-size:var(--tablet-footer-info);
                    &.company-info{
                        display:flex;
                        flex-direction:column;
                    }
                    &.location-addr{
                        display:flex;
                        flex-direction:column;
                    }
                    &.contact-info{
                        width:85.5%;
                        display:flex;
                        flex-direction:row;
                        justify-content:center;
                        >span{
                            font-size:var(--tablet-footer-info);
                        }
                        span+span{
                            margin-left:10px;
                        }
                    }
                }
            }
            .sns-info{
                height:auto;
                justify-content:center;
                p{
                    font-size:font-size:var(--tablet-footer-info);
                }
            }
            margin-bottom: 12.5px;
            
        }
        .sub-info-wrap{
            width:100%;
            flex-direction:column;
            justify-content:space-evenly;
            height:62px;
            p{
                font-size:var(--tablet-footer-info);
                span{
                    a{
                        font-size:var(--footer-info);
                    }
                }
            }
        }
    }
    @media screen and (max-width:578px){
        .main-info-wrap{
            .addr-info{
                p{
                    &.contact-info{
                        display:flex;
                        flex-direction:row;
                        justify-content:center;
                        width: 84.5%;margin: 0 auto;
                        >span{
                            font-size:var(--tablet-footer-info);
                        }
                        span+span{
                            margin-left:0;
                        }
                    }
                }
            }
            .sns-info{
                height:auto;
                justify-content:center;
                p{
                    font-size:font-size:var(--tablet-footer-info);
                }
            }
            margin-bottom: 12.5px;
            
        }
        .sub-info-wrap{
            width:100%;
            flex-direction:column;
            justify-content:space-evenly;
            height:62px;
            p{
                font-size:var(--tablet-footer-info);
                span{
                    a{
                        font-size:var(--footer-info);
                    }
                }
            }
        }
    }
`;

function Footer() {

    return (
        <FooterContainer>
            <FooterWrapper>
                <TitleComponent>
                    <p>COLLERY</p>
                </TitleComponent>
                <ServiceInfoContainer>
                    <ServiceInfoWrapper>
                        <div className="main-info-wrap">
                            <div className="addr-info">
                                <p className="company-info">
                                    <span>상호명 : (주)내스타일, 사업자 등록 번호 : 382-81-01860, </span>
                                    <span>대표자 : 이용균, </span>
                                    <span>통신판매업신고 : 제 2022-서울서초-1973호</span>
                                </p>
                                <p className="location-addr"> 주소 : 서울특별시 서초구 매헌로 16, 12층 1호<span>(양재동, 하이브랜드)</span></p>
                                <p className="contact-info">
                                    <span>이메일:</span>
                                    <span>contact@nestyle.ai</span>
                                </p>
                                
                            </div>
                            <div className="sns-info">
                                <p>
                                    <span>
                                        <img src={sns1} alt="sns-logo" />
                                    </span>
                                    <span>
                                        <img src={sns2} alt="sns-logo" />
                                    </span>
                                    <span>
                                        <img src={sns3} alt="sns-logo" />
                                    </span>
                                    <span>
                                        <img src={sns4} alt="sns-logo" />
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="sub-info-wrap">
                            <p>
                                Copyright 2022. COLLERY All rights reserved
                            </p>
                            <p>
                                <span>
                                    <Link to="/policy/personal-info">
                                        개인정보처리방침
                                    </Link>
                                </span>
                                <span>
                                    <Link to="/policy/service-policy">
                                        이용약관
                                    </Link>
                                </span>
                            </p>
                        </div>
                    </ServiceInfoWrapper>
                </ServiceInfoContainer>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer;