import React, { useCallback, useEffect, useState, useRef } from 'react';
/* import { Link } from 'react-router-dom'; */
import web3 from 'web3';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../Loader';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Web3Modal from 'web3modal';
import { create } from 'ipfs-http-client';
/* import { ethers } from 'ethers';
import {
    nftaddress, nftmarketaddress
} from '../../../config';
 */
/* import NFT from '../../../artifacts/contracts/NFT.sol/NFT.json';
import Market from '../../../artifacts/contracts/Market.sol/Market.json';
 */

import { AVAILABLE_BLOCKCHAINS, AVAILABLE_CATEGORIES, MAX_ROYALTY_FEES } from '../../../constants/application';
/* import { IPFS_URL } from '../../../constants/urls';
import { USER_NFT } from '../../../constants/applicationUrls';
import { startImageMinting, uploadFileToIPFS } from '../../../api/minting';
 */
import './style.css';
import { Gasfee } from '../../Gasfees/Gasfee';
/* 22_05_08 wooseok Kim */
import styled from 'styled-components';
import { PictureOutlined } from "@ant-design/icons";
import MessageComponent from "../../MessageCommponent"
import {INIT_MINTING_STATE,LOADING_MINTING} from "../../../reducers/nftTokens"
import {useDispatch,useSelector} from "react-redux";
import { MARKETPLACE,HOMEPAGE, USER_NFT_MINTING, USER_IMAGE_NFT_MINTING} from '../../../constants/applicationUrls';
//const client = create('https://ipfs.infura.io:5001/api/v0');
import LoadingComponent from "../../LoadingComponent";
import {NFTProductMinting} from '../../../constants/applicationMessage';
import axios from 'axios';

const EntireContainer = styled.div`
    @media screen and (max-width:587px){
        .col-lg-12{
            padding:0;
        }
    }
`;

/* 22_05_08 wooseok Kim */
const Row = styled.div`
    width:auto;height:auto;
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:space-evenly;
    @media screen and (max-width:1280px){
        flex-direction:column;
        justify-content:center;
    }
    @media screen and (max-width:587px){
        padding:5.5px;
    }
`;

/* 22_05_08 wooseok Kim */
const UploadFileComponentWrap = styled.div`
    padding: 0;
    width:auto;
    height:auto;
    padding-top: 20px;
    box-shadow: 0 0 15px rgb(0 0 0 / 6%);
    @media screen and (max-width:855px){
        margin:10px auto;
    }
`;

/* 22_05_08 wooseok Kim */
const UploadImagePreviewBox = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    padding:0 24px;
    box-sizing:border-box;
    h3{
        font-size:var(--dashboard-page-title);
        font-weight:bold;
    }
    .preview-button{
        background:#7e7e7e;
        width:344px;height:344px;
        .previewButton-input{
            display:none;
        }
        .previewButton-button{
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:center;
            width:100%;height:100%;
            .upload-text{
                width:240px;
                height:58px;
                text-align:center;
                color:#fff;
                font-weight:650;
                font-size:var(--dashboard-page-info);
                margin:0 auto;
                &.icon{
                    font-size:36px;
                }
            }
        }
    }
    .file-delete-button-wrap{
        margin-top:15.5px;
        height:50px;width:344px;
        box-sizing:border-box;
        padding:1px 0;
        border:none;
        .file-delete-button{
            width:100%;
            height:48px;
            border:none;
            text-align:center;
            line-height:48px;
            color:#fff;
            background:#ff4d2c;
            font-size:var(--dashboard-page-button);
            border-radius:4.5px;
            &:hover{
                color:#ff4d2c;
                background:#fff;
                box-sizing:border-box;
                border:1px solid #ff4d2c;
            }
        }
    }
    @media screen and (max-width:855px){
        h3{
            font-size:var(--tablet-dashboard-page-title);
        }
        .preview-button{
            width:304px;height:304px;
            .previewButton-button{
                .upload-text{
                    font-size:var(--tablet-dashboard-page-info);
                    &.icon{
                        font-size:26px;
                    }
                }
            }
        }
        .file-delete-button-wrap{
            margin-top:10.5px;
            height:35.5px;width:304px;
            .file-delete-button{
                height:32.5px;
                line-height:32.5px;
                font-size:var(--tablet-dashboard-page-button);
            }
        }
    }
    @media screen and (max-width:587px){
        h3{
            font-size:var(--mobile-dashboard-page-title);
        }
        .preview-button{
            width:275px;height:275px;
            .previewButton-button{
                .upload-text{
                    font-size:var(--mobile-dashboard-page-info);
                    &.icon{
                        font-size:26px;
                    }
                }
            }
        }
        .file-delete-button-wrap{
            .file-delete-button{
                font-size:var(--mobile-dashboard-page-button);
            }
        }
    }
`;

/* 22_05_08 wooseok Kim */
const ImageShowComponentWrap = styled.div`
    width:344px;height:344px;
    border-radius:9.5px;
    img{
        display:block;
        width:100%;height:100%;
        object-position:50%;
        object-fit:contain;
    }
    @media screen and (max-width:855px){
        width:304px;height:304px;
    }
    @media screen and (max-width:587px){
        width:275px;height:275px;
    }
`;

const Container = styled.div`
    .header-wrap{
        margin-bottom:50.5px;
        .page-title{
            font-size:var(--page-title);
            height:55.5px;
            margin-bottom:15.5px;
            margin-top:15.5px;
            font-weight:bold;
        }
        .page-explain{
            line-height:var(--line-height-exp);
            font-size:var(--explain-text);
            color:#000;
            font-weight:650;
        }    
    }
    .row{
        width:100%;
        margin:0;
    }
    .form-group{
        .form-select{
            padding:5px;
        }
        &.half{
            width:367.5px;
        }
        label{
            font-size:var(--dashboard-page-title);
            font-weight:bold;
        }
        .form-control,textarea{
            font-size:var(--dashboard-page-info);
        }
        .purpose-of-issue-radio-wrap{
            height:55px;width:100%;
            box-sizing:border-box;
            padding:10px;
            display:flex;flex-direction:row;
            align-items:center;
            justify-content:flex-start;
            border-radius: 4.5px;
            box-shadow: none;
            .check-box{
                height:45px;width:auto;
                display:flex;
                align-items:center;
                input{
                    display:inline-block;
                    width:25px;height:25px;
                }
                label{
                    display:inline-block;
                    margin:0px 0px 0px 10px;
                    height:35px;
                    font-weight:400;
                    font-size:var(--dashboard-page-info);
                    line-height:35px;
                }
            }
            .check-box+.check-box{
                margin-left:15.5px;
            }
        }
        .setting_blockchain_type_and_price_wrap{
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:flex-start;
            margin-bottom:15.5px;
            label{
                white-space:nowrap;
                margin:0px 15.5px 0px 0px;
                font-weight:bold;
                font-size:var(--dashboard-page-title);
            }
            .set_blockchain_type{
                font-weight: 400;
                margin-left: 10px;
                color: #454545;
                font-size:var(--dashboard-page-info);
            }
        }
    }
    .submit-button-wrap button{
        display:block;
        width:245px;height:38px;
        font-size:var(--dashboard-page-button);
        font-weight:400;
        border-radius:4.5px;
        background-color:#ff4d2c;
        color:#fff;
        margin:0 auto;
        &:hover{
            color:#ff4d2c;
            background:#fff;
            box-sizing:border-box;
            border:1px solid #ff4d2c;
        }
    }

    @media screen and (max-width:855px){
        .header-wrap{
            margin-bottom:25.5px;
            .page-title{
                font-size:var(--tablet-page-title);
                height:40px;
                margin-bottom:15.5px;
                margin-top:15.5px;
                font-weight:bold;
            }
            .page-explain{
                line-height:var(--tablet-page-subtitle);
                font-size:var(--tablet-explain-text);
                color:#000;
                font-weight:650;
            }    
        }
        .form-group{
            label{
                font-size:var(--tablet-dashboard-page-title);
            }
            .form-control,textarea{
                font-size:var(--tablet-dashboard-page-info);
            }
            .purpose-of-issue-radio-wrap{
                justify-content:space-around;
                .check-box{
                    input{
                        width:15.5px;height:15.5px;
                    }
                    label{
                        font-size:var(--tablet-dashboard-page-info);
                    }
                }
                .check-box+.check-box{
                    margin-left:10.5px;
                }
            }
            .setting_blockchain_type_and_price_wrap{
                label{
                    font-size:var(--tablet-dashboard-page-title);
                }
                .set_blockchain_type{
                    font-size:var(--tablet-dashboard-page-info);
                }
            }
            .submit-button-wrap button{
                font-size:var(--tablet-dashboard-page-button);
            }
        }
    }
    @media screen and (max-width:587px){
        padding:0;
        .header-wrap{
            margin-bottom:20.5px;
            .page-title{
                font-size:var(--mobile-page-title);
                height:30px;
                margin-bottom:10.5px;
                margin-top:10.5px;
                font-weight:bold;
            }
            .page-explain{
                line-height:var(--mobile-list-subtitle);
                font-size:var(--mobile-explain-text);
                color:#000;
                font-weight:650;
            }    
        }
        .form-group{
            label{
                font-size:var(--mobile-dashboard-page-title);
                &.por{
                    display:flex;
                    flex-direction:column;
                    line-height: 22.5px;
                }
            }
            .form-control,textarea{
                font-size:var(--mobile-dashboard-page-info);
            }
            .purpose-of-issue-radio-wrap{
                .check-box{
                    input{
                        width:15.5px;height:15.5px;
                    }
                    label{
                        margin-left:2.5px;
                        font-size:var(--mobile-dashboard-page-info);
                    }
                }
                .check-box+.check-box{
                    margin-left:5.5px;
                }
            }
            .setting_blockchain_type_and_price_wrap{
                label{
                    font-size:var(--mobile-dashboard-page-title);
                }
                .set_blockchain_type{
                    font-size:var(--mobile-dashboard-page-info);
                }
            }
            .submit-button-wrap button{
                font-size:var(--mobile-dashboard-page-button);
            }
        }
    }
    @media screen and (max-width:400px){
        width:360px;
        .header-wrap{
            margin-bottom:20.5px;
            .page-title{
            }
            .page-explain{
            }    
        }
        .form-group{
            padding:5.5px;
            label{
                font-size:var(--mobile-dashboard-page-title);
            }
            .form-control,textarea{
                display:block;
                box-sizing:border-box;
                padding: 8.5px 10px;
                width:295px;
                font-size:var(--mobile-dashboard-page-info);
            }
            .purpose-of-issue-radio-wrap{
                .check-box{
                    input{
                        width:15.5px;height:15.5px;
                    }
                    label{
                        margin-left:2.5px;
                        font-size:var(--mobile-dashboard-page-info);
                    }
                }
                .check-box+.check-box{
                    margin-left:5.5px;
                }
            }
            .setting_blockchain_type_and_price_wrap{
                label{
                    font-size:var(--mobile-dashboard-page-title);
                }
            }
            .submit-button-wrap button{
                font-size:var(--mobile-dashboard-page-button);
            }
        }
    }
`;

const FormContainer = styled.div`
    width:auto;height:auto;
    width:100%;
`;

const FormWrapper = styled.div`
    padding: 15px 15px;
    box-sizing:border-box;
    margin-bottom: 30px;
    background-color: var(--whiteColor);
    -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 6%);
    box-shadow: 0 0 15pxrgba(0,0,0,.06);
    @media screen and (max-width:400px){
        width:100%;
        padding: 5.5px 5.5px;
        box-shadow:none;
        box-sizing:content-box;
        form{
            width:100%;
        }
    }
`;

function UserImageMinting({ currentUser }) {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(AVAILABLE_CATEGORIES.length ? AVAILABLE_CATEGORIES[0].value : 'other');
    const [blockchain, setBlockchain] = useState(AVAILABLE_BLOCKCHAINS.length ? AVAILABLE_BLOCKCHAINS[0] : { 'etherium': 'eth' });
    const [price, setPrice] = useState('');
    const [royalty, setRoyalty] = useState(0);

    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const [mintMessage, setMintMessage] = useState('');
    const [mintProcessing, setMintProcessing] = useState(false);
    const [mintCompleted, setMintCompleted] = useState(false);
    const [mintError, setMintError] = useState(false);

    const history = useHistory();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);

    const [rent, setRent] = useState('false')
    const [pricerent, setPriceRent] = useState('');
    const [purposeOfIssue, setPurposeOfIssue] = useState('sell');
    const [showMessageComponent,setShowMessageComponent]=useState(false);
    const [username,setUserName]=useState('')

    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const priceRef = useRef(null);
    const priceRentRef = useRef(null);

    const{
        loadingMinting ,
        mintingSuccess,
        mintingData,
        mintingFailure
    } = useSelector(state=>state.nftTokens) 
    const dispatch = useDispatch();

    const onChangePurposeOfIssue = useCallback((evt) => {
        const { value } = evt.target;
        setPurposeOfIssue(value);
    }, []);

    /* const onChangeBlockChain = useCallback((evt) => {
        const { value } = evt.target;
        const idx = AVAILABLE_BLOCKCHAINS.findIndex(elem => elem.name === value);
        console.log(AVAILABLE_BLOCKCHAINS[idx]);
        setBlockchain(AVAILABLE_BLOCKCHAINS[idx]);
    }, []); */

    useEffect(()=>{
        if(!loadingMinting && mintingSuccess){
            setMintMessage(mintingData);
            setShowMessageComponent(true);
        }else if(!loadingMinting&&mintingFailure){
            setMintMessage(mintingFailure);
            setShowMessageComponent(true);
            dispatch({type:INIT_MINTING_STATE});
        }
    },[loadingMinting,
        mintingSuccess,
        mintingData,
        mintingFailure])

    useEffect(() => {

        loadUserMe()
    
    }, [currentUser.uid])

    async function loadUserMe(){
        await axios.get(`https://api.collery.io/getuserinfo/${currentUser.uid}`).then((res)=>{

        setUserName(res.data[0].firstname); 
        console.log("USERNAME===========================", username);

        }).catch((err) => {
            console.log(err)
        })
    }
    

    /* const checkForm = useCallback(()=>{
        console.log('checkForm');
        if(!imageFile){
            setMintMessage('Minting 할 Image 파일을 업로드 해주세요!');
            setShowMessageComponent(true);
            return false;
        }
        if(title===''){
            setMintMessage('제목을 입력해주세요');
            setShowMessageComponent(true);
            return false;
        }
        if(description===''){
            setMintMessage('Minting 할 NFT 의 설명을 입력해주세요');
            setShowMessageComponent(true);
            return false;
        }
        if(purposeOfIssue==='rent'||purposeOfIssue==='both'){
            if(pricerent<=0){
                setMintMessage('Rent 할 가격의 0원 이상이어야 합니다.');
                setShowMessageComponent(true);
                return false;
            }
        }else if(purposeOfIssue==='sell'||purposeOfIssue==='both'){
            if(price<=0){
                setMintMessage('판매할 가격의 0원 이상이어야 합니다.');
                setShowMessageComponent(true);
                return false;
            }
        }
        if(purposeOfIssue=='both'){
            if(price<pricerent){
                setMintMessage("임대 가격이 판매 가격보다 클 수는 없습니다.");
                setShowMessageComponent(true);
                return false;
            }
        }
        return true;
    },[imageFile,
        title,
        description,
        price,
        purposeOfIssue,
        price,
        pricerent,
    ]) */

    const handleSubmit = async (event) => {

        /*if(purposeOfIssue=='sell'){
            setRent('false')
            setPriceRent(0)

            console.log(rent,price)
        }else if(purposeOfIssue=='rent'){
            setRent('true')
            setPrice(0)

            console.log(rent,price)
            
        }else if(purposeOfIssue=='both'){
            setRent('both')
            console.log(rent,price)
            
        }else if(purposeOfIssue==='proof'){
            setRent('proof')
            setPrice(0)
            setPriceRent(0)
            console.log(rent,price)
            
        }*/
        event.preventDefault();
       /*  const flag = checkForm();
        if(!flag){return false;} */
        
        handleShow();
        setMintCompleted(false);
        setMintError(false);
        setMintProcessing(true);
        setMintMessage(NFTProductMinting.uploadmessage);
        const formData = new FormData();
        formData.append('file', imageFile);
        formData.append('name', title);
        formData.append('description', description);
        formData.append('price', !price.match(',')?price:price.split(',').join('')); 
        formData.append('userid', currentUser.uid);
        formData.append('username', username);
        formData.append('rent', purposeOfIssue);
        formData.append('pricerent', !pricerent.match(',')?pricerent:pricerent.split(',').join(''));
        formData.append('category', category);
        formData.append('format', 'image');
        formData.append('bgimage', '');
        dispatch({type:LOADING_MINTING,data:formData,mintType:'image'});
        /* await axios.post(`https://api.collery.io/mintnft`, formData).then((response) => {

            console.log(response.data)
            setMintCompleted(true);
            setMintError(false);
            setMintProcessing(false);
            setMintMessage('Done');
            toast.info("Created successfully", { position: toast.POSITION.BOTTOM_RIGHT });

        }).catch((err) => {

            console.log(err)

            setMintCompleted(true);
            setMintError(false);
            setMintProcessing(false);
            setMintMessage('error');

            toast.error("Error, please try later", { position: toast.POSITION.BOTTOM_RIGHT });

        }) */

    }

    const handleImageSelect = selectedImage => {
        setImageFile(selectedImage);
        setImagePreview(URL.createObjectURL(selectedImage));
    }

    const onConfirmMintSuccess= ()=>{
        return new Promise((resolve,reject)=>{
            resolve(dispatch({type:INIT_MINTING_STATE}));
        })
    }

    const confirmFunction = useCallback(async()=>{
        if(mintingSuccess){
            history.push(USER_IMAGE_NFT_MINTING);
            setTitle('')
            setDescription('')
            setPrice('')
            setImagePreview(null)
            setImageFile(null)
        }
        setShowMessageComponent(false);
        dispatch({type:INIT_MINTING_STATE});
    },
    [mintingSuccess]);

    const onPriceChange = useCallback((evt)=>{
        let {value}=evt.target;
        value=value.split(',').join('');
        if(value.match(/\d+/)){
            setPrice(value.match(/.{1,3}(?=(.{3})*$)/g).join(','));
        }else{
            if(priceRef.current){
                priceRef.current.focus();
            }
            alert("오직 숫자만 입력해야 합니다.")
        }
    },[priceRef.current]);

    const onPriceRentChange = useCallback((evt)=>{
        let {value}=evt.target;
        value=value.split(',').join('');
        if(value.match(/\d+/)){
            setPriceRent(value.match(/.{1,3}(?=(.{3})*$)/g).join(','));
        }else{
            if(priceRentRef.current){
                setPriceRent('');
                priceRentRef.current.focus();
            }
            alert("오직 숫자만 입력해야 합니다.")
        }
    },[priceRentRef.current]);

    return (
        <>
            <Header />
            <div style={{ padding: 0 }} className="create-nft-area pt-50 pb-70">
                <EntireContainer className="container">
                    {
                        loadingMinting
                        &&<LoadingComponent message={mintMessage}/>
                    }
                    {
                        showMessageComponent&&
                        <MessageComponent message={mintMessage} cancelFunction={confirmFunction}/>
                    }
                    <div className="row">

                        <div className="col-lg-12">

                            <Container className="container">
                                <div className="header-wrap">
                                    <h1 className="page-title">Create Image NFT</h1>
                                </div>
                                <Row>
                                    <UploadFileComponentWrap>

                                        <UploadImagePreviewBox className="preview-box">
                                            <h3>Image Preview</h3>
                                            <div className="preview-button">
                                                <input className="previewButton-input" type="file" name="attachments[]" accept="image/*" id="upload" onChange={(e) => handleImageSelect(e.target.files[0])} />
                                                {
                                                    imagePreview ?
                                                        (
                                                            <ImageShowComponentWrap>
                                                                <img src={imagePreview} />
                                                            </ImageShowComponentWrap>
                                                        ) : (
                                                            <label className="previewButton-button ripple-effect" htmlFor="upload">
                                                                <p className="upload-text icon">
                                                                    <PictureOutlined />
                                                                </p>
                                                                <p className="upload-text">
                                                                    Upload image to preview your brand new NFT
                                                                </p>
                                                            </label>
                                                        )
                                                }
                                                <span className="previewButton-file-name"></span>
                                            </div>
                                            {
                                                imagePreview&&
                                                <div className="file-delete-button-wrap">
                                                    <button onClick={() => setImagePreview(null)} className="file-delete-button">
                                                        삭제
                                                    </button>
                                                </div>
                                            }
                                            
                                        </UploadImagePreviewBox>



                                        {/* {imagePreview &&<span style={{cursor:"pointer"}} onClick={() => setImagePreview('')}>Change image</span>} */}

                                    </UploadFileComponentWrap>

                                    <FormContainer>
                                        <FormWrapper className="item-details-dsce collection-form">

                                            <form onSubmit={handleSubmit}>
                                                <div className="row">

                                                    <div className="col-lg-12 ">
                                                        <div className="form-group">
                                                            <label>Title (제목)</label>
                                                            <input ref={titleRef} type="text" value={title} className="form-control" placeholder="My First NFT" onChange={(e) => setTitle(e.target.value)} required />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Description (설명)</label>
                                                            {/* <input type="password" value={description} className="form-control" placeholder="Password" onChange={(e) => setDescription(e.target.value)} /> */}
                                                            <textarea ref={descriptionRef} value={description} className="form-control" cols="30" rows="5" placeholder="e. g. This NFT shows the nature is beautiful" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="por">Purpose Of Registeration <span>(발행 목적)</span></label>
                                                            {/* <input type="password" value={description} className="form-control" placeholder="Password" onChange={(e) => setDescription(e.target.value)} /> */}
                                                            <div className="purpose-of-issue-radio-wrap">
                                                                <p className="check-box">
                                                                    <input onChange={onChangePurposeOfIssue} id="sell" type="radio" name="purpose-of-issue" value="sell" checked={purposeOfIssue === 'sell' ? true : false} />
                                                                    <label htmlFor="sell">For Sale</label>
                                                                </p>
                                                                <p className="check-box">
                                                                    <input onChange={onChangePurposeOfIssue} id="rent" type="radio" name="purpose-of-issue" value="rent" checked={purposeOfIssue === 'rent' ? true : false} />
                                                                    <label htmlFor="rent">For Rent</label>
                                                                </p>
                                                               
                                                                <p className="check-box">
                                                                    <input onChange={onChangePurposeOfIssue} id="proof" type="radio" name="purpose-of-issue" value="proof" checked={purposeOfIssue === 'proof' ? true : false} />
                                                                    <label htmlFor="proof">CopyRight Proof</label>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="form-group half">
                                                            <label>Category (카테고리)</label>
                                                            <select className="form-select form-control" onChange={(e) => setCategory(e.target.value)}>
                                                                {!AVAILABLE_CATEGORIES.length && (<option value='other'>Other</option>)}
                                                                {AVAILABLE_CATEGORIES && AVAILABLE_CATEGORIES.map((category, index) => {
                                                                    return (<option key={category.value + `-` + index} value={category.value}>{category.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {(purposeOfIssue !== 'proof') && (
                                                        <>
                                                            {
                                                                purposeOfIssue !== 'rent'&&
                                                                <div className="col-lg-12">
                                                                    <div className="form-group half">
                                                                            <p className="setting_blockchain_type_and_price_wrap">
                                                                            <label>
                                                                                Price (가격)<span className="set_blockchain_type">WON</span>
                                                                            </label>
                                                                            
                                                                            {/* <select onChange={onChangeBlockChain} className="set_blockchain_type" name="blockchain_type">
                                                                                {
                                                                                    AVAILABLE_BLOCKCHAINS.map((v, i) => (
                                                                                        <option key={`${v.name}_${v.value}`} value={v.name} selected={blockchain.name === v.name ? true : false}>{v.name} ({v.value})</option>
                                                                                    ))
                                                                                }
                                                                            </select> */}

                                                                            
                                                                        </p>
                                                                        <input placeholder="판매 할 가격을 입력하세요" ref={priceRef} type="text" value={price} className="form-control" onChange={(e) => onPriceChange(e)} required />
                                                                    </div>
                                                                </div>
                                                            }   


                                                            {purposeOfIssue !== 'sell' && (purposeOfIssue === 'both' || purposeOfIssue === 'rent') && <div className="col-lg-12">
                                                                <div className="form-group half">
                                                                    {purposeOfIssue !== 'rent' ?(
                                                                         <label>Unit Price (/day) (1일당 임대 가격) </label>
                                                                    ):(
                                                                        <>
                                                                            <p className="setting_blockchain_type_and_price_wrap">
                                                                                <label>Unit Price (/day) (1일당 임대 가격)<span className="set_blockchain_type">WON</span>
                                                                                </label> 
                                                                                {/* <select onChange={onChangeBlockChain} className="set_blockchain_type" name="blockchain_type">
                                                                                    {
                                                                                        AVAILABLE_BLOCKCHAINS.map((v, i) => (
                                                                                            <option key={`${v.name}_${v.value}`} value={v.name} selected={blockchain.name === v.name ? true : false}>{v.name} ({v.value})</option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                            </p>
                                                                        </>
                                                                    )}

                                                                    <input placeholder="Rent 가격을 입력하세요" ref={priceRentRef} type="text" value={pricerent} className="form-control" onChange={(e) =>onPriceRentChange(e)} required />
                                                                </div>
                                                            </div>}
                                                        </>
                                                    )
                                                    }



                                                  <div className="col-lg-12 col-md-12 pt-50">
                                                        <div className="submit-button-wrap text-center">
                                                            <button type="submit">발행하기</button>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-lg-12 text-center">
                                                        {loadingMinting && (<>
                                                            <Loader size={10} />
                                                            <br />
                                                            <h5 className="text-warning">{mintMessage}</h5>
                                                        </>)}
                                                    </div> */}

                                                    <div className="col-lg-12 text-center pt-50">
                                                        {mintError && (
                                                            <div className="involved-card">
                                                                <div className="icon">
                                                                    <i className="ri-close-circle-line text-danger"></i>
                                                                </div>
                                                                <h3 className="text-danger">{mintMessage}</h3>
                                                            </div>
                                                        )}

                                                    </div>

                                                    {imagePreview && !mintProcessing && !mintCompleted && (<div className="col-lg-12 col-md-12 pt-50">
                                                        <h6 className="text-center text-danger">
                                                            NFT발행을 처리하는 과정이 시간이 다소 걸릴수 있습니다. 
                                                            조금만 기다려 주세요.
                                                            <br />
                                                        </h6>

                                                    </div>)}
                                                </div>
                                            </form>

                                        </FormWrapper>
                                    </FormContainer>

                                </Row>
                            </Container>


                        </div>
                    </div>
                </EntireContainer>
            </div>

            <div>


                {/*<Modal 
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"

                
            >
                <Modal.Header>
                <Modal.Title className="text-sm">Minting your NFT ...</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <Loader size={10} />
                
                </Modal.Body>
                
                <a className="btn btn-light btn-sm text-center text-green-500" onClick={handleClose}>
                    Run in background
                </a>
            
            
                </Modal>*/}

            </div>


            <Footer />
        </>
    )
}

export default UserImageMinting;

