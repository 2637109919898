{/* FAIVER API URLS */}
export const API_URL = process.env.REACT_APP_API_URL;

export const EXPLORERS = {
    'eth': process.env.REACT_APP_ETH_EXPLORER,
    'matic': process.env.REACT_APP_MATIC_EXPLORER,
    'celo': process.env.REACT_APP_CELO_EXPLORER,
    'bnb': process.env.REACT_APP_BNB_EXPLORER,
};

export const IPFS_API_CLIENT_URL = process.env.REACT_APP_IPFS_API_CLIENT_URL;

export const IPFS_URL = process.env.REACT_APP_IPFS_URL;

export const NFT_URL = {
    nft_resell_price:`${process.env.NEW_API_URL}/resellnft`,
    nft_change_price:`${process.env.NEW_API_URL}/changenftprice`
}