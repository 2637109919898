import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import { Button, Row, Col } from 'antd';
import {
    CloseOutlined,FullscreenOutlined,
    FilePdfOutlined, LeftOutlined, RightOutlined,
    CheckOutlined
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { INIT_PAINTINGS_STATE, LOADING_GALLERY_DATA } from "../../reducers/paintings";
import {
    LOAD_USER_PROFILE_IMG_REQUEST,
    LOAD_USER_PROFILE_IMG_INIT,
    REQUEST_CERTIFICATION_REQUEST,
    REQUEST_CERTIFICATION_INIT
} from "../../reducers/users";
import { useAuth } from '../../contexts/AuthContext';
import userProfilePicSample from '../../assets/images/userProfilePic.svg';
import FullImage from '../Gallery/FullImage';
import ShowPDFDocument from './ShowPDFComponent';
import { isMobile, isBrowser } from "react-device-detect";
import MessageComponent from '../MessageCommponent';
import LoadingComponent from '../LoadingComponent';
import {CertifyPageLoading} from '../../constants/applicationMessage'
import ColleryLogo3 from '../../assets/Collary_logo_03.jpg';
import Helmet from 'react-helmet';
import {useQuery,useMutation,useQueryClient,QueryObserver} from 'react-query';
import axios from 'axios';

const CertifyContentContainer = styled.div`
    width:100%;
    height:auto;
    h1{
        width:75%;
        margin:12.5px auto 25.5px auto;
        font-size: var(--page-title);
        font-weight:bold;
    }
    @media screen and (max-width:1600px){
        h1{
            width:95.5%;
        }
    }
    @media screen and (max-width:1200px){
        h1{
            width:73.5%;
        }
    }
    @media screen and (max-width:855px){
        h1{
            font-size: var(--tablet-page-title);
            text-align:center;
        }
    }
    @media screen and (max-width:585px){
        h1{
            font-size: var(--mobile-page-title);
        }
    }
`;

const CertifyContentWrap = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin:0 auto;
    width:75.5%;
    @media screen and (max-width:1200px){
        flex-direction:column;
        align-items:center;
    }
    @media screen and (max-width:1600px){
        width:95.5%;
    }
    height:auto;
    box-sizing:border-box;
    padding:15.5px;
`;

const CertifyProfileWrap = styled.div`
    width:22.5%;
    height:550px;
    border-radius:15.5px;
    box-shadow:0 0 5.5px #454545;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
    @media screen and (max-width:1200px){
        width:73.5%;
        height:255px;
        margin-bottom:30px;
    }
    @media screen and (max-width:800px){
        width:95.5%;
        height:255px;
    }
    @media screen and (max-width:361px){
        width: 98.5%;
        height: auto;
        padding: 10px;
    }
`;

const CertifyProfileInfoWrap = styled.div`
    width : 100%;
    height : 83.5%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
    @media screen and (max-width:1200px){
        flex-direction:row;
    }
    @media screen and (max-width:800px){
        height:79.5%px;
    }
    @media screen and (max-width:361px){
        flex-direction:column;
    }
`;

const CertifyProfilePictureWrap = styled.div`
    width:155px;
    height:155px;   
    border-radius:100%;
    margin:0 auto;
    overflow:hidden;
    box-shadow:0 0 2.5px #454545;
    @media screen and (max-width:1200px){
        margin:0;
    }
    @media screen and (max-width:800px){
        width:130px;
        height:130px;
    }
    @media screen and (max-width:361px){
        width:140px;
        height:140px;
    }
    img{
        display:block;
        width:100%;height:100%;
        object-fit:cover;
    }
`;

const CertifyProfileTextWrap = styled.div`
    width:95.5%;
    height:150px;
    display:flex;
    flex-direction:column;
    margin:0 auto;
    align-items: center;
    justify-content: center;
    p{
        margin:10px 0;
        text-align:center;
    }
    #name{
        font-weight:bold;
        font-size:var(--dashboard-page-title);
    }
    #email{
        font-size:var(--dashboard-page-info);
    }
    #nftAddr{
        font-size:var(--dashboard-page-info);
        color:#f14d5d;
    }
    @media screen and (max-width:1200px){
        margin:0;
        width:250px;
    }
    @media screen and (max-width:855px){
        #name{
            font-size:var(--tablet-dashboard-page-title);
        }
        #email{
            font-size:var(--tablet-dashboard-page-info);
        }
        #nftAddr{
            font-size:var(--tablet-dashboard-page-info);
        }
    }
    @media screen and (max-width:578px){
        #name{
            font-size:var(--mobile-dashboard-page-title);
        }
        #email{
            font-size:var(--mobile-dashboard-page-info);
        }
        #nftAddr{
            font-size:var(--mobile-dashboard-page-info);
        }
    }
    @media screen and (max-width:500px){
        width:200px;
    }
    @media screen and (max-width:400px){
        width:150px;
        height:70px;
    }
`;



const CertifyGalleryWrap = styled.div`
    width:73.5%;
    height:auto;
    border-radius:15.5px;
    box-shadow: 0 0 5.5px #454545;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    &::-webkit-scrollbar{ display:none; }
    -ms-overflow-style: none;
    >h2{
        font-size:var(--dashboard-page-title);
        width:96.5%;
        margin:11px 0;
        font-weight:bold;
    }
    .productListTitle{
        margin:11px 0;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
        width:96.5%;
        >h2{
            font-size:var(--dashboard-page-title);
            width:65%;
            font-weight:bold;
        }
        .appendCertifiedProduct{
            width:15.5%;
            height: 30px;
            border-radius:4.5px;
            border:none;
            background-color:#ff4d2c;
            color:#fff;
            font-size:var(--dashboard-page-button);
            &:hover{
                box-sizing:border-box;
                color:#ff4d2c;
                border:#ff4d2c;
                background-color:#fff;
            }
        }
    }
    @media screen and (max-width:855px){
        width:95.5%;
        >h2{
            font-size:var(--tablet-dashboard-page-title);
        }
        .productListTitle{
            .appendCertifiedProduct{
                width:25%;
                font-size:var(--tablet-dashboard-page-button);
            }
            >h2{
                font-size:var(--tablet-dashboard-page-title);
            }
        }
    }
    @media screen and (max-width:578px){
        height:auto;
        width:98.5%;
        >h2{
            font-size:var(--mobile-dashboard-page-title);
        }
        .productListTitle{
            >h3{
                width:65%;
            }
            >h2{
                font-size:var(--mobile-dashboard-page-title);
            }
            .appendCertifiedProduct{
                width:35%;
                font-size:var(--mobile-dashboard-page-button);
            }
        }
    }
`;

const CertifyMyNFTProductListWrap = styled.div`
    width:96.5%;height:${props => props.exists ? `530px` : `0px`};
    border-radius:15.5px;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    overflow: hidden;
    box-shadow:0px 0px 2.5px #454545;
    margin:0 auto;
    transition:height 0.6s;
    .arrowWrap{
        z-index:1;
        width:100%;
        height:32px;
        position:absolute;
        top:50%;left:50%;
        transform:translateY(-50%);
        transform:translateX(-50%);
        display:flex;
        align-items:center;
        justify-content:space-between;
        p{
            width:32px; height:32px;        
            margin: 0 10px;
            border-radius:100%;
            background-color:#454545;
            cursor:pointer;
            span{
                display:flex;
                width:100%;height:100%;
                font-size:18.5px;
                line-height:32px;
                color:#fff;
                justify-content: center;
                align-items: center;
            }
        }
    }
    @media screen and (max-width:400px){
        display:none;
    }
`;

const CertifyNFTProductList = styled.div`
    width:auto; height:500px;
    overflow:hidden;
    border-radius:15.5px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    position:relative;
    @media screen and (max-width:400px){
        display:none;
    }
`;

const CertifyNFTProduct = styled.div`
    width:auto;height:97.5%;
    border-radius:15.5px;
    overflow:hidden;
    position:relative;
    user-select:none;
    margin:0 auto;
    >img{
        display: block;
        width:auto;
        height:100%;
        user-select:none;
        object-fit:cover;
    }
    p{
        position:absolute;
        top:12.5px;right:12.5px;
        border-radius:100%;
        box-shadow:2.5px 2.5px 5.5px #454545;
        cursor:pointer;
        margin:0;
        width:30px;height:30px;
        background:#fff;color:#000;
        font-size:18.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:active{
            box-shadow:none;
        }
    }
    @media screen and (max-width:400px){
        height:100%;
    }
`;

const CertifyMyProductListWrap = styled.div`
    width:96.5%;height:700px;
    border-radius:15.5px;
    overflow-y:scroll;
    position:relative;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #2f3542;
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: grey;
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
    padding:15px;
    box-sizing:border-box;
    @media screen and (max-width:578px){
        height:425px;
    }
`;

const CertifyMyProductImgWrap = styled(Col)`
    height:200px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:0px;
    overflow: hidden;
    box-sizing:border-box;
    .showFullImageComponentButton{
        display:none;
    }
    .productImgFrame{
        width:100%;height:100%;
        border-radius:15.5px;
        overflow:hidden;
        transition all 0.4s;
        .checked-component{
            display:${isMobile?'block':'none'};
            margin:0;
            position:absolute;
            top: 0px;
            left: 0px;
            width:100%;height:100%;
            text-align:center;
            background:rgba(45,45,45,0.8);
            cursor:pointer;
            span{
                line-height:200px;
                font-size:62px;
                font-weight:bold;
                color:#fff;
            }
        }
        &:hover{
            filter:brightness(0.65);
        }
        > img{
            object-fit:cover;
            display:block;
            width:100%;height:100%;
        }
    }
    .on{
        border: ${isMobile?'none':'8px solid #ff4d2c'};
        
    }
    @media screen and (max-width:855px){
        height:200px;
        .on{
            border: ${isMobile?'none':'8px solid #ff4d2c'};
        }
        .productImgFrame{
            position:relative;
            
            &:hover{
                filter:brightness(1.0);
            }
        }
    }
`;

const ShowCertificateBtnWrap = styled.div`
    width:96.5%;
    height: 60px;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
`

const ShowCertificateButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    width:130px;
    height:50px;
    font-size:var(--dashboard-page-button);
    line-height:40px;
    border-radius:4.5px;
    border:none;
    text-align:center;
    padding:0 3.5px;
    box-sizing:border-box;
    color:#fff;
    background-color:${props=>props.disabled?'#454545':'#FF4D2C'};
    &.disabled{
        background:#454545;
        color:#fff;
    }
    &:hover{
        background:${props => props.disabled ? '#454545' : '#FFF'};
        color:${props => props.disabled ? '#FFF' : '#FF4D2C'};
        box-sizing:border-box;
        border: 1px solid ${props => props.disabled ? '#454545' : '#FF4D2C'};
    }
    &:active{
        background:${props => props.disabled ? '#454545' : '#000'};
    }
    @media screen and (max-width:855px){
        font-size:var(--tablet-dashboard-page-button);
    }

    @media screen and (max-height:800px){
        height: 32.5px;
        line-height:32.5px;
    }

    @media screen and (max-width:578px){
        font-size:var(--mobile-dashboard-page-button);
    }
    
`;

const ProductDataLoadingComponent = styled.div`
    position:fixed;
    top:0;left:0;
    width:100%;height:100%;
    background:rgba(45,45,45,0.75);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    z-index:100;
    >img{
        border-radius:100%;
        display:block;
        width:55.5px;height:55.5px;
    }
    p{
        text-align:center;
        font-size:19.5px;
        margin-top:5.5px;
        color:#fff;
    }
`;

const PDFRenderContainer = styled.div`
    position:${isBrowser ? 'absolute' : 'fixed'};
    top:${isBrowser?'35px':'0px'};left:0;
    margin-top:${isMobile ? '0px' : '-30px'};
    width:100%;height:${props => props.height};
    background-color:rgba(45,45,45,0.75);
    z-index:1000;
    @media screen and (max-width:855px){
        position:fixed;
        top:0px;margin-top:0px;
    }
`;

/* const SelectButtonWrapper = styled.div`
    width:100%;
    height:45px;
    display:flex;
    justify-content:space-evenly;
    button{
        display:block;
        height:35px;
        box-shadow:2.5px 2.5px 2.5px #454545;
        width:125px;
        border-radius:8.5px;
        line-height:35px;
        color:#fff;
        text-align:center;
        background-color:#000;   
        border:none;         
        &:hover{
            box-shadow:none;
        }
        &.chosen{
            background-color:#f14d5d;
        }
    }
`; */

const PDFRenderWrapper = isMobile ?
    styled.div`
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
    `:
    styled.div`
        margin: 0 auto;
        width: 1108.05px;
        height: 1020.75px;
        border-radius:12.5px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        overflow:hidden;
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
        align-items:center;
    `
    ;

const findElement = (elem, targetProperty, stopCondition) => {
    try {
        let flag = elem.classList.contains(targetProperty) || elem.tagName === targetProperty.toUpperCase();
        if (flag) {
            return elem;
        } else {
            if (elem.classList.contains(stopCondition) || elem.tagName === stopCondition.toUpperCase()) {
                return null;
            } else {
                return flag ? elem : findElement(elem.parentElement, targetProperty, stopCondition);
            }
        }
    } catch (err) {
        return null;
    }
};

const CertifyPage = () => {
    const [NFTProducts, setNFTProducts] = useState();
    const [products, setProducts] = useState([]);
    const [noMoreData, setNoMoreData] = useState(false);
    const { currentUser } = useAuth();
    const NFTListWrapRef = useRef(null);
    const NFTListRef = useRef(null);
    const productListWrapRef = useRef(null);
    const productListRef = useRef(null);
    const maxrecords = 12;
    const pageRef = useRef(1);
    const entireWrapperRef = useRef(null);
    const [userInfo, setUserInfo] = useState(null);
    const [showPDF, setShowPDF] = useState(false);
    const [enableShowPDF, setEnableShowPDF] = useState(false);
    const [certificationData, setCertificationData] = useState(null);
    //const [productImgBase64,setProductImgBase64] = useState(null);
    //const [languageType, setLanguageType] = useState('KOREAN');
    const [showMessage,setShowMessage]=useState(false);
    const [message,setMessage]=useState(null);
    const queryClient= useQueryClient();
    /* const {
        isLoading:loadingUserCertificationList,
        data:certificationList
    }= useQuery(
        `certificate_data : ${currentUser.id}`,
        ()=>{
            return axios.get(`https://api.collery.io/getusercertificate/${currentUser.uid}`).then(resp=>resp.data);
        },{
            cachedTime:1000,
        }); */

    /* const certificationListMutation = useMutation((userid)=>{
        console.log(`uid in mutation : ${userid}`);
        return axios.get(`https://api.collery.io/getusercertificate/${userid}`).then(resp=>resp.data)
    },
        {  
            onSuccess:(data,variable,context)=>{
                console.log(queryClient.getQueryData(`certificate_data : ${currentUser.id}`));
                queryClient.setQueryData(`certificate_data : ${currentUser.id}`,[...(data)]);
                setEnableShowPDF(true)
            },
            onError:(error,variable,context)=>{
                console.log(error);
            },
        }
    ) */

    const { loadingGalleryData,
        loadingGalleryDataSuccess,
        loadingGalleryDataFailure,
        galleryData } = useSelector(state => state.paintings);

    const {/*  loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData, */
        requestCertificationLoading,
        requestCertificationSuccess,
        requestCertificationFailure,
        requestCertificationData
    } = useSelector(state => state.users);

    const dispatch = useDispatch();

    /* useEffect(()=>{
        if(loadingUserCertificationList){
            console.log(certificationList);
        }
    },[loadingUserCertificationList]) */


    useEffect(() => {
        if (!requestCertificationLoading && requestCertificationSuccess) {
            if (requestCertificationData) {
                setCertificationData({
                    ...requestCertificationData
                })
                //certificationListMutation.mutate(currentUser.uid)
            }
            setMessage(CertifyPageLoading.publichingSuccess);
            setShowMessage(true);
            //dispatch({ type: REQUEST_CERTIFICATION_INIT });
        } else if (requestCertificationFailure) {
            //alert(requestCertificationFailure);
            setMessage(requestCertificationFailure);
            dispatch({ type: REQUEST_CERTIFICATION_INIT });
            setShowMessage(true);
        }
    }, [requestCertificationLoading,
        requestCertificationSuccess,
        requestCertificationFailure,
        requestCertificationData]);

    const onClickRequestCertification = useCallback(() => {
        if (requestCertificationLoading || !NFTProducts) {
            setMessage(
                !NFTProducts?
                CertifyPageLoading.didNotSelectProductMessage
                :CertifyPageLoading.publishingMessage
            );
            setShowMessage(true);
            return false;
        }
        /* if(certificationList){
            const certificationOfSelectedProduct=certificationList.find(v=>v.imgid===NFTProducts.imgid);
            if(certificationOfSelectedProduct){
                setEnableShowPDF(true);
                setCertificationData({...certificationOfSelectedProduct});
                return false;
            }
        } */
        const year = (new Date()).getFullYear();
        const month = (new Date()).getMonth()+1;
        const date = (new Date()).getDate();
        const issueddate = `${year}.${month/10===0?'0'+month:month}.${date}`;
        
        const certificatenumber = `${year}-${month/10===0?'0'+month:month}-${date}-${NFTProducts.imgid}`;
        
        const data={
            userid:currentUser.uid,
            certifiedimageurl:NFTProducts.image,
            authorname:NFTProducts.author,
            imagetitle:NFTProducts.title,
            issueddate:NFTProducts.datetime,
            imgid:NFTProducts.imgid,
            imagehash:NFTProducts.imagehash,
            certificatenumber
        }
        dispatch({ type: REQUEST_CERTIFICATION_REQUEST, data });
    }, [requestCertificationLoading, NFTProducts,/* certificationList */])

    useEffect(() => {
        if(currentUser){
            const queryObserver = new QueryObserver(
                queryClient,
                {
                    queryKey:'basicUserData'
                }
            );
            const unsubscribe = queryObserver.subscribe(result=>{
                if(result.data){
                    setUserInfo({...(result.data)});
                }
            })
            const offset = (pageRef.current - 1) * maxrecords;
            const limit = maxrecords;
            dispatch({
                type: LOADING_GALLERY_DATA,
                isFirst: true,
                data: {withWaterMark:true,apiType:'gallery_with_user', offset, limit, userid: currentUser.uid }
            })
            /* dispatch({
                type: LOAD_USER_PROFILE_IMG_REQUEST,
                data: { userid: currentUser.uid }
            }) */
            return ()=>{unsubscribe();}
        }
        
    }, [])

    /* useEffect(() => {
        if (!loadUserProfileImgLoading && loadUserProfileImgSuccess) {
            if (loadUserProfileImgData) {
                setUserInfo({ ...loadUserProfileImgData });
            }
            dispatch({ type: LOAD_USER_PROFILE_IMG_INIT })
        } else if (loadUserProfileImgFailure) {
            setShowMessage(true);
            setMessage(loadUserProfileImgFailure);
            dispatch({ type: LOAD_USER_PROFILE_IMG_INIT })
        }
    }, [
        loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData
    ]) */

    useEffect(() => {
        if (!loadingGalleryData && loadingGalleryDataSuccess) {
            if (galleryData && galleryData.length!==0) {
                pageRef.current += 1;
                products.length === 0 ?
                    setProducts([...galleryData])
                    : setProducts(prev => [...prev, ...galleryData]);
                galleryData.length % maxrecords !== 0 ? setNoMoreData(true) : setNoMoreData(false);
            } else if(galleryData.length===0||!galleryData){

                setNoMoreData(true);
            }
            dispatch({ type: INIT_PAINTINGS_STATE });
        } else if (loadingGalleryDataFailure) {
            alert(loadingGalleryDataFailure);
            dispatch({ type: INIT_PAINTINGS_STATE });
        }
    }, [loadingGalleryData,
        loadingGalleryDataSuccess,
        loadingGalleryDataFailure,
        galleryData]);

    const onClickCertifyMyProductListWrap = useCallback((evt) => {
        if (certificationData && enableShowPDF) {
            setCertificationData(null);
            setEnableShowPDF(false);
        }
        const parentElem = findElement(evt.target, "productImgFrame", "productImgWrap");
        if (parentElem) {
            const { index: id } = parentElem.dataset;
            const indexInProductList = products.findIndex(elem => elem.imgid === parseInt(id));
            setNFTProducts({ ...products[indexInProductList] });
        }
    }, [products, certificationData, enableShowPDF]);

    const onClickDeleteNFTProduct = useCallback((evt) => {
        setNFTProducts(null);
    }, []);

    const loadMoreProductData = useCallback(() => {
        if (noMoreData) {
            setShowMessage(true);
            setMessage(CertifyPageLoading.noMoreDataMessage);
            return false;
        }
        const offset = (pageRef.current - 1) * maxrecords;
        const limit = maxrecords;
        dispatch({
            type: LOADING_GALLERY_DATA,
            data: { withWaterMark:true,apiType:'gallery_with_user',offset, limit, userid: currentUser.uid }
        })

    }, [pageRef.current,noMoreData])

    useEffect(() => {
        if (NFTListWrapRef.current && NFTListRef.current) {
            const nftListWidth = parseFloat(document.defaultView.getComputedStyle(NFTListRef.current).width);
            const nftListWrapWidth = parseFloat(document.defaultView.getComputedStyle(NFTListWrapRef.current).width);
            if (nftListWidth < nftListWrapWidth) {
                NFTListRef.current.style.left = '0px';
            }
        }
    }, [NFTListWrapRef.current, NFTListRef.current, NFTProducts])

    useEffect(() => {
        if (productListWrapRef.current && productListRef.current) {
            const productListHeight = parseFloat(document.defaultView.getComputedStyle(productListRef.current).height);
            productListWrapRef.current.scrollTo(0, productListHeight);
        }
    }, [productListWrapRef.current, productListRef.current, products])

    

    const onClickShowPDFComponent = useCallback((evt) => {
        evt.preventDefault();
        if (NFTProducts && enableShowPDF) {
            setShowPDF(true);
        } else {
            if (!enableShowPDF) return false;
            setMessage(CertifyPageLoading.didNotSelectProductMessage);
            setShowMessage(true);
            return false;
        }
    }, [NFTProducts, enableShowPDF])

    const onClickCancelShowPDF = useCallback(() => {
        console.log("cancel how pdf")
        setShowPDF(false);
    }, [])

    const onClickArrowBtn = useCallback((evt) => {
        const { target } = evt;
        const elem = findElement(target, 'p', 'certifyGalleryWrap');
        //console.log(elem);
        if (elem) {
            const { direction } = elem.dataset;
            const indexInProductList = products.findIndex(elem => elem.imgid === NFTProducts.imgid);
            if (indexInProductList > products.length) {
                return false;
            }
            switch (direction) {
                case 'right': {
                    if (indexInProductList >= products.length - 1) {
                        setNFTProducts(products[0]);
                    } else {
                        setNFTProducts(products[indexInProductList + 1]);
                    }
                    break;
                }
                case 'left': {
                    if (indexInProductList <= 0) {
                        setNFTProducts(products[products.length - 1]);
                    } else {
                        setNFTProducts(products[indexInProductList - 1]);
                    }
                    break;
                }
            }
        } else {
            return false;
        }
    }, [NFTProducts])

    const cancelFunction = useCallback(()=>{
        if(requestCertificationData){
            dispatch({ type: REQUEST_CERTIFICATION_INIT });
            setShowPDF(true);
            setEnableShowPDF(false);
        }
        setShowMessage(false);
    },[NFTProducts, enableShowPDF, requestCertificationData]);

    return (
        <>
            <Helmet>
                <link rel="icon" href={ColleryLogo3}></link>    
            </Helmet>
            <div ref={entireWrapperRef}>
                {
                    showMessage&&
                    <MessageComponent message={message} cancelFunction={cancelFunction}/>
                }
                {
                    showPDF
                    &&
                    (
                        <PDFRenderContainer height={isMobile ? '100%' : document.defaultView.getComputedStyle(entireWrapperRef.current).height}>
                            {/* <SelectButtonWrapper>
                                <button className={languageType === `KOREAN` ? 'chosen' : ''} data-language='KOREAN' onClick={onClickSelectLanguageType}>
                                    한글(KOREAN)
                                </button>
                                <button className={languageType === `ENGLISH` ? 'chosen' : ''} data-language='ENGLISH' onClick={onClickSelectLanguageType}>
                                    영문(ENGLISH)
                                </button>
                            </SelectButtonWrapper> */}
                            <PDFRenderWrapper>
                                <ShowPDFDocument certificationData={certificationData} onClickCancelShowPDF={onClickCancelShowPDF}/>
                            </PDFRenderWrapper>
                        </PDFRenderContainer>
                    )
                }
                <Header />
                <CertifyContentContainer>
                    <h1>Issue Certification</h1>
                    <CertifyContentWrap>
                        <CertifyProfileWrap>
                            <CertifyProfileInfoWrap>
                                <CertifyProfilePictureWrap>
                                    <img src={(userInfo&&!!userInfo.userthumbnail && userInfo.userthumbnail !== '') ? userInfo.userthumbnail : userProfilePicSample} alt="user profile Image" />
                                </CertifyProfilePictureWrap>
                                <CertifyProfileTextWrap>
                                    <p id="name">{userInfo ? `${userInfo.firstname}` : ''}</p>
                                    <p id="email">{userInfo?.email}</p>
                                </CertifyProfileTextWrap>
                            </CertifyProfileInfoWrap>

                           
                        </CertifyProfileWrap>
                        <CertifyGalleryWrap style={{ overflow: 'scroll' }} className="certifyGalleryWrap">
                            {isBrowser &&
                                (<>
                                    <h2>인증할 작품</h2>
                                    <CertifyMyNFTProductListWrap
                                        ref={NFTListWrapRef}
                                        exists={NFTProducts ? true : false}
                                        className="nftProductListWrap"
                                        onClick={onClickArrowBtn}
                                    >
                                        <div className="arrowWrap">
                                            <p data-direction="left">
                                                <LeftOutlined />
                                            </p>
                                            <p data-direction="right">
                                                <RightOutlined />
                                            </p>
                                        </div>
                                        {NFTProducts &&
                                            <CertifyNFTProductList
                                                ref={NFTListRef}
                                                className={"NFTProductList"}
                                            >
                                                <CertifyNFTProduct>
                                                    <p onClick={onClickDeleteNFTProduct} className={"NFTCloseBtn"}><CloseOutlined /></p>
                                                    <img className="NFTImg" src={NFTProducts.image} alt="productImg" />
                                                </CertifyNFTProduct>
                                            </CertifyNFTProductList>}
                                    </CertifyMyNFTProductListWrap>
                                </>
                                )
                            }

                            <div className="productListTitle">
                                <h2>내가 가진 작품 목록</h2>
                                <button onClick={loadMoreProductData} className="appendCertifiedProduct">
                                    더 보기
                                </button>
                            </div>

                            <CertifyMyProductListWrap
                                ref={productListWrapRef}
                                onClick={onClickCertifyMyProductListWrap}
                            >
                                {
                                    (requestCertificationLoading || loadingGalleryData) && 
                                    (
                                        <LoadingComponent message={
                                            loadingGalleryData?
                                            CertifyPageLoading.message
                                            :CertifyPageLoading.publishingMessage
                                        }/>
                                    )
                                }
                                <Row ref={productListRef} gutter={[16, 16]} >
                                    {
                                        products.length !== 0 && products.map((v, i) => (
                                            <CertifyMyProductImgWrap
                                                className="productImgWrap"
                                                key={`${v.imgid}_product_wrap`}
                                                xs={24} sm={12} md={6}
                                            >
                                                <div
                                                    className={`productImgFrame ${(NFTProducts && NFTProducts.imgid === v.imgid) ? 'on' : ''}`}
                                                    data-index={v.imgid}
                                                >
                                                    {(isMobile && NFTProducts && NFTProducts.imgid === v.imgid) && (
                                                        <p
                                                            className="checked-component"
                                                            data-index={i}>
                                                            <CheckOutlined />
                                                        </p>
                                                    )}
                                                    <img src={v.image} alt="productImg" />
                                                </div>

                                            </CertifyMyProductImgWrap>
                                        ))
                                    }
                                </Row>
                            </CertifyMyProductListWrap>
                            <ShowCertificateBtnWrap>
                                <ShowCertificateButton className={!enableShowPDF ? '' : 'disabled'} disabled={enableShowPDF} onClick={onClickRequestCertification}>
                                    인증서 신청
                                </ShowCertificateButton>
                                {/* <ShowCertificateButton onClick={onClickShowPDFComponent} className={enableShowPDF ? '' : 'disabled'} disabled={!enableShowPDF}>
                                    <FilePdfOutlined /> 인증서 보기
                                </ShowCertificateButton> */}
                            </ShowCertificateBtnWrap>
                        </CertifyGalleryWrap>
                    </CertifyContentWrap>
                </CertifyContentContainer>
            </div>
        </>
    )

}

export default CertifyPage;