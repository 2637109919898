
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthProvider } from "./contexts/AuthContext"
import 'react-toastify/dist/ReactToastify.css';
import { HOMEPAGE, USER_FORGOT_PASSWORD, MARKETPLACE, NFT_DETAILS, 
    USER_AUDIO_NFT_MINTING, USER_COLLECTION, USER_COLLECTION_CREATE, 
    USER_COLLECTION_DETAILS, USER_DASHBOARD, USER_IMAGE_NFT_MINTING, 
    USER_LOGIN, USER_NFT, USER_NFT_DETAILS, USER_NFT_MINTING, USER_PASSWORD,
    USER_PROFILE, USER_SIGNUP, USER_VIDEO_NFT_MINTING, USER_WALLET, USER_WALLET_SEND,
    USER_SIGNUP_CERTIFICATION 
} from './constants/applicationUrls';

import Home from './components/Home';
import NotFound from './components/NotFound';
import ScrollToTop from './components/ScrollToTop';
import Login from './components/Login';
import Signup from './components/Signup';
import SignUpCertificate from './components/Signup/SignUpCertificate';
import RouteSignUp from './components/Signup/RouteSignUp';
import Forgotpwd from './components/Forgotpwd';
import PrivateRoute from './components/PrivateRoute';
import UserDashboard from './components/User/Dashboard';
import UserPassword from './components/User/Password';
import UserProfile from './components/User/Profile';
import UserNFTMinting from './components/User/Minting';
import UserImageMinting from './components/User/Minting/Image';
import ComingSoon from './components/ComingSoon';
import Wallet from './components/User/Wallet';
import Marketplace from './components/Marketplace';
import Gallery from './components/Gallery';
import NFT from './components/NFT';
import UserNFT from './components/User/NFT';
import UserWalletDetails from './components/User/Wallet/Details';
import Maintenance from './components/Maintenance';
import { MAINTENANCE_MODE } from './constants/application';
import { ToastContainer } from 'react-toastify';
import { InitWallet } from './components/Initwallet/Init';
import GalleryMinting from './components/User/GalleryMinting/GalleryMinting';
import store from './store/configStore';
import { Provider } from 'react-redux';
import ProductDetail from './components/ProductDetail';
import CertifyPage from './components/CertifyPage';
import PolicyPage from './components/PolicyPage';
import ServicePolicy from './components/PolicyPage/ServicePolicy';
import 'antd/dist/antd.css';
import { QueryClient, QueryClientProvider } from "react-query";
import UserAudioMinting from './components/User/Minting/Audio';
import './index.css';

const queryClient = new QueryClient();

function App() {

    /*useEffect(() => {
        InitWallet()
    }, [])*/


    if (MAINTENANCE_MODE) {
        return (
            <>
                <Router>
                    <ScrollToTop>
                        <QueryClientProvider contextSharing={true} client={queryClient}>
                            <Provider store={store}>
                                <AuthProvider>
                                    <Switch>
                                        <Route component={Maintenance} />
                                    </Switch>
                                </AuthProvider>
                            </Provider>
                        </QueryClientProvider>
                    </ScrollToTop>
                </Router>
            </>
        )
    }

    return (
        <>
            <Router>
                <ScrollToTop>
                    <QueryClientProvider contextSharing={true} client={queryClient}>
                        <Provider store={store}>
                            <AuthProvider>
                                <Switch>
                                    {/* Public Routes */}
                                    <Route exact path={HOMEPAGE} component={Home} />
                                    <Route exact path={USER_SIGNUP} component={RouteSignUp(Signup)} />
                                    <Route exact path={USER_FORGOT_PASSWORD} component={Forgotpwd} />
                                    <Route exact path={USER_LOGIN} component={Login} />
                                    <Route exact path={MARKETPLACE} component={Marketplace} />
                                    <Route exact path='/gallery' component={Gallery} />
                                    <Route exact path='/gallery/:userid' component={Gallery} />
                                    <Route exact path='/product/:productId' component={ProductDetail} />
                                    <Route exact path='/detailpage/:itemId' component={NFT} />
                                    <Route exact path='/certifypage' component={CertifyPage} />
                                    <Route exact path={['/policy/service-policy','/policy/personal-info']} component={PolicyPage} />
                                    <Route exact path={NFT_DETAILS + `/:nftTokenId`} component={NFT} />
                                    <Route exact path={USER_SIGNUP_CERTIFICATION} component={SignUpCertificate}/>
                                    {/* Private Routes */}
                                    <PrivateRoute exact path={USER_DASHBOARD} component={UserDashboard} />
                                    <PrivateRoute exact path={USER_PROFILE} component={UserProfile} />
                                    <PrivateRoute exact path={USER_PASSWORD} component={UserPassword} />

                                    <PrivateRoute exact path={USER_NFT} component={UserNFT} />
                                    <PrivateRoute exact path={USER_NFT_MINTING} component={UserNFTMinting} />
                                    <PrivateRoute exact path={USER_IMAGE_NFT_MINTING} component={UserImageMinting} />
                                    <PrivateRoute exact path={USER_VIDEO_NFT_MINTING} component={ComingSoon} />
                                    <PrivateRoute exact path={USER_AUDIO_NFT_MINTING} component={UserAudioMinting} />

                                    <PrivateRoute exact path={USER_COLLECTION} component={ComingSoon} />
                                    <PrivateRoute exact path={USER_COLLECTION_CREATE} component={GalleryMinting} />
                                    <PrivateRoute exact path={USER_COLLECTION_DETAILS} component={ComingSoon} />

                                    <PrivateRoute exact path={USER_WALLET} component={Wallet} />
                                    <PrivateRoute exact path={USER_WALLET + `/:wallet`} component={UserWalletDetails} />

                                    {/* Error Routes */}
                                    <Route component={NotFound} />

                                </Switch>
                            </AuthProvider>
                        </Provider>
                    </QueryClientProvider>
                </ScrollToTop>
            </Router>

            <ToastContainer
                autoClose={3000}

            />


        </>

    );
}

export default App;
