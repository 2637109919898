import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';

import Header from '../../Header';
import Footer from '../../Footer';

import './style.css';
import { USER_DASHBOARD, USER_LOGIN } from '../../../constants/applicationUrls';
/* import { useAuth } from '../../../contexts/AuthContext'; */
import { getFirstName, getLastName } from '../../../helpers/firbaseFunctions';

import styled from 'styled-components';
import { UploadOutlined, CheckOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {/*  MODIFY_USER_INFO_REQUEST,
    MODIFY_USER_INFO_INIT,
    LOAD_USER_PROFILE_IMG_REQUEST,
    LOAD_USER_PROFILE_IMG_INIT, */
    MODIFY_USER_PROFILE_IMG_REQUEST,
    MODIFY_USER_PROFILE_IMG_INIT,
} from '../../../reducers/users';

import {
    LOADING_GALLERY_DATA,/* UPLOADING_IMAGE_FILES_SUCCESS,
    UPLOADING_IMAGE_FILES_FAILURE, */
    INIT_PAINTINGS_STATE,
    DELETE_GALLERY_PRODUCT_INIT,
    DELETE_GALLERY_PRODUCT_REQUEST
} from '../../../reducers/paintings';

import { useMutation, useQueryClient, QueryObserver } from 'react-query';
import axios from 'axios';
import LoadingComponent from '../../LoadingComponent';
import MessageComponent from '../../MessageCommponent';
import {GALLERY_BANK_NAME} from '../../../constants/application'
import { UserProfileModifyPage } from '../../../constants/applicationMessage';
import Helmet from 'react-helmet';
import Collery_logo_03 from '../../../assets/Collary_logo_03.jpg';

const StyledUploadProfileImageContainer = styled.div`
    height:225px;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
`;

const StyledUploadProfileImageWrap = styled.div`
    height:200px;
    width:200px;
    #profile_picture{
        display:none;
    }
    position:relative;
`;

const StyledUploadProfileImageCircle = styled.div`
    height:100%;
    width:100%;
    border-radius:100%;
    border:0.5px solid rgba(45,45,45,0.3);
    overflow:hidden;
    img{
        width:100%;
        height:100%;
        object-fit:cover;
    }
`

const StyledUploadProfileImageLabel = styled.label`
    position:absolute;
    top:calc(100% - 30px);left:calc(100% - 30px);
    width:30px; height:30px;
    border-radius:100%;
    box-shadow : 0 0 3px #454545;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    span{
        font-size: 15.5px;
    }
`;

const StyledRow = styled.div`
    width:100%;
    height:auto;
    display:flex;
    flex-wrap : wrap;
    align-items:flex-start;
    justify-content:space-evenly;
    h2{
        font-size:var(--page-title);
        font-weight:bold;
    }
    .user-password-wrap{
        width:35.5%;
        >div{
            .form-group{
                label{
                    font-size:var(--dashboard-page-title);
                    margin-top: 15.5px;
                }
                input{
                    font-size:var(--dashboard-page-info);
                }
                select{
                    padding: 10px 15px;
                    >option{
                        font-size:var(--dashboard-page-info);
                    }
                }
                .account-info-wrap{
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    align-items:center;
                    .form-control{
                        width: calc(100% - 155px);
                        display: block;
                        font-size:var(--dashboard-page-info);
                        &.select-box{
                            width:135px;
                        }
                    } 
                }
            }
        }
        .submit-btn{
            background-color:#ff4d2c;
            font-size:var(--dashboard-page-button);
            font-weight:400;
        }
        .link-btn{
            font-size:var(--dashboard-page-button);
            font-weight:400;
        }
        .register-accound-btn{
            width:100%;
            background-color:#ff4d2c;
            font-size:var(--dashboard-page-button);
            font-weight:400;
        }
    }
    @media screen and (max-width:860px){
        flex-direction:column;
        margin: 0 auto;
        h2{
            font-size:var(--tablet-page-title);
            font-weight:bold;  
            text-align:left;
        }
        .user-password-wrap{
            margin:0 auto;
            width:93.5%;
            padding: 0;
            >div{
                .form-group{
                    label{
                        font-size:var(--tablet-dashboard-page-title);
                    }
                    input{
                        font-size:var(--tablet-dashboard-page-info);
                    }
                    select{
                        padding: 10px 15px;
                        >option{
                            font-size:var(--tablet-dashboard-page-info);
                        }
                    }
                    .account-info-wrap{
                        display:flex;
                        flex-direction:row;
                        justify-content:space-between;
                        align-items:center;
                        .form-control{
                            width: calc(100% - 155px);
                            display: block;
                            font-size:var(--tablet-dashboard-page-info);
                            &.select-box{
                                width:135px;
                            }
                        } 
                    }
                }
            }
            .register-accound-btn{
                height:31.5px;
                font-size:var(--tablet-dashboard-page-button);
            }
            .submit-btn{
                height:31.5px;
                font-size:var(--tablet-dashboard-page-button);
            }
            .link-btn{
                height:31.5px;
                font-size:var(--tablet-dashboard-page-button);
            }
        }
    }

    @media screen and (max-width:578px){
        flex-direction:column;
        h2{
            font-size:var(--mobile-page-title);
            font-weight:bold;  
            text-align:left;
        }
        .user-password-wrap{
            >div{
                .form-group{
                    label{
                        font-size:var(--mobile-dashboard-page-title);
                    }
                    input{
                        font-size:var(--mobile-dashboard-page-info);
                    }
                    .account-info-wrap{
                        display:flex;
                        flex-direction:row;
                        justify-content:space-between;
                        align-items:center;
                        .form-control{
                            width: calc(100% - 155px);
                            display: block;
                            font-size:var(--mobile-dashboard-page-info);
                            &.select-box{
                                width:135px;
                            }
                        } 
                    }
                }
            }
            .register-accound-btn{
                height:31.5px;
                font-size:var(--mobile-dashboard-page-button);
            }
            .submit-btn{
                height:31.5px;
                font-size:var(--mobile-dashboard-page-button);
            }
            .link-btn{
                height:31.5px;
                font-size:var(--mobile-dashboard-page-button);
            }
        }
    }
`;

const StyledProductsComponent = styled.div`
    position:relative;
    width:53.5%;height:942px;
    box-shadow:0 0 5.5px #adb5bd;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
    background: #fff;
    @media screen and (max-width:860px){
        width:93.5%;
        margin: 0 auto;
        height:635px;
    }
`;

const TitleWrap = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin:10px 0px 10px 0px;
    width:95%;height:auto;
    margin-bottom:25px;
    h3{
        font-size:var(--dashboard-page-title);
        font-weight:bold;margin:0px;
    }
    p{
        width:auto;height:31.5px;
        display:flex;
        button{
            display: block;
            border: none;
            color: #fff;
            border-radius: 5.5px;
            background: #ff4d2c;
            width: auto;
            height: 100%;
            padding: 0 10px;
            font-size:var(--dashboard-page-button);
        }
        button:active{
            background:#000;
            color:#fff;
        }
        button:hover{
            background:#000;
            color:#fff;
        }
        button+button{
            margin-left:10px;
        }
    }
    @media screen and (max-width:860px){
        h3{
            font-size:var(--tablet-dashboard-page-title);
        }
        p{
            button{
                font-size:var(--tablet-dashboard-page-button);
            }
        }
    }
    @media screen and (max-width:460px){
        h3{
            font-size:var(--mobile-dashboard-page-title);
        }
        p{
            button{
                font-size:var(--mobile-dashboard-page-button);
            }
        }
    }
`;

const DragButtonComponent = styled.div`
    display:none;
    @media screen and (max-width:860px){
        display:block;
        width:30px;height:150px;
        position:absolute;
        top:50%;right:-30px;
        margin-top:-75px;
        background:#f14d5d;  
        border-radius:0 15.5px 15.5px 0;
        line-height: 140px;
        text-align: center;
        color:#fff;
        font-size:16.5px;
    }
    @media screen and (max-width:560px){
        width:22.5px;height:75px;
        margin-top:-32.5px;
        right:-22.5px;
        line-height:75px;
        font-size:10.5px;
    }
`;

const CancelDeleteListButton = styled.p`
    position:absolute;
    top:15px;right:15px;
    width:30px;height:30px;
    border-radius:100%;
    box-shadow: 4.5px 4.5px 4.5px #454545;
    background: #fff;
    color:#000;
    margin:0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:active{
        box-shadow:none;
    }
`;

const StyledProductListWrap = styled.div`
    width:95%;height:95%;
    border-radius:15.5px;
    display:flex;
    align-items:center;
    justify-content:center;
    overflow-y:scroll;
    position:relative;
    transform:translateX(0%);
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #2f3542;
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: grey;
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px #fff;
    }
    .add_delete_list_btn.on{
        background-color:#000;
        color:#fff;
    }
    
`;

const StyledProductList = styled.div`
    margin-top:24px;
    width:calc(100% - 24px);height:100%;
    display:grid;
    grid-template-columns:repeat(4,23.5%);
    grid-column-gap:12px;
    grid-row-gap:12px;
    grid-auto-rows:233.86px;
    justify-content:center;
    div{
        position:relative;
        width:auto;height::233.86px;
        border-radius:15.5px;
        overflow:hidden;
        img{
            user-select:none;
            display:block;
            width: 100%;height: 100%;
            object-fit:cover;
        }
    }
    @media screen and (max-width:1620px){
        grid-template-columns:repeat(2,45.5%);
        grid-column-gap:10.5px;grid-row-gap:10.5px;
        grid-auto-rows:233.86px;
        div{
            height:233.86px;
        }
    }
    @media screen and (max-width:1020px){
        grid-auto-rows:163.81px;
        div{
            height:163.81px;
        }
    }
    @media screen and (max-width:760px){
        grid-template-columns:repeat(2,45.5%);
        grid-auto-rows:160.71px;
        div{
            height:160.71px;
        }
    }
    @media screen and (max-width:460px){
        grid-template-columns:repeat(1,1fr);
    }
`;

function findElement(element, findCondition, endCondition) {
    const flag = element.classList.contains(findCondition) || element.tagName === findCondition.toUpperCase();
    if (flag) {
        return element;
    } else {
        const endFlag = element.classList.contains(endCondition) || element.tagName === endCondition.toUpperCase();
        if (endFlag) {
            return null;
        } else {
            return findElement(element.parentElement, findCondition, endCondition);
        }
    }
}

function Profile({ currentUser }) {



    //const { updateProfile } = useAuth();
    const [profilePicBase64, setProfilePicBase64] = useState(null);
    const [profilePicURL, setProfilePicURL] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [bankaccount, setBankaccount] = useState('');
    const [bankname, setBankname] = useState(GALLERY_BANK_NAME.SHIN_HAN);
    const [accountholder,setAccountholder] = useState('');
    const [error, setError] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const profileImageInputRef = useRef(null);
    const [profileImageFile, setProfileImageFile] = useState(null);
    const [deleteNumList, setDeleteNumList] = useState({});
    const [myProductList, setMyProductList] = useState([]);
    const [showProductComponent, setShowProductComponent] = useState(false);
    const [noMoreData, setNoMoreData] = useState(false);
    const [messageComponent, setMessageComponent] = useState(false);
    const [message, setMessage] = useState('');
    const [showRegisterAccount, setShowRegisterAccount] = useState(true);
    const productComponentRef = useRef(null);
    const pageRef = useRef(1);
    const maxRange = 12;
    const [modifySuccess, setModifySuccess] = useState(false);
    const {/* 
        modifyUserInfoLoading,
        modifyUserInfoSuccess,
        modifyUserInfoFailure, */
        /* loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData, */
        modifyUserProfileImgLoading,
        modifyUserProfileImgSuccess,
        modifyUserProfileImgFailure,
        modifyUserProfileImgData,
    } = useSelector((state) => state.users);

    const { loadingGalleryData,
        loadingGalleryDataSuccess,
        loadingGalleryDataFailure,
        galleryData,
        deleteGalleryProductLoading,
        deleteGalleryProductSuccess,
        deleteGalleryProductFailure
    } = useSelector((state) => state.paintings);

    const dispatch = useDispatch();
    const history = useHistory();
    const queryClient = useQueryClient();

    const mutateUserInfo = useMutation(
        (data) => axios.post(
            'https://api.collery.io/updateuserinfo', data
        ), {
        onMutate: (data) => {
            console.log(data);
            setLoading(true);
        },
        onSuccess: (data) => {
            console.log(data);
            setModifySuccess(true);
            setMessageComponent(true);
            setMessage(UserProfileModifyPage.modifingUserInfoSuccessMessage);
            const basicUserData = queryClient.getQueryData(['basicUserData']);
            queryClient.setQueryData(['basicUserData'], {
                authhorid: basicUserData.authhorid,
                userid: basicUserData.userid,
                firstname: firstName,
                userthumbnail: profilePicURL,
                email: basicUserData.email,
            });
        },
        onSettled: (data, error) => {
            if (error) {
                console.log(error);
                setMessageComponent(true);
                setMessage(UserProfileModifyPage.serverErrorModifingUserInfoMessage);
            }
            console.log(data);
            setLoading(false);
        }
    }
    )

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault()
        if (modifyUserProfileImgLoading) {
            alert("프로필 이미지 upload 중 입니다.");
            return false;
        }
        if(bankaccount===''){
            
            setMessage(UserProfileModifyPage.setBankAccountMessage);
            
        }
        if(accountholder===''){
            
            setMessage(UserProfileModifyPage.setBankAccountHolderMessage);
           
        }
        console.log(profilePicURL);
        if (profilePicURL) {
            const data = {
                userid: currentUser.uid, userthumbnail: profilePicURL,
                firstname: firstName,bankname,bankaccount,
                accountholder
            };
            console.log(data);
            mutateUserInfo.mutate(data);
        } else {
            history.replace("/user/dashboard");
            return false;
        }
        //dispatch({type:MODIFY_USER_INFO_REQUEST,data});

    }, [
        bankname,bankaccount,modifyUserProfileImgLoading, 
        profilePicURL, firstName,accountholder,
    ]);

    /* useEffect(()=>{
        if(!modifyUserInfoLoading&&modifyUserInfoSuccess){
            history.replace("/user/dashboard");
            dispatch({type:MODIFY_USER_INFO_INIT});
        }else if(modifyUserInfoFailure){
            alert(modifyUserInfoFailure);
            dispatch({type:MODIFY_USER_INFO_INIT});
        }
    },[modifyUserInfoLoading,
        modifyUserInfoSuccess,
        modifyUserInfoFailure]) */

    /* useEffect(()=>{
        if(modifyUserInfoFailure){
            alert(modifyUserInfoFailure);
            dispatch({type:MODIFY_USER_INFO_INIT});
        }
    },[modifyUserInfoFailure]) */

    useEffect(() => {
        if (currentUser && (!profilePicBase64)) {
            const queryObserver = new QueryObserver(
                queryClient,
                {
                    queryKey: 'basicUserData'
                }
            );
            const unsubscribe = queryObserver.subscribe(result => {
                if (result.data) {
                    setProfileImageFile(result.data?.userthumbnail);
                    setFirstName(result.data?.firstname);
                    setProfilePicBase64(result.data?.userthumbnail);
                    setProfilePicURL(result.data?.userthumbnail);
                }
            });
            dispatch({ type: LOADING_GALLERY_DATA, isFirst: true, data: { withWaterMark: true, apiType: 'gallery_with_user', offset: (pageRef.current - 1) * maxRange, limit: maxRange, userid: currentUser.uid } });
            return () => {
                unsubscribe();
            }
        }
    }, [profilePicBase64]);

    useEffect(() => {
        if (!loadingGalleryData && loadingGalleryDataSuccess) {
            console.log(galleryData);
            if (galleryData) {
                const imageArr = [];
                galleryData.forEach((v) => {
                    const singleData = { imgid: v.imgid, src: v.image };
                    imageArr.push(singleData);
                })
                myProductList.length === 0 ? setMyProductList([...imageArr]) : setMyProductList(prev => [...prev, ...imageArr]);
                pageRef.current = pageRef.current + 1;
            } else {
                setNoMoreData(true);
            }
            dispatch({ type: INIT_PAINTINGS_STATE });
        } else if (loadingGalleryDataFailure) {
            setMessage(loadingGalleryDataFailure);
            setMessageComponent(true);
            dispatch({ type: INIT_PAINTINGS_STATE });
        }
    }, [
        loadingGalleryData,
        loadingGalleryDataSuccess,
        loadingGalleryDataFailure,
        galleryData
    ])

    /* useEffect(()=>{
        if(!loadUserProfileImgLoading&&loadUserProfileImgSuccess){
            if(loadUserProfileImgData){
                setProfilePicBase64(loadUserProfileImgData.image);
                setProfilePicURL(loadUserProfileImgData.image);
                setFirstName(loadUserProfileImgData.firstname);
                setLastName(loadUserProfileImgData.lastname);
            }else{
                setProfilePicBase64("/static/media/userProfilePic.a16c0a5a.svg");
            }
            dispatch({type:LOAD_USER_PROFILE_IMG_INIT});
        }else if(loadUserProfileImgFailure){
            setMessageComponent(true);
            setMessage(loadUserProfileImgFailure);
            setProfilePicBase64("/static/media/userProfilePic.a16c0a5a.svg");
            dispatch({type:LOAD_USER_PROFILE_IMG_INIT});
        }
    },[loadUserProfileImgLoading,loadUserProfileImgSuccess,loadUserProfileImgData,loadUserProfileImgFailure])
 */
    /* useEffect(() => {
        setFirstName(getFirstName(currentUser));
        setLastName(getLastName(currentUser));

        let timer1 = setTimeout(() => setSuccessMsg(''), 5 * 1000);

        return () => clearTimeout(timer1);
    }, [successMsg]); */
    const onChangeAccoountHolder = useCallback((evt)=>{
        const {target} = evt;
        const {value} = target;
        setAccountholder(value);
    },[]);

    const onChangeBankName = useCallback((evt)=>{
        const {target} = evt;
        const {value} = target;
        setBankname(value);
    },[])

    const onChangeAccountNum = useCallback((evt) => {
        setBankaccount(evt.target.value);
    }, [])

    const onChangeProfilePicture = useCallback((evt) => {
        const file = evt.target.files[0];
        if (file.type.match('image/*')) {
            const fileReader = new FileReader();
            fileReader.onload = (readerEvt) => {
                setProfileImageFile(file);
                setProfilePicBase64(readerEvt.target.result);
                const data = { userid: currentUser.uid, firstname: firstName, file };
                dispatch({ type: MODIFY_USER_PROFILE_IMG_REQUEST, data });
            }
            fileReader.readAsDataURL(file);
        }
    }, [firstName])

    useEffect(() => {
        if (!modifyUserProfileImgLoading && modifyUserProfileImgSuccess) {
            if (modifyUserProfileImgData) {
                setProfilePicURL(modifyUserProfileImgData.url);
            }
            dispatch({ type: MODIFY_USER_PROFILE_IMG_INIT });
        } else if (modifyUserProfileImgFailure) {
            setMessageComponent(true);
            setMessage(modifyUserProfileImgFailure);
            setProfileImageFile(null);
            //setProfilePicBase64(null);
            dispatch({ type: MODIFY_USER_PROFILE_IMG_INIT });
        }
    }, [modifyUserProfileImgLoading,
        modifyUserProfileImgSuccess,
        modifyUserProfileImgFailure,
        modifyUserProfileImgData])


    const onClickProductList = useCallback((evt) => {
        const { target } = evt;
        const elem = findElement(target, 'add_delete_list_btn', 'product_list');
        if (elem) {
            const { index } = elem.dataset;
            const { imgid } = myProductList[index];
            console.log(`imgid:${imgid}`);
            const hasClassOn = elem.classList.contains('on');
            if (hasClassOn) {
                elem.classList.remove('on');
                setDeleteNumList(prev => {
                    delete prev[imgid];
                    return { ...prev };
                });
            } else {
                elem.classList.add('on');
                deleteNumList[imgid] = true
                setDeleteNumList({ ...deleteNumList });
            }
        }
    }, [deleteNumList, myProductList]);

    const onClickAddDeleteListBtn = useCallback((evt) => {
        const data = Object.keys(deleteNumList).map(v => ({ imgid: v }));
        console.log(data);
        dispatch({ type: DELETE_GALLERY_PRODUCT_REQUEST, data });
    }, [deleteNumList]);

    useEffect(() => {
        if (!deleteGalleryProductLoading && deleteGalleryProductSuccess) {
            setTimeout(() => { changeProductsInfoState(); }, 0);
            dispatch({ type: DELETE_GALLERY_PRODUCT_INIT });
        } else if (deleteGalleryProductFailure) {
            setMessage(deleteGalleryProductFailure);
            setMessageComponent(true);
            dispatch({ type: DELETE_GALLERY_PRODUCT_INIT });
        }
    }, [deleteGalleryProductLoading,
        deleteGalleryProductSuccess,
        deleteGalleryProductFailure]);

    const changeProductsInfoState = useCallback(() => {
        Object.keys(deleteNumList).forEach(key => {
            const indexInMyProductList = myProductList.findIndex(ele => ele.imgid === parseInt(key));
            myProductList.splice(indexInMyProductList, 1);
        })
        console.log(myProductList);
        setDeleteNumList({});
        setMyProductList([...myProductList]);
    }, [deleteNumList, myProductList])

    const onClickLoadMoreButton = useCallback(() => {
        if (!loadingGalleryData) {
            dispatch({
                type: LOADING_GALLERY_DATA,
                data: {
                    offset: (pageRef.current - 1) * maxRange,
                    limit: maxRange,
                    userid: currentUser.uid,
                    apiType: 'gallery_with_user',
                    withWaterMark: true
                }
            });
        }
    }, [loadingGalleryData, pageRef.current])

    const cancelFunction = useCallback(() => {
        if (modifySuccess) { history.replace("/user/dashboard"); }
        setMessageComponent(false);
    }, [modifySuccess])

    return (
        <>
            <Helmet>
                <link rel="icon" href={Collery_logo_03}></link>
            </Helmet>

            {
                messageComponent &&
                <MessageComponent message={message} cancelFunction={cancelFunction} />
            }
            {
                (loading || modifyUserProfileImgLoading)
                &&
                (
                    <LoadingComponent
                        message={
                            modifyUserProfileImgLoading ?
                                UserProfileModifyPage.modifingUserProfileMessage
                                : UserProfileModifyPage.modifingUserInfoMessage
                        }
                    />
                )
            }
            <Header />
            <div className="author-profile-area pt-50 pb-70" style={{ paddingTop: 15.5 }}>
                <div>

                    <StyledRow className="row">

                        <div className="user-password-wrap">

                            <div className="user-password-form-area">
                                <div className="section-title">
                                    <h2 className="text-center">Update Profile</h2>
                                </div>

                                <div className="user-password-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">

                                            <div className="col-lg-12" style={{ height: '250px' }}>
                                                <div className="form-group" style={{ height: 'auto', margin: '0' }}>
                                                    <label style={{ fontSize: 'var(--dashboard-page-title:22.5px)', margin: '0' }}>Profile Picture</label>
                                                </div>
                                                <StyledUploadProfileImageContainer>
                                                    <StyledUploadProfileImageWrap>
                                                        <input ref={profileImageInputRef} id="profile_picture" onChange={onChangeProfilePicture} type="file" />
                                                        <StyledUploadProfileImageCircle>
                                                            {
                                                                profilePicBase64 &&
                                                                <img src={profilePicBase64} alt="profile_picture" />
                                                            }
                                                        </StyledUploadProfileImageCircle>
                                                        <StyledUploadProfileImageLabel htmlFor="profile_picture">
                                                            <UploadOutlined />
                                                        </StyledUploadProfileImageLabel>
                                                    </StyledUploadProfileImageWrap>
                                                </StyledUploadProfileImageContainer>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                <label>FullName</label>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                 
                                                    <input type="text" name="name"  value={firstName} className="form-control" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} required />
                                                </div>
                                            </div>

                                            {/*<div className="col-lg-6">
                                                <div className="form-group">
                                                   
                                                    <input type="text" name="name" value={lastName} className="form-control" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} required />
                                                </div>
                                                        </div>*/}

                                            {(error.length > 0) && (<div className="col-lg-12 col-md-12">
                                                <div className="alert alert-danger text-center" role="alert">{error}</div>
                                            </div>)}

                                            {(successMsg.length > 0) && (<div className="col-lg-12 col-md-12">
                                                <div className="alert alert-success text-center" role="alert">{successMsg}</div>
                                            </div>)}

                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>입금 계좌</label>
                                                    <div className="account-info-wrap">
                                                        <select defaultValue={bankname} onChange={onChangeBankName} className="select-box form-control">
                                                            {
                                                               Object.keys(GALLERY_BANK_NAME).map(v=>(
                                                                <option key={GALLERY_BANK_NAME[v]}>
                                                                    {GALLERY_BANK_NAME[v]}
                                                                </option>
                                                               ))
                                                            }
                                                        </select>
                                                        <input type="text" placeholder="계좌를 입력해 주세요" className="form-control" name="bankaccount" onChange={onChangeAccountNum} value={bankaccount} />
                                                    </div>
                                                    <label>예금주명</label>
                                                    <div className="account-info-wrap">
                                                        <input type="text" placeholder='예금주를 입력해 주세요' className="form-control" onChange={onChangeAccoountHolder} name="bankaccountholder" value={accountholder}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12 pt-50">
                                                <button type="submit" disabled={loading} className="submit-btn default-btn border-radius-5 right">Update Profile</button>
                                                <Link to={USER_DASHBOARD} className="link-btn default-btn cancel-btn bg-dark border-radius-5 right">Cancel</Link>
                                            </div>

                                            {/* <div className="col-lg-12 col-md-12 pt-20">
                                                <hr className="bg-secondary"/>
                                                <p className="text-secondary text-center pt-20">You will be logged out once the passowrd is changed successfully.<br/>Please log in again.</p>
                                            </div> */}

                                        </div>
                                    </form>
                                </div>

                            </div>


                        </div>
                        <StyledProductsComponent showProductComponent={showProductComponent} ref={productComponentRef}>
                            {
                                loadingGalleryData &&
                                (
                                    <LoadingComponent
                                        message={UserProfileModifyPage.message}
                                    />
                                )
                            }
                            {/* <DragButtonComponent onClick={onClickDragButton}>
                                {showProductComponent?<RightOutlined/>:<LeftOutlined/>}
                            </DragButtonComponent> */}
                            <TitleWrap>
                                <h3>나의 작품들</h3>
                                <p>
                                    {
                                        !noMoreData && (
                                            <button onClick={onClickLoadMoreButton} className="load-more-button">
                                                더 보기
                                            </button>
                                        )
                                    }
                                    <button onClick={onClickAddDeleteListBtn}>
                                        삭제
                                    </button>
                                </p>
                            </TitleWrap>
                            <StyledProductListWrap>
                                <StyledProductList className='product_list' onClick={onClickProductList}>
                                    {
                                        myProductList.length !== 0 &&
                                        myProductList.map((v, i) => (
                                            <div key={`${v.imgid}_${i}`}>
                                                <CancelDeleteListButton data-index={i} className="add_delete_list_btn">
                                                    <CheckOutlined />
                                                </CancelDeleteListButton>
                                                <img src={v.src} alt={"product_image"} />
                                            </div>
                                        ))
                                    }
                                    {
                                        loadingGalleryDataFailure
                                        &&
                                        (
                                            <div>
                                                data를 로드 하지 못하였습니다.
                                            </div>
                                        )
                                    }

                                </StyledProductList>

                            </StyledProductListWrap>
                        </StyledProductsComponent>

                    </StyledRow>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Profile;