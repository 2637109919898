
import React, { useEffect, useState,useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { USER_DASHBOARD, USER_FORGOT_PASSWORD, USER_SIGNUP,HOMEPAGE } from '../../constants/applicationUrls';
import { useAuth } from '../../contexts/AuthContext';

import Footer from '../Footer';
import Header from '../Header';
import MessageComponent from '../MessageCommponent';
import LoadingComponent from '../LoadingComponent';
import './style.css';

import styled from 'styled-components';
import { useSelector,useDispatch } from 'react-redux';
import {LOAD_USER_PROFILE_IMG_REQUEST,
    LOAD_USER_PROFILE_IMG_INIT
} from '../../reducers/users';
import {LoginPage} from '../../constants/applicationMessage';
import Helmet from 'react-helmet';
import ColleryLogo3 from '../../assets/Collary_logo_03.jpg';

const LoginFormContainer = styled.div`
    width:100%;height:auto;
    min-height:${window.innerHeight-84-192}px;
    @media screen and (max-width:855px){
        min-height:${window.outerHeight}px;
    }
`;

const LogInFormWrappper = styled.div`
    width:75%;height:100%;
    margin:55.5px auto;
`;

const LogInFormComponent = styled.div`
    width:100%;height:100%;
    form{
        height:calc(100% - 120px);
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        .links{
            width:100%;
            height:60px;
            margin-top:15.5px;
            ul{
                display:flex;
                list-style:none;
                width:330px;
                display:flex;
                align-items:center;
                justify-content:space-evenly;
                margin:0 auto;
                padding:0;
                li{
                    width:auto;
                    height:24px;
                    line-height:24px;
                    a{
                        font-size:var(--dashboard-page-info);
                        color:#454545;
                        &:hover{
                            color:#ff4d2c;
                        }
                    }
                }
                p{
                    width:1px;
                    margin:0 3.5px;
                    height: 15.5px;
                    background-color:rgba(45,45,45,0.4);
                }
            }
        }
        .form_row{
            width:85%;
            height:100%;
            display:flex;
            flex-direction:column;
            justify-content:center;
            .header-wrap{
                width:100%;height:auto;
                margin: 12.5px auto 35.5px;
                h1{
                    height:auto;
                    font-size: var(--page-title);
                    font-weight:bold;
                    text-align:left;
                    margin:0;
                }
            }
            
            .form_inner_row{
                padding-top: 20px;
                width:100%;
                height:auto;
                box-shadow: 0px 0px 2.5px #454545;
                border-radius: 4.5px;
                .link_to_new_passport{
                    width: 100%;
                    height: auto;
                    margin: 10px 0;
                }
                .form_component{
                    width:60%;
                    height:84px;
                    margin : 15.5px auto;
                    .form-group{
                        label{
                            font-weight:bold;
                            margin-bottom:10.5px;
                            font-size:var(--dashboard-page-title);
                        }
                        .form-control{
                            font-size:var(--dashboard-page-info);
                        }
                    }
                    .form-checkbox{
                        width:100%;height:45px;
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        input[type=\"checkbox\"]{
                            display: block;
                            width: 15.5px;
                            height: 15.5px;
                            margin-right:10px;
                        }
                        label{
                            font-size:var(--dashboard-page-info);
                        }
                    }
                    &.form_error_alert{
                        text-align:left;
                        height:32px;
                        line-height:24px;
                        font-size:var(--dashboard-page-info);
                        color:red;
                    }
                }
                .form_btn_component{
                    width:60%;
                    height:34px;
                    margin:0 auto;
                    button{
                        width:100%;
                        height:32px;
                        border:none;
                        border-radius:4.5px;
                        background-color:#ff4d2c;
                        font-size:var(--dashboard-page-button);
                        color:#fff;
                        &:hover{
                            background-color:#000;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:855px){
        form{
            .links{
                ul{
                    width: 100%;
                    li{
                        a{
                            font-size:var(--tablet-dashboard-page-info);
                        }
                    }
                }
            }
            .form_row{
                .header-wrap{
                    h1{
                        font-size: var(--tablet-page-title);
                    }
                }
                .form_inner_row{
                    .form_component{
                        width:84.5%;
                        .form-group{
                            label{
                                font-size:var(--tablet-dashboard-page-title);
                            }
                            .form-control{
                                font-size:var(--tablet-dashboard-page-info);
                            }
                        }
                        .form-checkbox{
                            label{
                                font-size:var(--tablet-dashboard-page-info);
                            }
                        }
                        &.form_error_alert{
                            height:auto;
                            font-size:var(--tablet-dashboard-page-info);
                        }
                    }
                    .form_btn_component{
                        font-size:var(--tablet-dashboard-page-button);
                    }
                }
            }
        }
    }

    @media screen and (max-width:578px){
        form{
            .links{
                ul{
                    li{
                        a{
                            font-size:var(--mobile-dashboard-page-info);
                        }
                    }
                }
            }
            .form_row{
                .header-wrap{
                    h1{
                        font-size: var(--mobile-page-title);
                    }
                }
                .form_inner_row{
                    .form_component{
                        width:84.5%;
                        .form-group{
                            label{
                                font-size:var(--mobile-dashboard-page-title);
                            }
                            .form-control{
                                font-size:var(--mobile-dashboard-page-info);
                            }
                        }
                        .form-checkbox{
                            label{
                                font-size:var(--mobile-dashboard-page-info);
                            }
                        }
                        &.form_error_alert{
                            height:auto;
                            font-size:var(--mobile-dashboard-page-info);
                        }
                    }
                    .form_btn_component{
                        font-size:var(--mobile-dashboard-page-button);
                    }
                }
            }
        }
    }
    }
`;

function Login() {
    const {  login,logout } = useAuth();
    const history = useHistory();
    const {
        loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData
    } = useSelector(state=>state.users);
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState("");
    
    const [showMessageComponent,setShowMessageComponent] = useState(false);
    const [message,setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingMessage,setLoadingMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginError('');
        setLoading(true);
        setLoadingMessage(LoginPage.message);
        login(email, password).then((response) => {
            dispatch({type:LOAD_USER_PROFILE_IMG_REQUEST,data:{userid:response.user.uid}})
        }).catch((error) => {
            console.log(error.message);
            setShowMessageComponent(true);
            console.error(error);
            if(error.message==="There is no user record corresponding to this identifier. The user may have been deleted."){
                setMessage(LoginPage.errorEmailNotFound);
            }else if(error.message==="The password is invalid or the user does not have a password."){
                setMessage(LoginPage.errorInvalidPassword);
            }
            setLoading(false);
        });
    }
/* LOAD_USER_PROFILE_IMG_REQUEST
LOAD_USER_PROFILE_IMG_INIT */
    useEffect(async()=>{
        if(!loadUserProfileImgLoading&&
            loadUserProfileImgSuccess){
            setLoading(false);
            dispatch({type:LOAD_USER_PROFILE_IMG_INIT});
            history.push('/user/dashboard');
        }else if(!loadUserProfileImgLoading&&
            loadUserProfileImgFailure
        ){
            //firebase 에는 존재하지만 우리 db에는 존재하지
            //않는 계정은 firebase 에서 invalidate 해줘야함.
            const resp = await logout();
            setLoading(false);
            setShowMessageComponent(true);
            setMessage(loadUserProfileImgFailure);
            dispatch({type:LOAD_USER_PROFILE_IMG_INIT});
        }
    },[
        loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData
    ])

    const cancelFunction =useCallback(()=>{
        setShowMessageComponent(false);
    },[]);

    return (
        <>
            <Helmet>
                <link rel="icon" href={ColleryLogo3}></link>
            </Helmet>
            {
                showMessageComponent
                &&
                <MessageComponent 
                    message={message}
                    cancelFunction={cancelFunction}
                />
            }
            {
                loading
                &&
                <LoadingComponent message={loadingMessage}/>
            }
            <Header />
            <LoginFormContainer>
                <LogInFormWrappper>
                    <LogInFormComponent>
                        <form onSubmit={handleSubmit}>
                            <div className="form_row">
                                <div className="header-wrap">
                                    <h1>Log In</h1>
                                </div>
                                <div className="form_inner_row">
                                    <div className="form_component">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" value={email} className="form-control" placeholder="example@gmail.com" onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                    </div>

                                    <div className="form_component">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" value={password} className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                                        </div>
                                    </div>

                                    {(loginError.length > 0) && (<div className="form_component form_error_alert">
                                        <div role="alert">{loginError}</div>
                                    </div>)}

                                    <div className="form_btn_component">
                                        <button type="submit" disabled={loading}>Log In Now</button>
                                    </div>
                                    
                                    <div className="form_component">
                                        <div className="form-checkbox">
                                            <input type="checkbox" checked={false}/>
                                            <label>자동 로그인</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="links">
                                <ul>
                                    <li>
                                        <Link to={USER_SIGNUP}>Sign Up Here</Link>
                                    </li>
                                    <p></p>
                                    <li>
                                        <Link to={USER_FORGOT_PASSWORD}>Forgot My Password?</Link>
                                    </li>
                                </ul>
                            </div>
                        </form>
                        
                    </LogInFormComponent>
                </LogInFormWrappper>
            </LoginFormContainer>
            <Footer />
        </>
    )
}

export default Login;

