import { faiverAPI } from '../config/axios';

export const getFeaturedNFTs = async (limit = null, category = null) => {
    const params = {}

    if(limit != null)
    {
        params.limit = limit.toString();
    }

    if(category != null)
    {
        params.category = category;
    }

    const { data } = await faiverAPI.post(`/nft/featured`, params);

    return data;
}

export const getSponsoredNFTs = async (limit = null, category = null) => {
    const params = {}

    if(limit != null)
    {
        params.limit = limit.toString();
    }

    if(category != null)
    {
        params.category = category;
    }

    const { data } = await faiverAPI.post(`/nft/sponsored`, params);

    return data;
}

export const getNFTDetails = async (tokenId = null) => {
    const params = {}

    if(tokenId != null)
    {
        params.tokenId = tokenId.toString();
    }

    const { data } = await faiverAPI.post(`/nft/detail`, params);

    return data;
}

export const getNFTHistory = async (tokenId = null) => {
    const params = {}

    if(tokenId != null)
    {
        params.tokenId = tokenId;
    }

    const { data } = await faiverAPI.post(`/nft/history`, params);

    return data;
}

export const purchaseNFT = async (userId, tokenId, price, chain, royalty) => {
    const params = {}

    if(userId != null)
    {
        params.uid = userId;
    }

    if(tokenId != null)
    {
        params.tokenid = tokenId;
    }

    if(price != null)
    {
        params.price = price;
    }

    if(chain != null)
    {
        params.chain = chain;
    }

    if(royalty != null)
    {
        params.royalty = royalty;
    }

    const { data } = await faiverAPI.post(`/newbuying`, params);

    return data;
}

export const getUserNFTs = async (userId = null, nftOption = null) => {
    let apiUrl = '';
    const params = {}

    if(nftOption === 'created')
    {
        apiUrl = 'getcreatednft';
    }
    else if(nftOption === 'listed')
    {
        apiUrl = 'getlistednft';
    }
    else if(nftOption === 'bought')
    {
        apiUrl = 'getboughtnft';
    }

    const { data } = await faiverAPI.get(`/${apiUrl}/${userId}`, params);

    return data;
}