import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../Header';
import Footer from '../../Footer';

import imageNFTPic from '../../../assets/images/nft/image-nft.jpg';
import videoNFTPic from '../../../assets/images/nft/video-nft.jpg';
import audioNFTPic from '../../../assets/images/nft/audio-nft.jpg';

import './style.css';
import { USER_AUDIO_NFT_MINTING, USER_IMAGE_NFT_MINTING, USER_VIDEO_NFT_MINTING } from '../../../constants/applicationUrls';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {LOGO_IMAGE} from '../../../constants/application';
import Collery_logo_03 from '../../../assets/Collary_logo_03.jpg';
const EntireWrapper = styled.div`
    padding-top:10.5px;
`;

const Container = styled.div`
    .section-title{
        h2{
            font-size:var(--page-title);
            margin:15.5px 0 25.5px 0;
            font-weight:bold;
        }
        p{
            font-size:var(--dashboard-page-info);
            line-height:var(--line-height-exp);
        }
    }
    .nft-option-card{
        .text-list{
            line-height:var(--line-height-exp);
            h3{
                font-size:var(--list-title);
            }
            p{
                font-size:var(--list-subtitle);
            }
            .top-btn{
                font-size:var(--list-subtitle);
            }
        }
    }
    @media screen and (max-width:855px){
        .section-title{
            h2{
                font-size:var(--tablet-page-title);
                margin:10.5px 0 20.5px 0;
            }
            p{
                font-size:var(--tablet-explain-text);
            }
        }
        .nft-option-card{
            .text-list{
                line-height:var(--line-height-exp);
                h3{
                    font-size:var(--tablet-list-title);
                }
                p{
                    font-size:var(--tablet-list-subtitle);
                }
                .top-btn{
                    font-size:var(--tablet-list-subtitle);
                }
            }
        }
    }
    @media screen and (max-width:587px){
        .section-title{
            h2{
                font-size:var(--mobile-page-title);
            }
            p{
                font-size:var(--mobile-explain-text);
                line-height:var(--line-height-exp);
            }
        }
        .nft-option-card{
            .text-list{
                line-height:var(--line-height-exp);
                h3{
                    font-size:var(--mobile-list-title);
                }
                p{
                    font-size:var(--mobile-list-subtitle);
                }
                .top-btn{
                    font-size:var(--mobile-list-subtitle);
                }
            }
        }
    }
`;

function UserNFTMinting({ currentUser }) {

    return (
        <>
            <Helmet>
                <link rel="icon" href={Collery_logo_03}></link>
            </Helmet>
            <Header />

            <EntireWrapper>
                <Container className="container">
                    <div className="row">

                        <div className="col-lg-12">

                            <div className="container">
                                <div className="section-title">
                                    <h2>Create NFT</h2>
                                    {/* <p>For now you can mint NFT using images only. In future videos and audios are going to be available soon.</p> */}
                                </div>

                                <div className="row pt-70">

                                    <div className="col-lg-4 col-6">
                                        <Link to={USER_IMAGE_NFT_MINTING}>
                                            <div className="nft-option-card">
                                                <img src={imageNFTPic} alt="Create Image NFT" />
                                                <div className="text-list">
                                                    <h3>Create Image NFT</h3>
                                                    <p>JPG, JPEG, PNG, GIF</p>
                                                    <div className="top-btn text-uppercase">Image</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="col-lg-4 col-6">
                                        <Link to={USER_AUDIO_NFT_MINTING}>
                                            <div className="nft-option-card">
                                                <img src={audioNFTPic} alt="Create Image NFT" />
                                                <div className="text-list">
                                                    <h3>Create Audio NFT</h3>
                                                    <p>MP3, WAV</p>
                                                    <div className="top-btn text-uppercase">Audio</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="col-lg-4 col-6">
                                        <Link to={USER_VIDEO_NFT_MINTING}>
                                            <div className="nft-option-card">
                                                <img src={videoNFTPic} alt="Create Image NFT" />
                                                <div className="text-list">
                                                    <h3>Create Video NFT</h3>
                                                    <p>MP4</p>
                                                    <div className="top-btn text-uppercase">Video</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>



                                </div>
                            </div>


                        </div>
                    </div>
                </Container>
            </EntireWrapper>

            <Footer />
        </>
    )
}

export default UserNFTMinting;

