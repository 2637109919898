import {ColleryLogo3} from '../assets/Collary_logo_03.jpg';

export const LOGO_IMAGE = ColleryLogo3;

export const MAX_FEATURED_ON_HOME = 16;

export const FEATURED_NFT_CATEGORIES_ON_HOME = ['arts', 'good', 'music', 'sport'];

export const MAX_SPONSORED_ON_HOME = 2;

export const SPONSORED_NFT_CATEGORIES_ON_HOME = ['arts', 'good', 'music', 'sport'];

export const MAX_TOP_AUTHORS_ON_HOME = 12;

export const AVAILABLE_BLOCKCHAINS = [
    /*{
        name: 'Ethereum',
        value: 'ETH'
    },
    {
        name: 'Celo',
        value: 'CELO'
    },
    {
        name: 'Binance',
        value: 'BNB'
    },*/
    {
        name: 'Polygon',
        value: 'MATIC'
    },
    {
        name: 'Klaytn',
        value: 'KLAY'
    },
    {
        name: 'Solana',
        value: 'SOL'
    }

];

export const AVAILABLE_NFT_TYPES=[
    {
        name: 'sell',
        value: 'sell'
    },
    {
        name: 'rent',
        value: 'rent'
    },
    {
        name: 'proof',
        value: 'proof'
    },
];

export const AVAILABLE_AUDIO_CATEGORIES = [
    {
        name: 'Music',
        value: 'Music'
    },
    {
        name: 'Sound',
        value: 'Sound'
    },
    
];

export const AVAILABLE_CATEGORIES = [
    {
        name: 'Picture',
        value: 'Picture'
    },
    {
        name: 'Painting',
        value: 'Painting'
    },
    {
        name: 'Illustrate',
        value: 'Illustrate'
    },
    {
        name: 'Design',
        value: 'Design'
    },
    {
        name: 'Character',
        value: 'Character'
    },
    {
        name: 'Other',
        value: 'Other'
    },
 
];

export const AVAILABLE_SORT_FILTER = [
    {
        name: 'Price (Low To High)',
        value: '낮은 가격순'
    },
    {
        name: 'Price (High To Low)',
        value: '높은 가격순'
    },
];

export const AVAILABLE_CATEGORY_FILTER = [
    {
        name: 'All',
        value: 'All'
    },

];

export const MAX_ROYALTY_FEES = 50;

export const MAX_NFTS_ON_MARKETPLACE = 50;

export const FAIVER_NFT_LISTING_FEES = {
    eth: '0.0004',
    matic: '0.04',
    bnb: '0.004',
    celo: '0.04'
}

export const MAINTENANCE_MODE = false;

export const MINTING_GALLERY_CATEGORY = [
    'Painting','Illustrate','Picture',
    'Design','Character','Other'
]

export const GALLERY_API_TYPE = [
    'gallery_with_nothing',
    'gallery_with_category',
    'gallery_with_user',
    'gallery_with_user_and_category',
]

export const GALLERY_BANK_NAME = {
    SHIN_HAN:'신한은행',
    DOUCH_BANK:'도이치뱅크',
    HANA_BANK:'하나은행',
    STANDARD_CHARTED:'스탠다드차티드',
    NH:'농협은행',
    KB:'KB국민은행',
    DAEGU:'대구은행',
    BUSAN:'부산은행',
    CITY:'씨티뱅크',
    GUANGJU:'광주은행',
    KAKAO:'카카오뱅크'
}
    

