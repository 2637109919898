import React, { useState, useEffect, useCallback,
    useReducer,useRef,useMemo,createContext } from 'react';
import { useQuery ,useQueryClient} from 'react-query';
import { useHistory, useLocation } from 'react-router';
import Header from '../../Header';
import Footer from '../../Footer';

/* import moment from "moment";
import { ethers } from 'ethers';
import axios from 'axios';
import Web3Modal from "web3modal";
import { toast } from 'react-toastify'; 
*/

import './style.css';
import SingleNFTProductComponent from './SingleNFTProductComponent';

import styled from "styled-components";
import { useDispatch,useSelector } from 'react-redux';
import {
    INIT_LOAD_CHANGE_PRICE_INFO_STATE,
    LOADING_MY_NFT_PRODUCTS,
    INIT_LOAD_MY_NFT_PRODUCTS_STATE,
    INIT_LOAD_MY_MINTED_PRODUCTS_STATE,
    LOAD_MY_MINTED_PRODUCTS,
    LOAD_BOUGHT_PRODUCTS,
    INIT_LOAD_BOUGHT_PRODUCTS_STATE,
    LOAD_RENT_PRODUCTS,
    INIT_LOAD_RENT_PRODUCTS_STATE
} from "../../../reducers/nftTokens";
import MessageComponent from "../../MessageCommponent";
import LoadingComponent from "../../LoadingComponent"
import { MARKETPLACE } from '../../../constants/applicationUrls';
import {UserNFTPage} from '../../../constants/applicationMessage';
import RentedNFTDataComponent from './RentedNFTDataComponent';
const EntireContainer = styled.div`
    width:auto;height:auto;
    min-height:${window.innerHeight}px;
    .tab{
        .row{
            .section-wrap{
                padding:0;
                .section-title{
                    h1{
                        margin-top:25.5px;
                        margin-bottom:50.5px;
                        margin-left:0;
                        padding:0;
                        font-weight:bold;
                        font-size:var(--page-title);
                    }
                }
            }
            
            .container{
                margin-top: 55.5px;padding:0;
                h2{
                    margin-bottom:15.5px;
                    margin-left:0;
                    font-weight:bold;
                    font-size:var(--subtitle);
                }
            }
        }
    }

    @media screen and (max-width:855px){
        .tab{
            .row{
                .section-wrap{
                    padding:0;
                    .section-title{
                        h1{
                            margin-top:15.5px;
                            margin-bottom:25.5px;
                            margin-left:0;
                            padding:0;
                            font-weight:bold;
                            font-size:var(--tablet-page-title);
                        }
                    }
                }
                .container{
                    margin-top: 25.5px;
                    h2{
                        font-weight:bold;
                        font-size:var(--tablet-subtitle);
                    }
                }
            }
        }
    }

    @media screen and (max-width:578px){
        .tab{
            .row{
                .section-wrap{
                    padding:0;
                    .section-title{
                        h1{
                            margin-top:10.5px;
                            margin-bottom:19.5px;
                            margin-left:0;
                            padding:0;
                            font-weight:bold;
                            font-size:var(--mobile-page-title);
                        }
                    }
                }
                .container{
                    margin-top: 20.5px;
                    h2{
                        font-weight:bold;
                        font-size:var(--mobile-subtitle);
                    }
                }
            }
        }
    }
`;

const StyledShowMoreButtonWrap = styled.div`
    width : 100%;
    height : 105.5px;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
        display:block;
        width:60%;
        height:50%;
    }
`;

const StyledShowMoreButton = styled.button`
    display:block;
    width:200px;
    height:48px;
    border:1px solid #313131;
    border-radius: 9.5px;
    color:white;
    font-size:var(--list-subtitle);
    padding:0;
    background: #fff;
    color:#313131;
    &:active{
        background:#313131;
        color:#fff;
    }
    &:hover{
        background:#313131;
        color:#fff;
    }
    @media screen and (max-width:855px){
        width: 150px;
        height: 28px;
        font-size:var(--tablet-list-subtitle);
    }
    @media screen and (max-width:578px){
        font-size:var(--mobile-list-subtitle);
    }
`;

const findElement = (elem, findCondition, endCondition) => {
    const flag = elem.tagName === findCondition.toUpperCase() || elem.classList.contains(findCondition);
    if (flag) {
        return elem;
    } else {
        const endFlag = elem.tagName === endCondition.toUpperCase() || elem.classList.contains(endCondition);
        if (endFlag) {
            return null;
        } else {
            return findElement(elem.parentElement, findCondition, endCondition);
        }
    }
}

export const RentNFTDataContext = createContext({...initialRentedNFTState});

const initialRentedNFTState = {
    RentedNFTDataDispatch:()=>{},
    RentedNFTDataList:[]
}

export const UPDATE_RENTED_NFT_DATA_STATE = 'UPDATE_RENTED_NFT_DATA_STATE';
export const DELETE_RENTED_NFT_DATA_STATE = 'DELETE_RENTED_NFT_DATA_STATE';

const RentedNFTReducers = (state,action)=>{
    const {type}=action;
    switch(type){
        case UPDATE_RENTED_NFT_DATA_STATE:{
            console.log(action.RentedNFTDataList);
            return {...state,RentedNFTDataList:[...state.RentedNFTDataList,...action.RentedNFTDataList]};
        }
        case DELETE_RENTED_NFT_DATA_STATE:{
            const {deleteRentNFTId} = action;
            return {...state,RentedNFTDataList:[...(
                state.RentedNFTDataList.filter(v=>v.id!=deleteRentNFTId)
            )]};
        }
        default:{
            return {...state};
        }
    }
}

function UserNFTs({ currentUser }) {

    const [mynfts, setMyNfts] = useState([]);
    const [bought, setBought] = useState([]);
    const [sold, setSold] = useState([]);
    const [rent, setRent] = useState([]);
    const [loadingMessage, setLoadingMessage]=useState('');
    const [loadingState, setLoadingState] = useState(false);
    const mynftsOffset = useRef(1);
    const soldOffset = useRef(1);
    const boughtOffset = useRef(1);
    const rentOffset = useRef(1);
    const [noMoreMyNft,setNoMoreMyNft] = useState(false);
    const [noMoreSoldNft,setNoMoreSoldNft] = useState(false);
    const [noMoreBoughtNft,setNoMoreBoughtNft] = useState(false);
    const [noMoreRentNft,setNoMoreRentNft] = useState(false);
    const maxrecord = 16;
    let queryClient = useQueryClient();

    const [
        showMessageComponent,
        setShowMessageComponent
    ] = useState(false);

    const [messageOnMessageComponent,setMessageOnMessageComponent]=useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    const {
        loadingChangePriceInfo,
        loadingChangePriceInfoSuccess,
        loadingChangePriceInfoData,
        loadingChangePriceInfoFailure,
        loadingChangePriceInfoType,
        loadingChangePriceInfoDataId,
        loadingMyNftProducts,
        loadingMyNftProductsSuccess,
        loadingMyNftProductsData,
        loadingMyNftProductsFailure,
        loadingMyMintedNftProductsFailure,
        loadingMyMintedNftProducts,
        loadingMyMintedNftProductsSuccess,
        loadingMyMintedNftProductsData,
        loadingBoughtNftProductsFailure,
        loadingBoughtNftProducts,
        loadingBoughtNftProductsSuccess,
        loadingBoughtNftProductsData,
        loadingRentNftProductsFailure,
        loadingRentNftProducts,
        loadingRentNftProductsSuccess,
        loadingRentNftProductsData
    }=useSelector(state=>state.nftTokens);

    /* INIT_LOAD_BOUGHT_PRODUCTS_STATE */

    /* LOAD_RENT_PRODUCTS
    INIT_LOAD_RENT_PRODUCTS_STATE */

    const [RentedNFTState,RentedNFTDataDispatch] = useReducer(RentedNFTReducers,initialRentedNFTState);

    const RentedNFTContextData= useMemo(()=>{
        return {RentedNFTDataDispatch,RentedNFTDataList:RentedNFTState.RentedNFTDataList}
    },[RentedNFTState])

    useEffect(()=>{
        if(!loadingBoughtNftProducts&&
            loadingBoughtNftProductsSuccess){
                setBought([...bought,...loadingBoughtNftProductsData]);
            if(loadingBoughtNftProductsData.length===0
                ||loadingBoughtNftProductsData.length%maxrecord!=0){
                setNoMoreBoughtNft(true);
            }else{
                boughtOffset.current=boughtOffset.current+1;
            }
            dispatch({type:INIT_LOAD_BOUGHT_PRODUCTS_STATE});
        }else if(!loadingBoughtNftProducts
            &&!!loadingBoughtNftProductsFailure){
            setShowMessageComponent(true);
            setMessageOnMessageComponent(loadingBoughtNftProductsFailure);
            dispatch({type:INIT_LOAD_BOUGHT_PRODUCTS_STATE});
        }
    },[
        loadingBoughtNftProductsFailure,
        loadingBoughtNftProducts,
        loadingBoughtNftProductsSuccess,
        loadingBoughtNftProductsData,
        bought
    ])

    useEffect(()=>{
        if(!loadingRentNftProducts&&
            loadingRentNftProductsSuccess){
                console.log(loadingRentNftProductsData);
                //setRent([...rent,...loadingRentNftProductsData]);
            if(loadingRentNftProductsData.length===0
                ||loadingRentNftProductsData.length%maxrecord!=0){
                setNoMoreRentNft(true);
                if(loadingRentNftProductsData.length!=0){
                    RentedNFTDataDispatch({
                        type:UPDATE_RENTED_NFT_DATA_STATE,
                        RentedNFTDataList:[...loadingRentNftProductsData]
                    })
                }
            }else{
                rentOffset.current=rentOffset.current+1;
                RentedNFTDataDispatch({
                    type:UPDATE_RENTED_NFT_DATA_STATE,
                    RentedNFTDataList:[...loadingRentNftProductsData]
                })
            }
            dispatch({type:INIT_LOAD_RENT_PRODUCTS_STATE});
        }else if(!loadingRentNftProducts
            &&!!loadingRentNftProductsFailure){
            setShowMessageComponent(true);
            setMessageOnMessageComponent(loadingRentNftProductsFailure);
            dispatch({type:INIT_LOAD_RENT_PRODUCTS_STATE});
        }
    },[
        loadingRentNftProductsFailure,
        loadingRentNftProducts,
        loadingRentNftProductsSuccess,
        loadingRentNftProductsData,
        rent
    ])

    useEffect(()=>{
        if(!loadingMyMintedNftProducts&&
            loadingMyMintedNftProductsSuccess){
                setMyNfts([...mynfts,...loadingMyMintedNftProductsData]);
            if(loadingMyMintedNftProducts.length===0
                ||loadingMyMintedNftProducts.length%maxrecord!=0){
                setNoMoreMyNft(true);
            }else{
                mynftsOffset.current=mynftsOffset.current+1;
            }
            dispatch({type:INIT_LOAD_MY_MINTED_PRODUCTS_STATE});
        }else if(!loadingMyMintedNftProducts
            &&!!loadingMyMintedNftProductsFailure){
            setShowMessageComponent(true);
            setMessageOnMessageComponent(loadingMyNftProductsFailure)
            dispatch({type:INIT_LOAD_MY_MINTED_PRODUCTS_STATE});
        }
    },[
        loadingMyMintedNftProductsFailure,
        loadingMyMintedNftProducts,
        loadingMyMintedNftProductsSuccess,
        loadingMyMintedNftProductsData,
        mynfts
    ])

    useEffect(()=>{
        console.log(queryClient);
        if(
            !loadingChangePriceInfo
            &&
            (loadingChangePriceInfoSuccess||loadingChangePriceInfoFailure)
        ){
            setLoadingState(false);
            console.log(queryClient.getQueryData(['NFT-DETAIL',Number(loadingChangePriceInfoDataId)]));
            queryClient.invalidateQueries(['NFT-DETAIL',Number(loadingChangePriceInfoDataId)])
            setShowMessageComponent(true);
            loadingChangePriceInfoSuccess?
                setMessageOnMessageComponent(loadingChangePriceInfoData)
                :setMessageOnMessageComponent(loadingChangePriceInfoFailure);
        }else if(loadingChangePriceInfo){
            setLoadingMessage(
                loadingChangePriceInfoType==="Resell"?
            UserNFTPage.resellingNFTPriceMessage
            :UserNFTPage.changingNFTPriceMessage
            );
            setLoadingState(true)
        }
    },[
        loadingChangePriceInfo,
        loadingChangePriceInfoSuccess,
        loadingChangePriceInfoData,
        loadingChangePriceInfoFailure,
        loadingChangePriceInfoType,
        loadingChangePriceInfoDataId,
    ])

    useEffect(() => {
        if(currentUser){
            dispatch({
                type:LOADING_MY_NFT_PRODUCTS,
                data:{
                    userid:currentUser.uid,
                    limit:maxrecord,offset:0
                },
            });
        }
    }, [currentUser]);
    
    useEffect(()=>{
        if(!loadingMyNftProducts&&loadingMyNftProductsSuccess){
            setLoadingState(false);
            setMyNfts([...(loadingMyNftProductsData.find(v=>v.key==='myNfts').data)]);
            setBought([...(loadingMyNftProductsData.find(v=>v.key==='bought').data)]);
            //setRent([...(loadingMyNftProductsData.find(v=>v.key==='rent').data)]);
            //console.log(rent);
            RentedNFTDataDispatch({
                type:UPDATE_RENTED_NFT_DATA_STATE,
                RentedNFTDataList:[...(loadingMyNftProductsData.find(v=>v.key==='rent').data)]
            })
            if(loadingMyNftProductsData.find(v=>v.key==='myNfts').data.length===0||
            loadingMyNftProductsData.find(v=>v.key==='myNfts').data.length%maxrecord!==0){
                setNoMoreMyNft(true);
            }else{
                mynftsOffset.current=mynftsOffset.current+1;
            }
            if(loadingMyNftProductsData.find(v=>v.key==='bought').data.length===0||
                loadingMyNftProductsData.find(v=>v.key==='bought').data.length%maxrecord!==0){
                setNoMoreBoughtNft(true);
            }else{
                boughtOffset.current=boughtOffset.current+1;
            }
            if(loadingMyNftProductsData.find(v=>v.key==='rent').data.length===0||
                loadingMyNftProductsData.find(v=>v.key==='rent').data.length%maxrecord!==0){
                setNoMoreRentNft(true);
            }else{
                rentOffset.current=rentOffset.current+1;
            }
            dispatch({type:INIT_LOAD_MY_NFT_PRODUCTS_STATE});
        }else if(!loadingMyNftProducts&&loadingMyNftProductsFailure){
            setLoadingState(false);
            //console.log(loadingMyNftProductsFailure);
            setShowMessageComponent(true);
            setMessageOnMessageComponent(loadingMyNftProductsFailure)
            //dispatch({type:INIT_LOAD_MY_NFT_PRODUCTS_STATE});
        }else if(loadingMyNftProducts){
            setLoadingMessage(UserNFTPage.message);
            setLoadingState(true);
        }
    },[
        loadingMyNftProducts,
        loadingMyNftProductsSuccess,
        loadingMyNftProductsData,
        loadingMyNftProductsFailure,
    ])

    /* async function loadMyNFTs() {
        setLoadingState(true)
        await axios.get(`https://api.collery.io/getusernft/${currentUser.uid}`).then((res) => {
            console.log(res.data);

            setMyNfts(res.data)
            setLoadingState(false)

        }).catch((err) => {
            //console.log(err)
            setLoadingState(false)
        })
    }

    async function loadboughtNFTs() {
        setLoadingState(true)
        await axios.get(`https://api.collery.io/getboughtnft/${currentUser.uid}`).then((res) => {
            console.log(res.data)

            setBought(res.data)
            setLoadingState(false)

        }).catch((err) => {
            console.log(err)
            setLoadingState(false)
        })
    }

    async function loadsoldNFTs() {
        setLoadingState(true)
        await axios.get(`https://api.collery.io/getusersold/${currentUser.uid}`).then((res) => {
            //console.log(res)

            setSold(res.data)
            setLoadingState(false)

        }).catch((err) => {
            console.log(err)
            setLoadingState(false)
        })
    }

    async function loadrentedNFTs() {
        setLoadingState(true)
        await axios.get(`https://api.collery.io/getuserrent/${currentUser.uid}`).then((res) => {
            console.log("=================rented============", res)

            setRent(res.data)
            setLoadingState(false)

        }).catch((err) => {
            console.log(err)
            setLoadingState(false)
        })
    } */

    const cancelFunction = useCallback((evt)=>{
        if(loadingChangePriceInfoSuccess){
            history.replace(MARKETPLACE);
            dispatch({type:INIT_LOAD_CHANGE_PRICE_INFO_STATE})
        }
        if(loadingChangePriceInfoFailure)dispatch({type:INIT_LOAD_CHANGE_PRICE_INFO_STATE});
        if(loadingMyNftProductsFailure)dispatch({type:INIT_LOAD_MY_NFT_PRODUCTS_STATE});
        
        setShowMessageComponent(false);
    },[loadingChangePriceInfoSuccess,
        loadingChangePriceInfoFailure,
        loadingMyNftProductsFailure
    ]);

    const onClickShowMore = useCallback((evt)=>{
        const {target:thisElement}=evt;
        const elem = findElement(thisElement,'BUTTON','container');
        if(elem){
            if(elem.classList.contains('show-more-my-minted-nfts')){
                console.log(noMoreMyNft);
                if(loadingMyMintedNftProducts||noMoreMyNft){
                   return false; 
                }
                console.log(maxrecord);
                console.log((mynftsOffset.current-1)*maxrecord);
                dispatch({
                    type:LOAD_MY_MINTED_PRODUCTS,
                    data:{
                        userid:currentUser.uid,
                        limit:maxrecord,
                        offset:(mynftsOffset.current-1)*maxrecord
                    }
                });
            }else if(elem.classList.contains('show-more-bought-nfts')){
                if(loadingBoughtNftProducts||noMoreBoughtNft){
                    return false;
                }
                dispatch({
                    type:LOAD_BOUGHT_PRODUCTS,
                    data:{
                        userid:currentUser.uid,
                        limit:maxrecord,
                        offset:(boughtOffset.current-1)*maxrecord
                    }
                })
            }else if(elem.classList.contains('show-more-rent-nfts')){
                if(loadingRentNftProducts||noMoreRentNft){
                    return false;
                }
                dispatch({
                    type:LOAD_RENT_PRODUCTS,
                    data:{
                        userid:currentUser.uid,
                        limit:maxrecord,
                        offset:(rentOffset.current-1)*maxrecord
                    }
                })
            }
        }else{
            return false;
        }
    },[mynftsOffset.current,
        soldOffset.current,
        boughtOffset.current,
        rentOffset.current,
        noMoreMyNft,
        noMoreSoldNft,
        noMoreBoughtNft,
        noMoreRentNft,
        loadingMyMintedNftProducts,
        loadingBoughtNftProducts,
        loadingRentNftProducts
    ])

    return (
        <>
            <Header />
            {
                
                showMessageComponent
                &&
                <MessageComponent 
                    message={messageOnMessageComponent} 
                    cancelFunction={cancelFunction}
                />
            }
            {
                loadingState
                &&
                <LoadingComponent message={loadingMessage}/>
            }
            <div className="featured-nfts pt-3 pb-70">
                <EntireContainer className="container">
                    <div className="tab featured-tab-area">
                        <div className="row align-items-center">
                            <div className="section-wrap">
                                <div className="section-title">
                                    <h1 className="pt-10 ml-10">My NFTs</h1>
                                </div>
                            </div>
                        </div>

                        {/* {loadingState&&

                            <div className="container d-flex justify-content-center mx-auto" style={{marginTop:'3%'}}>
                                                    
                                <div className="">
                                <Loader size={10} />

                                </div>   
                            
                            </div> 
                        } */}

                        <div className="row align-items-center">

                            {
                                mynfts
                                &&
                                <div className="container">
                                    <h2 className="text-3xl font-bold ml-3">{!loadingState && <>Minted NFTs</>}</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                        {mynfts?.map((value, key) =>
                                            <SingleNFTProductComponent userId={currentUser.uid} key={value.id} value={{ ...value, type: 'Edit' }} />
                                        )}
                                    </div>
                                    {
                                        (!loadingMyMintedNftProducts&&!noMoreMyNft)&&
                                        <StyledShowMoreButtonWrap>
                                            <StyledShowMoreButton onClick={onClickShowMore} className="show-more-my-minted-nfts">
                                                <span>
                                                    더 보기
                                                </span>
                                            </StyledShowMoreButton>
                                        </StyledShowMoreButtonWrap>
                                    }
                                </div>
                            }


                            {
                                bought&&<div className="container">
                                <h2 className="text-3xl font-bold ml-3">{!loadingState&&<>Purchased NFTs</>}</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                        
                                    {bought?.map((value,key)=>
                                        <SingleNFTProductComponent userId={currentUser.uid} key={value.id}  value={{...value,type:'Resell'}}/>
                                    )}
                                    </div>
                                    {
                                        (!loadingBoughtNftProducts&&!noMoreBoughtNft)&&
                                        <StyledShowMoreButtonWrap>
                                        <StyledShowMoreButton onClick={onClickShowMore} className="show-more-bought-nfts">
                                            <span>
                                                더 보기
                                            </span>
                                        </StyledShowMoreButton>
                                    </StyledShowMoreButtonWrap>
                                    }
                                    
                                </div>
                            }

                            {/* {sold&&<div class="container mx-auto mt-3">
                                <h2 className="text-3xl font-bold ml-3">{!loadingState&&<>Sold NFTs</>}</h2>
                                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                        
                                    {sold?.slice(0).reverse().map((value,key)=>
                                        <div key={key} class="flex flex-col justify-center text-md border-1 border-gray-100 rounded-xl p-1 bg-gray-100">
                                            
                                            <div className=" flex items-center justify-center">

                                            {value?.format==="image"&&<LazyLoadImage
                                            src={value.image} 
                                            effect="blur"
                                            placeholderSrc={<Loader size={10} />}
                                    
                                            className="rounded-lg object-cover h-80 w-96 sm:object-cover mt-3 sm:h-80 sm:w-96 " />}

                                            {value?.format==="audio"&&<LazyLoadImage
                                            src={value.bgimage} 
                                            effect="blur"
                                            placeholderSrc={<Loader size={10} />}
                                    
                                            className="rounded-lg object-cover h-80 w-96 sm:object-cover mt-3 sm:h-80 sm:w-96 " />}
                                        

                                            </div>

                                            <div className="h-10">
                                            {value?.media&&<audio src={value.media} controls controlsList="nodownload"></audio>}
                                            </div>
                                        
                                            <div className="text-gray-500 bg-gray-900 p-2 flex items-center justify-center rounded-full mt-3 cursor-pointer">
                                            <a>Share</a>
                                            </div>

                                        </div>
                                    )}
                                    
                                
                                    </div>
                                </div>} */}



                            <RentNFTDataContext.Provider value={RentedNFTContextData}>
                            {
                                rent && 
                                <div className="container">
                                    <h2 className="text-3xl font-bold ml-3">{!loadingState && <>Rented NFTs</>}</h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                        <RentedNFTDataComponent userId={currentUser.uid}/>
                                        {/* {
                                            rent?.map((nft,i)=>(
                                                <SingleNFTProductComponent userId={currentUser.uid} key={nft.id}  value={{...nft,type:'rented'}}/>
                                            ))
                                        } */}
                                    </div>
                                    {
                                        (!loadingRentNftProducts&&!noMoreRentNft)&&
                                        <StyledShowMoreButtonWrap>
                                            <StyledShowMoreButton onClick={onClickShowMore} className='show-more-rent-nfts'>
                                                <span>
                                                    더 보기
                                                </span>
                                            </StyledShowMoreButton>
                                        </StyledShowMoreButtonWrap>
                                    }
                                    
                                </div>
                            }
                            </RentNFTDataContext.Provider>







                            {/** <div className="row pt-70">
                                            <div className="alert alert-light container" role="alert" >
                                            Rented NFTs
                                                        
                                            </div>

                                            {loadingState && !rented?.length &&
                                                <p>You haven't rented any NFTs</p>
                                            } 
                                            
                                    </div>*/}
                            {/*
                                        rented?.map((nft, i) => (
                                        <div className="col-lg-3 col-md-4 col-6" key={i} >
                                            
                                            <div className="nft-option-card">
                                                <img src={nft.image} alt="Create Buy NFT" />
                                                <h3><b >{nft.title}</b></h3>
                                                <p>Chain: {nft.chain} </p>
                                                <p>Rented on: {nft.renteddate} </p>
                                                <p>Rented for: {nft.period} /Days </p>

                                                <div className='d-flex '><p>Countdown</p><p><Clock deadline={moment((nft.renteddate)).add(Number(nft.period)+1, 'day').format('LL')} id={nft.id} /> </p></div>

                                                <p>Smart contract: <a href={`https://mumbai.polygonscan.com/address/${nftaddress}`} target="_blank"> {nftaddress.slice(0,7)+'...'+nftaddress.slice(35,43)} </a></p>
                                       </div>
                                        </div>
                                        ))*/}






                        </div>
                    </div>
                </EntireContainer>
            </div>

            <Footer />
        </>
    )
}

export default UserNFTs;

