import React, { useEffect,useState, useCallback, memo, useRef } from "react";
import styled from "styled-components";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MusicPlayerComponent from "./MusicPlayerComponent";
import PriceComponent from "./PriceComponent";
import ShowRentInfoComponent from "./ShowRentInfoComponent";

const SingleNFTProductComponentContainer = styled.div`
    width:100%;height:475.5px;
    background-color:#fff;
    border-radius:15.5px;
    box-shadow:0 0 2.5px rgba(45,45,45,0.65);
    .NFT-media-wrap{
        width:100%;height:auto;
        margin:0 auto;
        .NFT-media-component{
            position:relative;
            width:100%;height:325.5px;
            border-radius:15.5px 15.5px 0 0;
            overflow:hidden;
            .NFT-issue-type-tag{
                position:absolute;
                top:20px;left:10px;
                width: 80.5px;height: 25.5px;
                font-size:15.5px;text-align:center;
                line-height:25.5px;color:#fff;
                background-color:#ff4d2c;
                box-shadow:1.5px 1.5px 2.5px #454545;
                border-radius:4.5px;
            }
            img{
                display:block;
                width:100%;height:100%;
                object-fit:cover;
            }
            .playerButton-wrap{
                position:absolute;
                width:65px;height:65px;
                border-radius:100%;
                overflow:hidden;
                border:2.5px solid #ff4d2c;
                box-sizing:border-box;
                top: calc(100% - 70px);
                left: 5px;
                .playerButton-component{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width:100%;height:100%;
                    line-height:65px;
                    text-align:center;
                    >span{
                        font-size:32.5px;
                        color: #ff4d2c;
                    }
                }
            }
        }
        .NFT-data-info-container{
            width:100%;height:150px;
            .NFT-data-info-wrap{
                width:100%;height:100%;
                padding:5.5px;
                box-sizing:border-box;
                display:flex;
                flex-direction:column;  
                align-items:flex-start;
                justify-content:space-evenly;
                .show-rent-dueDate-wrap{
                    width:100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    height: 100px;
                    .countdown-header{
                        font-size:var(--list-subtitle);
                        width:100%;height:auto;
                        color:#000;
                    }
                }
                .NFT-data-info-name{
                    font-size:var(--list-title);
                    font-weight:bold;
                }
                .NFT-data-info-category{
                    font-size:var(--list-subtitle);
                }
                .NFT-data-info-price{
                    width:100%;
                    font-size:var(--list-subtitle);
                    .price-editer{
                        display: block;
                        width: 100%;
                        height: 29.5px;
                        border-radius: 4.5px;
                        box-sizing: border-box;
                        margin: 1.5px 0;
                        padding: 1.5px 2.5px;
                        font-size: var(--list-subtitle);
                        border: 1px solid rgb(24,104,183);
                        &:focus{
                            border: 1px solid rgb(24,104,183);
                        }
                    }
                    .price-text{
                        display:inline-block;
                        font-size:var(--list-subtitle);
                        overflow: hidden;
                        max-width:75.5%;
                        text-overflow: ellipsis;    
                    }
                    .price-measure-text{
                        display:inline-block;
                        margin-left:5.5px;
                        font-size:var(--list-subtitle);
                    }
                }
                .NFT-data-info-Button-wrap{
                    width:100%;height:32px;
                    margin:0 auto;
                    .NFT-data-info-Button{
                        display:block;
                        width:100%;height:100%;
                        color:#fff;
                        background-color:rgb(24,104,183);
                        border-radius: 4.5px;
                        line-height:32px;
                        font-size:var(--list-subtitle);
                    }
                }
            }
        }
    }
    @media screen and (max-width:885px){
        .NFT-media-wrap{
            width:100%;height:auto;
            margin:0 auto;
            .NFT-media-component{
                width:100%;height:325.5px;
                .playerButton-wrap{
                    width:45px;height:45px;
                    top: calc(100% - 50px);
                    .playerButton-component{
                        line-height:45px;
                        >span{
                            font-size:25.5px;
                        }
                    }
                }
            }
            .NFT-data-info-container{
                width:100%;height:150px;
                .NFT-data-info-wrap{
                    .NFT-data-info-name{
                        font-size:var(--tablet-list-title);
                    }
                    .NFT-data-info-category{
                        font-size:var(--tablet-list-subtitle);
                    }
                    .NFT-data-info-price{
                        font-size:var(--tablet-list-subtitle);
                    }
                    .NFT-data-info-Button-wrap{
                        .NFT-data-info-Button{
                            font-size:var(--tablet-list-subtitle);
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:578px){
        width: 92.5%;
        margin: 0 auto;
        .NFT-media-wrap{
            width:100%;height:auto;
            margin:0 auto;
            .NFT-media-component{
                width:100%;height:325.5px;
                .playerButton-wrap{
                    width:35px;height:35px;
                    top: calc(100% - 50px);
                    .playerButton-component{
                        line-height:35px;
                        >span{
                            font-size:18.5px;
                        }
                    }
                }
            }
            .NFT-data-info-container{
                width:100%;height:150px;
                .NFT-data-info-wrap{
                    .NFT-data-info-name{
                        font-size:var(--mobile-list-title);
                    }
                    .NFT-data-info-category{
                        font-size:var(--mobile-list-subtitle);
                    }
                    .NFT-data-info-price{
                        font-size:var(--mobile-list-subtitle);
                    }
                    .NFT-data-info-Button-wrap{
                        .NFT-data-info-Button{
                            font-size:var(--mobile-list-subtitle);
                        }
                    }
                }
            }
        }
    }
`;

const SingleNFTProductComponent = memo(({value,userId}) => {
    return (
        <SingleNFTProductComponentContainer>
            <div className="NFT-media-wrap">
                <div className="NFT-media-component">
                    <p className="NFT-issue-type-tag">{value?.rent||value.type}</p>
                    {
                        value?.format === "image" && 
                        <img src={value?.image} alt="NFT-Image"/>
                    }
                    {
                        value?.format === "audio" && (
                            <>
                                <img src={value?.bgimage} alt="NFT-Image"/>
                                <MusicPlayerComponent audioSrc={value.media}/>
                            </>
                        )
                    }
                </div>
                <div className="NFT-data-info-container">
                    <div className="NFT-data-info-wrap">
                        <h3 className="NFT-data-info-name">
                            {value.name.slice(0,value.name.lastIndexOf("#"))}
                        </h3>
                        {
                            (value.type!=="rented")&&
                            (<p className="NFT-data-info-category">
                                {value?.category}
                            </p>)
                        }
                        
                        {
                            (value.rent!=="proof"||value.type==="Resell"||value.type==="Edit")
                            &&(value.price||value.pricerent)
                            &&(
                                <PriceComponent userId={userId} dataId={value.id} type={value.type} rent={value.rent} priceData={value.rent==="rent"?value.pricerent:value.price}/>
                            )
                        }
                        {
                            (value.type==="rented")
                            &&(
                                <ShowRentInfoComponent rentData={value}/>
                            )
                            
                        }
                    </div>
                </div>
            </div>
        </SingleNFTProductComponentContainer>
    )
});

export default SingleNFTProductComponent;
