import React, {useState, useEffect, useCallback, useRef, memo } from "react";
import {
    CaretRightOutlined, SoundOutlined,
    PauseOutlined, CloseOutlined
} from "@ant-design/icons";

const MusicPlayerComponent = memo((audioSrc) => {
    const musicPlayerRef = useRef(null);
    const [playing, setPlaying] = useState(false);

    const mediaEndCallback=useCallback(()=>{
        if(musicPlayerRef.current){
            setPlaying(false);
            musicPlayerRef.current.load();
        }
    },[musicPlayerRef.current])

    const mediaPlayingCallback = useCallback(()=>{
        if(musicPlayerRef.current){
            setPlaying(true);
        }
    },[musicPlayerRef.current]);

    const mediaPauseCallback = useCallback(()=>{
        if(musicPlayerRef.current){
            setPlaying(false);
        }
    },[musicPlayerRef.current]);

    const mediaLoadCallback = useCallback(()=>{
        if(musicPlayerRef.current){
            playing&&musicPlayerRef.current.play();
        }
    },[musicPlayerRef.current,playing]);

    useEffect(() => {
        musicPlayerRef.current=new Audio(audioSrc.audioSrc);
        musicPlayerRef.current.load();
        musicPlayerRef.current.addEventListener('play',mediaPlayingCallback);
        musicPlayerRef.current.addEventListener('pause',mediaPauseCallback);
        musicPlayerRef.current.addEventListener('canplay',mediaLoadCallback);
        musicPlayerRef.current.addEventListener('end',mediaEndCallback);
        return ()=>{
            if(musicPlayerRef.current){
                musicPlayerRef.current.pause();
                musicPlayerRef.current.removeEventListener('play',mediaPlayingCallback);
                musicPlayerRef.current.removeEventListener('pause',mediaPauseCallback);
                musicPlayerRef.current.removeEventListener('canplay',mediaLoadCallback);
                musicPlayerRef.current.removeEventListener('end',mediaEndCallback);
                musicPlayerRef.current=null;
            }
        }
    }, [])

    const onClickPlay = useCallback((evt)=>{
        if(!musicPlayerRef.current)return false;
        if(playing&&!musicPlayerRef.current.paused){
            musicPlayerRef.current.pause();
        }else if(!playing&&musicPlayerRef.current.paused){
            musicPlayerRef.current.play();
        }else if(!playing&&musicPlayerRef.current.ended){
            musicPlayerRef.current.load();
            setPlaying(true);
        }
    },[playing,musicPlayerRef.current]);

    return (
        <>
            <div className="playerButton-wrap">
                <p onClick={onClickPlay} className="playerButton-component">
                    {
                        playing ?
                            <PauseOutlined /> :
                            <CaretRightOutlined />
                    }
                </p>
            </div>
        </>
    )
})

export default MusicPlayerComponent;
