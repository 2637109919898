import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { QueryCache } from 'react-query';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../Loader';
import { Modal } from 'react-bootstrap';
import { useHistory,useLocation } from 'react-router-dom';
import Web3Modal from 'web3modal';
import { create } from 'ipfs-http-client';
import { toast } from 'react-toastify';

import { ethers } from 'ethers';
import {
    nftaddress, nftmarketaddress
} from '../../../config';

import NFT from '../../../artifacts/contracts/NFT.sol/NFT.json';
import Market from '../../../artifacts/contracts/Market.sol/Market.json';
import Helmet from 'react-helmet';

import { AVAILABLE_BLOCKCHAINS, AVAILABLE_CATEGORIES, MAX_ROYALTY_FEES } from '../../../constants/application';
import { MINTING_GALLERY_CATEGORY } from '../../../constants/application';

import './style.css';
import styled from 'styled-components';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    UPLOADING_IMAGE_FILES_INIT, UPLOADING_IMAGE_FILES_REQUEST,
} from '../../../reducers/paintings';

import LoadingComponent from '../../LoadingComponent';
import MessageCommponent from '../../MessageCommponent';
import {GalleryProductUpload} from '../../../constants/applicationMessage';
import ColleryLogo3 from '../../../assets/Collary_logo_03.jpg';

const StyledUploadImageFormatWrap = styled.div`
  width:95%;
  height:auto;
  box-sizing:border-box;
  border-radius: 15px;
  margin:0 auto;
  @media screen and (max-width: 1050px){
    width:100%;
  }
`;

const StyledUploadedImageListWrap = styled.div`
  position:relative;
  width:100%;
  height: ${props => props.showImgPreview ? '390px' : '0px'};
  background:#dee2e6;
  display:flex;
  align-items:center;
  border-radius: 15px;
  margin-top: 30px;
  transition:height 0.7s,width 0.4s;
  overflow:hidden;
  @media screen and (max-width:800px){
    height: ${props => props.showImgPreview ? `290px` : '0px'};
  }
  @media screen and (max-width:800px){
    height: ${props => props.showImgPreview ? `190px` : '0px'};
  }
`;

const StyledUploadedImageList = styled.div`
  position:absolute;
  top:0;left:0;
  width:auto;
  height:100%;
  padding: 10px;
  box-sizing:border-box;
  display:flex;
  justify-content : center;
  align-items:center;
`;

const StyledUploadedImageFrame = styled.div`
    width:${props => props.width}px;
    height:${props => props.height}px;
    margin-right:10px;
    border-radius:11.5px;
    overflow:hidden;
    position:relative;
`;

const StyledCloseButton = styled.p`
    position:absolute;
    top:18.5px;right:18.5px;
    font-size:18.5px;
    color:#000;
    width:30px;height:30px;
    background:#fff;
    border-radius:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2.5px 2.5px 2.5px #454545;
    margin: 0;
    &:active{
        box-shadow:none;
    }
`;

const StyledUploadedImage = styled.img`
  display:block;
  width:${props => props.width}px;
  height:${props => props.height}px;
  -webkit-user-drag: none;
  user-select:none;
`;

const StyledInfoForamtWrap = styled.div`
    width:95%;
    height:auto;
    padding: 0;
    margin:0 auto;
    border-radius: 15px;
    .item-details-dsce{
        form{
            .row{
                >div{
                    .form-group{
                        label{
                            font-size:var(--dashboard-page-title);
                        }
                        >input{
                            font-size:var(--dashboard-page-info);
                        }
                        >textarea{
                            font-size:var(--dashboard-page-info);
                        }
                        >select option{
                            font-size:var(--dashboard-page-info);
                        }
                    }
                    
                    .text-center{
                        >button{
                            font-size:var(--dashboard-page-button);
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:1050px){
        width:100%;
    }
    @media screen and (max-width:855px){
        .item-details-dsce{
            form{
                .row{
                    >div{
                        .form-group{
                            label{
                                font-size:var(--tablet-dashboard-page-title);
                            }
                            >input{
                                font-size:var(--tablet-dashboard-page-info);
                            }
                            >textarea{
                                font-size:var(--tablet-dashboard-page-info);
                            }
                        }
                        
                        .text-center{
                            >button{
                                font-size:var(--tablet-dashboard-page-button);
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:578px){
        .item-details-dsce{
            form{
                .row{
                    >div{
                        .form-group{
                            label{
                                font-size:var(--mobile-dashboard-page-title);
                            }
                            >input{
                                font-size:var(--mobile-dashboard-page-info);
                            }
                            >textarea{
                                font-size:var(--mobile-dashboard-page-info);
                            }
                            >select option{
                                font-size:var(--mobile-dashboard-page-info);
                            }
                        }       
                        .text-center{
                            >button{
                                font-size:var(--mobile-dashboard-page-button);
                            }
                        }
                    }
                }
            }
        }
    }
`;

const StyledUploadHeaderWrap = styled.div`
    display:flex;
    flex-direction : row;
    align-items:center;
    justify-content:space-between;
    .uploadTypeName{
        display:inline-block;
        font-size:var(--dashboard-page-button);
        padding:1.5px;
        border-radius:5px;
        color:#000;
        transition: all .5s;
        cursor:pointer;
    }
    .uploadTypeName.on{
        font-weight:bold;
        background:#f14d5d;
        color:#fff;
    }
    > p {
        width:auto;
        display:flex;
        align-items:center;
        justify-content:center;
    }
`

const EntireContainer = styled.div`
    width:100%;height:auto;
    position:relative;
    @media screen and (min-width:1051px){
        height:auto;
    }
`;

const EntireWrapper = styled.div`
    width:85%;
    height:auto;
    margin:0 auto;
    @media screen and (max-width:1300px){
        width: 96.5%;
    }
    
`;

const EntireRow = styled.div`
    width:100%;
    margin:0 auto;
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:flex-start;
    @media screen and (max-width:1050px){
        flex-direction:column;
    }
`;

const UploadFileComponentContainer = styled.div`
    width:392px;height:auto;
    box-shadow: 0 0 15px rgb(0 0 0 / 6%);
    @media screen and (max-width:1050px){
        width:100%;
    }
`;

const UploadFileComponentWrapper = styled.div`
    width:100%;height:722px;
    padding:24px;
    .title{
        width:100%;
        h3{
            margin-bottom:10px;
            text-align:left;
            font-size: var(--dashboard-page-title);
            font-weight: bold;
            color: var(--titleColor);
        }
        margin-bottom:15.5px;
    }
    .content-wrap{
        
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
        align-items:center;
        .previewButton-input{display:none;}
        .label-button-upload-file{
            display:flex;
            justify-content:center;
            alignt-items:center;
            width:344px;height:344px;
            line-height:344px;
            background:#e3ded1;
            color:#454545;
            .label-text{
                text-align:center;
                font-size:var(--dashboard-page-button);
                .plus-icon{
                    font-size:42px;
                    text-align:center;
                }
            }
        }
        .upload-button-wrap{
            width: 344px;
            margin: 0;
            height: 120px;
            border-radius: 4.5px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .uploadTypeName{
                font-size:var(--dashboard-page-button);
                width:100%;
                cursor:pointer;
                height:48px;
                border-radius:4.5px;
                margin: 0;
                text-align: center;
                line-height: 48px;
                color: rgb(24,104,183);
                border: 1px solid rgb(24,104,183);
                &.on{
                    color:#fff;
                    background-color:rgb(24,104,183);
                    border:none;
                }
                label{
                    display:block;
                    width:100%;height:100%;
                }
            }
            border-radius:4.5px;
        }
    }
    .other-text{
        line-height:24px;
        font-size:var(--dashboard-page-info);
        text-align:center;
    }
    @media screen and (max-width:1050px){
        height:auto;
        .other-text{margin-top:10px;}
    }
    @media screen and (max-width:855px){
        .title{
            h3{
                font-size: var(--tablet-dashboard-page-title);
            }
            margin-bottom:15.5px;
        }
        .content-wrap{
            .label-button-upload-file{
                width:295px;height:295px;
                line-height:295px;
                .label-text{
                    text-align:center;
                    font-size:var(--tablet-dashboard-page-button);
                    .plus-icon{
                        font-size:25px;
                        text-align:center;
                    }
                }
            }
            .upload-button-wrap{
                width: 295px;height:auto;
                .uploadTypeName{
                    height:38px;
                    line-height:38px;
                    margin-top:10.5px;
                    font-size:var(--tablet-dashboard-page-button);
                }
                border-radius:4.5px;
            }
            .label-button-upload-file{
                width:250px;height:250px;
                line-height:250px;
            }
            .upload-button-wrap{
                width: 250px;
            }
        }
        .other-text{
            line-height:24px;
            font-size:var(--tablet-dashboard-page-info);
            text-align:center;
        }
    }

    @media screen and (max-width:578px){
        .title{
            h3{
                font-size: var(--mobile-dashboard-page-title);
            }
            margin-bottom:15.5px;
        }
        .content-wrap{
            .label-button-upload-file{
                width:295px;height:295px;
                line-height:295px;
                .label-text{
                    text-align:center;
                    font-size:var(--mobile-dashboard-page-button);
                    .plus-icon{
                        font-size:19.5px;
                        text-align:center;
                    }
                }
            }
            .upload-button-wrap{
                width: 295px;
                .uploadTypeName{
                    height:28px;
                    line-height:28px;
                    margin-top:8.5px;
                    font-size:var(--mobile-dashboard-page-button);
                }
                border-radius:4.5px;
            }
            .label-button-upload-file{
                width:210px;height:210px;
                line-height:210px;
            }
            .upload-button-wrap{
                width: 210px;
            }
        }
        .other-text{
            line-height:24px;
            font-size:var(--mobile-dashboard-page-info);
            text-align:center;
        }
    }
`;

/* const LoadingComponent = styled.div`
    position:absolute;
    top:0;left:0;
    width:100%;height:100%;
    display:flex;align-items:center;
    justify-content:center;
    z-index: 1000;
    background:rgba(45,45,45,0.7);
    img{
        display:block;
        border-radius: 100%;
        width: 15vh;
        height: 15vh;
    }
` */
const StyledH1 = styled.h1`
    width:100%;
    margin:25px 0;
    font-size: var(--page-title);
    font-weight:bold;
    @media screen and (max-width:855px){
        text-align:center;
        font-size: var(--tablet-page-title);
    }
    @media screen and (max-width:578px){
        font-size: var(--mobile-page-title);
    }
`
//const client = create('https://ipfs.infura.io:5001/api/v0');

const FormContainer = styled.div`
    width:auto;height:auto;
    @media screen and (max-width:1050px){
        width:100%;
    }
`;

const FormRow = styled.div`
    width:auto;height:auto;
    @media screen and (max-width:1050px){
        width:100%;
    }
`;

const StyledCol=styled.div`
    width:auto; height:auto;
    @media screen and (max-width:1050px){
        width:100%;
    }
`;

const checkButtonElement = (element, token) => {
    const flag = element.classList.contains(token) || element.tagName === token.toUpperCase();
    if (flag) {
        return element;
    } else {
        if (element.classList.contains('image_list')) {
            return null;
        } else {
            return checkButtonElement(element.parentElement, token);
        }
    }
}

function GalleryMinting({ currentUser }) {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const {state} = useLocation();
    const [author, setAuthor] = useState(`${state.userName}`);
    const [exhibition, setExhi] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const [mintMessage, setMintMessage] = useState('');
    const [mintProcessing, setMintProcessing] = useState(false);
    const [mintCompleted, setMintCompleted] = useState(false);
    const [mintError, setMintError] = useState(false);
    const [showImgPreview, setShowImgPreview] = useState(false);
    
    const history = useHistory();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [imgListDrag, setImgListDrag] = useState(false);
    const [imgListOffset, setImgListOffset] = useState(-1);
    const [uploadType, setUploadType] = useState(false);
    const imageListWrapRef = useRef(null);
    const imageListRef = useRef(null);
    const fileUploadLabelRef = useRef(null);
    const [category,setCategory] = useState(MINTING_GALLERY_CATEGORY[0]);

    const [showMessageComponent,setShowMessageComponent] = useState(false);
    const [loadingMessage,setLoadingMessage] = useState('');
    const [alertMessage,setAlertMessage] = useState('');

    const {
        uploadingImageFiles,
        uploadingImageFilesSuccess,
        uploadingImageFilesFailure
    } = useSelector(state => state.paintings);

    const dispatch = useDispatch();
    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        console.log(imageFile);
        setIsLoading(true);
        const data = {
            title,
            description,
            author,
            image: [...(imageFile)],
            exhibition,
            userid: currentUser.uid,
            cat:category,
        }
        dispatch({ type: UPLOADING_IMAGE_FILES_REQUEST, data });
        setLoadingMessage(GalleryProductUpload.uploadImagesMessage);
    }, [imageFile, title, description, author, exhibition,category]);

    useEffect(() => {
        if (!uploadingImageFiles) {
            if (uploadingImageFilesSuccess) {
                //history.push('/gallery');
                //dispatch({type:UPLOADING_DATA_REQUEST,data});
                setIsLoading(false);
                setAlertMessage(GalleryProductUpload.uploadImagesSuccessMessage);
                setShowMessageComponent(true);
                setTitle('')
                setDescription('')
                setShowImgPreview(false)
                setImagePreview(null)
                setImageFile(null)
               
                //dispatch({ type: UPLOADING_IMAGE_FILES_INIT });
            } else if (uploadingImageFilesFailure) {
                setIsLoading(false);
                setAlertMessage(uploadingImageFilesFailure);
                setShowMessageComponent(true);
                //dispatch({ type: UPLOADING_IMAGE_FILES_INIT });
            }
        }
    }, [uploadingImageFiles, uploadingImageFilesSuccess, uploadingImageFilesFailure]);

    const getWidthHeight = (url) => {
        return new Promise((resolve, reject) => {
            const tempImg = new Image();
            tempImg.src = url;
            tempImg.onload = () => {
                let standardHeight;
                if(window.innerWidth > 1340){
                    standardHeight=350;
                }else if(485<window.innerWidth&&window.innerWidth <= 1340){
                    standardHeight=250;
                }else if(485>=window.innerWidth){
                    standardHeight=150;
                }
                const ratio = tempImg.width / tempImg.height;
                resolve({ height: standardHeight, width: standardHeight * ratio });
            }
        })
    };

    const onDropFiles = async (dataTransfer) => {
        const items = dataTransfer.items;

        const fileArr = [];
        const traverseFileTree = (item) => {
            return new Promise((resolve, reject) => {
                const itemObj = item.webkitGetAsEntry ? item.webkitGetAsEntry() : item;
                if (itemObj.isFile) {
                    itemObj.file(file => {
                        if (file.type.match('image/*')) {
                            fileArr.push(file);
                            resolve(file);
                        } else {
                            resolve();
                        }
                    })
                } else if (itemObj.isDirectory) {
                    let entriesPromises = [];
                    let dirReader = itemObj.createReader();
                    dirReader.readEntries(entries => {
                        entries.forEach(async entry => {
                            try {
                                entriesPromises.push(traverseFileTree(entry));
                            } catch (err) {

                            }
                        })
                        resolve(Promise.all(entriesPromises));
                    })
                }
            })
        }
        await Promise.all(Array.prototype.map.call(items, (async (item, i) => {
            await traverseFileTree(item);
        })))
        return fileArr;
    }

    const handleImageSelect = useCallback(async (evt) => {
        let fileArr = null;
        if (evt.type === 'drop') {
            evt.stopPropagation();
            evt.preventDefault();
            if (fileUploadLabelRef.current) {
                fileUploadLabelRef.current.style.background = '#f8f8f8';
                fileUploadLabelRef.current.style.border = 'none';
            }
            fileArr = await onDropFiles(evt.dataTransfer);
        } else if (evt.type === 'change') {
            const { files } = evt.target;
            fileArr = Array.prototype.filter.call(files, (elem) => {
                if (elem.type.match('image/*')) {
                    return true;
                }
                return false;
            });
        }
        const newFileArr = await Promise.all(fileArr.map(ele => {
            return new Promise(resolve => {
                let fileReader = new FileReader();
                fileReader.readAsDataURL(ele);
                fileReader.onload = async (e) => {
                    const url = e.target.result;
                    const { width, height } = await getWidthHeight(url);
                    resolve({
                        url, width, height,
                    })
                }
            })
        }))

        if (!imagePreview || imagePreview.length === 0) {
            setImagePreview([...newFileArr]);
            setImageFile([...fileArr]);
        } else {
            setImagePreview(prev => [...prev, ...newFileArr]);
            setImageFile(prev => [...prev, ...fileArr]);
        }
        setShowImgPreview(true);
    }, [fileUploadLabelRef.current, imagePreview, imageFile]);

    const onMouseDownImgList = useCallback((evt) => {
        
        if (imageListRef.current && imageListWrapRef.current) {
            const imgListWidth = imageListRef.current.getBoundingClientRect().width;
            const imgListWrapWidth = imageListWrapRef.current.getBoundingClientRect().width;

            if (imgListWidth > imgListWrapWidth) {
                if (!imgListDrag) {
                    const imgListLeft = parseFloat(document.defaultView.getComputedStyle(imageListRef.current).left.split("px")[0]);
                    const imgListPadding = parseFloat(document.defaultView.getComputedStyle(imageListRef.current).padding.split("px")[0]);
                    if(evt.type==='mousedown'){
                        const { clientX: mouseClientX } = evt;
                        setImgListOffset(mouseClientX - imgListLeft - imgListPadding);
                    }else if(evt.type==='touchstart'){
                        const touchX = evt?.touches[0].pageX;
                        setImgListOffset(touchX - imgListLeft - imgListPadding);
                    }
                    setImgListDrag(true);
                }
            }
        }
    }, [imageListWrapRef.current, imageListRef.current, imgListDrag])

    const onMouseMoveImgList = useCallback((evt) => {
        if (imgListDrag) {
            const { clientX: mouseClientX } = evt;
            const imgListPadding = parseFloat(document.defaultView.getComputedStyle(imageListRef.current).padding.split("px")[0]);
            if(evt.type==='mousemove'){
                const newTopValue = mouseClientX - imgListOffset - imgListPadding;
                imageListRef.current.style.left = `${newTopValue}px`;
            }else if(evt.type==='touchmove'){
                const touchX = evt.changedTouches[0].pageX;
                const newTopValue = touchX - imgListOffset - imgListPadding;
                imageListRef.current.style.left = `${newTopValue}px`;
            }
        }
    }, [imageListRef.current, imgListDrag]);

    const onMouseUpImgList = useCallback((evt) => {
        if (imgListDrag) {
            setImgListDrag(false);
            setImgListOffset(-1);
        }
    }, [imgListDrag]);

    const onMouseLeaveImgList = useCallback(() => {
        setImgListDrag(false);
        setImgListOffset(-1);
    }, []);

    const onClickImgList = useCallback((evt) => {
        const closeButtonElement = checkButtonElement(evt.target, 'closeButton');
        if (closeButtonElement) {
            const index = parseInt(closeButtonElement.dataset.index);
            const newImgPreview = imagePreview;
            const newImgFile = imageFile
            newImgPreview.splice(index, 1);
            newImgFile.splice(index, 1);
            if (newImgPreview.length !== 0) {
                setImagePreview([...newImgPreview]);
                setImageFile([...newImgFile]);
            } else {
                setImagePreview([]);
                setImageFile(null);
            }
        }
    }, [imageFile, imagePreview]);

    useEffect(() => {
        if (imageListRef.current && imageListWrapRef.current) {
            const imgListWidth = imageListRef.current.getBoundingClientRect().width;
            const imgListWrapWidth = imageListWrapRef.current.getBoundingClientRect().width;
            if (imgListWidth < imgListWrapWidth) {
                imageListRef.current.style.left = '0px';
            }
            if (imagePreview.length === 0) {
                setShowImgPreview(false);
            }
        }
    }, [imageListRef.current, imageListWrapRef.current, imageFile, imagePreview])

    const onDragOver = useCallback((evt) => {
        evt.preventDefault();
        if (fileUploadLabelRef.current) {
            fileUploadLabelRef.current.style.background = '#616161';
            fileUploadLabelRef.current.style.border = '1px solid #454545';
        }

    }, [fileUploadLabelRef.current]);

    const onDragleave = useCallback((evt) => {
        evt.preventDefault();
        if (fileUploadLabelRef.current) {
            fileUploadLabelRef.current.style.background = '#f8f8f8';
            fileUploadLabelRef.current.style.border = 'none';
        }

    }, [fileUploadLabelRef.current]);

    const onToggleUploadType = useCallback((evt) => {
        const {target} = evt;
        const {filetype}= target.dataset;
        switch(filetype){
            case 'imageFile':{
                if(uploadType){
                    setUploadType(false);
                }
                break;
            }
            case 'folderFile':{
                if(!uploadType){
                    setUploadType(true);
                }
                break;
            }
        }
    }, [uploadType]);

    const cancelFunction = useCallback((evt)=>{
        if(uploadingImageFilesSuccess){
            //history.push('/gallery');
            setTitle('')
            setDescription('')
            setShowImgPreview(false)
            setImagePreview(null)
            setImageFile(null)
        }
        setShowMessageComponent(false);
        dispatch({ type: UPLOADING_IMAGE_FILES_INIT });
      
    },[uploadingImageFilesSuccess]);

    const onCategoryChange = useCallback((evt)=>{
        const {value} = evt.target;
        console.log(value);
        setCategory(value);
    },[])

    return (
        <>
        <Helmet>
            <link rel="icon" href={ColleryLogo3}></link>
        </Helmet>
            <Header />
            <EntireContainer >
                <EntireWrapper>
                    <StyledH1>Upload Gallery</StyledH1>
                    <EntireRow>
                        <UploadFileComponentContainer>
                            <UploadFileComponentWrapper>
                                <div className="title">
                                    <h3>작품 이미지 업로드</h3>
                                </div>
                                <div className="content-wrap">
                                    {!uploadType ?
                                        <input
                                            className="previewButton-input"
                                            multiple="multiple" type="file"
                                            name="attachments[]" accept="image/*"
                                            id="upload" onChange={handleImageSelect}
                                        />
                                        : <input
                                            className="previewButton-input"
                                            multiple="multiple" type="file"
                                            name="attachments[]" accept="image/*"
                                            id="upload_dir" onChange={handleImageSelect}
                                            webkitdirectory="webkitdirectory"
                                            mozdirectory="mozdirectory"
                                            msdirectory="msdirectory"
                                            odirectory="odirectory"
                                            directory="directory"
                                        />}

                                    <label ref={fileUploadLabelRef}
                                        onDragLeave={onDragleave}
                                        onDragOver={onDragOver}
                                        onDrop={handleImageSelect}
                                        className="label-button-upload-file"
                                        htmlFor={!uploadType ? 'upload' : 'upload_dir'}
                                    >
                                        <p className="label-text">
                                            <PlusOutlined className="plus-icon" />
                                            {!uploadType ? 'Upload image' : 'Upload Folder'}
                                        </p>
                                    </label>
                                    <span className="previewButton-file-name"></span>
                                    <div className="upload-button-wrap">
                                        <p className={`uploadTypeName ${!uploadType ? 'on' : ''}`} >
                                            <label htmlFor="upload" data-filetype="imageFile" onClick={onToggleUploadType}>
                                                파일 업로드
                                            </label>
                                        </p>
                                        <p className={`uploadTypeName ${uploadType ? 'on' : ''}`} >
                                            <label htmlFor="upload_dir" data-filetype="folderFile" onClick={onToggleUploadType}>
                                                폴더 업로드
                                            </label> 
                                        </p>
                                        {/* <Switch defaultChecked={false} onChange={onToggleUploadType}/> */}
                                    </div>
                                </div>
                                <div className="other-text">
                                    작품들을 전시할 수 있도록 갤러리에 공유합니다. 
                                    전시를 위한 작품을 업로드해주세요. 갤러리에 적합하지 않은
                                    이미지의 경우 관리자에 의해 삭제 될 수 있습니다.
                                </div>
                            </UploadFileComponentWrapper>
                        </UploadFileComponentContainer>

                        <FormContainer>
                            {
                                isLoading
                                &&
                                (<LoadingComponent message={loadingMessage}/>)
                            }
                            {
                                showMessageComponent&&
                                <MessageCommponent cancelFunction={cancelFunction} message={alertMessage} />
                            }

                            <FormRow>

                                <StyledCol>

                                    <div>

                                        <div>
                                            <StyledUploadImageFormatWrap>

                                                {
                                                    imagePreview &&
                                                    <StyledUploadedImageListWrap showImgPreview={showImgPreview} ref={imageListWrapRef}>
                                                        <StyledUploadedImageList
                                                            className="image_list"
                                                            onClick={onClickImgList}
                                                            onMouseDown={onMouseDownImgList}
                                                            onMouseUp={onMouseUpImgList}
                                                            onMouseMove={onMouseMoveImgList}
                                                            onMouseLeave={onMouseLeaveImgList}
                                                            onTouchStart={onMouseDownImgList}
                                                            onTouchMove={onMouseMoveImgList}
                                                            onTouchEnd={onMouseUpImgList}
                                                            ref={imageListRef}>
                                                            {
                                                                imagePreview.map((v, i) => {
                                                                    return (
                                                                        <StyledUploadedImageFrame key={`${i}_wrap`} width={v.width} height={v.height}>
                                                                            <StyledCloseButton data-index={i} className="closeButton">
                                                                                <CloseOutlined />
                                                                            </StyledCloseButton>
                                                                            <StyledUploadedImage key={`${i}_${i}`} width={v.width} height={v.height} src={v.url} />
                                                                        </StyledUploadedImageFrame>
                                                                    )
                                                                })
                                                            }
                                                        </StyledUploadedImageList>
                                                    </StyledUploadedImageListWrap>
                                                } < br />

                                            </StyledUploadImageFormatWrap>

                                            <StyledInfoForamtWrap className="col-lg-8 col-md-6">
                                                <div className="item-details-dsce collection-form">

                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row">

                                                            <div className="col-lg-12 ">
                                                                <div className="form-group">
                                                                    <label>제목</label>
                                                                    <input type="text" value={title} className="form-control" placeholder="작품제목" onChange={(e) => setTitle(e.target.value)} required />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                    <label>작품설명</label>
                                                                    {/* <input type="password" value={description} className="form-control" placeholder="Password" onChange={(e) => setDescription(e.target.value)} /> */}
                                                                    <textarea value={description} className="form-control" cols="30" rows="5" placeholder="작품설명을 해주세요" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>작가명</label>
                                                                    <input type="text" value={author} className="form-control" placeholder="작가명" onChange={(e) => setAuthor(e.target.value)} required />

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>카테고리</label>
                                                                    <select defaultValue={category} onChange={onCategoryChange} className="form-control" name='category'>
                                                                        {
                                                                            MINTING_GALLERY_CATEGORY.map((v,i)=>(
                                                                                <option key={`${v}_${i}`} value={v}>
                                                                                    {v}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>




                                                            {imagePreview && !mintProcessing && !mintCompleted && (<div className="col-lg-12 col-md-12 pt-50">
                                                                <div className="text-center">
                                                                    <button style={{borderRadius:'4.5px',background:'#ff4d2c',height:'50px'}} type="submit" className="default-btn" >Create Gallery</button>
                                                                </div>
                                                            </div>)}

                                                            <div className="col-lg-12 text-center">
                                                                {mintProcessing && (<>
                                                                    <Loader size={10} />
                                                                    <br />
                                                                    <h5 className="text-warning">{mintMessage}</h5>
                                                                </>)}
                                                            </div>

                                                            <div className="col-lg-12 text-center pt-50">
                                                                {mintError && (
                                                                    <div className="involved-card">
                                                                        <div className="icon">
                                                                            <i className="ri-close-circle-line text-danger"></i>
                                                                        </div>
                                                                        <h3 className="text-danger">{mintMessage}</h3>
                                                                    </div>
                                                                )}
                                                                {mintCompleted && (
                                                                    <div className="involved-card">
                                                                        <div className="icon">
                                                                            <i className="ri-checkbox-circle-line text-success"></i>
                                                                        </div>
                                                                        <h3 className="text-success">Done</h3>
                                                                    </div>
                                                                )}
                                                            </div>


                                                        </div>
                                                    </form>

                                                </div>
                                            </StyledInfoForamtWrap>

                                        </div>
                                    </div>


                                </StyledCol>
                            </FormRow>
                        </FormContainer>
                    </EntireRow>
                </EntireWrapper>
            </EntireContainer>

            <div>


                {/*<Modal 
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"

                
            >
                <Modal.Header>
                <Modal.Title className="text-sm">Minting your NFT ...</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <Loader size={10} />
                
                </Modal.Body>
                
                <a className="btn btn-light btn-sm text-center text-green-500" onClick={handleClose}>
                    Run in background
                </a>
            
            
                </Modal>*/}

            </div>


            <Footer />
        </>
    )
}

export default GalleryMinting;

