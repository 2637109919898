import React from 'react'
import {AiOutlineMenu} from 'react-icons/ai';
import {HiChevronRight,HiChevronLeft} from 'react-icons/hi';
import logo from '../assets/newhomepage/logo.png';
import arrowdown from '../assets/newhomepage/22.png';
import hero from '../assets/newhomepage/16.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroSlider from './HeroSlider';
AOS.init();

function Hero() {
  return (
    <div className="-mt-[5rem]" styles={{zIndex:'1'}}>


        <div className='herobg h-[22rem] sm:h-[40rem] pt-[9rem] sm:pt-[16rem]' id="top">

           

            <div className="sm:mt-10 -mt-4"> {/**flex sm:flex-col lg:items-center sm:justify-center lg:flex-row */}

                <div className="pt-4 sm:pl-20 sm:pr-20 pl-6 pr-6 " >
                    <div className="flex text-2xl sm:text-4xl xl:text-6xl"><p className="font-bold text-orange-700">CO</p><p className="text-white font-bold">LLABO GA</p><p className="font-bold text-orange-700">LLERY</p></div>
                    <div className="bg-white h-[2px] w-[20px] mt-3 sm:mt-5 ml-[3px] sm:ml-3"></div>
                    <div className="text-white text-[10px] ml-[3px] mt-[2px] sm:ml-3 sm:text-[19px] ">
                        <p className="text-white mt-3">Create and Make your</p>
                        <p className="text-white">dreams with AI</p>
                        <p className="mt-[3px] text-white">AI와 함께하는 갤러리</p>
                    </div>

                    <div className="flex sm:mt-5 sm:ml-3">
                        <div className="bg-white h-[1px] w-[20px] mt-3 ml-[3px]"></div>
                        <div className="bg-gray-600 h-[1px] w-[74px] mt-3"></div>
                    </div>
                    
                    <div className="text-gray-600 flex items-center ml-[48px] mt-[6px] sm:mt-4">
                        <p><HiChevronLeft/></p>
                        <p className="text-[9px]">|</p>
                        <p><HiChevronRight/></p>
                    </div>

                </div>

                {/*<div className="sm:ml-[1rem] mt-10 hidden lg:block sm:-mt-[245px] mr-10">
                    <HeroSlider />
                </div>*/}

            </div>

         

            <div className="text-white flex items-center justify-center mt-2 sm:mt-20 md:mt-14 cursor-pointer mb-10 ">
                <a href="#aipics" className="cursor-pointer"><img src={arrowdown} className="h-[50px] w-[40px] sm:h-[80px] sm:w-[55px] animate-bounce" /></a>
            </div>
       
        </div>

    </div>
  )
}

export default Hero