import React, { memo } from "react";
import styled from "styled-components";

const EntireWrapper = styled.div`
    width:74px;
    height:auto;
    box-sizing:border-box;
    padding:1.5px;
    .date-type{
        width:100%;
        height:13.5px;
        font-size:10.5px;
        line-height:13.5px;
        text-align:center;
        font-weight:bold;
    }
    .showNumber-wrap{
        width:100%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-evenly;
        
        .showNumberComponent{
            height:32.5px;
            width:32.5px;
            text-align:center;
            line-height:32.5px;
            font-weight:bold;
            font-size:15.5px;
            background: #000;
            color: #fff;
            border-radius: 4.5px;
            margin-left: 3.5px;
        }
    }
`;

const ShowSingleTimeComponent = memo(({ dateVal, dateType }) => {
    return (
        <EntireWrapper>
            <div className="date-type">
                {dateType}
            </div>
            {
                dateType === "DAY" ?
                    (
                        <div className="showNumber-wrap">
                            <div className="showNumberComponent">
                                {dateVal[0]}
                            </div>
                            <div className="showNumberComponent">
                                {dateVal[1]}
                            </div>
                            <div className="showNumberComponent">
                                {dateVal[2]}
                            </div>
                        </div>
                    )
                    : (
                        <div className="showNumber-wrap">
                            <div className="showNumberComponent">
                                {dateVal[0]}
                            </div>
                            <div className="showNumberComponent">
                                {dateVal[1]}
                            </div>
                            &nbsp;{dateType !== 'SEC' && ':'}
                        </div>
                    )
            }

        </EntireWrapper>
    )
})

export default ShowSingleTimeComponent;