import React from 'react';
import {CgArrowUpR} from 'react-icons/cg';
import {FaLinkedinIn,FaTwitter,FaYoutube,FaBlogger} from 'react-icons/fa';

export default function Footer() {
  return (
    <div className="bg-black ">

        <div className="flex items-center p-4 justify-between sm:mt-10 sm:pt-8 sm:pr-14 sm:pl-14">

            <p className="text-lg text-gray-300 font-bold sm:text-3xl">COLLERY</p>

           {/* <div className="text-gray-500">
                <a href="#top" className="cursor-pointer"><CgArrowUpR className="text-gray-500 text-3xl sm:text-4xl animate-bounce"/></a>
            </div>*/}

        </div>

        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-2 ml-2 mr-2 md:pr-14 md:pl-11 cursor-pointer">

            <div className="pl-2 leading-normal">

                <div className="text-gray-300 text-[10px] flex items-center leading-normal"><p className="h-3 w-3 bg-white rounded-full mr-2"></p><p className="font-bold md:text-[15px] text-white">(주) 내스타일 </p></div>

                <div className=" text-[10px] flex md:items-center mt-3 ml-5 flex-col md:flex-row"><p className="text-gray-300 md:w-[100px] md:text-[12px] ">대표</p><p className="text-gray-500 md:text-[12px] mb-2 md:mb-0">이용균</p></div>
                <div className=" text-[10px] flex md:items-center ml-5 flex-col md:flex-row"><p className="text-gray-300  md:w-[100px] md:text-[12px]">사업자 등록 번호</p><p className="text-gray-500 md:text-[12px] mb-2 md:mb-0">382-81-01860</p></div>
                <div className=" text-[10px] flex md:items-center ml-5 flex-col md:flex-row"><p className="text-gray-300 md:w-[100px] md:text-[12px]">통신판매업신고</p><p className="text-gray-500 md:text-[12px] mb-2 md:mb-0">제 2022-서울서초-1973호</p></div>
                <div className=" text-[10px] flex md:items-center ml-5 flex-col md:flex-row"><p className="text-gray-300  md:w-[100px] md:text-[12px]">주소</p><p className="text-gray-500 md:text-[11px] mb-2 md:mb-0">서울특별시 서초구 매헌로 16, 12층 1호(양재동, 하이브랜드)</p></div>
                <div className=" text-[10px] flex md:items-center ml-5 flex-col md:flex-row"><p className="text-gray-300  md:w-[100px] md:text-[12px]">이메일</p><p className="text-gray-500 md:text-[12px] md:mb-0">contact@nestyle.ai</p></div>

            </div>

            <div className="pl-2">

                <div className="text-gray-300 text-[10px] flex items-center"><p className="h-3 w-3 border rounded-full mr-2 text-white" style={{border: "1px solid #EDEDED"}}></p><p className="font-bold md:text-[15px] text-white" >HOME</p></div>
                <div className="ml-5">
                    <p className="font-bold text-[9px] mt-2 text-gray-600 sm:text-[12px]">HOME</p>
                    
                </div>
                
            </div>

            <div className="pl-2">

                <div className="text-gray-300 text-[10px] flex items-center"><p className="h-3 w-3 border rounded-full mr-2 text-white" style={{border: "1px solid #EDEDED"}}></p><p className="font-bold sm:text-[15px] text-white">GALLERY</p></div>
                <div className="ml-5 leading-normal">
                    <p className="font-bold text-[9px] mt-2 text-gray-600 sm:text-[12px]">RECENT</p>
                    <p className="font-bold text-[9px] text-gray-600 sm:text-[12px]">PAINTING</p>
                    <p className="font-bold text-[9px] text-gray-600 sm:text-[12px]">ILLUSTRATION</p>
                    <p className="font-bold text-[9px] text-gray-600 sm:text-[12px]">PICTURE</p>
                    <p className="font-bold text-[9px] text-gray-600 sm:text-[12px]">DESIGN</p>
                    <p className="font-bold text-[9px] text-gray-600 sm:text-[12px]">CHARACTER</p>
                    <p className="font-bold text-[9px]  text-gray-600 sm:text-[12px]">OTHER</p>

                    
                </div>
                
            </div>

            <div className="pl-2">

                <div className="text-gray-300 text-[10px] flex items-center"><p className="h-3 w-3 border rounded-full mr-2 text-white" style={{border: "1px solid #EDEDED"}}></p><p className="font-bold sm:text-[15px] text-white">NFTS</p></div>
                <div className="ml-5 leading-normal">
                    <p className="font-bold text-[9px] sm:text-[12px] mt-2 text-gray-600">CATEGORY</p>
                    <p className="font-bold text-[9px] sm:text-[12px] text-gray-600">NFT-TYPE</p>
                    <p className="font-bold text-[9px] sm:text-[12px] text-gray-600">PRICE-ORDER</p>
                
                    
                </div>
                
            </div>

          

        </div>

        <div className="flex justify-end sm:mr-10 mr-4">

            <div className="text-black text-sm sm:text-lg p-2 bg-white  rounded-md cursor-pointer mr-2 hover:bg-gray-200"><FaLinkedinIn /></div>
            <div className="text-black text-sm sm:text-lg p-2 bg-white  rounded-md cursor-pointer mr-2 hover:bg-gray-200"><FaTwitter /></div>
            <div className="text-black text-sm sm:text-lg p-2 bg-white  rounded-md cursor-pointer mr-2 hover:bg-gray-200"><FaBlogger /></div>
            <div className="text-black text-sm sm:text-lg p-2 bg-white  rounded-md cursor-pointer mr-2 hover:bg-gray-200"><FaYoutube /></div>
            
        </div>

        <hr className="text-gray-500 border-y-[1] divide-y border-gray-800 mt-4"></hr>


        <div className="flex flex-col pl-4 pb-4 mt-4 sm:flex-row sm:justify-between sm:pr-10 sm:pl-14 cursor-pointer">

            <p className="text-[8px] text-gray-300 font-bold sm:text-[11px]">COPYRIGHT 2022 COLLERY ALL RIGHT RESERVED</p>
            <div className="text-gray-500 text-[9px] sm:text-[12px] mt-1 ">
               <p>개인정보처리방침이용약관</p>
            </div>

        </div>

        
    </div>
  )
}
