/* Gallery 페이지 : https://www.collery.io/gallery*/
export const GalleryIndexLoading={
    message:"Gallery 작품 데이터들을 불러오고 있습니다",
    loadErrorMessage:"Gallery 작품 데이터 로드 오류(데이터 전송 오류)",
    serverErrorMessage:"Gallery 작품 데이터 로드 오류(서버 점검 중)",
    noMoreDataMessage:"모든 Gallery 작품 데이터를 로드하였습니다.",
    errorloadUsersDataMessage:"작가들 작품 로드 오류(데이터 전송 오류)",
    serverloadUsersDataErrorMessage:"작가들 작품 로드 오류(서버 점검 중)"
};

/* Gallery 작품 상세 페이지 : https://www.collery.io/product/{product-id}*/
export const GalleryProductDetail = {
    message:"작품의 데이터를 불러오고 있습니다.",
    loadErrorMessage:"작품 데이터 로드 오류(데이터 전송 오류)",
    serverErrorMessage:"작품 데이터 로드 오류(서버 점검 중)"
};

/* NFT Marketplace : https://www.collery.io/marketplace */
export const NFTMarketPlaceLoading ={
    message: "NFT 작품 데이터를 불러오고 있습니다.",
    loadErrorMessage:"NFT 작품 데이터 로드 오류(데이터 전송 오류)",
    serverErrorMessage:"NFT 작품 데이터 로드 오류(서버 점검 중)",
}

/* NFT 상세 페이지 : https://www.collery.io/nft/{nft-product-id} */
export const NFTProductDetail = {
    message:"NFT 작품의 데이터를 불러오고 있습니다.",
    loadErrorMessage:"NFT 작품 데이터 로드 오류(데이터 전송 오류)",
    serverErrorMessage:"NFT 작품의 데이터 로드 오류(서버 점검 중)"
};

/* Gallery Upload 페이지 : https://www.collery.io/user/collection/create */
export const GalleryProductUpload = {
    uploadImagesMessage:"Gallery 작품 데이터를 업로드 하고 있습니다.",
    uploadImagesSuccessMessage:"Gallery 작품 데이터 업로드를 완료하였습니다.",
    uploadImagesErrorMessage:"Gallery 작품 데이터 업로드 오류(데이터 전송 오류)",
    uploadImagesserverErrorMessage:"Gallery 작품 데이터 업로드 오류(서버 점검 중)",
};

/* NFT 작품 업로드 페이지 : https://www.collery.io/user/nft/mintimage & https://www.collery.io/user/nft/mintaudio */
export const NFTProductMinting = {
    uploadmessage:"NFT 발행을 하고 있습니다.",
    uploadSuccessMessage:"NFT 발행이 완료되었습니다.",
    uploadErrorMessage:"NFT 발행 오류(데이터 전송 오류)",
    uploadserverErrorMessage:"NFT 발행 오류(서버 점검 중)",
};

/* 인증서 페이지 https://www.collery.io/certifypage */
export const CertifyPageLoading = {
    message:`나의 Gallery 작품 데이터들을 불러오고 있습니다`,
    loadErrorMessage:`Gallery 작품 데이터 로드 오류(데이터 전송 오류)`,
    serverErrorMessage:`Gallery 작품 데이터 로드 오류(서버 점검 중)`,
    noMoreDataMessage:`나의 모든 Gallery 작품 데이터들을 로드하였습니다.`,
    publishingMessage:'선택한 Gallery 작품의 인증서를 발급 중입니다',
    publishingLoadErrorMessage:'인증서 발급 요청 오류(데이터 전송 오류)',
    publishingLoadserverErrorMessage:'인증서 발급 요청 오류(서버 점검중)',
    publichingSuccess:'인증서 발급이 완료되었습니다.',
    didNotSelectProductMessage:'인증서를 발급할 작품 이미지를 선택 해주세요.',
};

/* 프로필 수정 & 포트폴리오 수정 페이지 : https://www.collery.io/user/profile */
export const UserProfileModifyPage = {
    message:`나의 Gallery 작품 데이터들을 불러오고 있습니다`,
    loadErrorMessage:`Gallery 작품 데이터를 로드 오류(데이터 전송 오류)`,
    serverErrorMessage:`Gallery 작품 데이터를 로드 오류(서버 점검 중)`,
    noMoreDataMessage:`나의 모든 Gallery 작품 데이터들을 로드하였습니다.`,
    modifingUserInfoMessage:'사용자 정보를 수정 중입니다.',
    serverErrorModifingUserInfoMessage:'사용자 정보 수정 오류(데이터 전송 오류)',
    modifingUserInfoSuccessMessage:'정보 수정이 완료되었습니다.',
    ErrorModifingUserInfoMessage:'사용자 정보 수정 오류(서버 점검중)',
    modifingUserProfileMessage:'프로필 사진을 업로드 중입니다.',
    ErrorModifingUserInfoMessage:'프로필 사진 업로드 오류(데이터 전송 오류)',
    SuccessModifyUserProfileImageMessage:'프로필 사진 업로드가 완료되었습니다.',
    serverErrorModifingUserInfoMessage:'프로필 사진 업로드 오류(서버 점검중)',
    deletingGalleryProductMessage:'선택한 작품들을 삭제하고 있습니다',
    errorDeletingGalleryProductMessage:'작품 삭제 오류(데이터 전송 오류)',
    serverErrorDeletingGalleryProductMessage:'작품 삭제 오류(서버 점검중)',
    setBankAccountMessage:'계좌 번호를 입력 해주세요!',
    setBankAccountHolderMessage:'예금주 이름을 입력 해주세요!',
}

/* 나의 NFT 페이지 : https://www.collery.io/user/nft */
export const UserNFTPage = {
    message:'나의 NFT 데이터들을 로드하는 중 입니다',
    errorLoadingMyNFT:'나의 NFT 데이터 로드 오류(데이터 전송 오류)',
    serverErrorLoadingMyNFT:'나의 NFT 데이터 로드 오류(서버 점검중)',
    changingNFTPriceMessage:'선택한 NFT 의 가격을 변경하고 있습니다.',
    resellingNFTPriceMessage:'선택한 NFT를 재발매하고 있습니다.',
    successChangingNFTPriceMessage:'NFT 판매가격 변경을 완료하였습니다.',
    successResellingNFTPriceMessage:'NFT 재판매설정이 완료되었습니다',
    errorModifyNFTInfo:'NFT 정보 변경 오류(데이터 전송 오류)',
    serverErrorModifyNFTInfo:'NFT 정보 변경 오류(서버 점검중)',
};

/* 회원가입 페이지 : https://www.collery.io/user/signup */
export const SignupPage={
    message:'회원정보를 업로드 중입니다',
    errorUploadingMessage:'회원정보를 업로드 오류(데이터 전송 오류)',
    serverErrorUploadingMessage:'회원정보를 업로드 오류(서버 점검중)',
    successMessage:'회원가입이 완료되었습니다.',
    passwordNotMatch:'비밀번호가 서로 일치하지 않습니다.',
    didNotUploadProfileImg:'프로필 사진을 업로드 해주세요.',
    didNotWriteEmail:'이메일 주소를 입력 해주세요.',
    didNotWriteFirstName:'이름(Last Name)을 입력 해주세요.',
    didNotWriteLastName:'성(First Name)을 입력 해주세요.',
}

/* 로그인 페이지 : https://www.collery.io/user/login */
export const LoginPage={
    message:'로그인 중입니다.',
    errorUploadingMessage:'로그인 오류(데이터 전송 오류)',
    serverErrorUploadingMessage:'로그인 오류(서버 점검중)',
    successMessage:'로그인을 성공했습니다!',
    errorEmailNotFound:'입력하신 email에 해당되는 계정이 존재하지 않습니다',
    errorInvalidPassword:'비밀번호가 틀렸습니다. 다시 입력 바랍니다',
    errorObjectNotExist:'계정이 존재하지 않습니다.'
}

export const SignUpCertificateMessage={
    agreePersonalInfoMessage:'개인정보 수집 및 이용에 동의해 주세요.',
    agreeUseOfServiceMessage:'이용약관에 동의해 주세요.'
}