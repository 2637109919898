import React from 'react';
import pic1 from '../../assets/top2/painting.png';
import pic2 from '../../assets/top2/cate.png';
import pic3 from '../../assets/top2/person.png';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

const LinkButton = styled.div`
    display:block;
    width:256px;
    height:48px;
    color:#fff;
    background:#ff4d2c;
    box-sizing:border-box;
    text-align:center;
    line-height:48px;
    border-radius:4.5px;
    margin:0 auto;
    &:hover{
      color:#ff4d2c;
      background:#fff;
      border:1px solid #ff4d2c;
    }
    @media screen and (max-width:1265px){
      height:38px;
      line-height:38px;
    }
    @media screen and (max-width:1015px){
      width:156px;
    }
`;

const Section2Container = styled.div`
    width:100%;height:auto;
    margin-top:35.5px;
    h2{
      width:100%;height:58px;
      font-size:53px;
      font-weight:bold;
      line-height:53px;
      text-align:center;
      margin:15.5px 0;
    }
    .explain-text{
      margin-bottom:10px;
      font-size:18.5px;
      color:#000;
      text-align:center;
      font-weight:650;
    }
    @media screen and (max-width:1265px){
      h2{
        height:48px;
        line-height:48px;
        font-size:var(--tablet-page-title);
      }
      .explain-text{
        font-size: var(--tablet-service-explain-text);
      }
    }
    @media screen and (max-width:1015px){
      h2{
        height:38px;
        line-height:38px;

      }
    }
    @media screen and (max-width:578px){
      margin-top:15.5px;
      h2{
        height:42px;
        line-height:42px;
        font-size: var(--mobile-page-title);
      }
      .explain-text{
        width:95%;
        margin:0 auto;
        line-height:var(--mobile-line-height-exp);
        font-size: var(--mobile-service-explain-text);
      }
    }
`;

const Section2Wrapper = styled.div`
    width:auto;
    margin:0 auto;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-evenly;
    grid-template-columns:repeat(3,1fr);
    .grid-col{
      margin:15.5px 0;
      width:392px;height:auto;
      a{
        display:block;
        width:100%;height:100%;
        .img-wrap{
          width:100%;
          height:473px;
          img{
            display:block;
            width:100%;height:100%;
            object-fit:cover;
          }
        }
        .linkbutton-wrap{
          display:block;
          margin-top: 10px;
          width:100%;
          height:50px;
          box-sizing:border-box;
          padding:1px 0;
          font-size:var(--list-subtitle);
        }
      }
    }
  @media screen and (max-width:1265px){
    .grid-col{
      width:309.59px;height:413px;
      a{
        .img-wrap{
          width:100%;
          height:373px;
        }
        .linkbutton-wrap{
          font-size:var(--tablet-list-subtitle);
          height:40px;
        }
      }
    }
  }
  @media screen and (max-width:1015px){
    .grid-col{
      width:226.59px;height:313px;
      a{
        .img-wrap{
          width:100%;
          height:273px;
        }
        .linkbutton-wrap{
          height:40px;
        }
      }
    }
  }
  @media screen and (max-width:720px){
    flex-direction:column;
    row-gap: 37.5px;
    .grid-col{
      margin-bottom:35.5px;
      width: 426.59px;
      height: 513px;
      a{
        .img-wrap{
          margin:0 auto;
          width:392px;
          height:473px;
        }
        .linkbutton-wrap{
          height:40px;
        }
      }
    }
  }
  @media screen and (max-width:450px){
    flex-direction:column;
    
    .grid-col{
      margin-bottom:20px;
      width:426.59px;height:413px;
      a{
        .img-wrap{
          margin:0 auto;
          width:309.59px;
          height:373px;
        }
        .linkbutton-wrap{
          height:40px;
          font-size:var(--mobile-list-subtitle);
        }
      }
    }
  }
`

export default function Section2() {

  return (

    <Section2Container>

      <h2 >AI Art 지원 도구</h2> < br />

      <p className="explain-text" style={{textAlign:'center'}}>인공지능 기술로 만들어진 Art 지원도구들이 준비되어 있습니다. AI 전문가의 도움으로 작품을 만들어보세요. </p>
      <p className="explain-text" style={{textAlign:'center'}}>만들어진 결과물에 대한 저작권은 모두 작가님들에게 있습니다.</p> < br />


      <Section2Wrapper>

        <div className="grid-col">
          <a href='https://painting.aizac.io/' target="_blank" >
            <div className="img-wrap">
              <img src={pic1} />
            </div>
            <div className='linkbutton-wrap'>
              <LinkButton type="button">AI Painting​</LinkButton>
            </div>
          </a>
        </div>
        <div className="grid-col">
          <a href='https://novel.aizac.io/' target="_blank" >
            <div className="img-wrap">
              <img src={pic2} width="" alt="" />
            </div>
            <div className='linkbutton-wrap'>
              <LinkButton type="button">AI 웹소설​​</LinkButton>
            </div>
          </a>
        </div>

        <div class="grid-col">
          <Link to='#'>
            <div className="img-wrap">
              <img src={pic3} width="" alt="" />
            </div>
            <div className='linkbutton-wrap'>              
                <LinkButton type="button">
                  AI 캐릭터 (Comming Soon)
                </LinkButton>
              </div>
          </Link>
        </div>

      </Section2Wrapper>


    </Section2Container>
  )

}