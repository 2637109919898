import React from 'react';
import lend from '../assets/newhomepage/19.png';
import borders from '../assets/newhomepage/20.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export default function SectionLend() {
  return (
    <div className="mt-6 lg:mt-20">
        <div className="containerstack container">

            <div className="innerstack"><img src={lend} className="" /></div>
            <div className="innerstack m-4"><img src={borders} className="" /></div>
            <div className="innerstack">
                <div className="mt-8">
                    <p className="text-white font-bold text-[12px] items-center justify-center lg:text-4xl sm:text-2xl">"WE LEND YOU VIRTUAL EXHIBITION SPACE."</p>
                    <p className="flex items-center justify-center text-[9px] text-gray-300 mt-2 lg:text-xl lg:mt-4 sm:text-md sm:mt-2">가상의 전시 공간을 빌려드립니다.</p>
                    <div className="flex items-center justify-center mt-4 lg:mt-10"><p className="text-white text-[6px] mt-1 lg:text-lg">Discover</p><p className="text-gray-500 text-[30px] lg:text-[40px]">+</p></div>
                </div>
               
            </div>

        </div>
    </div>
  )
}
