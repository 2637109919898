import React, { useCallback, useEffect, useState, useRef } from 'react';
import { QueryObserver, QueryClient } from 'react-query';
import axios from 'axios';
import Footer from '../Footer';
import Header from '../Header';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import './style.css';

import Loader from '../Loader';
import Gallerycard from '../GalleryCard';
import RM from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {
    LOADING_GALLERY_DATA, INIT_PAINTINGS_STATE, GET_COUNT_GALLERY_INIT,
    GET_COUNT_GALLERY_REQUEST
} from '../../reducers/paintings';
import { LOAD_USER_PROFILE_IMG_REQUEST, LOAD_USER_PROFILE_IMG_INIT } from '../../reducers/users';
import AuthorList from './AuthorList';
import { Button } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import SingleThumbNailImage from './SingleThumbNailImage';
import LoadingComponent from '../LoadingComponent';
import MessageCommponent from '../MessageCommponent'
import {GalleryIndexLoading} from '../../constants/applicationMessage';
import { isMobile } from 'react-device-detect';
import {MINTING_GALLERY_CATEGORY} from '../../constants/application'
import Helmet  from 'react-helmet';
import Collery_logo_03 from './../../assets/Collary_logo_03.jpg';
import { GALLERY_API_TYPE } from "../../constants/application";
import NoDataExistsComponent from '../NoDataExistsComponent';

const GalleryContainer = styled.div`
    width:100%;
    height:auto;
    min-height:${window.innerHeight}px;
`;

const GalleryWrapper = styled.div`
    width:100%;
    margin:0 auto;
    margin-top:25px;
    h1{ 
        font-size: var(--page-title);margin-bottom:15.5px;
        height:95px;
        font-weight:bold;
    }
    @media screen and (max-width:980px){
        width:96.5%;
        margin-top:15.5px;
    }
    @media screen and (max-width:855px){
        h1{
            font-size: var(--tablet-page-title);
            height:65px;
        }
    }
    @media screen and (max-width:585px){
        h1{
            font-size: var(--mobile-page-title);
            height:45px;
        }
    }
`

const StyledShowMoreButtonWrap = styled.div`
    width : 100%;
    height : 105.5px;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
        display:block;
        width:60%;
        height:50%;
    }
`;

const StyledShowMoreButton = styled.button`
    display:block;
    width:200px;
    height:48px;
    border:1px solid #313131;
    border-radius: 9.5px;
    color:white;
    font-size:var(--list-subtitle);
    padding:0;
    background: #fff;
    color:#313131;
    &:active{
        background:#313131;
        color:#fff;
    }
    &:hover{
        background:#313131;
        color:#fff;
    }
    @media screen and (max-width:855px){
        width: 150px;
        height: 28px;
        font-size:var(--tablet-list-subtitle);
    }
    @media screen and (max-width:578px){
        font-size:var(--mobile-list-subtitle);
    }
`

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 10000,
        backgroundColor: 'white',
        borderRadius: '25px',
    },

    overlay: {
        backgroundColor: 'rgba(0,0,0,0.2)',
    }
};

const StyledProductWrap = styled.div`
    display:grid;
    grid-template-columns:repeat(4,minmax(0,1fr));
    grid-column-gap:12px;
    grid-row-gap:12px;
    @media screen and (max-width:1400px){
        grid-template-columns:repeat(3,minmax(0,1fr));
        grid-column-gap:12px;
        grid-row-gap:12px;
    }
    @media screen and (max-width:980px){
        grid-template-columns:repeat(2,minmax(0,1fr));
        grid-column-gap:12px;
        grid-row-gap:12px;
    }
    @media screen and (max-width:470px){
        grid-template-columns:repeat(1,minmax(0,1fr));
        grid-column-gap:12px;
        grid-row-gap:12px;
    }
`;

const StyledProductColumn = styled.div`
    width:auto;height:auto;
`;

const StyledProfileLinkButton = styled(Button)`
    width:90%;height:45px;
    padding:0;
    background:#f14d5d;color:#fff;
    border:0; border-radius:12.5px;
`;

const StyledProductLink = styled(Link)`
    display:block;
    grid-row:auto/auto;
    width:100%;height:auto;
    position:relative;
    transition:all 0.6s;
    &:hover{
        .content-wrap{
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:space-between;
        }
    }
    .content-wrap{
        padding:10px;
        box-sizing:border-box;
        position:absolute;
        top:0;left:0;
        width:100%;height:100%;
        display:none;
        background-color:rgba(45,45,45,0.65);
        .content-info{
            width:100%;
            h3{color:#fff;font-size:var(--list-title);}
            p{color:#fff;font-size:var(--list-subtitle);}
            color:#fff;
            display:flex;
            justify-content:space-between;
            .profile-component{
                font-size:17.5px;
                width:45.5%;
                .profile-picture-wrap{
                    width:41.5px;height:41.5px;
                    border-radius:100%;
                    overflow:hidden;
                    margin:0;
                    img{
                        display:block;
                        width:100%;
                        height:100%;
                        object-fit:cover;
                    }
                }
            }
            .profile-link-wrap{
                width:45.5%;
                height:auto;
                display:flex;
                justify-content:center;
                align-items:center;
            }
        }
    }

    @media screen and (max-width:855px){
        .content-wrap{
            .content-info{
                h3{
                    font-size:var(--tablet-list-title);
                }
                .profile-component{
                    p{
                        font-size:var(--tablet-list-subtitle);
                    }
                }
            }
        }
    }

    @media screen and (max-width:578px){
        .content-wrap{
            .content-info{
                h3{
                    font-size:var(--mobile-list-title);
                }
                .profile-component{
                    p{
                        font-size:var(--mobile-list-subtitle);
                    }
                }
            }
        }
    }
`

const StyledIndividualProduct = styled.div`
    width:100%;
    height:auto;
    margin-top:12px;
`;
const StyledIndividualProductImgWrap = styled.div`
    width:100%;
    height:auto;
`;

const AuthorProfileContainer = styled.div`
    width:60%;height:350px;
    margin:0 auto;
    display:flex;
    box-sizing:border-box;
    padding:0 10px;
    flex-direction:row;
    align-items:center;
    justify-content:space-evenly;
    border-radius:12.5px;
    box-shadow:0 0 2.5px #454545;
    margin-bottom:30px;
    @media screen and (max-width:980px){
        width:100%;
        flex-direction:column;
        height:550px;
    }
`

const AuthorProfileWrap = styled.div`
    width:256px;height:256px;
    overflow:hidden;
    img{
        display:block;
        border-radius:100%;
        width:100%;height:100%;
        object-fit:cover;
    }
`;

const PTagPropertyInAuthorInfo = styled.p`
    margin:0;
    width:100%;
    height:auto;
    text-align:center;
`;

const AuthorProfileInfoWrap = styled.div`
    width : calc(100% - 256px);
    height: 100%;
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:flex-start;
    padding:40px 6px;
    box-sizing:border-box;
    .text-info{
        width:256px;
        height:184px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        p{
            margin:0;
            width:100%;
            height:auto;
            text-align:center;
            &.author-name{
                height:29px;
                line-height:29px;
                font-size:20px;
                font-weight:bold;
            }
            &.email{
                height:24px;
                line-height:24px;
                font-size:16px;
                color:#7e7e7e;
            }
            &.userId{
                height:24px;
                line-height:24px;
                font-size:16px;
                color:#ff4d2c;
            }
        }
    }
    .other-info{
        width:216px;
        height:184px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        p{
            margin:0;
            width:100%;
            height:auto;
            text-align:center;
            &.follower{
                width: 218px;
                height: 66px;
                font-size:19.5px;
                font-weight:bold;
                line-height:66px;
            }
            &.nft-link-button-wrap{
                width:100%;
                height:50px;
                padding:1px 0px;
                box-sizing:border-box;
                .link-to-nft-button{
                    display:block;
                    background-color:#fff;
                    width:100%;height:48px;
                    box-sizing:border-box;
                    border:1px solid #ff4d2c;
                    color:#ff4d2c;
                    line-height:48px;
                    text-align:center;
                    border-radius:4.5px;
                    &:hover{
                        color:#fff;
                        border:none;
                        background-color:#ff4d2c;
                    }
                }
            }
        }
    }
    @media screen and (max-width:1500px){
        justify-content:space-between; 
        padding: 40px 56px;
    }
    @media screen and (max-width:1215px){
        justify-content:space-between; 
        padding: 40px 28px;
    }
    @media screen and (max-width:980px){
        width:100%;
        flex-direction:column;
        align-items: center;
        justify-content:flex-start; 
        width:100%;
        height:calc(100% - 256px);
    }
`;

const BannerComponent = styled.div`
    width:100%;height:280px;
    text-align:center;
    line-height:280px;
    font-size:64px;
    position: relative;
    img{
        display:block;
        width:100%;height:100%;
        object-fit:cover;
    }
    p{
        position:absolute;
        top:0;left:0;
        color:#fff;
        width:100%;height:100%;
        margin:0;
        text-align:center;
        line-height:280px;
        font-size:64px;
    }
    @media screen and (max-width:855px){
        height:200px;
        p{
            line-height:200px;
            font-size:44px;
        }
    }
    @media screen and (max-width:555px){
        height:170px;
        p{
            line-height:170px;
            font-size:34px;
        }
    }
`;

const SubTitleComponent = styled.p`
    font-size:var(--subtitle);
    margin:0;
    font-weight: 700;
    color:#000;
    @media screen and (max-width:855px){
        font-size: var(--tablet-subtitle);
    }
    @media screen and (max-width:578px){
        font-size: var(--mobile-subtitle);
    }
`;

const SelectCategoryTabContainer = styled.div`
    width:100%;
    height:41.5px;
    border-bottom:0.5px solid #313131;
    @media screen and (max-width:855px){
        border-bottom:none;
    }
`;

const SelectCategoryTabWrapper = styled.div`
    width:auto;
    height:41px;
    .selected-category-text{
        display:none;
    }
    ul{
        list-style:none;
        width:auto;
        height:100%;
        display:flex;
        margin:0;
        padding:0;
        width: 100%;
        justify-content: space-between;
        li{
            width:auto;
            cursor:pointer;
            height:100%;
            font-size:var(--list-title);
            color:#313131;
            text-align:center;
            line-height:41px;
            box-sizing:border-box;
            padding:0 2.5px;
            &.on{
                border-bottom:4.5px solid #313131;
                font-weight:bold;
            }
            transition:border-bottom 0.5s;
        }
        li+li{
            margin-left:5px;
        }
    }
    @media screen and (max-width:855px){
        position:relative;
        padding:4.5px;
        border-radius:3.5px;
        color:#313131;
        background-color:#fff;
        width:150px;
        .selected-category-text{
            display:flex;
            justify-content: space-around;
            height:100%;
            text-align:center;
            box-shadow: 0px 0px 1.5px #313131;
            span{
                color:#000;
                display:inline-block;
                width:auto;
                height:32px;
                line-height: 32px;
            }
        }
        ul{
            width:100%;
            position:absolute;
            top:100%;left:0;
            z-index: 1000;
            width: 150px;
            background: #fff;
            height: auto;
            flex-direction:column;
            border-radius:2.5px;
            justify-content:space-evenly;
            li{
                width: 150px;
                height:32.5px;
                border-radius:0px;
                line-height:32.5px;
                font-size:var(--tablet-list-subtitle);
                &.on{
                    border-bottom:none;
                }
            }
            li+li{margin:0;}
        }
    }
    @media screen and (max-width:578px){
        ul{
            li{
                font-size:var(--mobile-list-subtitle);
            }
        }
    }
`;

const AuthorListWrap = styled.div`
    height:auto;
    width:100%%;
    margin:17.5px auto;
    @media screen and (max-width:980px){
        width:96.5%;
        margin-top:15.5px;
    }
`;

const renderedColumnNumber = () => {
    const { innerWidth } = window;
    if (innerWidth <= 470) {
        return 1;
    }
    else if (innerWidth <= 980) {
        return 2;
    }
    else if (innerWidth <= 1400) {
        return 3;
    }
    else {
        return 4;
    }
}

const findElement = (elem, findCondition, endCondition) => {
    const flag = elem.tagName === findCondition.toUpperCase() || elem.classList.contains(findCondition);
    if (flag) {
        return elem;
    } else {
        const endFlag = elem.tagName === endCondition.toUpperCase() || elem.classList.contains(endCondition);
        if (endFlag) {
            return null;
        } else {
            return findElement(elem.parentElement, findCondition, endCondition);
        }
    }
}

function Gallery({ match, location }) {
    const [showMessageComponent,setShowMessageComponent] = useState(false);
    const [alertMessage,setAlertMessage] = useState('');
    const [nfts, setNfts] = useState([]);
    const [nftArr1, setNftArr1] = useState([]);
    const [nftArr2, setNftArr2] = useState([]);
    const [nftArr3, setNftArr3] = useState([]);
    const [nftArr4, setNftArr4] = useState([]);
    const [showCateList, setShowCateList] = useState(isMobile ? false : true);
    const pageRef = useRef(Number(1));
    const maxrecords = Number(30);
    const [changedCate,setChangedCate]=useState(false);
    let totalGalleryNumber = -1;
    const [noMoreData, setNoMoreData] = useState(false);
    const [authorNumOfProducts,setAuthorNumOfProducts]=useState(null);
    const [AuthorName, setAuthorName] = useState(null);
    const [AuthorProfilePic, setAuthorProfilePic] = useState("/static/media/userProfilePic.a16c0a5a.svg");
    const [loadingState, setLoadingState] = useState(false);
    const categoryListRef = useRef(["Recent",...MINTING_GALLERY_CATEGORY]);
    const [selectedCate, setSelectedCate] = useState("Recent");

    const { loadingGalleryData,
        loadingGalleryDataSuccess,
        loadingGalleryDataFailure,
        countGalleryData,
        galleryData } = useSelector(state => state.paintings);

    const {
        loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData,
        loadUserListLoading,
        loadUserListFailure
    } = useSelector(state => state.users);
    const dispatch = useDispatch();

    const { userid } = useParams();
    const { state } = useLocation();
    

    const windowResizeEvent = useCallback(() => {
        // category selector 보여줄 지 여부 선택
        const { innerWidth: width } = window;
        if (width < 815) { setShowCateList(false); }
        else if (width >= 815) { setShowCateList(true) }
        // 화면너비의 변화에 따른 grid column 변경
        const renderIndex = renderedColumnNumber();
        switch (renderIndex) {
            case 1: {
                if(nftArr2&&nftArr2.length!==0){
                    deployGalleryData([...nftArr1,...nftArr2].sort((a,b)=>parseInt(b.id)-parseInt(a.id)),true);
                }
                break;
            }
            case 2: {
                if(nftArr2&&nftArr2.length!==0){
                    if(nftArr3&&nftArr3.length!==0){
                        deployGalleryData([...nftArr1,...nftArr2,...nftArr3].sort((a,b)=>parseInt(b.id)-parseInt(a.id)),true);
                    }
                }else{
                    deployGalleryData([...nftArr1],true)
                }
                break;
            }
            case 3: {
                if(nftArr3&&nftArr3.length!==0){
                    if(nftArr4&&nftArr4.length!==0){
                        deployGalleryData([...nftArr1,...nftArr2,...nftArr3,...nftArr4].sort((a,b)=>parseInt(b.id)-parseInt(a.id)),true);
                    }
                }else{
                    deployGalleryData([...nftArr1,...nftArr2].sort((a,b)=>parseInt(b.id)-parseInt(a.id)),true)
                }
                break;
            }
            case 4: {
                if(nftArr4&&nftArr4.length===0){
                    deployGalleryData([...nftArr1,...nftArr2,...nftArr3].sort((a,b)=>parseInt(b.id)-parseInt(a.id)),true);
                }
                break;
            }
            default: break;
        }
    },[nftArr1,nftArr2,nftArr3,nftArr4]);

    useEffect(() => {
        window.addEventListener("resize", windowResizeEvent)
        return () => {
            window.removeEventListener("resize", windowResizeEvent);
        }
    }, [nftArr1,nftArr2,nftArr3,nftArr4])

    // 특정 user 의 작품을 볼려고 link 를 click 했을 때,
    //(/gallery/:userid 로 접속한 경우)
    // 만약 location 의 state 객체에 user의 profile 이 없다면..
    useEffect(() => {

        if (!state && userid) {
            pageRef.current=1;
            dispatch({ type: LOAD_USER_PROFILE_IMG_REQUEST, data: { userid } });
        } else if (state) {
            pageRef.current=1;
            setAuthorName(state.authorName);
            setAuthorProfilePic(state.userthumbnail);
            setAuthorNumOfProducts(state.numberOfProducts)
        }
    }, [state,userid])

    useEffect(() => {
        if (!loadUserProfileImgLoading && loadUserProfileImgSuccess) {
            if (loadUserProfileImgData) {
                if (loadUserProfileImgData.userthumbnail 
                    && loadUserProfileImgData.userthumbnail !== '') {
                    setAuthorProfilePic(loadUserProfileImgData.userthumbnail);
                    setAuthorName(`${loadUserProfileImgData.firstname}`);

                }
                dispatch({ type: LOAD_USER_PROFILE_IMG_INIT });
            }
        } else if (loadUserProfileImgFailure) {
            alert(loadUserProfileImgFailure);
            dispatch({ type: LOAD_USER_PROFILE_IMG_INIT });
        }
    }, [loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData,
    ]);

    useEffect(()=>{
        if(!loadUserListLoading&&loadUserListFailure){
            setShowMessageComponent(true);
            setAlertMessage(loadUserListFailure);
        }
    },[loadUserListLoading,loadUserListFailure])

    // /gallery 로 접속한 경우와 /gallery/:userid 로 접속한 경우 
    // 요쳥되어지는 data 가 달라짐
    useEffect(() => {
        const dataToSend=userid?
        {
            apiType:GALLERY_API_TYPE[2],
            offset: ((pageRef.current - 1) * maxrecords),
            limit: (maxrecords),
            userid:userid,
            withWaterMark:false,
        }:{
            apiType:GALLERY_API_TYPE[0],
            offset: ((pageRef.current - 1) * maxrecords),
            limit: (maxrecords),
            userid:null
        }
        dispatch({
            type: LOADING_GALLERY_DATA,
            data:dataToSend,
            isFirst: true
        })

        return () => {
            setNftArr1([]);
            setNftArr2([]);
            setNftArr3([]);
            setNftArr4([]);
            pageRef.current = 1;

            //setSelectedCate('Recent')
        }
    }, [userid]);

    const deployGalleryData = useCallback((data,isResize=false) => {
      
        const tmpNftArr1 = [];
        const tmpNftArr2 = [];
        const tmpNftArr3 = [];
        const tmpNftArr4 = [];
        const columnNumber = renderedColumnNumber();
        let arrOffset = nftArr1.length + nftArr2.length + nftArr3.length + nftArr4.length;
        data.forEach((v) => {
            const nth = arrOffset;
            const newlyComposedItem = { ...v, image: v.image };
            switch (nth % columnNumber) {
                case 0:
                    tmpNftArr1.push(newlyComposedItem);
                    break;
                case 1:
                    tmpNftArr2.push(newlyComposedItem);
                    break;
                case 2:
                    tmpNftArr3.push(newlyComposedItem);
                    break;
                case 3:
                    tmpNftArr4.push(newlyComposedItem);
                    break;
            }
            arrOffset++;
        })
        if(isResize){
            setNftArr1([...tmpNftArr1]);
            setNftArr2([...tmpNftArr2]);
            setNftArr3([...tmpNftArr3]);
            setNftArr4([...tmpNftArr4]);
        }else{
            nftArr1.length === 0 ? setNftArr1([...tmpNftArr1]) : setNftArr1([...nftArr1,...tmpNftArr1]);
            nftArr2.length === 0 ? setNftArr2([...tmpNftArr2]) : setNftArr2([...nftArr2,...tmpNftArr2]);
            nftArr3.length === 0 ? setNftArr3([...tmpNftArr3]) : setNftArr3([...nftArr3,...tmpNftArr3]);
            nftArr4.length === 0 ? setNftArr4([...tmpNftArr4]) : setNftArr4([...nftArr4,...tmpNftArr4]);
        }
        
    }, [nftArr1,nftArr2,nftArr3,nftArr4])

    useEffect(() => {
        if (!loadingGalleryData && loadingGalleryDataSuccess) {
            console.log(galleryData);
            console.log(`data length : ${galleryData.length}`);
            if (!!galleryData&&galleryData.length!=0) {
                deployGalleryData(galleryData);
                galleryData.length % maxrecords === 0 ? setNoMoreData(false) : setNoMoreData(true);
                pageRef.current = pageRef.current + 1;
            } else if (!galleryData||galleryData.length==0) {
                setNoMoreData(true);
            }
            dispatch({ type: INIT_PAINTINGS_STATE });
        } else if (loadingGalleryDataFailure) {
            setShowMessageComponent(true);
            setAlertMessage(loadingGalleryDataFailure);
            dispatch({ type: INIT_PAINTINGS_STATE });
        }
    }, [countGalleryData,loadingGalleryData, loadingGalleryDataSuccess, loadingGalleryDataFailure, galleryData]);

    const onClickShowMoreButton = useCallback((evt) => {
        if(noMoreData){return false;}
        
        const offset = (pageRef.current - 1) * maxrecords;
        let flagBit = 0;
        const flagkBitMask = 3;
        if(selectedCate!=='Recent'){
            flagBit=(flagBit|1);
        }
        if(userid){
            flagBit=(flagBit|2);
        }
        console.log(flagBit);
        flagBit=flagBit&flagkBitMask;
        console.log(flagBit);
        switch(flagBit){
            case 0 :{
                dispatch({ type: LOADING_GALLERY_DATA, 
                    data: { 
                        apiType:GALLERY_API_TYPE[0],
                        offset, limit: maxrecords,  
                    } 
                });
                break;
            }
            case 1:{
                dispatch({
                    type:LOADING_GALLERY_DATA,
                    data:{
                        apiType:GALLERY_API_TYPE[1],
                        offset, limit: maxrecords,
                        cat:selectedCate
                    }
                })
                break;
            }
            case 2:{
                dispatch({
                    type:LOADING_GALLERY_DATA,
                    data:{
                        apiType:GALLERY_API_TYPE[2],
                        offset, limit: maxrecords,
                        userid,
                        withWaterMark:false,
                    }
                })
                break;
            }
            case 3:{
                dispatch({
                    type:LOADING_GALLERY_DATA,
                    data:{
                        apiType:GALLERY_API_TYPE[3],
                        offset, limit: maxrecords,
                        cat:selectedCate,
                        userid
                    }
                })
                break;
            }
        }
        
    },[noMoreData,pageRef.current,userid,selectedCate]);

    const onClickCategoryList = useCallback((evt) => {
        const { target } = evt;
        const elem = findElement(target, 'category', 'category-list');
        if (elem) {
            const { cate } = elem.dataset;
            setSelectedCate(cate);
            (window.innerWidth<=815)&&setShowCateList(false);
            setNftArr1([]);
            setNftArr2([]);
            setNftArr3([]);
            setNftArr4([]);
            pageRef.current=1;
            setChangedCate(true);
            cate==="Recent"?
            userid?
            dispatch({type:LOADING_GALLERY_DATA,data:{
                userid,
                apiType:GALLERY_API_TYPE[2],
                offset: ((pageRef.current - 1) * maxrecords),
                limit: (maxrecords),
                withWaterMark:false
            }}):
            dispatch({type:LOADING_GALLERY_DATA,data:{
                apiType:GALLERY_API_TYPE[0],
                offset: ((pageRef.current - 1) * maxrecords),
                limit: (maxrecords)
            }})
            :userid?
            dispatch({
                type:LOADING_GALLERY_DATA,data:{
                    apiType:GALLERY_API_TYPE[3],
                    userid,
                    limit:(maxrecords),
                    offset:((pageRef.current - 1) * maxrecords),
                    cat:cate,
                }
            })
            :dispatch({type:LOADING_GALLERY_DATA,data:{
                apiType:GALLERY_API_TYPE[1],
                isCate:true,cat:cate,limit:maxrecords,
                offset:((pageRef.current-1)*maxrecords)
            }})
            
        }else {
            const spanElem = findElement(target,'selected-category-text','category-list');
            if(spanElem){
                setShowCateList(prev=>!prev);
            }
        }
    }, [userid]);

    const cancelFunction = useCallback((evt)=>{
        setShowMessageComponent(false);
    },[]);

    return (
        <div style={{ position: 'relative' }} >
            <Helmet>
                <link rel="icon" href={Collery_logo_03}/>
            </Helmet>
            <Header />
            {/* <BannerComponent>
                <img src={bannerImage} alt="banner-image"/>
                <p>Gallery</p>
            </BannerComponent> */}
            <div className="container-fluid">
                <GalleryContainer>
                    <GalleryWrapper className="col-lg-12">
                        {
                            userid &&
                            <AuthorProfileContainer>
                                <AuthorProfileWrap>
                                    <img src={location?.state?.image ?
                                        location?.state?.image : AuthorProfilePic}
                                        alt="author profile image" />
                                </AuthorProfileWrap>
                                <AuthorProfileInfoWrap>
                                    <div className="text-info">
                                        <p className="author-name">
                                            {AuthorName}
                                        </p>
                                        <p className="userId">
                                           {/* @{userid?.slice(0,5)+'...'+userid?.slice(8,12)} */}
                                        </p>
                                        {
                                            authorNumOfProducts&&
                                                <p className="follower">
                                                작품 수 : {authorNumOfProducts}
                                            </p>
                                        }
                                    </div>
                                </AuthorProfileInfoWrap>
                            </AuthorProfileContainer>
                        }
                        <h1>Gallery</h1>
                        <SelectCategoryTabContainer>
                            <SelectCategoryTabWrapper className="category-list" onClick={onClickCategoryList}>
                                <span className="selected-category-text">
                                    <span>{selectedCate}</span>
                                    {showCateList ? <CaretUpOutlined /> : <CaretDownOutlined />}
                                </span>
                                {
                                    showCateList &&
                                    <ul >
                                        {
                                            categoryListRef.current.map((v, i) => (
                                                <li className={selectedCate === v ? 'category on' : 'category'} key={v} data-cate={v}>{v}</li>
                                            ))
                                        }
                                    </ul>
                                }
                            </SelectCategoryTabWrapper>
                        </SelectCategoryTabContainer>
                        
                        <StyledProductWrap>
                            {nftArr1 && (<StyledProductColumn>
                                {nftArr1?.slice(0).map((NFT, index) => {
                                    return (
                                        <StyledIndividualProduct data-index={index} className="col-xl-3 col-lg-4 col-md-6" key={`fe-${Math.random()}-${NFT?.id}`}>
                                            <StyledProductLink to={{
                                                pathname: `/product/${NFT.id}`,
                                                state: { userid: NFT.userid },
                                            }}>
                                                <div>
                                                    <StyledIndividualProductImgWrap>
                                                        <SingleThumbNailImage image={NFT.watermarkurl} />
                                                        {/* <img src={NFT?.image[0]} alt="NFT Image"/> */}

                                                        {/* <div className="featured-item-clock" data-countdown="2021/09/09"></div> */}
                                                    </StyledIndividualProductImgWrap>
                                                    <div className="content-wrap" >
                                                        <div className="content-info content-info1">
                                                            <div>
                                                                <h3>{NFT?.title.substr(0, 20)}</h3>
                                                            </div>
                                                        </div>
                                                        <div className=" content-info content-info2">
                                                            <div className="profile-component">
                                                                <p className="profile-picture-wrap">
                                                                    <img src={userid?AuthorProfilePic:NFT.userthumbnail} alt="profile pic" />
                                                                </p>
                                                                <p>by@ {NFT?.author}</p>
                                                            </div>
                                                            {/* <div className='profile-link-wrap'>
                                                            <StyledProfileLinkButton>
                                                                작품들 보기
                                                            </StyledProfileLinkButton>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </StyledProductLink>
                                        </StyledIndividualProduct>)
                                })}
                            </StyledProductColumn>)}
                            {nftArr2 && (<StyledProductColumn>
                                {nftArr2?.slice(0).map((NFT, index) => {
                                    return (

                                        <StyledIndividualProduct data-index={index} className="col-xl-3 col-lg-4 col-md-6" key={`fe-${Math.random()}-${NFT?.id}`}>
                                            <StyledProductLink to={{
                                                pathname: `/product/${NFT.id}`,
                                                state: { userid: NFT.userid },
                                            }}>
                                                <div>

                                                    <StyledIndividualProductImgWrap>
                                                        <SingleThumbNailImage image={NFT.watermarkurl} />
                                                        {/* <img src={NFT?.image[0]} alt="NFT Image"/> */}

                                                        {/* <div className="featured-item-clock" data-countdown="2021/09/09"></div> */}
                                                    </StyledIndividualProductImgWrap>

                                                    <div className="content-wrap" >

                                                        <div className="content-info content-info1">
                                                            <div>
                                                                <h3>{NFT?.title.substr(0, 20)}</h3>
                                                                {/* <p>exhibition : {NFT?.exhibition}</p> */}
                                                            </div>
                                                        </div>
                                                        <div className=" content-info content-info2">
                                                            <div className="profile-component">
                                                                <p className="profile-picture-wrap">
                                                                    <img src={userid?AuthorProfilePic:NFT.userthumbnail} alt="profile pic" />
                                                                </p>
                                                                <p>by@ {NFT?.author}</p>
                                                            </div>
                                                            {/* <div className='profile-link-wrap'>
                                                            <StyledProfileLinkButton>
                                                                작품들 보기
                                                            </StyledProfileLinkButton>
                                                        </div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </StyledProductLink>

                                        </StyledIndividualProduct>)

                                })}
                            </StyledProductColumn>)}
                            {nftArr3 && (<StyledProductColumn>
                                {nftArr3?.slice(0).map((NFT, index) => {
                                    return (
                                        <StyledIndividualProduct data-index={index} className="col-xl-3 col-lg-4 col-md-6" key={`fe-${Math.random()}-${NFT?.id}`}>
                                            <StyledProductLink to={{
                                                pathname: `/product/${NFT.id}`,
                                                state: { userid: NFT.userid },
                                            }}>
                                                <div>

                                                    <StyledIndividualProductImgWrap>
                                                        <SingleThumbNailImage image={NFT.watermarkurl} />
                                                        {/* <img src={NFT?.image[0]} alt="NFT Image"/> */}

                                                        {/* <div className="featured-item-clock" data-countdown="2021/09/09"></div> */}
                                                    </StyledIndividualProductImgWrap>

                                                    <div className="content-wrap" >

                                                        <div className="content-info content-info1">
                                                            <div>
                                                                <h3>{NFT?.title.substr(0, 20)}</h3>
                                                                {/* <p>exhibition : {NFT?.exhibition}</p> */}
                                                            </div>
                                                        </div>
                                                        <div className=" content-info content-info2">
                                                            <div className="profile-component">
                                                                <p className="profile-picture-wrap">
                                                                    <img src={userid?AuthorProfilePic:NFT.userthumbnail} alt="profile pic" />
                                                                </p>
                                                                <p>by@ {NFT?.author}</p>
                                                            </div>
                                                            {/* <div className='profile-link-wrap'>
                                                                <StyledProfileLinkButton>
                                                                    작품들 보기
                                                                </StyledProfileLinkButton>
                                                            </div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </StyledProductLink>

                                        </StyledIndividualProduct>)
                                })}
                            </StyledProductColumn>)}
                            {nftArr4 && (<StyledProductColumn>
                                {nftArr4?.slice(0).map((NFT, index) => {
                                    return (
                                        <StyledIndividualProduct className="col-xl-3 col-lg-4 col-md-6" key={`fe-${Math.random()}-${NFT?.id}`}>
                                            <StyledProductLink to={{
                                                pathname: `/product/${NFT.id}`,
                                                state: { userid: NFT.userid },
                                            }}>
                                                <div>
                                                    <StyledIndividualProductImgWrap>
                                                        <SingleThumbNailImage image={NFT.watermarkurl} />
                                                        {/* <img src={NFT?.image[0]} alt="NFT Image"/> */}

                                                        {/* <div className="featured-item-clock" data-countdown="2021/09/09"></div> */}
                                                    </StyledIndividualProductImgWrap>
                                                    <div className="content-wrap" >
                                                        <div className="content-info content-info1">
                                                            <div>
                                                                <h3>{NFT?.title.substr(0, 20)}</h3>
                                                                {/* <p>exhibition : {NFT?.exhibition}</p> */}
                                                            </div>
                                                        </div>
                                                        <div className=" content-info content-info2">
                                                            <div className="profile-component">
                                                                <p className="profile-picture-wrap">
                                                                    <img src={userid?AuthorProfilePic:NFT.userthumbnail} alt="profile pic" />
                                                                </p>
                                                                <p>by@ {NFT?.author}</p>
                                                            </div>
                                                            {/* <div className='profile-link-wrap'>
                                                        <StyledProfileLinkButton>
                                                            작품들 보기
                                                        </StyledProfileLinkButton>
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </StyledProductLink>
                                        </StyledIndividualProduct>)
                                })}
                            </StyledProductColumn>)}
                        </StyledProductWrap>

                    </GalleryWrapper>
                    {loadingState && !nfts?.length && <div className="container d-flex justify-content-center">

                        <div className="alert alert-light container" role="alert" >
                            Empty Gallery

                        </div>
                        <div className="d-grid gap-2 others-options" >

                            <Link to='/user/collection/create' className="btn btn-dark">I want to create an Image for Gallery</Link>

                        </div>

                    </div>
                    }
                    {
                        showMessageComponent&&
                        <MessageCommponent message={alertMessage} cancelFunction={cancelFunction}/>
                    }
                    {
                        loadingGalleryData &&
                        <LoadingComponent message={GalleryIndexLoading.message} />
                    }

                    {/*<div className="col-lg-10" >
                          
                        <div className="row pt-70">
                    
                        {
                            nfts.map((nft, i) => (
                            <div className="col-lg-3 col-md-4 col-6" key={i}  onClick={() => buyNft(nft)}>
                            
                                <div className="nft-option-card">
                                    <img src={nft.image} alt="Create Buy NFT" />
                                    <h3><b >{nft.title}</b></h3>
                                    <p>Price: {nft.price} {nft.chain}</p>
                                    <p>Chain: {nft.chain} </p>
                                    <p>Minter: {nft.seller.slice(0,7)+'...'+nft.seller.slice(36,42)} </p> 
                                    <p>date: {nft.date} </p>
                                    <p>Smart contract: <a href="https://polygonscan.com/address/0x901d97b75e13e20d91db0e1cb863547303b93795" target="_blank"> {nft.contract.slice(0,7)+'...'+nft.contract.slice(20,25)} </a></p>
                                    
                                    <div className="top-btn- text-uppercase btn-dark btn"  onClick={() => buyNft(nft)}>Buy</div>
                                </div>
                            </div>
                        ))}   
                        </div>
                        </div>*/}
                    {
                        !loadingGalleryData && !noMoreData &&
                        <StyledShowMoreButtonWrap>
                            <StyledShowMoreButton onClick={onClickShowMoreButton}>
                                <span>
                                    작품 더 보기
                                </span>
                            </StyledShowMoreButton>
                        </StyledShowMoreButtonWrap>
                    }
                </GalleryContainer>
                <AuthorListWrap>
                    <SubTitleComponent>작가들 목록</SubTitleComponent>
                    <AuthorList />
                </AuthorListWrap>
                {/* <Pagination currentPage={currentPage} totalPage={totalPage} onPageChange={setCurrentPage} /> */}


            </div>

            <Footer />


        </div>
    )
}

export default Gallery;