
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { Button, Modal } from 'react-bootstrap';
import StakeModalDetails from '../CustomModal/StakeModalDetails'


function Gallerycard({ data = null }){

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modedata, setModeldata]=useState([]);

    function EnlargeMe(image){

        let datamodal=[image];

        setModeldata([...datamodal])
    
        return handleShow
    }
    

    return (
        <>

            <div className="featured-item" onClick={()=>EnlargeMe(data?.image)}>
                
                <div className="featured-item-img">
                    <Link >  {/**  to={`/nft/${data.itemId}`} */}
                        <img src={data?.image} alt="NFT Image"/>
                    </Link>
                   
                    {/* <div className="featured-item-clock" data-countdown="2021/09/09"></div> */}
                </div>

                <div className="content" >
                    <h3><Link>{data?.title.substr(0, 20)}</Link></h3>
                    <div className="content-in">
                        <span>{data?.description}</span>
                    </div>
              
                </div>

            </div>

        </>
    )
}

export default Gallerycard;
