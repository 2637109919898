
import React from 'react';
import { Link } from 'react-router-dom';
import certificate from '../../../assets/images/icons/certificate.png';
import gallery from '../../../assets/images/icons/gallery.png';
import nft_asset_list from '../../../assets/images/icons/nft_asset_list.png';
import nft from '../../../assets/images/icons/nft.png';
import password from '../../../assets/images/icons/password.png';
import profile_portforlio from '../../../assets/images/icons/profile_portforlio.png';
import { useState, useEffect } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import userProfilePicSample from '../../../assets/images/userProfilePic.svg';
import './style.css';
import { USER_COLLECTION, USER_COLLECTION_CREATE, USER_NFT, USER_NFT_MINTING, USER_PASSWORD, USER_PROFILE, USER_WALLET } from '../../../constants/applicationUrls';

import { QueryObserver, useQueryClient } from "react-query";
import styled from "styled-components";
import { LOGO_IMAGE } from '../../../constants/application';
import Helmet  from 'react-helmet';

const PortforlioContainer = styled.div`
    width:100%;
    height:1027px;
    @media screen and (max-width:1350px){height:auto;}
`;

const PortforlioWrap = styled.div`
    width:85%;
    height:auto;
    margin:25px auto;
    @media screen and (max-width:1650px){
        width:90.5%;
    }
`;

const PortforlioRow = styled.div`
    width:100%;
    height:auto;
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content: center;
    @media screen and (max-width:1350px){
        flex-direction:column;
    }
`;

const AuthorProfileContainer = styled.div`
    width:auto;height:auto;
    @media screen and (max-width:1350px){
        width:100%;
    }
`;
const AuthorProfileWrap = styled.div`
    width:288px;
    height:auto;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    .author-profile-pic-wrap{
        width:256px;
        height:256px;
        img{
            display:block;
            width:100%;height:100%;
            object-fit:contain;
        }
    }
    p{
        width:100%;height:100%;
        font-size:var(--list-subtitlie);
        margin:10px 0;
    
        &.author-name-text{
            text-align:center;
            font-weight:bold;
            font-size:var(--list-title);
        }
        .wallet-btn{
            display: inline-block;
            width:100%;
            font-size:var(--list-subtitlie);
            font-weight: 400;
            line-height: 1.5;
            color: #fff;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            background-color: #ff4d2c;
            border: 1px solid transparent;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            border-radius: 0.25rem;
            transition: color .15s
        }
    }
    @media screen and (max-width:1350px){
        width:100%;
        p{
            text-align:center;
            &.author-name-text{
            }
            .wallet-btn{
                width:20%;
                margin:0 auto;
            }
        }
    }
    @media screen and (max-width:855px){
        p{
            font-size:var(--tablet-list-subtitle);
            &.author-name-text{
                font-size:var(--tablet-list-title);
                font-weight:bold;
            }
            .wallet-btn{
                width:50%;
                margin:0 auto;
                font-size:var(--tablet-list-subtitle);
            }
        }
    }
    @media screen and (max-width:578px){
        p{
            font-size:var(--mobile-list-subtitle);
            &.author-name-text{
                font-size:var(--mobile-list-title);
                font-weight:bold;
            }
            .wallet-btn{
                width:50%;
                margin:0 auto;
                font-size:var(--mobile-list-subtitle);
            }
        }
    }
`;

const ContentsContainer = styled.div`
    width:auto;
    height:auto;
`;
const ContentsWrap = styled.div`
    width:100%;
    height:auto;
    @media screen and (max-width:1650px){
        margin:0 auto;
    }
`;
const ContentsRow = styled.div`
    width:800px;
    height:auto;
    margin:0 auto;
    display:grid;
    grid-template-columns:repeat(2,1fr);
    column-gap:20px;row-gap:20px;
    -webkit-perspective: 550px;
    -moz-perspective: 550px;
    -ms-perspective: 550px;
    perspective: 550px;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    .grid{
        width:100%;height:246px;
        border-radius:4.5px;
        box-shadow:0 0 2.5px #313131;
        
        a{
            display:block;
            width:100%;
            height:100%;
            box-sizing:border-box;
            padding:25px;
            >div{
                text-align:center;
                width: 100%;
                height: 100%;
                div{
                    height:auto;
                    h3{
                        margin-top:10.5px;
                        font-weight:bold;
                        font-size:var(--list-title);
                    }
                    .sub-Title{
                        margin:0 auto;
                        width:40px;height:40px;
                        font-size:35px;
                        line-height:40px;
                        img{
                            display:block;
                            width:100%;height:100%
                        }
                    }
                }
                .explain-text{
                    width:100%;
                    height:auto;
                    margin-top: 15.5px;
                    white-space:normal;
                    font-size:var(--explain-text);
                    line-height:26.5px;
                    color:#000;
                }
            }
        }
        transition:transform 0.5s;
        &:hover{
            transform:translateZ(15.5px);
        }
    }
    @media screen and (max-width:1650px){
        width:100%;
    }
    @media screen and (max-width:855px){
        grid-template-columns:repeat(1,1fr);
        .grid{
            a{
                div{
                    div{
                        h3{
                            font-size:var(--tablet-list-title);
                        }
                    }
                    .explain-text{
                        font-size:var(--tablet-explain-text);
                    }
                }
            }
        }   
    }

    @media screen and (max-width:578px){
        .grid{
            a{
                div{
                    div{
                        h3{
                            font-size:var(--mobile-list-title);
                        }
                    }
                    .explain-text{
                        font-size:var(--mobile-explain-text);
                    }
                }
            }
        }
    }
`;

function Dashboard({ currentUser }) {
    const [userProfilePic, setUserProfilePic] = useState(userProfilePicSample);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [walletuser, Setwalletuser] = useState('');
    const [email,setEmail]=useState('');
    const queryClient = useQueryClient();
    useEffect(() => {
        if (currentUser) {
            const queryObserver = new QueryObserver(
                queryClient,
                {
                    queryKey: ['basicUserData']
                }
            );
            const unsubscribe = queryObserver.subscribe(result => {
                if (result.data) {
                    setUserProfilePic(result.data?.userthumbnail);
                    setFirstName(result.data?.firstname);
                  
                    setEmail(result.data?.email)
                }
            })
            return () => { unsubscribe(); }
        }
    }, [])

    /* const connect = () => {
        let provider = window.ethereum;
        if (typeof provider !== 'undefined') {

            provider.request({ method: 'eth_requestAccounts' })
                .then(accounts => {
                    selectedAccount = accounts[0];
                    if (accounts.length == 0) {

                        toast.success("You are disconnected", { position: toast.POSITION.BOTTOM_RIGHT });

                    }
                    else {
                        console.log(`connected to 1 ${selectedAccount}`);

                        const cutit = selectedAccount.slice(0, 7) + '...' + selectedAccount.slice(36, 42);
                        Setwalletuser(cutit);
                        toast.success("You are connected to MetaMask", { position: toast.POSITION.BOTTOM_RIGHT });

                    }

                })
                .catch((err) => {

                    console.log(err.message);

                    if (err.code === -32002) {
                        toast.info("Waiting for Metamask", { position: toast.POSITION.BOTTOM_RIGHT });
                    } else if (err.code === 4001) {
                        toast.error("Request rejected", { position: toast.POSITION.BOTTOM_RIGHT });
                    }


                });

            window.ethereum.on('accountsChanged', function (accounts) {
                selectedAccount = accounts[0];
                if (accounts.length == 0) {

                    toast.success("User disconnected", { position: toast.POSITION.BOTTOM_RIGHT });
                    Setwalletuser('');

                } else {
                    console.log(`Account changed to 2 ${selectedAccount}`);
                    const cutit = selectedAccount.slice(0, 7) + '...' + selectedAccount.slice(36, 42);
                    Setwalletuser(cutit);

                }


            })


        } else {
            console.log('metamask not detected')

            toast("Wallet not detected, please install ethereum wallet like Metamask", { position: toast.POSITION.BOTTOM_RIGHT });
            Setwalletuser('');

        }

    } */

    return (
        <>
            <Helmet>
                <link rel="icon" href={LOGO_IMAGE}></link>
            </Helmet>
            <Header />
            <PortforlioContainer>
                <PortforlioWrap>
                    <PortforlioRow>

                        <AuthorProfileContainer>
                            <AuthorProfileWrap className="author-profile-sidebar mr-20">
                                <div className="author-profile-pic-wrap">
                                    <img src={userProfilePic} alt="Images" />
                                </div>
                                <p className="author-name-text">{`${firstName}`}</p>
                                <p>
                                    <small>
                                       {email}
                                    </small>
                                </p>

                                {/* <div className="row pt-100">
                                    <div className="col-lg-12">
                                        <p><small>Please use Metamask wallet for safety</small></p>
                                    </div>
                                </div> */}
                            </AuthorProfileWrap>
                        </AuthorProfileContainer>

                        <ContentsContainer>
                            <ContentsWrap>
                                <ContentsRow>
                                    <div className="grid">
                                        <Link to={{
                                            pathname:USER_NFT_MINTING,
                                        }}>
                                            <div >
                                                <div>
                                                    <p className="sub-Title">
                                                        <img src={nft} alt="icon"/>
                                                    </p>
                                                    <h3>NFT 발행하기</h3>
                                                </div>
                                                <p className="explain-text">작품을 NFT로 발행해드립니다. 이미지, 동영상, 오디오 등의 파일을 작가명, 제목, 작품설명 등 정보를 추가해서 NFT를 발행합니다. </p>
                                                
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="grid">
                                        <Link to={{pathname:USER_COLLECTION_CREATE,state:{userName:`${firstName} ${lastName}`}}}>
                                            <div>
                                                <div>
                                                    <p className="sub-Title">
                                                        <img src={gallery} alt="icon"/>
                                                    </p>
                                                    <h3>갤러리</h3>
                                                </div>
                                                <p className="explain-text">갤러리에 작품을 전시하세요.  NFT로 발행이 되지 않습니다. 멋진 작품들을 모두에게 공유할 수 있습니다.</p>
                                                
                                            </div>
                                        </Link>
                                    </div>



                                    <div className="grid">
                                        <Link to={USER_NFT}>
                                            <div>
                                                <div>
                                                    <p className="sub-Title">
                                                        <img src={nft_asset_list} alt="icon"/>
                                                    </p>
                                                    <h3>NFT 자산 목록</h3>
                                                </div>
                                                <p className="explain-text">보유하고 있는 NFT 자산에 대한 포트폴리오를 확인할수 있습니다. </p>
                                                
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="grid">
                                        <Link to={'/certifypage'}>
                                            <div>
                                                <div>
                                                    <p className="sub-Title">
                                                        <img src={certificate} alt="icon"/>
                                                    </p>
                                                    <h3>인증서 발급하기</h3>
                                                </div>
                                                <p className="explain-text">작품의 저작권을 보호하기 위한 방법으로 사설 저작권 인증서를 발급해드립니다. (* 인증서가 저작권을 법적으로 인정받을 수는 없습니다.)</p>
                                            </div>
                                        </Link>
                                    </div>

                                    {/*<div className="col-lg-4 col-6">
                                            <Link to={USER_WALLET} className="user-dashboard-card-link">
                                                <div className="user-dashboard-card">
                                                    <h3>Wallets</h3>
                                                    <p>Check and manage all your existing crypto wallets</p>
                                                    <div className="top-btn bg-warning">All</div>
                                                </div>
                                            </Link>
                                    </div>*/}



                                    <div className="grid">
                                        <Link to={USER_PROFILE}>
                                            <div>
                                                <div>
                                                    <p className="sub-Title">
                                                        <img src={profile_portforlio} alt="icon"/>
                                                    </p>
                                                    <h3>Profile & Portforlio</h3>
                                                </div>
                                                <p className="explain-text">
                                                    프로필 설정 및 포트폴리오 보기
                                                </p>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="grid">
                                        <Link to={USER_PASSWORD}>
                                            <div>
                                                <div>
                                                    <p className="sub-Title">
                                                        <img src={password} alt="icon"/>    
                                                    </p>    
                                                    <h3>Password</h3>
                                                </div>
                                                <p className="explain-text">비밀번호 변경</p>
                                            </div>
                                        </Link>
                                    </div>
                                </ContentsRow>
                            </ContentsWrap>
                        </ContentsContainer>
                    </PortforlioRow>
                </PortforlioWrap>
            </PortforlioContainer>
            <Footer />
        </>
    )
}

export default Dashboard;

