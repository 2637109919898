import React, { memo } from "react";
import styled from "styled-components";

const EntireWrapper = styled.div`
    width:87.5%;height:auto;
    margin: 0 auto 95.5px auto;
    .paragraph-container{
        width:100%;
        height:auto;
        .single-paragraph{
            margin-top:62.5px;
            h2{
                font-weight:bold;
                font-size:var(--subtitle);
            }
            .paragraph{
                font-size:var(--explain-text);
                padding:25.5px 0;
                color:#444141;
                line-height:32.5px;
            }
        }
    }
    @media screen and (max-width:855px){
        width:92.5%;
        .paragraph-container{
            .single-paragraph{
                margin-top:42.5px;
                h2{
                    font-weight:bold;
                    font-size:var(--tablet-subtitle);
                }
                .paragraph{
                    font-size:var(--tablet-explain-text);
                    padding:19.5px 0;
                    color:#444141;
                    line-height:22.5px;
                }
            }
        }
    }
    @media screen and (max-width:578px){
        width:96.5%;
        .paragraph-container{
            .single-paragraph{
                margin-top:22.5px;
                h2{
                    font-weight:bold;
                    font-size:var(--mobile-subtitle);
                }
                .paragraph{
                    font-size:var(--mobile-explain-text);
                    padding:19.5px 0;
                    color:#444141;
                    line-height:22.5px;
                }
            }
        }
    }
`;

const PersonalInfo = memo(() => {
    return (
        <>
            <EntireWrapper>
                <div className="paragraph-container">
                    <div className="single-paragraph">
                        <p className="paragraph">
                            COLLERY의 회원님께서 COLLERY(https://www.collery.io)의 콘텐츠를 주문하거나 각종 서비스를 이용하는 데 있어, 원활한 주문 및 서비스 접수, 대금 결제 및 회원님에게 편리하고 유익한 맞춤 정보를 제공하기 위한 최소한의 정보를 필수 사항으로 수집합니다.
                            회원님께서 제공하신 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며, 수집 정보의 범위나 사용 목적, 용도가 변경될 시에는 반드시 회원님께 사전 동의를 구할 것입니다.<br />
                            개인정보 보호정책은 정보통신망이용촉진등에관한법률(이하 '법률'이라 합니다) 제16조(개인정보의 수집 및 취급) 내지 18조(이용자의 권리)를 기본으로 하여 회원 여러분이 신뢰를 바탕으로 더욱 안전하고 편안하게 COLLERY을 이용하게 하는 데 그 목적이 있습니다.
                        </p>
                    </div>

                    <div className="single-paragraph">
                        <h2>
                            개인정보의 처리 목적
                        </h2>
                        <p className="paragraph">
                            회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며,
                            이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                        </p>
                        <p className="paragraph">
                            1. 홈페이지 회원 가입 및 관리<br />
                            회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별ㆍ인증, 회원자격 유지ㆍ관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지ㆍ통지, 고충 처리 등을 목적으로 개인정보를 처리합니다.
                        </p>
                        <p className="paragraph">
                            2. 재화 또는 서비스 제공<br />
                            서비스 제공, 계약서ㆍ청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제ㆍ정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
                        </p>
                        <p className="paragraph">
                            3. 고충 처리<br />
                            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락ㆍ통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.

                        </p>
                    </div>
                    <div className="single-paragraph">
                        <h2>
                            회원 정보의 수집 및 이용에 대한 안내
                        </h2>
                        <p className="paragraph">
                            1. 개인정보의 수집항목 및 수집방법<br />
                            다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.<br />
                            - 성명 (실명 및 회사명)<br />
                            - 현재 사용하는 E-mail 주소<br />
                            - 닉네임
                        </p>
                        <p className="paragraph">
                            판매 수익금 출금 및 세금 신고를 위해 다음의 정보들이 추가로 수집될 수 있습니다.<br />
                            - 예금주 성명<br />
                            - 은행명<br />
                            - 계좌번호<br />
                            - 작가회원 실명 및 휴대폰 번호 (본인인증을 위한 정보)<br />
                            - 주민등록번호 (1회 세금 신고 후 삭제 및 파기됨)<br />
                        </p>
                        <p className="paragraph">
                            소셜 계정을 통해 회원가입 시 다음의 정보들이 추가로 수집될 수 있습니다.<br />
                            - 네이버 : 아이디, 닉네임, 성별, 연령대, 생일, 프로필 이미지<br />

                            COLLERY은 다음과 같은 방법으로 개인정보를 수집합니다.<br />
                            - 홈페이지를 통한 회원가입, 문의 이메일, 경품 행사 응모<br />
                        </p>
                        <p className="paragraph">
                            2. 회원 정보의 수집종류 및 이용범위<br />
                            회원님이 제공한 개인정보는 오직 회원님에게 최적화된 서비스(신규 서비스의 개발 및 마케팅 광고에 활용)를 제공하기 위해서만 수집, 이용되고 있습니다.<br />
                            우선 COLLERY의 이용을 위해서는 기본적으로 회원님의 실명, E-mail 주소, 사용자 암호 등의 기본 정보가 필요합니다.
                            나아가 새롭고 발전된 특정 서비스의 제공을 위해 회원님의 자발적인 참여와 동의를 조건으로 개별 및 집단적 설문조사 등을 통해 추가적인 정보를 수집하거나,
                            변경된 서비스 내용을 E-mail로 알려드릴 수 있습니다.
                            회원 가입과 동시에 해당 정보를 E-mail을 통해 수신받는 것을 동의한 것으로 간주합니다.
                            회원은 언제든지 E-mail 수신 동의를 거절할 수 있습니다.<br />
                            위의 방법을 통해 수집된 개인정보는 원칙적으로 해당 서비스나 기타 조사 당시 고지 또는 명시된 목적 이외의 용도로는 사용되지 않습니다.
                        </p>
                        <p className="paragraph">
                            단, 법률 제17조에 의거 회원님이 동의하거나 다음의 경우에는 예외로 합니다.<br />
                            - 서비스의 제공에 따른 요금 정산을 위하여 필요한 경우<br />
                            - 통계작성ㆍ학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
                        </p>
                    </div>
                    <div className="single-paragraph">
                        <h2> 개인정보의 보유 기간</h2>
                        <p className="paragraph">
                            1. 개인정보의 보유 기간<br />
                            회원님의 개인정보는 COLLERY의 회원으로서 서비스를 이용하는 동안 COLLERY에 의해 보호되며, 앞서 언급된 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에(탈퇴 시에) 회원 권익을 보호하기 위해 다음과 같이 회원 정보가 일정 기간 보존됩니다.
                            각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                        </p>
                        <p className="paragraph">
                            A. 홈페이지 회원 가입 및 관리: 회원 탈퇴 시까지. 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지<br />
                            - 관계 법령 위반에 따른 수사ㆍ조사 등이 진행 중인 경우에는 해당 수사ㆍ조사 종료 시까지<br />
                            - 홈페이지 이용에 따른 채권ㆍ채무 관계 잔존 시에는 해당 채권ㆍ채무 관계 정산 시까지.<br />
                        </p>
                        <p className="paragraph">
                            B. 재화 또는 서비스 제공: 재화ㆍ서비스 공급 완료 및 요금결제ㆍ정산 완료 시까지. 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지.<br />
                            - 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 표시ㆍ광고, 계약 내용 및 이행 등 거래에 관한 기록<br />
                            &nbsp;→표시ㆍ광고에 관한 기록: 6월<br />
                            &nbsp;→계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년<br />
                            &nbsp;→소비자 불만 또는 분쟁처리에 관한 기록 : 3년<br />
                            - 통신비밀 보호법 제41조에 따른 통신 사실확인자료 보관<br />
                            &nbsp;→가입자 전기통신일시, 개시ㆍ종료 시간, 상대방 가입자 번호, 사용도수, 발신기지국 위치추적자료: 1년<br />
                            &nbsp;→컴퓨터 통신, 인터넷 로그 기록자료, 접속지 추적자료:3개월<br />
                            - 정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행령 제29조에 따른 본인확인 정보 보관<br />
                            &nbsp;→게시판에 정보 게시가 종료된 후 6개월<br />
                        </p>
                        <p className="paragraph">
                            C. 1회 사용 후 즉시 삭제 및 파기되는 정보<br />
                            &nbsp;- 사업 소득세 신고를 위해 출금 신청시 주민등록번호 수집. 익월 10일 전 사업 소득세 신고 후 즉시 삭제 및 파기
                        </p>
                        <p className="paragraph">
                            2. 개인정보의 파기절차<br />
                            COLLERY는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 바로 파기합니다. 파기절차 및 방법은 다음과 같습니다.<br />
                            - 파기절차 : 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용 기간 참조) 일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
                            - 파기방법 : 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                        </p>
                        <p className="paragraph">
                            3. 개인정보의 제삼자 제공<br />
                            COLLERY는 회원님의 개인정보를 위 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 회원님의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조, 제18조에 해당하는 경우에만 개인정보를 제삼자에게 제공합니다.<br />
                            회사는 결제 서비스 및 세금 신고를 위하여 아래와 같이 외부 전문업체에 수집한 개인정보를 위탁하여 운영하고 있습니다.<br />
                            - 위탁대상자 및 위탁업무 내용
                            &nbsp;① NICE페이먼츠 (결제 및 에스크로 서비스)
                            &nbsp;② 페이레터 (결제 서비스)
                            &nbsp;③ 페이플/주식회사 케이에스넷 (결제 서비스)
                            &nbsp;④ 회계법인/세무법인 (세금 신고)<br />
                        </p>
                        <p className="paragraph">
                            4. 개인정보 유출방지를 위한 기술적, 관리적 보호조치<br />
                            COLLERY상의 모든 정보는 아마존 웹서버(AWS)에서 전송, 처리 및 저장이 이루어지며 안전하게 보관되어 처리됩니다. 그러나, 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 서비스를 이용하는 경우 반드시 로그아웃(Log-out)을 정상적으로 하시고 브라우저를 종료하셔서, 공공장소에서의 개인정보 유출 방지에 주의하셔야 합니다.<br />
                        </p>
                        <p className="paragraph">
                            5. 개인정보의 열람, 정정, 삭제 및 동의철회의 방법<br />
                            회원님은 언제든지 제공된 개인 정보를 열람, 정정,
                            삭제를 요청하실 수 있습니다. 개인 정보 열람 및 정정,
                            삭제는 COLLERY의 "회원정보" 메뉴를 선택한 다음 스스로
                            정정이 가능합니다. 개인정보 동의 철회는 COLLERY
                            운영자 메일(contact@nestyle.ai)로 연락을 주시면 본인
                            확인절차를 통하여 즉시 삭제되며, 회원님의 개인정보는 어떤
                            이유나 방법으로도 재생되거나 이용할 수 없도록 처리됩니다. 개인 정보와 관련하여 불만이나 의견이 있으신 분은 COLLERY 운영자 메일(contact@nestyle.ai)로 의견을 주시면 접수 즉시 조치하고 처리 결과를 통보해 드리겠습니다<br />
                        </p>
                        <p className="paragraph">
                            6. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항<br />
                            쿠키란?<br />
                            - COLLERY은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.<br />
                            - 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장된 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.<br />
                            - 쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.<br />

                            회사의 쿠키 사용 목적<br />
                            - COLLERY은 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 더욱 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다.<br />
                            - COLLERY은 쿠키를 통해 서비스의 접속 빈도, 방문 시간, 방문 횟수 등을 분석하여 이용자의 취향과 관심 분야를 파악하여 서비스 제공에 활용합니다.<br />

                            쿠키의 설치/운영 및 거부<br />
                            - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br />
                            - 다만, 쿠키의 저장을 거부할 경우에는 감사 추적 인증서 등 온라인 계약을 진행하면서 COLLERY의 서비스의 기능이 제대로 동작하지 않을 수 있으며, 이와 관련하여 회사는 책임지지 않습니다.<br />
                            - 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.<br />
                            &nbsp;① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.<br />
                            &nbsp;② [개인정보 탭]을 클릭합니다.<br />
                            &nbsp;③ [개인정보 취급 수준]을 설정하시면 됩니다.<br />
                        </p>
                        <p className="paragraph">
                            7. 개인 아이디와 비밀번호 관리<br />
                            회원님이 사용하고 계시는 ID와 비밀번호는 원칙적으로 회원님만이 사용하게 되어 있습니다. COLLERY 의 고의 또는 과실이 없는 경우에, 회원님의 ID와 비밀번호 도용 또는 기타 타인의 사용으로 의해 발생한 문제에 대하여 COLLERY이 책임지지 않습니다. 어떤 상황에서도 비밀번호는 타인에게 알려 주지 마시고 로그온 상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록 특별한 주의를 기울여 주시기 바랍니다.<br />
                        </p>
                        <p className="paragraph">
                            8. 고객의 개인정보 관리 책임자<br />
                            회사는 귀하가 회사의 서비스를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 귀하께서는 회사의 서비스를 이용하시며 발생하는 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당자로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하고 성실하게 답변해드릴 것입니다.<br />
                            개인정보관리책임자<br />
                            성명 : 이용균<br />
                            연락처 : 02-2155-2040<br />
                            전자우편 : contact@nestyle.ai<br />
                            COLLERY 이용 중 피해 사항 신고는 '한국소비자보호원' (http://www.cpb.or.kr) 에서 하실 수 있으며, 개인정보침해에 관련된 신고는 개인정보침해신고센터 (http://www.cyberprivacy.or.kr / 국번 없이 118), 대검찰청 사이버범죄수사단(www.spo.go.kr / 02-3480-3571), 경찰청 사이버안전국 (www.ctrc.go.kr / 국번 없이 182)에서 하실 수 있습니다.<br />
                        </p>
                    </div>
                    <div className="single-paragraph">
                        <h2>개인정보의 보유 기간</h2>
                        <p className="paragraph">
                            본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있으면 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.<br />
                            다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때는 최소 30일 전에 공지하며, 필요하면 이용자 동의를 다시 받을 수도 있습니다.<br />

                        </p>
                    </div>
                </div>
            </EntireWrapper>
        </>
    )
});

export default PersonalInfo;