import React, { useState, useCallback ,useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';
import web3 from 'web3';
import Header from '../../Header';
import Footer from '../../Footer';
import Loader from '../../Loader';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Web3Modal from 'web3modal';
import { create } from 'ipfs-http-client';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ethers } from 'ethers';
import {
    nftaddress, nftmarketaddress
} from '../../../config';

import NFT from '../../../artifacts/contracts/NFT.sol/NFT.json';
import Market from '../../../artifacts/contracts/Market.sol/Market.json';


import { AVAILABLE_BLOCKCHAINS, AVAILABLE_AUDIO_CATEGORIES, MAX_ROYALTY_FEES } from '../../../constants/application';
import { IPFS_URL } from '../../../constants/urls';
import { USER_AUDIO_NFT_MINTING, USER_NFT } from '../../../constants/applicationUrls';
import { startImageMinting, uploadFileToIPFS } from '../../../api/minting';

import './style.css';
import { Gasfee } from '../../Gasfees/Gasfee';
import styled from 'styled-components';
import { SoundOutlined, PictureOutlined } from '@ant-design/icons';
import MessageComponent from "../../MessageCommponent"
import {INIT_MINTING_STATE,LOADING_MINTING} from "../../../reducers/nftTokens"
import {useDispatch,useSelector} from "react-redux";
import { MARKETPLACE,} from '../../../constants/applicationUrls';
//const client = create('https://ipfs.infura.io:5001/api/v0');
import LoadingComponent from "../../LoadingComponent";
import {NFTProductMinting} from '../../../constants/applicationMessage';

const Container = styled.div`
    .content-input-wrap{
        box-shadow: 0 0 15px rgb(0 0 0 / 6%);
        width:auto;height:auto;
        .image-content-wrap{
            cursor:pointer;
            margin-top:50.5px;
            .preview-msg-wrap{
                height:344px;
                background:#7e7e7e;
            }
        }
        .audio-content-wrap{
            cursor:pointer;
            margin-top:25.5px;
            .preview-msg-wrap{
                height:auto;
            }
        }
        .content-input-container{
            width:392px;height:auto;
            padding:0 24px;
            box-sizing:border-box;
            .preview-msg-wrap{
                width:100%;
                >img{
                    display:block;
                    width:100%;height:100%;
                    object-position:50%;
                    object-fit:contain;
                }
                >audio{margin:0 auto;}
                .preview-msg-component{
                    display:block;
                    width:100%;height:48px;
                    .preview-msg-button{
                        display:block;
                        width:100%;height:100%;
                        text-align:center;
                        vertical-align:center;
                        font-size:var(--dashboard-page-button);
                        color:#fff;
                        background:#ff4d2c;
                        border-radius:4.5px;
                        margin-top:9.5px;
                        &:hover{
                            color:#ff4d2c;
                            background:#fff;
                            box-sizing:border-box;
                            border:1px solid #ff4d2c;
                        }
                    }
                }
            }
            .content-title{
                font-size:var(--dashboard-page-title);
                font-weight:bold;
                text-align:center;
                margin-bottom:15.5px;
            }
            .preview-box+.preview-box{
                margin-top:25.5px;
            }
            .preview-box{
                width:100%;height:344px;
                .previewButton-wrap{
                    background:#7e7e7e;
                    width:100%;height:100%;
                    line-height:344px;
                    .previewButton-input{
                        display:none;
                    }
                    .previewButton-upload-label{
                        width:100%;height:100%;
                        color:#fff;
                        line-height:344px;
                        text-align:center;
                        .previewButton-button{
                            
                            display:flex;
                            flex-direction:column;
                            justify-content:center;
                            width:100%;height:100%;
                            span{
                                margin:0 auto;
                                display:block;
                                width:244px;height:58px;
                                font-weight:bold;
                                color:#fff;
                                vertical-align:center;
                                &.explain-text{
                                    line-height:var(--line-height-exp);
                                    font-size:var(--dashboard-page-info);
                                }
                                &.icon{
                                    >span{
                                        font-size:36px;
                                        margin:0 auto; 
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .audio-content-wrap{
                width: 100%;
                height:auto;
                
            }
        }
    }

    .header-wrap{
        margin-bottom:50.5px;
        .page-title{
            font-size:var(--page-title);
            height:95px;
            margin-bottom:15.5px;
            margin-top:15.5px;
            font-weight:bold;
        }
        .page-explain{
            line-height:var(--line-height-exp);
            font-size:var(--explain-text);
            color:#000;
            font-weight:650;
        }    
    }

    .preview-msg-wrap{
        .preview-msg-component{
            line-height:var(--line-height-exp);
            font-size:var(--dashboard-page-info);
            span{
                cursor:pointer;
            }
            margin-bottom:12.5px;
        }
    }

    .form-group{
        .form-select{
            padding:5px;
        }
        &.half{
            width:367.5px;
        }
        label{
            font-size:var(--dashboard-page-title);
            font-weight:bold;
        }
        .form-control,textarea{
            font-size:var(--dashboard-page-info);
        }
        .purpose-of-issue-radio-wrap{
            height:55px;width:100%;
            box-sizing:border-box;
            font-weight:400;
            box-shadow:none;
            padding:10px;
            display:flex;flex-direction:row;
            align-items:center;
            justify-content:flex-start;
            border-radius: 4.5px;
            box-shadow: 0px 0px 0.5px rgba(45, 45, 45,0.3);
            .check-box{
                height:45px;width:auto;
                display:flex;
                align-items:center;
                input{
                    display:inline-block;
                    width:25px;height:25px;
                }
                label{
                    display:inline-block;
                    margin:0px 0px 0px 10px;
                    height:35px;
                    font-weight:400;
                    font-size:var(--dashboard-page-info);
                    line-height:35px;
                }
            }
            .check-box+.check-box{
                margin-left:15.5px;
            }
        }
        .setting_blockchain_type_and_price_wrap{
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:flex-start;
            margin-bottom:15.5px;
            label{
                margin:0px 15.5px 0px 0px;
                font-weight:bold;
                font-size:var(--dashboard-page-title);
                white-space:nowrap;
            }
            .set_blockchain_type{
                font-weight: 400;
                margin-left: 10px;
                color: #454545;
                font-size:var(--dashboard-page-info);
            }
        }
    }
    .text-center{
        .default-btn{
            font-size:var(--dashboard-page-button);
            font-weight:400;
        }
    }
    .previewButton-input{
        font-size:var(--dashboard-page-info);
    }
    .previewButton-button{
        font-size:var(--dashboard-page-info);
        font-weight:400;
    }
    .previewButton-file-name{
        font-size:var(--dashboard-page-info);
        font-weight:400;
    }

    .submit-button-wrap button{
        font-size:var(--dashboard-page-button);
        font-weight:400;
        border-radius:4.5px;
        background-color:#ff4d2c;
        color:#fff;
        margin:0 auto;
        display:block;
        width:245px;height:38px;
        &:hover{
            color:#ff4d2c;
            background:#fff;
            box-sizing:border-box;
            border:1px solid #ff4d2c;
    
        }
    }

    @media screen and (max-width:855px){
        padding:0;
        .header-wrap{
            margin-bottom:25.5px;
            .page-title{
                font-size:var(--tablet-page-title);
                height:55.5px;
                margin-bottom:15.5px;
                margin-top:15.5px;
            }
            .page-explain{
                line-height:var(--line-height-exp);
                font-size:var(--tablet-explain-text);
            }    
        }

        .row{
            padding:0;
            .text-center{
                .default-btn{
                    font-size:var(--tablet-dashboard-page-button);
                }
            }
            .container{
                padding:0;
                .form-container{
                    width:100%;
                    margin-top:10.5px;
                    .item-details-dsce{
                        box-shadow:none;
                        .form-group{
                            .form-select{
                                padding:5px;
                            }
                            &.half{
                                width:367.5px;
                            }
                            label{
                                font-size:var(--tablet-dashboard-page-title);
                                font-weight:bold;
                            }
                            .form-control,textarea{
                                font-size:var(--tablet-dashboard-page-info);
                            }
                            .purpose-of-issue-radio-wrap{
                                justify-content:space-around;
                                border-radius: 4.5px;
                                .check-box{
                                    height:45px;width:auto;
                                    display:flex;
                                    align-items:center;
                                    input{
                                        display:inline-block;
                                        width:15px;height:15px;
                                    }
                                    label{
                                        display:inline-block;
                                        font-weight:400;
                                        margin:0px 0px 0px 3.5px;
                                        font-size:var(--tablet-dashboard-page-info);
                                    }
                                }
                                .check-box+.check-box{
                                    margin-left:10.5px;
                                }
                            }
                            .setting_blockchain_type_and_price_wrap{
                                display:flex;
                                flex-direction:row;
                                align-items:center;
                                justify-content:flex-start;
                                margin-bottom:15.5px;
                                label{
                                    margin:0px 3.5px 0px 0px;
                                    font-weight:bold;
                                    font-size:var(--tablet-dashboard-page-title);
                                    white-space:nowrap;
                                }
                                .set_blockchain_type{
                                    font-weight: 400;
                                    font-size:var(--tablet-dashboard-page-info);
                                }
                            }
                        }
                    }
                }
                .content-input-wrap{
                    width:100%;
                    box-shadow:none;
                    .content-input-container{
                        width:100%;
                        display:flex;
                        flex-direction:row;
                        justify-content:space-evenly;
                        align-items:center;
                        .preview-box{
                            width:100%;
                            height:30.5vw;
                            
                            .previewButton-wrap{
                                line-height:30.5vw;
                                .previewButton-upload-label{
                                    line-height:30.5vw;
                                    .previewButton-button{
                                        line-height:30.5vw;
                                        span{
                                            width:100%;
                                            &.explain-text{
                                                line-height:var(--line-height-exp);
                                                font-size:var(--tablet-dashboard-page-info);
                                            }
                                            &.icon{
                                                height: 38px;
                                                >span{
                                                    font-size: 28px;
                                                    margin:0 auto; 
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                        .image-content-wrap{
                            width:30.5vw;
                            height:auto;
                            cursor:pointer;
                            margin-top:15.5px;
                            .content-title{
                                font-size:var(--tablet-dashboard-page-title);
                                white-space:nowrap;
                            }
                            .preview-msg-wrap{
                                height:100%;
                                background:#fff;
                                .preview-msg-component{
                                    .preview-msg-button{
                                        height:34.5px;
                                        font-size:var(--tablet-dashboard-page-button);
                                    }
                                }
                            }
                        }
                        .audio-content-wrap{
                            width:30.5vw;
                            cursor:pointer;
                            margin-top:15.5px;
                            .content-title{
                                font-size:var(--tablet-dashboard-page-title);
                                white-space:nowrap;
                            }
                            .preview-msg-wrap{
                                height:100%;
                                background:#fff;
                                audio{
                                    width:100%;
                                }
                                .preview-msg-component{
                                    .preview-msg-button{
                                        height:34.5px;
                                        font-size:var(--tablet-dashboard-page-button);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }

    @media screen and (max-width:587px){
        padding:0;
        .header-wrap{
            margin-bottom:25.5px;
            .page-title{
                font-size:var(--mobile-page-title);
                height:35.5px;
                margin-bottom:10.5px;
                margin-top:10.5px;
            }
            .page-explain{
                line-height:var(--line-height-exp);
                font-size:var(--mobile-explain-text);
            }    
        }

        .row{
            padding:0;
            .text-center{
                .default-btn{
                    font-size:var(--mobile-dashboard-page-button);
                }
            }
            .container{
                padding:0;
                .form-container{
                    width:100%;
                    margin-top:10.5px;
                    .item-details-dsce{
                        box-shadow:none;
                        .col-lg-12{
                            padding:0;
                        }
                        .form-group{
                            padding:0;
                            .form-select{
                                padding:5px;
                            }
                            &.half{
                                width:95.5%;
                            }
                            label{
                                font-size:var(--mobile-dashboard-page-title);
                                font-weight:bold;
                                &.por{
                                    display:flex;
                                    flex-direction:column;
                                    line-height:22.5px;
                                }
                            }
                            .form-control,textarea{
                                font-size:var(--mobile-dashboard-page-info);
                            }
                            .purpose-of-issue-radio-wrap{
                                justify-content:space-around;
                                border-radius: 4.5px;
                                padding:0;
                                box-shadow:none;
                                .check-box{
                                    height:45px;width:auto;
                                    display:flex;
                                    align-items:center;
                                    input{
                                        display:inline-block;
                                        width:15px;height:15px;
                                    }
                                    label{
                                        display:inline-block;
                                        font-weight:400;
                                        margin:0px 0px 0px 3.5px;
                                        font-size:var(--mobile-dashboard-page-info);
                                    }
                                }
                                .check-box+.check-box{
                                    margin-left:5.5px;
                                }
                            }
                            .setting_blockchain_type_and_price_wrap{
                                display:flex;
                                flex-direction:row;
                                align-items:center;
                                justify-content:flex-start;
                                margin-bottom:15.5px;
                                label{
                                    margin:0px 3.5px 0px 0px;
                                    font-weight:bold;
                                    font-size:var(--mobile-dashboard-page-title);
                                    white-space:nowrap;
                                }
                                .set_blockchain_type{
                                    font-weight: 400;
                                    font-size:var(--mobile-dashboard-page-info);
                                }
                            }
                        }
                    }
                }
                .content-input-wrap{
                    width:100%;
                    box-shadow:none;
                    .content-input-container{
                        width:100%;
                        display:flex;
                        flex-direction:row;
                        justify-content:space-between;
                        align-items:center;
                        .preview-box{
                            width:100%;
                            height:35.5vw;
                            
                            .previewButton-wrap{
                                line-height:30.5vw;
                                .previewButton-upload-label{
                                    line-height:35.5vw;
                                    .previewButton-button{
                                        line-height:35.5vw;
                                        span{
                                            width:100%;
                                            &.explain-text{
                                                display:none;
                                            }
                                            &.icon{
                                                height: 28px;
                                                >span{
                                                    font-size: 28px;
                                                    margin:0 auto; 
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                        .image-content-wrap{
                            width:35.5vw;
                            height:auto;
                            cursor:pointer;
                            margin-top:15.5px;
                            .content-title{
                                font-size:var(--mobile-dashboard-page-title);
                                white-space:nowrap;
                            }
                            .preview-msg-wrap{
                                height:100%;
                                background:#fff;
                                .preview-msg-component{
                                    .preview-msg-button{
                                        height:34.5px;
                                        font-size:var(--mobile-dashboard-page-button);
                                    }
                                }
                            }
                        }
                        .audio-content-wrap{
                            width:35.5vw;
                            cursor:pointer;
                            margin-top:15.5px;
                            .content-title{
                                font-size:var(--mobile-dashboard-page-title);
                                white-space:nowrap;
                            }
                            .preview-msg-wrap{
                                height:100%;
                                background:#fff;
                                audio{
                                    width:100%;
                                }
                                .preview-msg-component{
                                    .preview-msg-button{
                                        height:34.5px;
                                        font-size:var(--mobile-dashboard-page-button);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
`;

function UserAudioMinting({ currentUser }) {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(AVAILABLE_AUDIO_CATEGORIES.length ? AVAILABLE_AUDIO_CATEGORIES[0].value : 'other');
    const [blockchain, setBlockchain] = useState(AVAILABLE_BLOCKCHAINS.length ? AVAILABLE_BLOCKCHAINS[0] : { 'etherium': 'eth' });
    const [price, setPrice] = useState('');
    const [royalty, setRoyalty] = useState(0);

    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const [AudioFile, setAudioFile] = useState(null);
    const [AudioPreview, setAudioPreview] = useState(null);

    const [mintMessage, setMintMessage] = useState('');
    const [mintProcessing, setMintProcessing] = useState(false);
    const [mintCompleted, setMintCompleted] = useState(false);
    const [mintError, setMintError] = useState(false);
    const [purposeOfIssue, setPurposeOfIssue] = useState('sell');

    const history = useHistory();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);

    const [pricerent, setPriceRent] = useState('')
    const [showMessageComponent,setShowMessageComponent]=useState(false);

    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const priceRef = useRef(null);
    const priceRentRef = useRef(null);
    const [username,setUserName]=useState('')

    const{
        loadingMinting ,
        mintingSuccess,
        mintingData,
        mintingFailure
    } = useSelector(state=>state.nftTokens);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(!loadingMinting && mintingSuccess){
            setMintMessage(mintingData);
            setShowMessageComponent(true);
        }else if(mintingFailure){
            setMintMessage(mintingFailure);
            setShowMessageComponent(true);
        }
    },[loadingMinting,
        mintingSuccess,
        mintingData,
        mintingFailure])


    useEffect(() => {

        loadUserMe()
    
    }, [currentUser.uid])

    async function loadUserMe(){
        await axios.get(`https://api.collery.io/getuserinfo/${currentUser.uid}`).then((res)=>{

        setUserName(res.data[0].firstname); 
        console.log("USERNAME===========================", username);

        }).catch((err) => {
            console.log(err)
        })
    }
        

    const handleSubmit = async (event) => {
        event.preventDefault();
       /*  if(purposeOfIssue=='both'&&price<pricerent){
            alert("임대 가격이 판매 가격보다 클 수는 없습니다.");
            return false;
        } */
        /* handleShow(); */
        setMintCompleted(false);
        setMintError(false);
        setMintProcessing(true);
        setMintMessage(NFTProductMinting.uploadmessage);

        const formData = new FormData();
        formData.append('file', AudioFile);
        formData.append('name', title);
        formData.append('description', description);
        formData.append('price', !price.match(',')?price:price.split(',').join(''));
        formData.append('userid', username);
        formData.append('username', username);
        formData.append('rent', purposeOfIssue);
        formData.append('pricerent', !pricerent.match(',')?pricerent:pricerent.split(',').join(''));
        formData.append('category', category);
        formData.append('format', 'audio');
        formData.append('bgimage', imageFile);
        dispatch({type:LOADING_MINTING,data:formData,mintType:'audio'});
        /* await axios.post(`https://api.collery.io/mintaudionft`, formData).then((response) => {

            console.log(response.data)

            setMintCompleted(true);
            setMintError(false);
            setMintProcessing(false);
            setMintMessage('Done');

            toast.info("Created successfully", { position: toast.POSITION.BOTTOM_RIGHT });


        }).catch((err) => {

            console.log(err)

            setMintCompleted(true);
            setMintError(false);
            setMintProcessing(false);
            setMintMessage('error');

            toast.error("Error, please try later", { position: toast.POSITION.BOTTOM_RIGHT });

        }) */


    }

    const onChangePurposeOfIssue = useCallback((evt) => {
        const { value } = evt.target;
        setPurposeOfIssue(value);
    }, []);

    const handleImageSelect = useCallback(selectedImage => {
        setImageFile(selectedImage);
        setImagePreview(URL.createObjectURL(selectedImage));
    }, [])

    const handleAudioSelect = useCallback(selectedAudio => {
        setAudioFile(selectedAudio);
        setAudioPreview(URL.createObjectURL(selectedAudio));
    }, []);

    /* const onChangeBlockChain = useCallback((evt) => {
        const { value } = evt.target;
        const idx = AVAILABLE_BLOCKCHAINS.findIndex(elem => elem.name === value);
        setBlockchain(AVAILABLE_BLOCKCHAINS[idx]);
    }, []); */

    const confirmFunction = useCallback((flag)=>{
        if(mintingSuccess){
            history.push(USER_AUDIO_NFT_MINTING);
            setTitle('')
            setDescription('')
            setPrice('')
            setImagePreview(null)
            setImageFile(null)
            setAudioFile(null)
            setAudioPreview(null)

        }
        setShowMessageComponent(flag);
        dispatch({type:INIT_MINTING_STATE});
    },
    [mintingSuccess]);

    const onPriceChange = useCallback((evt)=>{
        let {value}=evt.target;
        value=value.split(',').join('');
        if(value.match(/\d+/)){
            setPrice(value.match(/.{1,3}(?=(.{3})*$)/g).join(','));
        }else{
            if(priceRef.current){
                setPrice('');
                priceRef.current.focus();
            }
            alert("오직 숫자만 입력해야 합니다.")
        }
    },[priceRef.current]);

    const onPriceRentChange = useCallback((evt)=>{
        let {value}=evt.target;
        value=value.split(',').join('');
        if(value.match(/\d+/)){
            setPriceRent(value.match(/.{1,3}(?=(.{3})*$)/g).join(','));
        }else{
            if(priceRentRef.current){
                setPriceRent('');
                priceRentRef.current.focus();
            }
            alert("오직 숫자만 입력해야 합니다.")
        }
    },[priceRentRef.current]);

    return (
        <>
            <Header />

            <div className="create-nft-area pt-50 pb-70" style={{padding:0}}>
                <Container className="container">
                    {
                        loadingMinting
                        &&<LoadingComponent message={mintMessage}/>
                    }
                    {
                        showMessageComponent&&
                        <MessageComponent message={mintMessage} cancelFunction={confirmFunction}/>
                    }
                    <div className="header-wrap">
                        <h1 className="page-title">Create Audio NFT</h1>
                        {/* <p className="page-explain">
                            판매목적의 발행은 발행할 체인을 선택하여야 하고, 체인의 종류에 따라 가스비가 발생합니다.<br /> 임대나 증명용으로 발행할때에는 발행시
                            비용이 발생하지 않습니다
                        </p> */}
                    </div>
                    <div className="row">

                        <div className="col-lg-12">

                            <div className="container">
                                {/*  <div className="section-title">
                                    <h1 >Create Audio NFT</h1>
                                </div> */}

                                <div className="row pt-30">

                                    <div className="content-input-wrap">
                                        <div className="content-input-container">
                                            <div className="audio-content-wrap">
                                                <h2 className="content-title">Upload Audio</h2>
                                                {!AudioPreview ?
                                                    (
                                                        <div className="preview-box">

                                                            <div className="previewButton-wrap">
                                                                <input className="previewButton-input" type="file" name="attachments[]" accept="audio/wav,audio/mp3" id="upload" onChange={(e) => handleAudioSelect(e.target.files[0])} />
                                                                <p className="previewButton-upload-label">

                                                                    <label className="previewButton-button" htmlFor="upload">
                                                                        <span className="icon"><SoundOutlined /></span>
                                                                        <span className="explain-text">Upload audio file</span>
                                                                    </label>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="preview-msg-wrap">
                                                            <audio src={AudioPreview} controls controlsList="nodownload">
                                                            </audio>
                                                            <p className="preview-msg-component">
                                                                <button className="preview-msg-button" onClick={() => setAudioPreview('')}>Change audio</button> <br />
                                                            </p>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="image-content-wrap">
                                                <h2 className="content-title">Upload Image</h2>
                                                {!imagePreview ? (<div className="preview-box">

                                                    <div className="previewButton-wrap">
                                                        <input className="previewButton-input" type="file" name="attachments[]" accept="image/*" id="uploadbg" onChange={(e) => handleImageSelect(e.target.files[0])} />
                                                        <p className="previewButton-upload-label">
                                                            <label className="previewButton-button ripple-effect" htmlFor="uploadbg">
                                                                <span className="icon"><PictureOutlined /></span>
                                                                <span className="explain-text">Upload background <br/> image</span>
                                                            </label>
                                                        </p>
                                                    </div>
                                                </div>) : (
                                                    <div className="preview-msg-wrap">
                                                        <img src={imagePreview} />
                                                        <p className="preview-msg-component">
                                                            <button className="preview-msg-button" onClick={() => setImagePreview('')}>Change image </button> <br />
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-container col-lg-8 col-md-6">
                                        <div className="item-details-dsce collection-form">

                                            <form onSubmit={handleSubmit}>
                                                <div className="row">

                                                    <div className="col-lg-12 ">
                                                        <div className="form-group">
                                                            <label>Title (제목)</label>
                                                            <input type="text" value={title} className="form-control" placeholder="My First NFT" onChange={(e) => setTitle(e.target.value)} required />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Description (설명)</label>
                                                            {/* <input type="password" value={description} className="form-control" placeholder="Password" onChange={(e) => setDescription(e.target.value)} /> */}
                                                            <textarea value={description} className="form-control" cols="30" rows="5" placeholder="e. g. This NFT shows the nature is beautiful" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                        </div>
                                                    </div>



                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label  className="por">Purpose Of Registeration <span>(발행 목적)</span></label>
                                                            {/* <input type="password" value={description} className="form-control" placeholder="Password" onChange={(e) => setDescription(e.target.value)} /> */}
                                                            <div className="purpose-of-issue-radio-wrap">
                                                                <p className="check-box">
                                                                    <input onChange={onChangePurposeOfIssue} id="sell" type="radio" name="purpose-of-issue" value="sell" checked={purposeOfIssue === 'sell' ? true : false} />
                                                                    <label htmlFor="sell">Sell</label>
                                                                </p>
                                                                <p className="check-box">
                                                                    <input onChange={onChangePurposeOfIssue} id="rent" type="radio" name="purpose-of-issue" value="rent" checked={purposeOfIssue === 'rent' ? true : false} />
                                                                    <label htmlFor="rent">Rent</label>
                                                                </p>
                                                                
                                                                <p className="check-box">
                                                                    <input onChange={onChangePurposeOfIssue} id="proof" type="radio" name="purpose-of-issue" value="proof" checked={purposeOfIssue === 'proof' ? true : false} />
                                                                    <label htmlFor="proof">CopyRight Proof</label>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group half">
                                                            <label>Category (카테고리)</label>
                                                            <select className="form-select form-control" onChange={(e) => setCategory(e.target.value)}>
                                                                {!AVAILABLE_AUDIO_CATEGORIES.length && (<option value='other'>Other</option>)}
                                                                {AVAILABLE_AUDIO_CATEGORIES && AVAILABLE_AUDIO_CATEGORIES.map((category, index) => {
                                                                    return (<option key={category.value + `-` + index} value={category.value}>{category.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {(purposeOfIssue !== 'proof') && (
                                                        <>
                                                            {
                                                                purposeOfIssue !== 'rent' &&
                                                                <div className="col-lg-12">
                                                                    <div className="form-group half">
                                                                        <p className="setting_blockchain_type_and_price_wrap">
                                                                            <label>Price (가격) <span className="set_blockchain_type">WON</span></label>
                                                                            {/* <select onChange={onChangeBlockChain} className="set_blockchain_type" name="blockchain_type">
                                                                                {
                                                                                    AVAILABLE_BLOCKCHAINS.map((v, i) => (
                                                                                        <option key={`${v.name}_${v.value}`} value={v.name} selected={blockchain.name === v.name ? true : false}>{v.name} ({v.value})</option>
                                                                                    ))
                                                                                }
                                                                            </select> */}
                                                                        </p>
                                                                        <input placeholder="판매 할 가격을 입력하세요" ref={priceRef} type="text" value={price} className="form-control" onChange={(e) => onPriceChange(e)} required />
                                                                    </div>
                                                                </div>
                                                            }


                                                            {purposeOfIssue !== 'sell' && (purposeOfIssue === 'both' || purposeOfIssue === 'rent') && <div className="col-lg-12">
                                                                <div className="form-group half">
                                                                    {purposeOfIssue !== 'rent' ? (
                                                                        <label>Unit Price (/day) (1일당 임대 가격) </label>
                                                                    ) : (
                                                                        <>
                                                                            <p className="setting_blockchain_type_and_price_wrap">
                                                                                <label>
                                                                                    Unit Price (/day) (1일당 임대 가격)
                                                                                    <span className="set_blockchain_type">WON</span>
                                                                                </label>
                                                                                {/* <select onChange={onChangeBlockChain} className="set_blockchain_type" name="blockchain_type">
                                                                                    {
                                                                                        AVAILABLE_BLOCKCHAINS.map((v, i) => (
                                                                                            <option key={`${v.name}_${v.value}`} value={v.name} selected={blockchain.name === v.name ? true : false}>{v.name} ({v.value})</option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                            </p>
                                                                        </>
                                                                    )}

                                                                    <input placeholder="Rent 가격을 입력하세요" ref={priceRentRef} type="text" value={pricerent} className="form-control" onChange={(e) => onPriceRentChange(e)} required />
                                                                </div>
                                                            </div>}
                                                        </>
                                                    )
                                                    }

                                                    {/* <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Price <sup><small>[{blockchain}]</small></sup></label>
                                                            <input type="number" value={price} min={0} step={0.00001} className="form-control" onChange={(e) => setPrice(e.target.value)} required />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                              <input type="checkbox" value={rent} onChange={(e) => setRent(e.target.checked)}/> <label> Permit Rental (Unit price/Day)</label> 
                                                          
                                                        </div>
                                                    </div>

                                                   {rent && <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Unit Price (/day) </label>
                                                            <input type="number" value={pricerent} min={0} step={0.00001} className="form-control" onChange={(e) => setPriceRent(e.target.value)} required />
                                                        </div>
                                                    </div>} */}


                                                    <div className="col-lg-12 col-md-12 pt-50">
                                                        <div className="submit-button-wrap text-center">
                                                            <button type="submit"  >Start Minting</button>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 text-center">
                                                        {mintProcessing && (<>
                                                            <Loader size={10} />
                                                            <br />
                                                            <h5 className="text-warning">{mintMessage}</h5>
                                                        </>)}
                                                    </div>

                                                    <div className="col-lg-12 text-center pt-50">
                                                        {mintError && (
                                                            <div className="involved-card">
                                                                <div className="icon">
                                                                    <i className="ri-close-circle-line text-danger"></i>
                                                                </div>
                                                                <h3 className="text-danger">{mintMessage}</h3>
                                                            </div>
                                                        )}

                                                    </div>

                                                    {imagePreview && !mintProcessing && !mintCompleted && (<div className="col-lg-12 col-md-12 pt-50">
                                                        <h6 className="text-center text-danger">
                                                            NFT발행을 처리하는 과정이 시간이 다소 걸릴수 있습니다. 
                                                            조금만 기다려 주세요.
                                                            <br />
                                                        </h6>

                                                    </div>)}
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </Container>
            </div>

            <div>


                {/*<Modal 
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size="sm"

                
            >
                <Modal.Header>
                <Modal.Title className="text-sm">Minting your NFT ...</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <Loader size={10} />
                
                </Modal.Body>
                
                <a className="btn btn-light btn-sm text-center text-green-500" onClick={handleClose}>
                    Run in background
                </a>
            
            
                </Modal>*/}

            </div>


            <Footer />
        </>
    )
}

export default UserAudioMinting;

