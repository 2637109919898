import React, { useState, useRef, useEffect } from 'react';
import ContentLoader from "react-content-loader";

import { AVAILABLE_BLOCKCHAINS } from '../../constants/application';

import './style.css';

function UserWallets(){

    const availableWalletsLength = [...AVAILABLE_BLOCKCHAINS.map(blockchain => blockchain.value).keys()];

    const cardRef = useRef();

    const defaultWidth = 100;

    const [width, setWidth] = useState(defaultWidth);
    
    const getWalletCardDivSize = () => {
        const newWidth = cardRef.current ? cardRef.current.clientWidth : defaultWidth;
        setWidth(newWidth);
    };

    useEffect(() => {
        getWalletCardDivSize();
    }, [availableWalletsLength]);

    useEffect(() => {
        window.addEventListener("resize", getWalletCardDivSize);

        return window.removeEventListener("resize", getWalletCardDivSize);
    }, []);
  
    return (
        <>

            {1 && (<div className="row pt-50">

            {availableWalletsLength.map((NFT, index) => {
                return (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={`uwall-${index}`}>
                    
                        <div className="user-wallet-card-link">
                            <div className="user-wallet-card" ref={cardRef} >
                                <div className="text-center">
                                    <ContentLoader 
                                        speed={1}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                        width={Math.abs(width-90)}
                                        height={Math.abs(width-120)}
                                    >
                                        <rect x="0" y="0" rx="0" ry="0" width={Math.abs(width-90)} height={Math.abs(width-120)} />
                                    </ContentLoader>
                                </div>

                                <div className="wallet-details">

                                    <div className="row pt-20">
                                    
                                        <div className="col-lg-12 col-12">
                                            <div className="blockchain-details">
                                                <div className="content">
                                                    <ContentLoader 
                                                        speed={1}
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                        width={Math.abs(width)}
                                                        height={50}
                                                    >
                                                        <circle cx="25" cy="25" r="25" />
                                                        <rect x="70" y="10" rx="0" ry="0" width={150} height={30} />
                                                    </ContentLoader>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="wallet-info">
                                        <ContentLoader 
                                            speed={1}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                            width={Math.abs(width)}
                                            height={50}
                                        >
                                            <rect x="0" y="0" rx="0" ry="0" width={50} height={20} />
                                            <rect x="70" y="0" rx="0" ry="0" width={150} height={20} />
                                            <rect x="0" y="30" rx="0" ry="0" width={50} height={20} />
                                            <rect x="70" y="30" rx="0" ry="0" width={150} height={20} />
                                        </ContentLoader>
                                    </div>

                                    <div className="item-details-in-contentt">
                                        <ContentLoader 
                                            speed={1}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                            width={Math.abs(width -40)}
                                            height={80}
                                        >
                                            <rect x="0" y="0" rx="0" ry="0" width={Math.abs(width - 40)} height={80} />
                                        </ContentLoader>
                                    </div>

                                    <div className="item-details-btn pt-20">
                                        <ContentLoader 
                                            speed={1}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                            width={Math.abs(width - 40)}
                                            height={40}
                                        >
                                            <rect x="0" y="0" rx="0" ry="0" width={Math.abs(width - 40)} height={40} />
                                        </ContentLoader>
                                    </div>
                                </div>


                            </div>
                            
                        </div>
                </div>)
            })}

            </div>)}

        </>
    )
}

export default UserWallets;
