{/* PUBLIC URLS */}
export const HOMEPAGE = `/`;
export const MARKETPLACE = `/marketplace`
export const NFT_DETAILS = `/nft`

{/* PRIVATE URLS */}
export const USER_AREA = `/user`;
export const USER_LOGIN = `${USER_AREA}/login`;
export const USER_SIGNUP_CERTIFICATION = `${USER_AREA}/user_signup_certificate`;
export const USER_SIGNUP = `${USER_AREA}/signup`;
export const USER_FORGOT_PASSWORD = `${USER_AREA}/forgotpassword`;
export const USER_DASHBOARD = `${USER_AREA}/dashboard`;
export const USER_PROFILE = `${USER_AREA}/profile`;
export const USER_PASSWORD = `${USER_AREA}/password`;

export const USER_NFT = `${USER_AREA}/nft`;
export const USER_NFT_MINTING = `${USER_AREA}/nft/minting`;
export const USER_IMAGE_NFT_MINTING = `${USER_AREA}/nft/mintimage`;
export const USER_VIDEO_NFT_MINTING = `${USER_AREA}/nft/mintvideo`;
export const USER_AUDIO_NFT_MINTING = `${USER_AREA}/nft/mintaudio`;
export const USER_NFT_DETAILS = `${USER_AREA}/nft/details`;

export const USER_COLLECTION = `${USER_AREA}/collection`;
export const USER_COLLECTION_CREATE = `${USER_AREA}/collection/create`;
export const USER_COLLECTION_DETAILS = `${USER_AREA}/collection/details`;

export const USER_WALLET = `${USER_AREA}/wallet`;

{/* EXTERNAL URLS */}
