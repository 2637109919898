import axios from "axios";
import {fork,all, call, takeLatest, put} from "redux-saga/effects";
import {
    LOADING_MINTING,
    LOAD_MINTING_SUCCESS,
    LOAD_MINTING_FAILURE,
    LOADING_CHANGE_PRICE_INFO,
    LOAD_CHANGE_PRICE_INFO_SUCCESS,
    LOAD_CHANGE_PRICE_INFO_FAILURE,
    LOADING_NFT_PRODUCTS,
    LOAD_NFT_PRODUCTS_SUCCESS,
    LOAD_NFT_PRODUCTS_FAILURE,
    LOADING_MY_NFT_PRODUCTS,
    LOAD_MY_NFT_PRODUCTS_SUCCESS,
    LOAD_MY_NFT_PRODUCTS_FAILURE,
    LOAD_MY_MINTED_PRODUCTS,
    LOAD_MY_MINTED_PRODUCTS_SUCCESS,
    LOAD_MY_MINTED_PRODUCTS_FAILURE,
    LOAD_BOUGHT_PRODUCTS,
    LOAD_BOUGHT_PRODUCTS_SUCCESS,
    LOAD_BOUGHT_PRODUCTS_FAILURE,
    LOAD_RENT_PRODUCTS,
    LOAD_RENT_PRODUCTS_SUCCESS,
    LOAD_RENT_PRODUCTS_FAILURE
} from "../reducers/nftTokens"
import { NFT_URL } from "../constants/urls";
import {NFTMarketPlaceLoading,NFTProductMinting} from '../constants/applicationMessage';
import {UserNFTPage} from '../constants/applicationMessage';

const mintingNFTAPI=(formData,mintType)=>{
    const URL = mintType==='image'?`https://api.collery.io/mintnft`:'https://api.collery.io/mintaudionft'
    return axios.post(URL,formData);
}

function* mintingNFTFunction(action){
    try{
        const res= yield call(mintingNFTAPI,action.data,action.mintType);
        yield put({type:LOAD_MINTING_SUCCESS,data:NFTProductMinting.uploadSuccessMessage})
    }catch(err){
        if(err.response){
            const httpStatus = err.response.status;
            let message = '';
            if(httpStatus>=400&&httpStatus<500){
                message=NFTProductMinting.uploadErrorMessage;
            }else if(httpStatus<500){
                message=NFTProductMinting.uploadserverErrorMessage;;
            }
            yield put({type:LOAD_MINTING_FAILURE,data:message});
        }else{
            console.error(err);
            yield put({type:LOAD_MINTING_FAILURE,data:'브라우져 오류 발생!'});
        }
    }
}

function* mintingNFTSaga(){
    yield takeLatest(LOADING_MINTING,mintingNFTFunction);
}

const changePriceAPI = (priceInfoData,type)=>{
    return axios.post(type==="Resell"?
        'https://api.collery.io/resellnft':'https://api.collery.io/changenftprice'
    ,priceInfoData)
}

function* changePriceFunction(action){
    try{
        const resp = yield call(changePriceAPI,action.data,action.send_type);
        if(resp.data?.respomse==="Error while processing"){
            yield put({type:LOAD_CHANGE_PRICE_INFO_FAILURE,data:"데이터 전송 오류"});
        }else{
            yield put({
                type:LOAD_CHANGE_PRICE_INFO_SUCCESS,
                data:action.send_type==="Edit"
                ?UserNFTPage.successChangingNFTPriceMessage
                :UserNFTPage.successResellingNFTPriceMessage,
                id:action.data.id,
            })
        }
    }catch(err){
        if(err.response){
            const httpStatus = err.response.status;
            let message = '';
            if(httpStatus>=400&&httpStatus<500){
                message=UserNFTPage.errorModifyNFTInfo;
            }else if(httpStatus<500){
                message=UserNFTPage.serverErrorModifyNFTInfo;
            }
            yield put({type:LOAD_CHANGE_PRICE_INFO_FAILURE,data:message});
        }else{
            console.error(err);
            yield put({type:LOAD_CHANGE_PRICE_INFO_FAILURE,data:'브라우져 오류 발생!'});
        }
        
    }
}

function* changePriceSaga(){
    yield takeLatest(LOADING_CHANGE_PRICE_INFO,changePriceFunction);
}
/* LOAD_NFT_PRODUCTS_SUCCESS
LOAD_NFT_PRODUCTS_FAILURE */

const loadNFTProductsByCategoryAPI = (data,send_type)=>{
    let url ='';
    let isPost=true;
    console.log(data);
    let dataToSend=null;
    switch(send_type){
        case 'all_data':{
            const {limit,offset}=data;
            url=`https://api.collery.io/getallnfts/${limit},${offset}`;
            isPost=false;
            break;
        }
        case 'Category':{
            const {limit,offset}=data;
            url=`https://api.collery.io/getnftcat/${limit},${offset}`;
            isPost=true;
            dataToSend={category:data.category}
            break;
        }
        case 'NFT-Type':{
            const {limit,offset}=data;
            dataToSend={type:data.type}
            url=`https://api.collery.io/getnftbytype/${limit},${offset}`;
            isPost=true;
            break;
        }
        case 'Price Order':{
            const {limit,offset}=data;
            if(data.category==='낮은 가격순'){
                url=`https://api.collery.io/getnftpricelow/${limit},${offset}`;
            }else if(data.category==='높은 가격순'){
                url=`https://api.collery.io/getnftpricehigh/${limit},${offset}`;
            }
            isPost=false;
            break;
        }
        default:{
            isPost=false;
            url='https://api.collery.io/getallnfts/30,0';
            break;
        }
    }
    console.log(url);
    console.log(isPost);
    return isPost?axios.post(url,dataToSend):axios.get(url);
}

function* loadNFTProductsFunction(action){
    try{
        const resp = yield call(loadNFTProductsByCategoryAPI,action.data,action.send_type); 
        console.log(resp);
        if(resp.data.response==="Error while processing"){
            yield put({type:LOAD_NFT_PRODUCTS_FAILURE,data:'데이터 전송 오류'});
        }else{
            console.log(resp.data);
            yield put({type:LOAD_NFT_PRODUCTS_SUCCESS,data:resp.data});
        }
    }catch(err){
        if(err.response){
            const httpStatus = err.response.status;
            let message = '';
            if(httpStatus>=400&&httpStatus<500){
                message=NFTMarketPlaceLoading.loadErrorMessage
            }else if(httpStatus<500){
                message=NFTMarketPlaceLoading.serverErrorMessage;
            }
            yield put({type:LOAD_NFT_PRODUCTS_FAILURE,data:message});
        }else{
            console.error(err);
            yield put({type:LOAD_NFT_PRODUCTS_FAILURE,data:'브라우져 오류 발생!'});
        }
    }
}

function* loadNFTProductsSaga(){
    yield takeLatest(LOADING_NFT_PRODUCTS,loadNFTProductsFunction)
}

const loadMyMintedNFTDataAPI = (userid,limit,offset)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const resp = await axios.get(`https://api.collery.io/getusernft/${userid},${limit},${offset}`);
            resolve({key:'myNfts',data:resp.data});
        }catch(err){
            console.log(err);
            reject(err);
        }
    }) 
};

const loadMyBoughtNFTDataAPI = (userid,limit,offset)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const resp = await axios.get(`https://api.collery.io/getboughtnft/${userid},${limit},${offset}`);
            resolve({key:'bought',data:resp.data});
        }catch(err){
            console.log(err);
            reject(err);
        }
    })
};

const loadMyRentedNFTDataAPI = (userid,limit,offset)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const resp = await axios.get(`https://api.collery.io/getuserrent/${userid},${limit},${offset}`);
            resolve({key:'rent',data:resp.data});
        }catch(err){
            console.log(err);
            reject(err);
        }  
    })
    //return axios.get(`https://api.collery.io/getuserrent/${userid}`);
}

const callAllMyNftAPI = async(userid,limit,offset)=>{
    try{
        const NFTData = await Promise.all([
            loadMyMintedNFTDataAPI(userid,limit,offset),
            loadMyBoughtNFTDataAPI(userid,limit,offset),
            loadMyRentedNFTDataAPI(userid,limit,offset),
        ])
        return [...NFTData];
    }catch(err){
        console.log(err);
        throw err;
    }
}

function* loadMyNFTProducts(action){
    try{
        const {userid,limit,offset} = action.data;
        const apiType = action;
        console.log(`apiType : ${apiType}`);
        const myMintedResp = yield call(callAllMyNftAPI,userid,limit,offset);
        console.log(myMintedResp);
        /* const myBoughtResp = yield call(loadMyBoughtNFTDataAPI,userid);
        const myRentedResp = yield call(loadMyRentedNFTDataAPI,userid); */
        yield put({type:LOAD_MY_NFT_PRODUCTS_SUCCESS,data:{
            myMintedResp,
        }})
        
    }catch(err){
        if(err.response){
            if(err.response.status>=400&&err.response.status<500){
                yield put({type:LOAD_MY_NFT_PRODUCTS_FAILURE,data:UserNFTPage.errorLoadingMyNFT});
            }else if(err.response.status>=500){
                yield put({type:LOAD_MY_NFT_PRODUCTS_FAILURE,data:UserNFTPage.serverErrorLoadingMyNFT});
            }
        }else{
            yield put({type:LOAD_MY_NFT_PRODUCTS_FAILURE,data:UserNFTPage.errorLoadingMyNFT});
        }
    }
}

function* loadMyNFTProductsSaga(){
    yield takeLatest(LOADING_MY_NFT_PRODUCTS,loadMyNFTProducts);
}

/* 
LOAD_MY_MINTED_PRODUCTS
LOAD_MY_MINTED_PRODUCTS_SUCCESS
LOAD_MY_MINTED_PRODUCTS_FAILURE
 */

const loadMyMintedNFTDataSingleAPI = (userid,limit,offset)=>{
    return axios.get(`https://api.collery.io/getusernft/${userid},${limit},${offset}`);
};

function* loadMyMintedNFTProducts(action){
    try{
        const {userid,limit,offset} = action.data;
        const resp=yield call(loadMyMintedNFTDataSingleAPI,userid,limit,offset);
        console.log(resp.data);
        yield put({type:LOAD_MY_MINTED_PRODUCTS_SUCCESS,data:resp.data});
    }catch(err){
        if(err.response){
            if(err.response.status>=400&&err.response.status<500){
                yield put({type:LOAD_MY_MINTED_PRODUCTS_FAILURE,data:UserNFTPage.errorLoadingMyNFT});
            }else if(err.response.status>=500){
                yield put({type:LOAD_MY_MINTED_PRODUCTS_FAILURE,data:UserNFTPage.serverErrorLoadingMyNFT});
            }
        }else{
            yield put({type:LOAD_MY_MINTED_PRODUCTS_FAILURE,data:UserNFTPage.errorLoadingMyNFT});
        }
    }
}

function* loadMyMintedNFTProductsSaga(){
    yield takeLatest(LOAD_MY_MINTED_PRODUCTS,loadMyMintedNFTProducts)
}

/* 
    LOAD_BOUGHT_PRODUCTS
LOAD_BOUGHT_PRODUCTS_SUCCESS
LOAD_BOUGHT_PRODUCTS_FAILURE
*/

const loadMyBoughtNFTDataSingleAPI = (userid,limit,offset)=>{
    return axios.get(`https://api.collery.io/getboughtnft/${userid},${limit},${offset}`);
};

function* loadBoughtNFTProducts(action){
    try{
        const {userid,limit,offset} = action.data;
        const resp=yield call(loadMyBoughtNFTDataSingleAPI,userid,limit,offset);
        console.log(resp.data);
        yield put({type:LOAD_BOUGHT_PRODUCTS_SUCCESS,data:resp.data});
    }catch(err){
        if(err.response){
            if(err.response.status>=400&&err.response.status<500){
                yield put({type:LOAD_BOUGHT_PRODUCTS_FAILURE,data:UserNFTPage.errorLoadingMyNFT});
            }else if(err.response.status>=500){
                yield put({type:LOAD_BOUGHT_PRODUCTS_FAILURE,data:UserNFTPage.serverErrorLoadingMyNFT});
            }
        }else{
            yield put({type:LOAD_BOUGHT_PRODUCTS_FAILURE,data:UserNFTPage.errorLoadingMyNFT});
        }
    }
}

function* loadBoughtNFTProductsSaga(){
    yield takeLatest(LOAD_BOUGHT_PRODUCTS,loadBoughtNFTProducts);
}

/* LOAD_RENT_PRODUCTS,
    LOAD_RENT_PRODUCTS_SUCCESS,
    LOAD_RENT_PRODUCTS_FAILURE
*/

const loadMyRentNFTDataSingleAPI=(userid,limit,offset)=>{
    return axios.get(`https://api.collery.io/getuserrent/${userid},${limit},${offset}`);
}

function* loadRentNFTProducts(action){
    try{
        const {userid,limit,offset} = action.data;
        const resp=yield call(loadMyRentNFTDataSingleAPI,userid,limit,offset);
        console.log(resp.data);
        yield put({type:LOAD_RENT_PRODUCTS_SUCCESS,data:resp.data});
    }catch(err){
        if(err.response){
            if(err.response.status>=400&&err.response.status<500){
                yield put({type:LOAD_RENT_PRODUCTS_FAILURE,data:UserNFTPage.errorLoadingMyNFT});
            }else if(err.response.status>=500){
                yield put({type:LOAD_RENT_PRODUCTS_FAILURE,data:UserNFTPage.serverErrorLoadingMyNFT});
            }
        }else{
            yield put({type:LOAD_RENT_PRODUCTS_FAILURE,data:UserNFTPage.errorLoadingMyNFT});
        }
    }
}   

function* loadRentNFTProductsSaga(){
    yield takeLatest(LOAD_RENT_PRODUCTS,loadRentNFTProducts);
}

export default function* nftTokensSaga(){
    yield all([
        fork(mintingNFTSaga),
        fork(changePriceSaga),
        fork(loadNFTProductsSaga),
        fork(loadMyNFTProductsSaga),
        fork(loadMyMintedNFTProductsSaga),
        fork(loadBoughtNFTProductsSaga),
        fork(loadRentNFTProductsSaga)
    ]);
}