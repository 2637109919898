import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams, useLocation } from 'react-router';
import Web3Modal from "web3modal";
import web3 from 'web3';
import { Link } from 'react-router-dom';
import { getNFTDetails, getNFTHistory, getUserNFTs, purchaseNFT } from '../../api/nft';
import { USER_LOGIN, USER_NFT } from '../../constants/applicationUrls';
import { EXPLORERS } from '../../constants/urls';
import { useAuth } from '../../contexts/AuthContext';
import { ethers } from 'ethers';
import { Gasfee } from '../Gasfees/Gasfee';
import { toast } from 'react-toastify';

import Footer from '../Footer';
import Header from '../Header';
import Loader from '../Loader';
import axios from 'axios';
import './style.css';

import {
    nftaddress, nftmarketaddress
} from '../../config';

import NFT from '../../artifacts/contracts/NFT.sol/NFT.json';
import Market from '../../artifacts/contracts/Market.sol/Market.json';
import styled from "styled-components";
import { FileDoneOutlined, LockOutlined } from "@ant-design/icons";
import MediaPlayerComponent from './MediaPlayerComponent';
import LoadingComponent from '../LoadingComponent';
import { NFTProductDetail } from '../../constants/applicationMessage'

const EntireContainer = styled.div`
    width:100%;
    height:auto;
    padding:50.5px 0;
    min-height:${window.innerHeight}px;
    @media screen and (max-width:855px){
        padding:25.5px 0;
    }
`;

const Wrapper = styled.div`
    height:auto;
    width:69vw;
    margin:0 auto;
    display:flex;
    align-items:center;
    @media screen and (max-width:855px){
        width:auto;
    }
`;

const RowComponent = styled.div`
    width:100%;
    height:auto;
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:space-between;
    .nft-contents-wrap{
        width:35.5vw;
        height:auto;
        .nft-contents-component{
            width:100%;height:auto;
            padding-right:1.5px;
            .nft-contents-image{
                width:100%;height:auto;
                overflow:hidden;
                box-sizing:border-box;
                box-shadow: 0px 0px 2.5px #7e7e7e;
                >img{
                    display:block;
                    width:100%;height:auto;
                    object-fit:contain;
                    object-position:50% 50%;
                }
            }
        }
    }
    .nft-info-wrap{
        width:30.5vw;
        height:auto;
        .nft-info-component{
            width:100%;height:auto;
            .owner-info-container{
                width:100%;height:45px;
                margin-bottom:25.5px;
                .owner-info-wrapper{
                    width:100%;height:100%;
                    .owner-info-box{
                        line-height:22.75px;
                        width:100%;height:100%;
                        vertical-align:middle;
                        font-size:var(--tablet-detail-page-subtext);
                        font-weight:400;
                        .owned-by{
                            font-size:var(--detail-page-subtext);
                            font-weight:bold;
                        }
                    }
                }
            }
            .item-details-category-container{
                width:100%;height:45px;
                margin-bottom:25.5px;
                .item-details-category-wrapper{
                    width:100%;height:100%;
                    vertical-align:middle;
                    line-height:22.5px;
                    color:#000;
                    .category-title{
                        font-weight:bold;
                        font-size:var(--detail-page-subtext);
                    }
                    .category{
                        font-weight:500;
                        font-size:25px;
                    }
                }
            }
            .title-section-wrap{
                margin-bottom: 25px;
                >h3{
                    height:58px;
                    font-size:var(--detail-page-title);
                    font-weight:bold;
                    line-height:58px;
                }
                .decription-area{
                    line-height:var(--line-height-exp);
                    font-size:var(--dashboard-page-info);
                    margin:10px 0px;
                    padding:0;
                    text-align:justify;
                }
                .icon-info{
                    display:flex;
                    font-size:var(--dashboard-page-info);
                    align-items:center;
                    justify-content:flex-start;
                    margin:55.5px 0 25.5px 0;
                    >p{
                        display:flex;
                        span{
                            color:#ff4d2c;
                            margin-right:3.5px;
                        }
                    }
                    p+p{
                        margin-left:15.5px;
                    }
                }
            }
            .nft-datetime{
                font-size:var(--detail-page-subtext);
                .nft-datetime-title{
                    font-weight:bold;
                }
            }
            .nft-price-info-wrap{   
                width:100%;margin-top:70px;
                .nft-price-info-for-rent-component{
                    .price-for-rent{
                        width:90%;height:auto;
                        >p{
                            width:100%;height:100%;
                            line-height:60px;
                            color:#000;
                            font-weight:bold;
                            font-size:var(--detail-page-subtext);
                        }
                    }
                    .rent-button-wrap{
                        width:90%;height:60px;
                        margin-top:15.5px;
                        display:flex;
                        align-items:center;
                        justify-content:space-between;
                        >input{
                            display:inline-block;
                            height:100%;
                            width:45%;
                            padding:10.5px;
                            box-sizing:border-box;
                            border-radius:4.5px;
                            border:1px solid #e5e5e5;
                            font-size:var(--detail-page-button);
                        }
                        >button{
                            display:inline-block;
                            height:55.5px;
                            width:45%;
                            font-weight:bold;
                            border-radius:4.5px;
                            margin-left:10.5px;
                            background:rgb(24,104,183);
                            color:#fff;
                            font-size:var(--dashboard-page-button);
                        }
                    }
                }
                .nft-price-info-for-buying-component{
                    margin-bottom:45.5px;
                    .price-for-buying{
                        background:#e5e5e5;
                        border-radius:15.5px;
                        width:90%;height:90px;
                        >p{
                            width:100%;height:100%;
                            line-height:90px;
                            font-weight:bold;

                            font-size:var(--dashboard-page-title);
                        }
                        margin-bottom:25.5px;
                    }
                    .buying-button-wrap{
                        width:90%;
                        height:55.5px;
                        
                        >button{
                            display:block;
                            width:100%;height:100%;
                            border-radius:4.5px;
                            background:#ff4d2c;
                            font-weight:bold;
                            color:#fff;
                            font-size:var(--dashboard-page-button);
                        }
                    }
                }
            }
            
        }
    }
    @media screen and (max-width:855px){
        flex-direction:column;
        .nft-contents-wrap{
            width:85.5vw;
            height:auto;
            margin:0 auto;
            .nft-contents-component{
                width:100%;height:auto;
                padding-right:1.5px;
                .nft-contents-image{
                    >img{
                    }
                }
            }
        }
        .nft-info-wrap{
            width:85.5vw;
            height:auto;
            margin:0 auto;
            .nft-info-component{
                width:100%;height:auto;
                .owner-info-container{
                    width:100%;height:45px;
                    margin-bottom:15.5px;
                    .owner-info-wrapper{
                        width:100%;height:100%;
                        .owner-info-box{
                            width:100%;height:100%;
                            vertical-align:middle;
                            line-height: 45px;
                            font-size:var(--tablet-detail-page-subtext);
                            font-weight:400;
                            .owned-by{
                                font-size:var(--tablet-detail-page-subtext);
                            }
                        }
                    }
                }
                .item-details-category-container{
                    width:100%;height:45px;
                    margin-bottom:15.5px;
                    .item-details-category-wrapper{
                        width:100%;height:100%;
                        vertical-align:middle;
                        line-height: 45px;
                        color:#000;
                        .category-title{
                            font-weight:bold;
                            font-size:var(--tablet-detail-page-subtext);
                        }
                        .category{
                            font-weight:500;
                            font-size:25px;
                        }
                    }
                }
                .title-section-wrap{
                    margin-top: 15.5px;
                    margin-bottom: 15.5px;
                    >h3{
                        height:48px;
                        font-size:var(--tablet-detail-page-title);
                        font-weight:bold;
                        line-height:48px;
                    }
                    .decription-area{
                        line-height:var(--line-height-exp);
                        font-size:var(--tablet-detail-page-subtext);
                        margin:10px 0px;
                        padding:0;
                        text-align:justify;
                    }
                    .icon-info{
                        display:flex;
                        font-size:var(--tablet-detail-page-subtext);
                        align-items:center;
                        justify-content:flex-start;
                        margin:25.5px 0 12.75px 0;
                        >p{
                            display:flex;
                            span{
                                color:#ff4d2c;
                                margin-right:3.5px;
                            }
                        }
                        p+p{
                            margin-left:15.5px;
                        }
                    }
                }
                .nft-datetime{
                    font-size:var(--tablet-detail-page-subtext);
                    .nft-datetime-title{
                        font-weight:bold;
                    }
                }
                .nft-price-info-wrap{   
                    width:100%;
                    margin-top:0;
                    .nft-price-info-for-rent-component{
                        .price-for-rent{
                            width:100%;height:auto;
                            >p{
                                width:100%;height:100%;
                                line-height:60px;
                                color:#000;
                                font-weight:bold;
                                font-size:var(--tablet-detail-page-subtext);
                            }
                        }
                        .rent-button-wrap{
                            width:100%;height:60px;
                            margin-top:15.5px;
                            display:flex;
                            align-items:center;
                            justify-content:space-between;
                            >input{
                                display:inline-block;
                                height:100%;
                                width:45%;
                                padding:10.5px;
                                box-sizing:border-box;
                                border-radius:4.5px;
                                border:1px solid #e5e5e5;
                                font-size:var(--tablet-detail-page-button);
                            }
                            >button{
                                display:inline-block;
                                height:55.5px;
                                width:45%;
                                font-weight:bold;
                                border-radius:4.5px;
                                margin-left:10.5px;
                                background:rgb(24,104,183);
                                color:#fff;
                                font-size:var(--tablet-detail-page-button);
                            }
                        }
                    }
                    .nft-price-info-for-buying-component{
                        margin-bottom:22.75px;
                        .price-for-buying{
                            background:#e5e5e5;
                            border-radius:15.5px;
                            width:100%;height:90px;
                            >p{
                                width:100%;height:100%;
                                line-height:90px;
                                font-weight:bold;
                                font-size:var(--tablet-detail-page-subtitle);
                            }
                            margin-bottom:17.5px;
                        }
                        .buying-button-wrap{
                            height:55.5px;
                            width:100%;
                            >button{
                                font-size:var(--tablet-detail-page-button);
                            }
                        }
                    }
                }
                
            }
        }
    }

    @media screen and (max-width:578px){
        flex-direction:column;
        .nft-contents-wrap{
            width:85.5vw;
            height:auto;
            margin:0 auto;
            .nft-contents-component{
                width:100%;height:auto;
                padding-right:1.5px;
                .nft-contents-image{
                    >img{
                    }
                }
            }
        }
        .nft-info-wrap{
            width:85.5vw;
            .nft-info-component{
                .owner-info-container{
                    width:100%;height:22.75px;
                    margin-bottom:10.5px;
                    .owner-info-wrapper{
                        width:100%;height:100%;
                        .owner-info-box{
                            width:100%;height:100%;
                            vertical-align:middle;
                            font-size:var(--mobile-detail-page-subtext);
                            font-weight:400;
                            .owned-by{
                                font-size:var(--mobile-detail-page-subtext);
                            }
                        }
                    }
                }
                .item-details-category-container{
                    width:100%;height:22.75px;
                    margin-bottom:10.5px;
                    .item-details-category-wrapper{
                        width:100%;height:100%;
                        vertical-align:middle;
                        color:#000;
                        .category-title{
                            font-weight:bold;
                            font-size:var(--mobile-detail-page-subtext);
                        }
                        .category{
                            font-weight:500;
                            font-size:var(--mobile-detail-page-subtext);
                        }
                    }
                }
                .title-section-wrap{
                    margin-top: 10.5px;
                    margin-bottom: 10.5px;
                    >h3{
                        height:38px;
                        font-size:var(--mobile-detail-page-title);
                        font-weight:bold;
                        line-height:38px;
                    }
                    .decription-area{
                        line-height:var(--line-height-exp);
                        font-size:var(--mobile-detail-page-subtext);
                        margin:7.5px 0px;
                        padding:0;
                        text-align:justify;
                    }
                    .icon-info{
                        display:flex;
                        font-size:var(--mobile-detail-page-subtext);
                        align-items:center;
                        justify-content:flex-start;
                        margin:17.5px 0 7.75px 0;
                        >p{
                            display:flex;
                            span{
                                color:#ff4d2c;
                                margin-right:3.5px;
                            }
                        }
                        p+p{
                            margin-left:15.5px;
                        }
                    }
                }
                .nft-datetime{
                    font-size:var(--mobile-detail-page-subtext);
                    .nft-datetime-title{
                        font-weight:bold;
                    }
                }
                .nft-price-info-wrap{   
                    width:100%;
                    margin-top:0;
                    .nft-price-info-for-rent-component{
                        .price-for-rent{
                            >p{
                                width:100%;height:100%;
                                line-height:60px;
                                color:#000;
                                font-weight:bold;
                                font-size:var(--mobile-detail-page-subtext);
                            }
                        }
                        .rent-button-wrap{
                            width:100%;height:35.5px;
                            margin-top:10.5px;
                            display:flex;
                            align-items:center;
                            justify-content:space-between;
                            >input{
                                display:inline-block;
                                height:100%;
                                width:45%;
                                padding:10.5px;
                                box-sizing:border-box;
                                border-radius:4.5px;
                                border:1px solid #e5e5e5;
                                font-size:var(--mobile-detail-page-button);
                            }
                            >button{
                                display:inline-block;
                                height:100%;
                                width:45%;
                                font-weight:bold;
                                border-radius:4.5px;
                                margin-left:10.5px;
                                background:rgb(24,104,183);
                                color:#fff;
                                font-size:var(--mobile-detail-page-button);
                            }
                        }
                    }
                    .nft-price-info-for-buying-component{
                        margin-bottom:22.75px;
                        .price-for-buying{
                            background:#e5e5e5;
                            border-radius:15.5px;
                            width:100%;height:90px;
                            >p{
                                width:100%;height:100%;
                                line-height:90px;
                                font-weight:bold;
                                font-size:var(--mobile-detail-page-subtitle);
                            }
                            margin-bottom:17.5px;
                        }
                        .buying-button-wrap{
                            height:55.5px;
                            width:100%;
                            >button{
                                height:35.5px;
                                font-size:var(--mobile-detail-page-button);
                            }
                        }
                    }
                }
                
            }
        }
    }
    
`;


let urlmedia
const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};

function Marketplace({ }) {


    const routeHistory = useHistory();

    //const [playing, toggle] = useAudio(urlmedia);

    const history = useHistory();
    const { state } = useLocation();
    const params = useParams();
    const [datas, setDatas] = useState([]);
    const [showMediaComponent, setMediaCompoenent] = useState(null);
    const [rentalperiod, setRentalP] = useState('')

    const { nftTokenId: tokenId } = useParams();
    const { currentUser } = useAuth();
    const [loadingPurchase, setLoadingPurchase] = useState(false);
    const [purchaseDone, setPurchaseDone] = useState(false);
    const [purchaseError, setPurchaseError] = useState(false);
    const [loadData, setLoadingData] = useState(false);
    const [rentdays, setRentdays] = useState();
    const userId = currentUser ? currentUser.uid : null
    const rentInputRef = useRef(null);

    const { isLoading: isNFTLoading, error: isNFTError, data: nftData } = useQuery(['getNFTDetails', tokenId], () => getNFTDetails(tokenId));
    const { isLoading: isHistoryLoading, error: isHistoryError, data: historyData } = useQuery(['getNFTHistory', tokenId], () => getNFTHistory(tokenId));

    const { isLoading: isUserBoughtNFTLoading, error: isUserBoughtNFTError, data: userBoughtNFTData } = useQuery(['getUserBoughtNFTs', userId, 'bought'], () => getUserNFTs(userId, 'bought'));
    const { isLoading: isUserCreatedNFTLoading, error: isUserCreatedNFTError, data: userCreatedNFTData } = useQuery(['getUserCreatedNFTs', userId, 'created'], () => getUserNFTs(userId, 'created'));

    const { isLoading: isLoadingNFTDetail, data: NFTDetailData, error: loadNFTDataError } = useQuery(
        ['NFT-DETAIL', Number(tokenId)],
        () => {
            return axios.get(`https://api.collery.io/getonenft/${Number(tokenId)}`)
        }, {
        cacheTime: 20000,
    }
    );

    const handlePurchase = (event, nft) => {
        event.preventDefault();
        setLoadingPurchase(true);

        purchaseNFT(userId, nft.tokenid, nft.price, nft.chain, nft.royalty).then((response) => {
            if (response.txId.length === 0) {
                setPurchaseError(true);
            }
            else {
                setPurchaseDone(true);
            }
        }).catch((error) => {
            setPurchaseError(true);
        });

    }


    if (!isUserBoughtNFTLoading && !isUserBoughtNFTError) {
        let nftAlreadyPurchased = false;
        if (userBoughtNFTData) {
            nftAlreadyPurchased = userBoughtNFTData.find((obj) => obj.Tokenid === tokenId);
        }

        if (nftAlreadyPurchased) {
            routeHistory.push(`${USER_NFT}?option=bought`);
        }
    }

    if (!isUserCreatedNFTLoading && !isUserCreatedNFTError) {
        let nftOnlyCreated = false;
        if (userCreatedNFTData) {
            nftOnlyCreated = userCreatedNFTData.find((obj) => obj.Tokenid === tokenId);
        }

        if (nftOnlyCreated) {
            routeHistory.push(`${USER_NFT}?option=created`);
        }
    }

    /* useEffect(() => {
        loadNFTs()
    }, []); */

    useEffect(() => {
        if (!isLoadingNFTDetail && NFTDetailData) {
            console.log(NFTDetailData);
            setDatas(NFTDetailData.data);
        }
    }, [isLoadingNFTDetail, loadNFTDataError])



    /*  async function loadNFTs() {
         const datat = JSON.stringify({ id: Number(tokenId) })
         setLoadingData(true)
         await axios.get(`https://api.collery.io/getonenft/${Number(tokenId)}`).then((res) => {
             setDatas(res.data)
             setLoadingData(false)
         }).catch((err) => {
             setLoadingData(false);
 
         })
     } */
    async function buyNft(buyer, id, owner, price) {

        setLoadingData(true)

        const data = { userid: buyer, id: Number(id), tokenid: id, currentowner: owner, soldprice: Number(price) }

        try {

            axios.post(`https://api.collery.io/buynft`, data).then((response) => {

                setLoadingData(false);

                history.push('/user/nft')

            }).catch((error) => {
                setLoadingData(false);
            })

        } catch (error) {

            setLoadingData(false);
            console.log(error)


        }


    }

    const onChangeRentPrice = useCallback((evt) => {
        const { target: thisElement } = evt;
        const { value } = thisElement;
        const numberValue = Number(value.split(',').join(''));
        console.log(numberValue);
        if (!isNaN(numberValue)) {
            setRentalP(String(numberValue).match(/.{1,3}(?=(.{3})*$)/g).join(','));
        } else {
            alert("숫자만 입력해주세요!");
        }
    }, [])

    const rentNft = useCallback(async (renter, id, rentalperiod, owner, totalprice) => {

        const rentalperiodNumber = Number(rentalperiod.split(',').join(''));
        if(isNaN(rentalperiodNumber)){
            alert("숫자를 입력해 주세요!");
            return false;
        }
        if(rentalperiod===''){
            alert("임대할 일 수를 입력해 주세요. (365일 이하)");
            return false;
        }
        if(rentalperiodNumber===0){
            alert("임대할 일 수를 1일 이상, 365일 이하로 입력해 주세요!");
            return false;
        }
        if(rentalperiodNumber>=365){
            alert("임대하는 날짜는 365일 이하로 입력해 주세요");
            return false;
        }

        setLoadingData(true);

        const data = { renter: renter, tokenid: id + '', rentalperiod: rentalperiod, owner: owner, totalprice: totalprice + '' }
        console.log(data)
        try {

            axios.post(`https://api.collery.io/rentnft`, data).then((response) => {//https://api.collery.io

                console.log(response.data)
                setLoadingData(false);

                history.push('/user/nft')

            }).catch((error) => {
                console.log(error)
                setLoadingData(false);
            })

        } catch (error) {

            setLoadingData(false);
            console.log(error)


        }
    },[])

function ComingSoon(){
    alert("Coming Soon !")
}

    return (
        <>
            <Header />

            <EntireContainer >
                <Wrapper>


                    {datas?.map((nft, i) => (

                        <RowComponent key={i}>
                            {/* <h2 style={{fontSize:'30px'}}>{nft?.name}</h2> */}
                            <div className="nft-contents-wrap">
                                <div className="nft-contents-component">
                                    <div className="nft-contents-image">
                                        {nft.format === "image" && <img src={nft?.image} alt="Images" />}
                                        {nft.format === "audio" && <img src={nft?.bgimage} alt="Images" />}
                                    </div>
                                    {
                                        nft?.media &&
                                        <MediaPlayerComponent media={nft.media} />
                                    }
                                </div>
                            </div>
                            <div className="nft-info-wrap">
                                <div className="nft-info-component">
                                    <div className="title-section-wrap">
                                        <h3>{nft?.name.slice(0, nft.name.lastIndexOf("#"))}</h3>
                                        {/* <p className="decription-area" >
                                            {nft?.description}
                                        </p> */}
                                        <div className="icon-info">
                                            <p>
                                                <span>Collery Certified</span>
                                                <FileDoneOutlined />
                                            </p>
                                            <p >
                                                <span>Vault-In</span>
                                                <LockOutlined />
                                            </p>
                                        </div>
                                    </div>

                                    <div className="owner-info-container">
                                        <div className="owner-info-wrapper">
                                            <div className="owner-info-box">
                                                <span className="owned-by">Owned By </span> @{nft.firstowner.slice(0, 10)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item-details-category-container">
                                        {/* <div className="item-details-title">
                                        <h3>Current Price 324 ETH</h3>
                                        <p>$1200</p>
                                        <span>1/10</span>
                                    </div> */}


                                        <p className="item-details-category-wrapper">
                                            <span className="category-title">Category</span>&nbsp;
                                            <span className="category">: {
                                                nft.category.split('').map((v,i)=>{
                                                    if(i===0){return v.toUpperCase();}
                                                    else{return v.toLowerCase();}
                                                }).join('')
                                            }</span>
                                        </p>

                                        {/* {state?.NFT_register_type===2 && <p>Rent price<b>: {nft.pricerent} Matic/Day</b></p>} */}

                                    </div>


                                    <div className="nft-price-info-wrap">
                                        {nft.rent === 'both' &&
                                            <div className="nft-price-info-for-buying-component">
                                                <div className="price-for-buying">
                                                    <p>
                                                        {nft.price.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON
                                                    </p>
                                                </div>
                                                <div className="buying-button-wrap">
                                                    <button onClick={() => ComingSoon()}>
                                                        Buy {/**buyNft(currentUser.uid, nft.id, nft.firstowner, nft.price) */}
                                                    </button>
                                                </div>
                                            </div>}

                                        {nft.rent === 'sell' &&
                                            <div className="nft-price-info-for-buying-component">
                                                <div className="price-for-buying">
                                                    <p>
                                                        {nft.price.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON
                                                    </p>
                                                </div>
                                                <div className="buying-button-wrap">
                                                    <button onClick={() => ComingSoon()}>
                                                        Buy {/**buyNft(currentUser.uid, nft.id, nft.firstowner, nft.price) */}
                                                    </button>
                                                </div>
                                            </div>}


                                        {
                                            nft.rent === 'rent' &&
                                            <div className="nft-price-info-for-rent-component">
                                                <div className="price-for-rent">
                                                    <p>
                                                        Rent it for {nft.pricerent.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON / Day
                                                    </p>
                                                </div>
                                                <div className="rent-button-wrap">
                                                    <input value={rentalperiod} ref={rentInputRef} type="text" placeholder='Number of days' onChange={(e) => onChangeRentPrice(e)} />
                                                    <button onClick={() => ComingSoon()}>
                                                        Rent {/**rentNft(currentUser.uid, nft.id, rentalperiod, nft.firstowner, Number(nft.pricerent) * Number(rentalperiod)) */}
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {nft.rent === 'proof' && <div className="nft-price-info-wrap">
                                        <div className="nft-datetime">
                                            <span className="nft-datetime-title">Date of Registration</span> : 15/05/2022
                                        </div>
                                    </div>}



                                    {/* <div className="item-details-btn">
                                        {!currentUser && !loadingPurchase && (<Link to={USER_LOGIN} className="default-btn border-radius-50">Login To Buy</Link>)}
                                        {currentUser && !loadingPurchase && (<div className="default-btn border-radius-50" key={i} style={{ marginBottom: '1rem', cursor: 'pointer', background: '#ff4d2c' }} onClick={() => buyNft(nft, params.nftTokenId)}>Buy</div>)}

                                        {nft.rent == "true" && <b>Or rent it for {nft.pricerent} Matic/Day</b>} < br />

                                        {currentUser && nft.rent == "true" && !loadingPurchase && (<div className='d-flex items-center'><input type='number' required placeholder='Number of days' className='flex-grow' value={rentdays} onChange={(e) => setRentdays(e.target.value)} /><div style={{ marginLeft: '2rem' }} className="default-btn bg-dark border-radius-50 pl-10" onClick={() => rentNft(nft, rentdays, params.nftTokenId)}>Rent</div></div>)}

                                        {loadingPurchase && !purchaseDone && <Loader size={10} />}
                                        {purchaseDone && (
                                            <div className="involved-card">
                                                <div className="icon">
                                                    <i className="ri-checkbox-circle-line text-success"></i>
                                                </div>
                                                <h3 className="text-success">Purchase Successful</h3>
                                                <Link to={`${USER_NFT}?option=bought`} className="default-btn">Show My NFT</Link>
                                            </div>
                                        )}
                                        {purchaseError && (
                                            <div className="involved-card">
                                                <div className="icon">
                                                    <i className="ri-close-circle-line text-danger"></i>
                                                </div>
                                                <h3 className="text-danger">Oops! Something Happened.</h3>
                                                <h6 className="text-danger">Please Try Again After Sometime.</h6>
                                            </div>
                                        )}
                                    </div> */}
                                </div>
                            </div>
                        </RowComponent>))}


                </Wrapper>
            </EntireContainer>

            {isLoadingNFTDetail && <LoadingComponent message={NFTProductDetail.message} />}



            <Footer />
        </>
    )
}

export default Marketplace;

