import React from 'react';
import loaderImage from '../../assets/images/loader.gif';

import './style.css';
import styled from 'styled-components';

const LoadingContainer = styled.div`
    width:100%;height:100%;
    position:absolute;
    top:0;left:0;
    display:flex;
    align-items:center;
    justify-content:center;
    >img{
        display:block;
        width:150px;height:100px;
    }
`;

function Loader() {

    return (
        <LoadingContainer>
            <img src={loaderImage}/>
        </LoadingContainer>
    )
}

export default Loader;

