
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Header from '../../Header';
import Footer from '../../Footer';

import './style.css';
import { USER_DASHBOARD, USER_LOGIN } from '../../../constants/applicationUrls';
import { useAuth } from '../../../contexts/AuthContext';
import styled from "styled-components";
import Helmet from 'react-helmet';
import Collery_logo_03 from '../../../assets/Collary_logo_03.jpg';

const EntireContainer = styled.div`
    padding-top:0;
    min-height:${window.innerHeight-84-192}px;
    .container{
        .row{
            .user-password-form-area{
                .section-title{
                    margin: 12.5px auto 25.5px auto;
                    >h2{
                        text-align:left;
                        font-size:var(--page-title);
                        font-weight:bold;
                    }
                }
                .user-password-form{
                    margin-top:0px;
                    form{
                        .row{
                            .col-lg-12{
                                .form-group{
                                    label{
                                        font-size:var(--dashboard-page-title);
                                    }
                                    >input{
                                        font-size:var(--dashboard-page-info);
                                    }
                                }
                                
                                .text-secondary{
                                    font-size:var(--dashboard-page-info);
                                }
                            }
                            .button-container{
                                width:100%;height:auto;
                                display:flex;
                                justify-content:space-evenly;
                                align-items:center;
                                margin-bottom: 15.5px;
                                button{
                                    border-radius:4.5px;
                                    display:block;
                                    height: 35px;
                                    padding: 0 5.5px;
                                    width: auto;
                                    background: #ff4d2c;
                                    color: #fff;
                                    padding:0 5.5px;
                                    width:auto;height:35px;
                                    font-size:var(--dashboard-page-button);
                                    font-weight:400;
                                    background-color:#ff4d2c;
                                }
                                .to-dashboard-btn{
                                    border-radius:4.5px;
                                    line-height: 35px;
                                    display:block;
                                    height: 35px;
                                    padding: 0 5.5px;
                                    width: auto;
                                    background: #454545;
                                    color: #fff;
                                    padding:0 5.5px;
                                    width:auto;height:35px;
                                    font-size:var(--dashboard-page-button);
                                    font-weight:400;
                                    background-color:#ff4d2c;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:855px){
        min-height:${window.innerHeight}px;
        .container{
            .row{
                .user-password-form-area{
                    .section-title{
                        margin: 12.5px auto 25.5px auto;
                        >h2{
                            text-align:left;
                            font-size:var(--tablet-page-title);
                            font-weight:bold;
                        }
                    }
                    .user-password-form{
                        margin-top:0px;
                        form{
                            .row{
                                .col-lg-12{
                                    .form-group{
                                        label{
                                            font-size:var(--tablet-dashboard-page-title);
                                        }
                                        >input{
                                            font-size:var(--tablet-dashboard-page-info);
                                        }
                                    }
                                    .text-secondary{
                                        line-height:22.5px;
                                        font-size:var(--tablet-dashboard-page-info);
                                    }
                                }
                                .button-container{
                                    button{
                                        font-size:var(--tablet-dashboard-page-button);
                                    }
                                    .to-dashboard-btn{
                                        font-size:var(--tablet-dashboard-page-button);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width:578px){
        .container{
            .row{
                .user-password-form-area{
                    .section-title{
                        margin: 12.5px auto 25.5px auto;
                        >h2{
                            text-align:left;
                            font-size:var(--mobile-page-title);
                            font-weight:bold;
                        }
                    }
                    .user-password-form{
                        margin-top:0px;
                        form{
                            .row{
                                .col-lg-12{
                                    
                                    .form-group{
                                        label{
                                            font-size:var(--mobile-dashboard-page-title);
                                        }
                                        >input{
                                            font-size:var(--mobile-dashboard-page-info);
                                        }
                                    }
                                    
                                    
                                    .text-secondary{
                                        padding:-top:15.5px;
                                        font-size:var(--mobile-dashboard-page-info);
                                    }
                                }
                                .button-container{
                                    button{
                                        font-size:var(--mobile-dashboard-page-button);
                                    }
                                    .to-dashboard-btn{
                                        font-size:var(--mobile-dashboard-page-button);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

function Password({ currentUser }){

    const { login, logout, updatePassword } = useAuth();
    const history = useHistory();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setError('');

        if (newPassword !== confirmPassword)
        {
            return setError("New Password & Confirm Password Does Not Match");
        }

        setLoading(true);

        login(currentUser.email, oldPassword).then((response) => {
            updatePassword(newPassword).then((response) => {
                logout().then((response) => {
                    history.push(USER_LOGIN);
                }).catch((error) => {
                    setError(error.message);
                    setLoading(false);
                });
            }).catch((error) => {
                setError(error.message);
                setLoading(false);
            });
        }).catch((error) => {
            setError(error.message);
            setLoading(false);
        });

    }
  
    return (
        <>
            <Helmet>
                <link rel="icon" href={Collery_logo_03}/>
            </Helmet>

            <Header />
            
            <EntireContainer className="author-profile-area pt-50 pb-70">
                <div className="container">

                    <div className="row">
                        
                        <div className="col-sm-2"></div>

                        <div className="col-sm-8">

                            <div className="user-password-form-area">
                                <div className="section-title">
                                    <h2 className="text-center">Change Password</h2>
                                </div>
                                
                                <div className="user-password-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>이전 비밀번호 (Old Password)</label>
                                                    <input type="password" name="name" value={oldPassword} className="form-control" placeholder="Old Password"  onChange={(e) => setOldPassword(e.target.value)} required />
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>새 비밀번호 (New Password)</label>
                                                    <input type="password" name="name" value={newPassword} className="form-control" placeholder="New Password"  onChange={(e) => setNewPassword(e.target.value)} required />
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input type="password" name="name" value={confirmPassword} className="form-control" placeholder="Confirm New Password"  onChange={(e) => setConfirmPassword(e.target.value)} required />
                                                </div>
                                            </div>

                                            {(error.length > 0) && (<div className="col-lg-12 col-md-12">
                                                <div className="alert alert-danger text-center" role="alert">{error}</div>
                                            </div>)}
                                            
                                            <div className="button-container">
                                                <button type="submit" className="change-password-btn" disabled={loading} >비밀번호 변경</button>
                                                <Link className="to-dashboard-btn" to={USER_DASHBOARD} >DashBoard 페이지로</Link>
                                            </div>

                                            <div className="text-message col-lg-12 col-md-12">
                                                <p className="text-secondary text-center">비밀번호가 변경되고 나서<br/> 로그인 페이지로 이동 합니다.</p>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            
                            </div>

                            
                        </div>

                        <div className="col-sm-2"></div>

                    </div>
                </div>
            </EntireContainer>

            <Footer />
        </>
    )
}

export default Password;

