

import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import userProfilePic from '../../assets/images/userProfilePic.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.css';
/* 22_05_09 wooseok Kim */
import styled from 'styled-components';

const NFTCardTitleComponent = styled.div`
    font-size:var(--list-title);
    font-weight:bold;
    margin-top:12.5px;
    color:#000;
    @media screen and (max-width:855px){
        font-size:var(--tablet-list-title);
    }
    @media screen and (max-width:578px){
        font-size:var(--mobile-list-title);
    }
`;

/* 22_05_09 wooseok Kim */
const ButtonComponentWrap = styled.div`
    margin-top:15.5px;
    width:100%;
    height:50px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:${props => props.rent === 'single' ? 'center' : 'space-evenly'};
    .button-component{
        height:48px;
        width:124px;
    
        &.single{
            width:256px;
        }
        p{
            border-radius:4.5px;
            margin:0;
            width:100%;
            height:100%;
            font-size:var(--list-subtitle);
            text-align:center;
            line-height:48px;
            color:#fff;
            &.buy{
                background-color:#ff4d2c;
            }
            &.rent{
                background-color:rgb(24,104,183);
            }
            &.proof{
                background-color:#313131;
            }
        }   
    }
    @media screen and (max-width:855px){
        .button-component{
            p{
                font-size:var(--tablet-list-subtitle);
            }
        }
    }
    @media screen and (max-width:578px){
        .button-component{
            p{
                font-size:var(--mobile-list-subtitle);
            }
        }
    }
`;
/* 22_05_09 wooseok Kim */
const ContentContainer = styled.div`
    height:auto;
    width:100%;
`;
/* 22_05_09 wooseok Kim */
const ContentWrap = styled.div`
    height:auto;
    width:100%;
`;
/* 22_05_09 wooseok Kim */
const ContentIn = styled.div`
    width:100%;
    height:80px;
    box-sizing:border-box;
    padding:11px 0;
    .price-content{
        width:100%;
        height:58px;
        margin:0;
        line-height:22.5px;
        p{margin:0;}
        .price-title{
            width:100%;
            height:20px;
            font-size:var(--list-subtitle);
            font-weight:400;
        }
        .price{
            width:100%;
            height:38px;
            line-height:38px;
            span+span{
                margin-left:10px;
            }
            .price-text{
                font-size:var(--list-subtitle);
                color:#313131;
            }
            .fraction{
                font-size:var(--list-subtitle);
                color:#454545;
            }
        }
    }
    @media screen and (max-width:855px){
        .price-content{
            .price-title{
                font-size:var(--tablet-list-subtitle);
            }
            .price{
                .price-text{
                    font-size:var(--tablet-list-subtitle);
                }
                .fraction{
                    font-size:var(--tablet-list-subtitle);
                }
            }
        }
    }
    @media screen and (max-width:578px){
        .price-content{
            .price-title{
                font-size:var(--mobile-list-subtitle);
            }
            .price{
                .price-text{
                    font-size:var(--mobile-list-subtitle);
                }
                .fraction{
                    font-size:var(--mobile-list-subtitle);
                }
            }
        }
    }
`;



const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};

function NFTCard({ data = null }) {

    const [playing, toggle] = useAudio(data.media);
    return (
        <Link to={{
            pathname: `/nft/${data.id}`,
            state: { media: data?.media, NFT_register_type: data.NFT_register_type }
        }} style={{ width: '100%',cursor:'pointer'}}>
            <div className="featured-item">

                <div className="nft-option-card ">
                    {/* {data?.image
                    &&<LazyLoadImage src={data.image} effect="blur" width="100%" height="340px" style={{ height: '340px' }} placeholderSrc={''} alt="NFT Image" />} */}
                    {data?.format==="image"&&<LazyLoadImage src={data.image} effect="blur" width="100%" height="340px" style={{ height: '340px' ,objectFit:'cover'}} placeholderSrc={''} alt="NFT Image" />}
                    {data?.format==="audio"&&<LazyLoadImage src={data.bgimage} effect="blur" width="100%" height="340px" style={{ height: '340px' ,objectFit:'cover'}} placeholderSrc={''} alt="NFT Image" />}


                    <NFTCardTitleComponent>{data.name.split("#1/1")[0]}</NFTCardTitleComponent>


                    {/* <p> {data?.rent == "true" && <div >Rental Price: {data?.pricerent} Matic</div>} </p> */}
                    {/* {data?.rent == "false" && <div style={{ height: '2.5rem' }}></div>} */}
                    {data.media && <div className="top-btn-play" style={{ marginTop: '-11em', marginLeft: '15px', position: 'absolute' }}>

                        {!playing && <div onClick={toggle} className="hidden sm:block"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-play-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                        </svg></div>}

                        {playing && <div onClick={toggle} className="hidden sm:block"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-pause-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z" />
                        </svg></div>}

                        <div className="sm:hidden flex flex-col items-center justify-center"><audio src={data.media} controls controlsList="nodownload" style={{ width: '180px' }}></audio></div>




                    </div>}

                    <ContentContainer>
                        <ContentWrap>

                            <ContentIn>
                                {
                                   
                                    <div className="price-content">
                                        {   
                                            (data.rent==='sell'||data.rent==='rent')&&
                                            <p className="price-title">
                                                {data.rent==='sell'&& <>Fixed Price</>}
                                                {data.rent==='rent'&&<>Rental price/Day</>}
                                            
                                            </p>
                                        }
                                        {   
                                            (data.type==='sell'||data.type==='rent')&&
                                            <p className="price-title">
                                                {data.type==='sell'&& <>Fixed Price</>}
                                                {data.type==='rent'&&<>Rental price/Day</>}
                                            
                                            </p>
                                        }
                                        <div className="price">
                                            {
                                               
                                                <span className="price-text">
                                                    {data.rent==='sell'&&`${data.price.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON`}
                                                    {data.rent==='both'&&<p>Fixed Price {`${data.price.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON `} 
                                                    <br/> Rental price: {`${data.pricerent.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON `}/Day</p>}
                                                    {data.rent==='rent'&&<p>{`${data.pricerent.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON /Day`}</p>}

                                                    {data.type==='sell'&&`${data.price.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON`}
                                                    {data.type==='both'&&<p>Fixed Price {`${data.price.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON `} 
                                                    <br/> Rental price: {`${data.pricerent.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON `}/Day</p>}
                                                    {data.type==='rent'&&<p>{`${data.pricerent.match(/.{1,3}(?=(.{3})*$)/g).join(',')} WON /Day`}</p>}
                                                </span>
                                            }
                                        
                                        </div>
                                    </div>
                                }
                            </ContentIn>
                            <ButtonComponentWrap rent={data.rent==='both' ? 'double' : 'single'}>

                                {
                                    (data.rent === 'both'||data.type === 'both') && (
                                        <>
                                            <div to={{ pathname: `/nft/${data.id}`, state: {  media: data?.media,NFT_register_type: data.NFT_register_type } }} className={`button-component double`} >
                                                <p className="buy">
                                                    Buy
                                                </p>
                                            </div>

                                            <div to={{ pathname: `/nft/${data.id}`, state: {  media: data?.media,NFT_register_type: data.NFT_register_type } }} className='button-component rent'>
                                                <p className="rent">
                                                    Rent
                                                </p>
                                            </div>
                                        </>
                                    )}
                                {
                                    (data.rent === 'rent'||data.type === 'rent')
                                    &&
                                    <div to={{ pathname: `/nft/${data.id}`, state: {  media: data?.media,NFT_register_type: data.NFT_register_type } }} className='button-component single'>
                                        <p className="rent">
                                            Rent
                                        </p>
                                    </div>
                                }
                                {
                                    (data.rent === 'sell'||data.type === 'sell')
                                    &&
                                    <div to={{ pathname: `/nft/${data.id}`, state: {  media: data?.media,NFT_register_type: data.NFT_register_type } }} className={`button-component single`} >
                                        <p className="buy">
                                            Buy
                                        </p>
                                    </div>
                                }
                                {
                                    (data.rent=== 'proof'||data.type==='proof')
                                    &&
                                    <div to={{ pathname: `/nft/${data.id}`, state: {  media: data?.media,NFT_register_type: data.NFT_register_type } }} className={`button-component single`} >
                                        <p className="proof">
                                            None
                                        </p>
                                    </div>
                                }

                            </ButtonComponentWrap>
                        </ContentWrap>
                    </ContentContainer>
                    {/* <div className="nft-option-card ">
                        <LazyLoadImage src={data.image} effect="blur" width="100%" height="340px" style={{ height: '340px' }} placeholderSrc={''} alt="NFT Image" />

                        <h3 style={{ marginLeft: "15px" }}>{data.name}</h3>


                        <p style={{ marginLeft: "15px" }}> {data?.rent == "true" && <div >Rental Price: {data?.pricerent} Matic</div>} </p>
                        {data?.rent == "false" && <div style={{ height: '2.5rem' }}></div>}
                        {data.media && <div className="top-btn-play" style={{ marginTop: '-11em', marginLeft: '15px', position: 'absolute' }}>

                            {!playing && <div onClick={toggle} className="hidden sm:block"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                            </svg></div>}

                            {playing && <div onClick={toggle} className="hidden sm:block"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z" />
                            </svg></div>}

                            <div className="sm:hidden flex flex-col items-center justify-center"><audio src={data.media} controls controlsList="nodownload" style={{ width: '180px' }}></audio></div>




                        </div>}

                        <div className="content" style={{ marginTop: '-2em' }}>
                            <div className="content-in">
                                <span><span>Price (<small>Matic</small>)</span><br />{data.price} <sup>1/1</sup></span>
                                <Link to={`/nft/${data.id}`} ><div style={{ cursor: 'pointer' }}><a className='bg-dark p-2 text-white rounded-3 text-sm'>Buy</a>{data?.rent == "true" && <a className='bg-secondary p-2 m-2 text-white rounded-3 text-sm'>Rent</a>}</div></Link>
                            </div></div>

                    </div> */}

                </div>

            </div>

        </Link>
    )
}

export default NFTCard;
