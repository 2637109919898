import {all,fork} from 'redux-saga/effects';
import users from './usersSaga';
import paintings from './paintingsSaga';
import nftTokens from './nftTokensSaga';

export default function* rootSaga(){
    yield all([
        fork(users),
        fork(paintings),
        fork(nftTokens),
    ]);
}