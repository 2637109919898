import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import Skeleton from '../Skeleton/TopAuthorsHomeSkeleton';

import userProfilePic from '../../assets/images/userProfilePic.svg';

import { getTopAuthors } from '../../api/author';
import { MAX_TOP_AUTHORS_ON_HOME } from '../../constants/application';

function TopSellers(){

    let topAuthors = [];

    const limit = MAX_TOP_AUTHORS_ON_HOME;

    const { isLoading, error, data } = useQuery(['getTopAuthorsHome', limit], () => getTopAuthors(limit));
    
    if(!error && !isLoading)
    {
        topAuthors = data.data;
    }

    const handleImageError = imageElement => {
        imageElement.src = userProfilePic;
    }
  
    return (
        <>

              {/* <div className="top-sellers-area pt-100 pb-70">
                <div className="container">
                    
                    <div className="row">
                      <div className="col-lg-8 col-md-6">
                            <div className="section-title">
                                <h2>Top Authors</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="trending-btn text-end">
                                <a href="marketplace.php" className="default-btn border-radius-5">Explore More</a> *
                            </div></div>
                    </div>


                   
                    {(isLoading || error ) && (<Skeleton />)}

                    {topAuthors && (<div className="row justify-content-center pt-45">
                        
                        {topAuthors.map((author, index) => { 
                            return (
                            <div className="col-lg-3 col-6 col-md-4" key={`topauth-${index}`}>
                                <div className="top-sellers-item">
                                    <div className="number">{index + 1}.</div>
                                    <div className="top-sellers-img">
                                        <Link to={`/author/${author.userid}`}>
                                            <img src={author.profilepic} onError={(event) =>handleImageError(event.target)} alt="Images" />
                                        </Link>
                                        <i className="ri-check-line"></i>
                                    </div>
                                    <div className="content">
                                        <h3><Link to={`/author/${author.userid}`}><small>{author.userid.substr(0, 10)}</small></Link></h3>
                                        <span>{author.nftsold} NFTs Sold</span>
                                    </div>
                                </div>
                            </div>)}
                        )}
                        
                    </div>)}
                    
                   

                </div>
            </div> */}

        </>
    )
}

export default TopSellers;
