
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MenuOutlined } from "@ant-design/icons"
import './style.css';
import styled from "styled-components";

import { 
    HOMEPAGE, MARKETPLACE, USER_DASHBOARD, 
    USER_LOGIN, USER_SIGNUP, USER_SIGNUP_CERTIFICATION} from '../../constants/applicationUrls';
import logoImage from '../../assets/images/collery_symbol.svg';
import userProfilePicSample from '../../assets/images/userProfilePic.svg';
import { useAuth } from '../../contexts/AuthContext';
import { useQuery, useQueryClient } from "react-query";
import axios from 'axios';
import {isMobile} from "react-device-detect";

const OtherOptionsContainer = styled.div`
    width:auto;
    height:100%;
    @media screen and (max-width:850px){
        display:none;
    }
`;
const OtherOptionsWrapper = styled.div`
    width:auto;
    height:100%;
`;

const OtherOptionsUl = styled.ul`
    display:flex;
    flex-direction:row;
    align-items:center;
    height:100%;
    justify-content:space-evenly;
    margin:0;
    li{
        width:80px;
        height:35px;
        border-radius:15.5px;
        display:flex;
        align:items:center;
        justify-content:center;
        a{
            display:block;
            width:100%;
            height:100%;
            box-sizing:border-box;
            line-height: 24px;
            color: #fff;
            font-size:14.5px;
            border-radius:4px;
            text-align:center;
            background-color:#4a4a4a;
            padding:5.5px;
            &:hover{
                background-color:#ff4d2c;
            }
        }
    }
    li+li{
        margin-left:10px;
    }
    .link-to-user-dashboard{
        height:35px;
        width:150px;
        a{
            display:block;
            width:100%;height:100%;
            padding:5.5px;
            color:#fff;
            background-color:#000;
            p{
                margin:0px;
                width:100%;height:100%;
                display:flex;
                flex-direction:row;
                align-items:center;
                justify-content:space-evenly;
                img{
                    display:block;
                    width: 40.5px;
                    height: 40.5px;
                    border-radius:100%;
                    object-fit:cover;
                }
                span{
                    &.username{
                        color:#fff;
                    }
                }
                
            }
            &:hover{
                background-color:#000;
                color:#ff4d2c;
            }
        }
    }
`;

const HeaderContainer = styled.header`
    position:relative;
    height:84px;width:100%;
    background-color:#000;
    @media screen and (max-width:450px){
        height:44px;
    }
`;

const HeaderWrap = styled.div`
    width:75%;height:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    position:relative;
    margin:0 auto;
    @media screen and (max-width:850px){
        width:90.5%;
    }
`;

const LogoContainer = styled.div`
    height:100%;
    width:auto;
    a{
        display:flex;
        align-items:center;
        justify-content:space-evenly;
        width:100%;
        height:100%;
        .logo{
            width:45px;height:45px;
            background-image:url(${logoImage});
            background-size:100% 100%;
            background-repeat:no-repeat;
        }
        .serviceTitle{
            width:120.5px;
            height:45px;
            font-size:30px;
            text-align:center;
            line-height:45px;
            color:#ff4d2c;
        }
    }
    @media screen and (max-width:450px){
        a{
            .logo{
                background-size:35px 35px;
                width:35px;height:35px;
            }
            .serviceTitle{
                width:85.5px;
                height:35px;
                font-size:18.5px;
                text-align:center;
                line-height:35px;
                color:#ff4d2c;
            }
        }
    }
`;

const MainLinkContainer = styled.div`
    height:100%;
    width:auto;
    @media screen and (max-width:850px){
        display:none;
    }
`;

const MainLinkWrapper = styled.div`
    width:auto;
    height:100%;
    ul{
        width: 340px;
        height:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin:0;
        padding:0;
        li{
            width:80px;
            height:35px;
            a{
                box-sizing:border-box;
                padding:5.5px;
                display:block;
                height:100%;
                color:#fff;
                font-size:19.5px;
                text-align:center;
                line-height:24px;
                &:hover{
                    color:#ff4d2c;
                }
            }
        }
    }
`;

const MenuBarContainer = styled.div`
    display:none;
    @media screen and (max-width:850px){
        display:block;
        width:40px;
        height:40px;
        margin:2.5px;
    }
`;

const MenuBar = styled.div`
    cursor:pointer;
    height:40px;width:40px;
    border-radius:4.5px;
    padding:2.5px;
    box-sizing:border-box;
    margin:0;
    overflow:hidden;
    span{
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;height:100%;
        line-height:35px;
        font-size:28.5px;
        color:#fff;
    }
    &:hover{
        ${
            isMobile?'background-color:#4a4a4a;':'background-color:#ff4d2c;'
        }
    }
    &.on{
        background-color:#ff4d2c;
    }
`;

const SubMemuContainer = styled.div`
    display:none;
    @media screen and (max-width:850px){
        display:block;
        position:absolute;
        top:100%;
        right:-19.5px;
        width:150px;
        z-index:1001;
        height:auto;
        border-radius:4.5px;
        background-color:#000;
        ul{
            width:100%;
            height:auto;
            display:flex;
            flex-direction:column;
            align-items:center;
            padding:0;
            justify-content:space-evenly;
            margin:0;
            list-style-type: none;
            padding: 5.5px 0px;
            li{

                width:140px;
                height:25px;
                box-sizing:border-box;
                padding:2.5px;
                a{
                    display:block;
                    width:135px;
                    height:20px;
                    background-color:#4a4a4a;
                    border-radius:4.5px;
                    text-align:center;
                    line-height: 20px;
                    font-size: 14.5px;
                    color:#fff;
                    &:hover{
                        ${
                            isMobile?'background-color:#4a4a4a;'
                            :'background-color:#ff4d2c;'
                        }
                    }
                }
            }
        }    
    }
    
`

function Header() {
    const { currentUser, logout } = useAuth();
    const [showMenuBar, setShowMenuBar] = useState(false);
    const history = useHistory();
    const [isError,setIsError] = useState(false);
    const queryClient = useQueryClient();
    const { isLoading: loadingBasicUserData, data: basicUserData } = useQuery(
        ['basicUserData'],
        async() => {
            const res= await axios.get(`https://api.collery.io/getuserinfo/${currentUser.uid}`)
            return {...(res.data[0]),email:currentUser.email}
        }
    );

    useEffect(()=>{
        console.log(loadingBasicUserData);
        if(loadingBasicUserData){

           queryClient.invalidateQueries(['basicUserData'],{exact:true}) 
        }
    },[loadingBasicUserData])

    async function handleLogout(event) {
        event.preventDefault();

        logout().then((response) => {
            queryClient.cancelQuery(['basicUserData'],{exact:true});
            history.push(HOMEPAGE);
        }).catch((error) => {
        });
    }

    const onClickShowSubMenu = useCallback(() => {
        setShowMenuBar(prev=>!prev);
    }, []);

    return (
        <HeaderContainer >
            <HeaderWrap>
                <LogoContainer>
                    <Link to={HOMEPAGE}>
                        <span className="logo"></span>
                        <span className="serviceTitle">COLLERY</span>
                    </Link>
                </LogoContainer>

                <MainLinkContainer>
                    <MainLinkWrapper>
                        <ul>
                            <li>
                                <Link to={HOMEPAGE}>Home</Link>
                            </li>
                            <li>
                                <Link to='/gallery' >Gallery</Link>
                            </li>
                            <li>
                                <Link to={MARKETPLACE}>NFTs</Link>
                            </li>
                        </ul>
                    </MainLinkWrapper>
                </MainLinkContainer>
                <OtherOptionsContainer>
                    <OtherOptionsWrapper>
                        <OtherOptionsUl>
                            {
                                !currentUser &&
                                (<>
                                    <li><Link to={USER_LOGIN} className="active">Log In</Link></li>
                                    <li><Link to={USER_SIGNUP_CERTIFICATION}>Sign Up</Link></li>
                                </>)
                            }
                            {(currentUser&&!isError&&!loadingBasicUserData) && (<>
                                <li className="link-to-user-dashboard">
                                    <Link to={USER_DASHBOARD}>
                                        {/* {getFirstName(currentUser).toUpperCase() || 'Account'} */}
                                        <p>
                                            <span>
                                                <img src={(!loadingBasicUserData && basicUserData) ?
                                                    basicUserData.userthumbnail : userProfilePicSample} alt="user Profile ThumbNail" />
                                            </span>
                                            <span className="username">
                                                {(!loadingBasicUserData && basicUserData )? `${basicUserData.firstname}`:''}
                                            </span>
                                        </p>
                                    </Link>
                                </li>
                                <li><Link to={HOMEPAGE} className="active" onClick={handleLogout}>Logout</Link></li>
                            </>)}
                        </OtherOptionsUl>
                    </OtherOptionsWrapper>
                </OtherOptionsContainer>
                <MenuBarContainer>
                    <MenuBar className={`${showMenuBar?'on':''}`} onClick={onClickShowSubMenu}>
                        <MenuOutlined />
                    </MenuBar>
                </MenuBarContainer>
                {
                    showMenuBar &&
                    (
                        <SubMemuContainer>
                            <ul>
                                <li>
                                    <Link to={HOMEPAGE}>Home</Link>
                                </li>
                                <li>
                                    <Link to='/gallery'>Gallery</Link>
                                </li>
                                <li>
                                    <Link to={MARKETPLACE}>NFT</Link>
                                </li>
                                {
                                    !currentUser &&
                                    (
                                        <>
                                            <li>
                                                <Link to={USER_LOGIN}>LogIn</Link>
                                            </li>
                                            <li>
                                                <Link to={USER_SIGNUP_CERTIFICATION}>SignUp</Link>
                                            </li>
                                        </>
                                    )
                                }
                                {
                                    currentUser &&
                                    (
                                        <>
                                            <li>
                                                <Link to={USER_DASHBOARD}>MyPage</Link>
                                            </li>
                                            <li >
                                                <Link onClick={handleLogout} to={HOMEPAGE}>Logout</Link>
                                            </li>
                                        </>
                                    )
                                }
                            </ul>
                        </SubMemuContainer>
                    )
                }


            </HeaderWrap>
        </HeaderContainer >
    )
}

export default Header;

