import React, { useEffect, useState, useCallback, memo, useRef } from "react";
import {
    LOADING_CHANGE_PRICE_INFO
} from "../../../reducers/nftTokens";
import {useDispatch} from "react-redux"

const PriceComponent = memo(({userId,dataId, priceData ,rent,type}) => {
    const [price, setPrice] = useState(priceData.match(/.{1,3}(?=(.{3})*$)/g).join(','));
    const [showPriceInput, setShowPriceInput] = useState(false);

    const dispatch = useDispatch();

    const priceEditorRef = useRef(null);

    const onCilckButton = useCallback((evt)=>{
        console.log("onCilckButton");
        if(showPriceInput){
            const dataToSend = type==="Resell"?{
                owner:userId,
                id:dataId,
                price:price.split(',').join(''),
            }:{
                owner:userId,
                id:dataId,
                newprice:price.split(',').join(''),
                nftType:rent
            }
            console.log(dataToSend);
            dispatch({
                type:LOADING_CHANGE_PRICE_INFO,
                data:dataToSend,
                send_type:type,
            })
        }
        setShowPriceInput(prev=>!prev);
    },[showPriceInput,price]);

    const onChangePriceValue = useCallback((evt)=>{
        if(!priceEditorRef.current)return false;
        let {value} = evt.target;
        value=value.split(',').join('');
        if(value.match(/\d+/)){
            setPrice(value.match(/.{1,3}(?=(.{3})*$)/g).join(','));
        }else{
            priceEditorRef.current.focus();
            alert("오직 숫자만 입력해야 합니다");
        }
    },[priceEditorRef.current]);

    return (
        <>
            {
                showPriceInput ? (
                    <p className="NFT-data-info-price">
                        <input onChange={onChangePriceValue}
                            ref={priceEditorRef} className="price-editer"
                            value={price ? price : '0'} type="text" placeholder="type the price" />
                    </p>
                ) : (
                    <>
                        <p className="NFT-data-info-price">
                            <span className="price-text">{price}</span>
                            <span className="price-measure-text">
                                WON {rent === "rent" && "/Day"}
                            </span>
                        </p>
                    </>
                )
            }
            <p className="NFT-data-info-Button-wrap">
                <button onClick={onCilckButton} className="NFT-data-info-Button">
                    {showPriceInput?"confirm":type}
                </button>
            </p>
        </>
    )
});

export default PriceComponent;