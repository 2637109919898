
import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

import { useAuth } from '../../contexts/AuthContext';

import maintenancePic from '../../assets/images/maintenance.jpg';
import { HOMEPAGE, USER_DASHBOARD } from '../../constants/applicationUrls';

function Maintenance(){
    const { currentUser } = useAuth();
  
    return (
        <>
            <Header />

            <div className="coming-soon-area ptb-100">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="coming-soon-content">
                                <img src={maintenancePic} style={{ maxHeight: '400px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Maintenance;

