import React, { useEffect, useState, useRef, useCallback } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import styled from 'styled-components';
import { Button } from 'antd';
import FullImage from '../Gallery/FullImage';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import {
    LOAD_USER_PROFILE_IMG_REQUEST,
    LOAD_USER_PROFILE_IMG_INIT
} from '../../reducers/users';
import { useSelector, useDispatch } from "react-redux";
import userProfilePicSample from '../../assets/images/userProfilePic.svg';
import LoadingComponent from '../LoadingComponent';
import {GalleryProductDetail} from "../../constants/applicationMessage"

const StyledProductDetailContainer = styled.div`
    width:100%;
    height:auto;
    display:flex;
    justify-content:center;
    margin-top:60px;
`

const StyledProductDetailWrap = styled.div`
    width:850px;
    height:auto;
    @media screen and (max-width:1050px){
        width:770px;
    }
    @media screen and (max-width:920px){
        width:750px;
    }
    @media screen and (max-width:830px){
        width:630px;
    }
    @media screen and (max-width:740px){
        width:530px;
    }
    @media screen and (max-width:640px){
        width:430px;
    }
    @media screen and (max-width:510px){
        width:345px;
    }
`;

const StyledContentWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    width:100%;height:auto;
`;

const StyledImageWrap = styled.div`
    position:relative;
    width: 100%;
    height: auto;
    display:flex;
    align-items:center;
    border: none;
    border-radius: 10px;
    cursor:pointer;
    overflow:hidden;
    img{
        display:block;
        width:100%;
        object-fit:contain;
        object-position:50% 50%;
    }
`;

const StyledProductInfoWrap = styled.div`
    width:100%;
    height: auto;
    padding : 12px;
    box-sizing:border-box;
    
`;

const StyledProductInfoTitleWrap = styled.p`
    height: auto;
    width:100%;
    text-align:left;
    font-size:var(--detail-page-title);
    font-weight:bold;
    color:#000;
    padding:0;margin:15.5px 0 50.5px 0;
    span{
        display: block;
        width: 100%;height: 1px; 
        background: rgba(45,45,45,0.3);
        margin:10px 0;
    }
    @media screen and (max-width:1070px){
        font-size:var(--tablet-detail-page-title);
    }
    @media screen and (max-width:578px){
        margin:10.5px 0 20.5px 0;
        font-size:var(--mobile-detail-page-title);
    }
`;

const StyledAuthorInfoWrap = styled.div`
    width : 100%;
    height : auto;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items : center;
    box-sizing:border-box;
    padding:2.5px;
    .author_info_title{
        font-size:var(--detail-page-subtitle);
        font-weight:bold;
        color:#000;
        width:100%;margin:0;
        margin-bottom:30px;
    }
`;

const AuthorDetailInfoWrap = styled.div`
    width:100%;height:auto;
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:space-between;
    @media screen and (max-width:745px){
        flex-direction:column;
        align-items: center;
    }
`;

const StyledAuthorInfoImageWrap = styled.div`
    width:256px;height:256px;
    box-shadow : 0 0 1px #454545;
    border-radius: 100%;
    overflow: hidden;
    img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media screen and (max-width:855px){
        width: 150px;
        height: 150px;
        box-shadow: 0 0 1px #454545;
        box-shadow : 0 0 1px #454545;
        img{
            display: block;
            width: 100%;height:100%;
            object-fit: cover;
        }
    }
`;

const StyledAuthorInfoProfileWrap = styled.div`
    width:calc(100% - 284px);height:256px;
    margin-bottom:50.5px;
    padding: 2.5px;
    font-size:1.3em;
    .author-name{
        height:35.5px;margin:0px;
        font-weight:bold;
        font-size:var(--detail-page-subtext);
        color:#000;
    }
    .author_description{
        width:100%;
        height:auto;
        font-size:var(--explain-text);
        white-space:normal;
        line-height: 26.5px;
    }
    >span{
        display:block;
        width:100%;height:1px;
        margin-top:10px;
        background: rgba(45,45,45,0.3);
    }
    @media screen and (max-width:855px){
        .line{display:none;}
        .author-name{
            text-align:center;
            height:auto;
            font-size:var(--tablet-detail-page-subtitle);
        }
        .author_description{
            font-size:var(--tablet-explain-text);
        }
    }
    @media screen and (max-width:745px){
        width:100%;
        height:auto;
        margin-bottom: 22.5px;
    }
    @media screen and (max-width:578px){
        .author-name{
            font-size:var(--mobile-detail-page-subtitle);
        }
        .author_description{
            font-size:var(--mobile-explain-text);
        }
    }
`;

const StyledAuthorProfileInfoDetailWrap = styled.div`
    height:calc(100% - 36.5px);
    display:flex;
    justify-content: space-evenly;
    flex-direction:column;
    align-items:flex-start;
    span{
        display:flex;
        align-items:center;
    }
    .author_num_of_product_info{
        width:100%;
        display:flex;
        justify-content: flex-start;
        .number_of_product_title{
            font-size:15.5px;
            font-weight:bold;
        }
        .number_of_product{
            font-size:15.5px;
        }
    }
    .author_product_link{
        width:100%;height:100%;
        display:flex;
        align-items:center;
        justify-content:center;
    }
    @media screen and (max-width:1320px){
        justify-content:space-between;
        .author_product_link{
            justify-content:center;
        }
        .author_num_of_product_info{
            justify-content:flex-start;
        }
    }

    @media screen and (max-width:745px){
        height:90px;
        .author_product_link{
            height:80px;
        }
        .author_num_of_product_info{
            display:none;
        }
    }
`;

const StyledAuthorProductButton = styled.a`
    display:block;
    width:205.5px;height:50px;
    border-radius:5.5px;
    color:#fff;
    background:#ff4d2c;
    line-height:50px;
    padding: 0;
    text-align:left;
    vertical-align:middle;
    text-align:center;
    font-size:var(--detail-page-button);
    &:hover{
        color:#ff4d5c;
        background:#fff;
        box-sizing:border-box;
        border:1px solid #ff4d2c;
    }
    @media screen and (max-width:855px){
        font-size:var(--tablet-detail-page-button);
    }
    @media screen and (max-width:578px){
        font-size:var(--mobile-detail-page-button);
    }
`;

const StyledDescriptionContainer = styled.div`
    margin: 45px 0;
    width : 100%;
    height : auto;
`;

const StyledDescriptionWrap = styled.div`
    width:100%;
    height:auto;
    .title{
        height:auto;
        margin:0;
        font-size:19.5px;
        font-weight:bold;
    }
    .description_text{
        width:100%;
        height:auto;
        overflow:auto;
        border: 0.4px solid rgba(45,45,45,0.1);
        border-radius: 10px;
        font-size:var(--explain-text);
        color:#000;
        line-height:22.5px;
        padding: 3.5px;
        white-space:normal;
        margin:0;
    }
    @media screen and (max-width:1320px){
        height:auto;
    }
`;

/* const LoadingComponent = styled.div`
    position:absolute;
    top:0px;left:0px;
    width:100%;height:100%;
    background:rgba(45,45,45,0.75);
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:100;
    >img{
        display:block;
        width:150px;height:150px;
        border-radius:100%;
        object-fit:cover;
        @media screen and (max-width:637px){
            width:90px;height:90px;
        }
    }
`; */

const findElement = (element, token, endConditionToken) => {
    const flag = element.classList.contains(token) || element.tagName === token.toUpperCase();
    if (flag) {
        return element;
    } else {
        const endFlag = element.classList.contains(endConditionToken) || element.tagName.toUpperCase() === endConditionToken;
        if (endFlag) {
            return false;
        } else {
            return findElement(element.parentElement, token, endConditionToken);
        }
    }
}

const ProductDetail = () => {
    const imageWrapRef = useRef(null);
    const [isShowFullImage, setIsShowFullImage] = useState(false);
    const { productId } = useParams();
    const history = useHistory();
    /* onst [authorProfileImage, setAuthorProfileImage] = useState("/static/media/userProfilePic.a16c0a5a.svg");
    const { loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData } = useSelector(state => state.users);
    const dispatch = useDispatch(); */

    const { isLoading, data: productData } = useQuery(
        ['productDetail', productId],
        () => {
            return axios.get(`https://api.collery.io/getdetailgallery/${productId}`).then(res => res.data[0]);
        }, {
            // cache 시간 설정.
            cacheTime: 20000
        }
    );

    const onClickImage = useCallback((evt) => {
        setIsShowFullImage(prev => !prev);
    }, []);

    /* useEffect(() => {
        if (!isLoading) {
            dispatch({ type: LOAD_USER_PROFILE_IMG_REQUEST, data: { userid: productData.userid } });
            window.setTimeout(() => {
                setAuthorProfileImage('/static/media/loader.58607b10.gif');
            }, 0);
        }
    }, [isLoading]) */

    /* useEffect(() => {
        if (!loadUserProfileImgLoading && loadUserProfileImgSuccess) {
            if (loadUserProfileImgData?.image) {
                setAuthorProfileImage(loadUserProfileImgData.image);
            }else{
                setAuthorProfileImage(userProfilePicSample);
            }
            dispatch({ type: LOAD_USER_PROFILE_IMG_INIT });
        } else if (loadUserProfileImgFailure) {
            setAuthorProfileImage("/static/media/userProfilePic.a16c0a5a.svg");
            dispatch({ type: LOAD_USER_PROFILE_IMG_INIT });
        }
    }, [loadUserProfileImgLoading,
        loadUserProfileImgSuccess,
        loadUserProfileImgFailure,
        loadUserProfileImgData]) */

    useEffect(()=>{
        if(productData){
            console.log(productData);
        }
    },[productData]);

    const moveToAuthorGalleryPage = useCallback(() => {
        if (productData) {
            const { userid } = productData;
            history.replace(`/gallery/${userid}`);
        }
    }, [productData]);

    return (
        <>
            {/*isShowFullImage &&
                <FullImage title={productData.title} author={productData.author} image={productData.watermarkurl} setShowView={setIsShowFullImage}></FullImage>
            */}
            <div style={{ height: 'auto' }}>
                {isLoading ?
                    (
                        <LoadingComponent message={GalleryProductDetail.message} />
                    ) : (
                        <>
                            <Header />

                            <StyledProductDetailContainer>
                                <StyledProductDetailWrap>
                                    <StyledContentWrap>
                                        <StyledImageWrap className="ImageWrap" ref={imageWrapRef} >{/**onClick={onClickImage} */}
                                            <img src={productData.watermarkurl} id="productImg" alt="product image" />
                                        </StyledImageWrap>

                                        <StyledProductInfoWrap>
                                            <StyledProductInfoTitleWrap>
                                                {productData.title}
                                            </StyledProductInfoTitleWrap>
                                            {/* {
                                                productData.description&&productData.description!==''&&
                                                <StyledDescriptionContainer>
                                                    <StyledDescriptionWrap>
                                                        
                                                        <p className="description_text">
                                                            {productData.description}
                                                        </p>
                                                    </StyledDescriptionWrap>
                                                </StyledDescriptionContainer>
                                            } */}
                                            <StyledAuthorInfoWrap>
                                                <AuthorDetailInfoWrap>
                                                    <StyledAuthorInfoImageWrap>
                                                        <img src={productData.userthumbnail} alt="user-profile-image" />
                                                    </StyledAuthorInfoImageWrap>
                                                    <StyledAuthorInfoProfileWrap>
                                                        <p className="author-name" >
                                                            {`${productData.firstname}`}
                                                        </p>
                                                        <span className="line"></span>
                                                        {/* <div className="author_description">
                                                            흔히 탈인상주의 화가로 분류 되며, 또한 인상파, 
                                                            야수파, 초기 추상화, 표현주의에 미친 영향이 막대하며 
                                                            20세기 예술의 여러 다른 관점에서 보일 수 있다. 
                                                            암스테르담에 있는 반 고흐 미술관은 반 고흐의 작품과 
                                                            그의 동시대인들의 작품에 바쳐졌다. 네덜란드의 또 다른 도시인 
                                                            오테를로에 있는 크뢸러-뮐러 박물관도 상당히 많은 빈센트 반 고흐 
                                                            그림의 수집을 보유하고 있다.
                                                        </div> */}
                                                        <StyledAuthorProfileInfoDetailWrap>
                                                            {/* <div className="author_num_of_product_info">
                                                                <span className='number_of_product_title'>작품 수 : </span>
                                                                <span className='number_of_product'>1,024개</span>
                                                            </div> */}
                                                            <div className="author_product_link">
                                                                <StyledAuthorProductButton onClick={moveToAuthorGalleryPage}>
                                                                    작품 보기
                                                                </StyledAuthorProductButton>
                                                            </div>
                                                        </StyledAuthorProfileInfoDetailWrap>

                                                    </StyledAuthorInfoProfileWrap>
                                                </AuthorDetailInfoWrap>
                                            </StyledAuthorInfoWrap>
                                        </StyledProductInfoWrap>
                                    </StyledContentWrap>
                                </StyledProductDetailWrap>
                            </StyledProductDetailContainer>
                            <Footer/>
                        </>
                    )}


            </div>
        </>
    )
}

export default ProductDetail;