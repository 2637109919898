import React from 'react';
import monkey from '../assets/newhomepage/24.png';
import diamond from '../assets/newhomepage/17.png';
import oldphone from '../assets/newhomepage/18.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
AOS.init();

export default function SectionWorks() {
    function ComingSoon(){
        alert ("Coming Soon...")
    }
  return (
    <div className="pt-8 sm:pt-12" id="aipics" styles={{zIndex:'2'}}>

        <div>

           <div className="sm:flex items-center justify-center flex-col md:items-center lg:flex-row" >
            <div className="font-extrabold text-gray-600 ">
                    <p className="flex items-center justify-center sm:text-3xl">AI ART 지원도구</p>
                    <p className="h-[2px] ml-[230px] lg:ml-[205px] lg:mt-4 mt-1 bg-gray-600 w-[20px]"></p>
                </div>

                <div className="text-gray-500 text-[10px] font-bold mt-4 mr-8 ml-6 sm:text-lg sm:ml-20">
                    <p>인공지능 기술로 만들어진 ART 지원도구들이 준비되어 있습니다. AI 전문가의 도움으로 작품을 만들어보세요.</p>
                    <p>만들어진 결과물에 대한 저작권은 모두 작가님들에게 있습니다.</p>
                </div>
           </div>

            <div className="flex items-center justify-center sm:mt-4" >

                <div className="mt-16 sm:ml-4 lg:ml-2 lg:mt-36">
                    <a href='http://painting.aizac.io' target="_blank" ><img src={monkey} className="w-[100px] h-[120px] lg:w-[25rem] lg:h-[32rem] sm:w-[16rem] sm:h-[20rem] " /></a>
                   <div className="sm:flex sm:items-center sm:justify-between sm:mt-4"><p className="font-bold text-gray-600 text-center text-sm mt-2 sm:mt-0 sm:text-xl"><a href='http://painting.aizac.io ' target="_blank" className="text-gray-600">AI Painting</a></p><p className="text-gray-600 text-center flex items-center justify-center -mt-3 sm:mt-0 "><span className="text-[8px] sm:text-[12px] mt-2 sm:mt-1"><a href='http://painting.aizac.io ' target="_blank" className="text-gray-600" >Discover</a></span><span className="text-[30px] sm:text-[40px] mt-2 sm:mt-1">+</span></p></div>
                </div>

                <div className="ml-10 mt-2">
                <a href='http://novel.aizac.io' target="_blank" ><img src={diamond} className="w-[100px] h-[120px] lg:w-[25rem] lg:h-[32rem] sm:w-[16rem] sm:h-[20rem] " /></a>
               <div className="sm:flex sm:items-center sm:justify-between sm:mt-4"><p className="font-bold text-gray-600 text-center text-sm mt-2 sm:mt-0 sm:text-xl"><a href='http://novel.aizac.io' target="_blank" className="text-gray-600" >AI 웹소설</a></p><p className="text-gray-600 text-center flex items-center justify-center -mt-3 sm:mt-0 "><span className="text-[8px] sm:text-[12px] mt-2 sm:mt-1"><a href='http://novel.aizac.io' target="_blank" className="text-gray-600">Discover</a></span><span className="text-[30px] sm:text-[40px] mt-2 sm:mt-1">+</span></p></div>

                </div>

                <div className="ml-10 mt-16 sm:mr-4 lg:mr-2 lg:mt-36" onClick={() =>ComingSoon()}>
                    <img src={oldphone} className="w-[100px] h-[120px] lg:w-[25rem] lg:h-[32rem] sm:w-[16rem] sm:h-[20rem] " />
                    <div className="sm:flex sm:items-center sm:justify-between sm:mt-4"><p className="font-bold text-gray-600 text-center text-sm mt-2 sm:mt-0 sm:text-xl cursor-pointer">AI 캐릭터</p><p className="text-gray-600 text-center flex items-center justify-center -mt-3 sm:mt-3 "><span className="text-[8px] sm:text-[12px] cursor-pointer mt-2 sm:mt-1">Discover</span><span className="text-[30px] sm:text-[40px] mt-2 sm:mt-1">+</span></p></div>
                 
                </div>
            </div>
             
        </div>

    </div>
  )
}
