import React from 'react';
import styled from 'styled-components';
import {CloseOutlined} from '@ant-design/icons';

const MessageComponentContainer = styled.div`
    position:fixed;
    top:0;left:0;
    width:100%;height:100%;
    z-index: 10000;
    background-color:rgba(45,45,45,0.65);
`;

const MessageComponentWrapper = styled.div`
    position:absolute;
    top:50%;left:50%;
    transform:translateX(-50%) translateY(-50%);
    border-radius:15.5px;
    width: 555px;
    height:auto;
    min-height: 255px;
    box-shadow:0px 0px 2.5px #454545;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    overflow:hidden;
    background-color:#fff;
    .message-component-part{
        width:100%;
    }
    .message-component-header{
        height:48px;
        background-color:#000;
        color:#fff;
        vertical-align:middle;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .close-button{
            width:35px;height:35px;
            border-radius:100%;
            float:right;
            margin-right:10px;
            background-color:#000;
            >span{
                color:#fff;
                width:100%;height:100%;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .message-component{
        font-size:var(--dashboard-page-info);
        padding:0 10px;
        white-space:pre-wrap;
        text-align:center;
        line-height:22.5px;
    }
    .message-component-footer{
        height:75.5px;
        display:flex;
        >button{
            font-size:var(--dashboard-page-button);
            width:150px;height:50px;
            border-radius:4.5px;
            margin:0 auto;
            transform:translateY(25%);
            background-color:#ff4d2c;
            color:#fff;
            line-height:50px;
        }
    }
    @media screen and (max-width:855px){
        width:335px;
        min-height: 225px;
        .message-component-header{
            height: 34.5px;
            .close-button{
                width:26.5px;height:26.5px;
                margin-right:5.5px;
            }
        }
        .message-component{
            font-size:var(--tablet-dashboard-page-info);
        }
        .message-component-footer{
            height:45.5px;
            >button{
                font-size:var(--tablet-dashboard-page-button);
                width:120px;height:28px;
                border-radius:4.5px;
                margin:0 auto;
                transform:translateY(25%);
                background-color:#ff4d2c;
                color:#fff;
                line-height:28px;
            }
        }
    }
    @media screen and (max-width:578px){
        width:335px;
        min-height: 225px;
        border-radius:8.5px;
        .message-component{
            font-size:var(--mobile-dashboard-page-info);
            padding:0 8.5px;
            white-space:pre-wrap;
            text-align:center;
        }
        .message-component{
            font-size:var(--mobile-dashboard-page-info);
        }
        .message-component-footer{
            font-size:var(--mobile-dashboard-page-button);
        }
    }
`;



const MessageComponent = ({message,cancelFunction})=>{

    return (
        <MessageComponentContainer>
            <MessageComponentWrapper>
                <div className="message-component-part message-component-header">
                    <p className="close-button" onClick={()=>cancelFunction(false)}>
                        <CloseOutlined/>
                    </p>
                </div>
                <div className="message-component-part message-component">
                    {message}
                </div>
                <div className="message-component-part message-component-footer">
                    <button onClick={()=>cancelFunction(false)}>확인</button>
                </div>
            </MessageComponentWrapper>
        </MessageComponentContainer>
    )

}

export default MessageComponent;