import React,{memo} from 'react';
import styled from 'styled-components';
import {ToTopOutlined} from '@ant-design/icons';

const EntireWrapper = styled.div`
    width:100%;height:355px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: space-evenly;
    padding:65.5px;
    box-sizing:border-box;
    margin-top:95.5px;
    position:relative;
    border:2.5px solid transparent;
    color:#000;
    &:before{
        content:"";
        position:absolute;
        border-radius:15.5px;
        padding:2.5px;
        background: linear-gradient(45deg, #ff4d2c, #f5e905);
        inset:0;
        -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor; 
        mask-composite: exclude;
    }
    .upload-button-wrap{
        width:100%;
        height:55.5px;
        .upload-button-component{
            width:100%;
            height:100%;
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:space-evenly;
            .upload-message-component{
                >span{
                    font-size:var(--list-subtitle);
                    color:#000;
                }
            }
            .icon-component{
                width:48.5px;
                height:48.5px;
                font-size: 35.5px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                span{
                    color: #ff4d2c;
                }
            }
        }
    }
    .title-message-wrap{
        width:100%;
        height:auto;
        .title-message-component{
            height:100%;
            font-weight:bold;
            height:35px;
            line-height:35px;
            font-size:var(--list-title);
            text-align:center;
            color:#000;
        }
    }
    .sub-message-wrap{
        width:100%;
        height:auto;
        .sub-message-component{
            width:100%;
            height:24.5px;
            line-height:24.5px;
            font-size:var(--list-subtitle);
            text-align:center;
            color:#000;
        }
    }
    @media screen and (max-width:587px){
        .title-message-wrap{
            .title-message-component{
                height:25px;
                line-height:25px;
                font-size:var(--mobile-list-title);
            }
        }
        .sub-message-wrap{
            .sub-message-component{
                height:19.5px;
                line-height:19.5px;
                font-size:var(--mobile-list-subtitle);
            }
        }

    }
`;

const NoDataExistsComponent = memo(({
    uploadLinkComment,noMoreDataComment,
    uploadComment
})=>{
    return(
        <EntireWrapper>
            <div className="upload-button-wrap">
                <div className="upload-button-component">
                    <p className="icon-component">
                        <ToTopOutlined />
                    </p>
                    <p className="upload-message-component">
                        <span>{uploadLinkComment}</span>
                    </p>
                </div>
            </div>
            <div className='title-message-wrap'>
                <p className='title-message-component'>
                    {noMoreDataComment}
                </p>
            </div>
            <div className="sub-message-wrap">
                <p className='sub-message-component'>
                    {uploadComment}
                </p>
            </div>

        </EntireWrapper>
    )
});

export default NoDataExistsComponent;