import React, {useState, useEffect} from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'
//import data from './data'
import axios from 'axios';
import s1 from '../../../assets/top2/ss1.png';
import s2 from '../../../assets/top2/ss2.png';

import s4 from '../../../assets/top2/ss4.png';
import s5 from '../../../assets/top2/ss5.png';
import stars from '../../../assets/top2/stars.png';

const data =[
    {
      'id':0,
      'url':s1,
      'text':'Beeple로 알려진 Mike Winkelmann이 만든 디지털 예술 작품으로 Winkelmann이 자신의 Everydays 시리즈를 위해 무려 13년 넘는 시간 동안 매일같이 기록한 5천 장의 사진들을 합쳐 완성 것으로 크리스티스에서 역대 디지털 작품 중 최고가인 무려 6천9백30만 달러에 판매된 작품. 디지털 이미지를 NFT 화를 통해 판매된 작품으로 미술품 NFT 시장을 촉발시킨 대표적인 작품이다.'
     },

     {
      'id':1,
      'url':s2,
      'text':'Beeple로 알려진 Mike Winkelmann이 만든 디지털 예술 작품으로 Winkelmann이 자신의 Everydays 시리즈를 위해 무려 13년 넘는 시간 동안 매일같이 기록한 5천 장의 사진들을 합쳐 완성 것으로 크리스티스에서 역대 디지털 작품 중 최고가인 무려 6천9백30만 달러에 판매된 작품. 디지털 이미지를 NFT 화를 통해 판매된 작품으로 미술품 NFT 시장을 촉발시킨 대표적인 작품이다.'

     },


     {
        'id':3,
        'url':s4,
        'text':'Beeple로 알려진 Mike Winkelmann이 만든 디지털 예술 작품으로 Winkelmann이 자신의 Everydays 시리즈를 위해 무려 13년 넘는 시간 동안 매일같이 기록한 5천 장의 사진들을 합쳐 완성 것으로 크리스티스에서 역대 디지털 작품 중 최고가인 무려 6천9백30만 달러에 판매된 작품. 디지털 이미지를 NFT 화를 통해 판매된 작품으로 미술품 NFT 시장을 촉발시킨 대표적인 작품이다.'
  
       },


       {
        'id':4,
        'url':s5,
        'text':'Beeple로 알려진 Mike Winkelmann이 만든 디지털 예술 작품으로 Winkelmann이 자신의 Everydays 시리즈를 위해 무려 13년 넘는 시간 동안 매일같이 기록한 5천 장의 사진들을 합쳐 완성 것으로 크리스티스에서 역대 디지털 작품 중 최고가인 무려 6천9백30만 달러에 판매된 작품. 디지털 이미지를 NFT 화를 통해 판매된 작품으로 미술품 NFT 시장을 촉발시킨 대표적인 작품이다.'
  
       }

  
  
  ]

export default function Slider2() {

    const [slideIndex, setSlideIndex] = useState(1)
    //const [data, setData]=useState([]);


    const nextSlide = () => {
        if(slideIndex !== data.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === data.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(data.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }


    const changeSlide=()=>{
    setTimeout(()=>{
        nextSlide()
      
       },[4000]) 
       
    }
    
    useEffect(()=>{
         changeSlide()
    },[slideIndex])   

 
    return (
        <div className="container">
        <div className="d-flex justify-content-center pb-5"><img src={stars} alt="" width="80px"/></div>
         <div className='d-flex justify-content-center'><h3>세계적인 NFT 작품들</h3></div>
         <div className='d-flex justify-content-center'><h6>세계적으로 화제가 된 NFT 작품들입니다. 내일 당신의 작품이 이들 중 하나가 될 수 있습니다.</h6></div>
        <div className="container-slider">
            {data?.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                       <div className='d-flex justify-content-center mt-5 '>
                               <div className='w-full' >

                                   <img src={obj.url} />
                                

                                </div>

                        
                        </div>

                      
                      
                    </div>
                )
            })}
            {/*<BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/>*/}

            <div className="container-dots">
                {Array.from({length: data.length}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                ))}
            </div>
        </div>

        </div>
    )
}
