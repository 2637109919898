import React from 'react';
import { Link } from 'react-router-dom';
import QRCode from "react-qr-code";
import { useQuery } from 'react-query';

import Header from '../../Header';
import Footer from '../../Footer';

import { USER_WALLET } from '../../../constants/applicationUrls';
import { AVAILABLE_BLOCKCHAINS } from '../../../constants/application';
import { getUserWallets } from '../../../api/wallet';

import ethLogo from '../../../assets/images/crypto-logo/eth.svg';
import maticLogo from '../../../assets/images/crypto-logo/matic.svg';
import celoLogo from '../../../assets/images/crypto-logo/celo.svg';
import bnbLogo from '../../../assets/images/crypto-logo/bnb.svg';

import Skeleton from '../../Skeleton/UserWallets';

import './style.css';

function Wallet({ currentUser }){

    const userId = currentUser.uid
    let userWallets = [];

    const { isLoading, error, data } = useQuery(['getUserWallets', userId], () => getUserWallets(userId));
    
    if(!error && !isLoading)
    {
        const tempWallet = data[0];

        userWallets = [
            {
                name: 'eth',
                address: tempWallet.eth,
                balance: tempWallet.beth
            },
            {
                name: 'matic',
                address: tempWallet.matic,
                balance: tempWallet.bmatic
            },
            {
                name: 'celo',
                address: tempWallet.celo,
                balance: tempWallet.bcelo
            },
            {
                name: 'bnb',
                address: tempWallet.bnb,
                balance: tempWallet.bbnb
            }
        ];
    }

    const Logos = {
        eth: ethLogo,
        matic: maticLogo,
        celo: celoLogo,
        bnb: bnbLogo,
    }
  
    return (
        <>
            <Header />

            <div className="user-wallet-area pt-50 pb-70">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="container">
                                
                                <div className="section-title">
                                    <h2>Wallets</h2>
                                </div>

                                {isLoading && (<Skeleton />)}
                                {error && (<Skeleton />)}
                            
                                {userWallets && (<div className="row pt-50">

                                    {userWallets.map((wallet, index) => {
                                        return (<div className="col-lg-4 col-md-6 col-sm-12" key={`uwall-${index}`}>
                                        <div className="user-wallet-card-link">
                                            <div className="user-wallet-card">
                                                <div className="text-center">
                                                    <QRCode value={wallet.address} level={"H"} className="wallet-qrcode" />
                                                </div>

                                                <div className="wallet-details">
                                                    {/* <div className="section-title">
                                                        <h2>Description</h2>
                                                        <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks.</p>
                                                    </div> */}

                                                    <div className="row pt-20">
                                                    
                                                        <div className="col-lg-12 col-12">
                                                            <div className="blockchain-details">
                                                                <div className="content">
                                                                    <div className="images">
                                                                        <img src={Logos[wallet.name]} alt="Blockchain Image" />
                                                                    </div>
                                                                    <span>{AVAILABLE_BLOCKCHAINS[AVAILABLE_BLOCKCHAINS.map(bc => bc.value.toLowerCase()).findIndex(bcn => wallet.name === bcn)].name}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="col-lg-6 col-6">
                                                        <div className="item-details-user">
                                                        <div className="content">
                                                        <div className="images">
                                                        <img src="assets/images/Item-details/Item-details-user1.jpg" alt="Images" />
                                                        </div>
                                                        <span>Rose Gold</span>
                                                        </div>
                                                        </div>
                                                        </div> */}
                                                    </div>

                                                    <div className="wallet-info">
                                                        <ul>
                                                            <li>Last Update<b>: <small>NA</small></b></li>
                                                        </ul>
                                                    </div>

                                                    <div className="item-details-in-content">
                                                        <div className="item-left">
                                                            <span>Balance</span>
                                                            <div className="timer-text">{wallet.balance} <sup>{wallet.name.toUpperCase()}</sup></div>
                                                        </div>
                                                        <div className="item-right">
                                                            {/* <h3 className="item-remaining">Balance</h3>
                                                            <h3 className="item-right-eth">{wallet.balance} <sup>{wallet.name.toUpperCase()}</sup></h3> */}
                                                        </div>
                                                    </div>

                                                    <div className="item-details-btn">
                                                        <Link to={`${USER_WALLET}/${wallet.name}`} className="default-btn border-radius-50">VIEW {wallet.name.toUpperCase()} WALLET</Link>
                                                    </div>
                                                </div>


                                            </div>
                                            
                                        </div>
                                    </div>)})}

                                </div>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Wallet;

