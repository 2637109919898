import produce from "immer";

export const INIT_PAINTINGS_STATE = 'INIT_PAINTINGS_STATE';
export const LOADING_GALLERY_DATA = 'LOADING_GALLERY_DATA';
export const LOAD_GALLERY_DATA_SUCCESS = 'LOAD_GALLERY_DATA_SUCCESS';
export const LOAD_GALLERY_DATA_FAILURE = 'LOAD_GALLERY_DATA_FAILURE';

//ipfs 
export const UPLOADING_IMAGE_FILES_INIT = 'UPLOADING_IMAGE_FILES_INIT';
export const UPLOADING_IMAGE_FILES_REQUEST = 'UPLOAD_IMAGE_FILES_REQUEST';
export const UPLOADING_IMAGE_FILES_SUCCESS = 'UPLOAD_IMAGE_FILES_SUCCESS';
export const UPLOADING_IMAGE_FILES_FAILURE = 'UPLOAD_IMAGE_FILES_FAILURE';

//upload to main server
/* export const UPLOADING_DATA_INIT = 'UPLOADING_DATA_INIT';
export const UPLOADING_DATA_REQUEST = 'UPLOADING_DATA_REQUEST';
export const UPLOADING_DATA_SUCCESS = 'UPLOADING_DATA_SUCCESS';
export const UPLOADING_DATA_FAILURE = 'UPLOADING_DATA_FAILURE'; */

export const GET_COUNT_GALLERY_INIT = 'GET_COUNT_GALLERY_INIT';
export const GET_COUNT_GALLERY_REQUEST = 'GET_COUNT_GALLERY_REQUEST';
export const GET_COUNT_GALLERY_SUCCESS = 'GET_COUNT_GALLERY_SUCCESS';
export const GET_COUNT_GALLERY_FAILURE = 'GET_COUNT_GALLERY_FAILURE';

export const DELETE_GALLERY_PRODUCT_INIT = 'DELETE_GALLERY_PRODUCT_INIT';
export const DELETE_GALLERY_PRODUCT_REQUEST = 'DELETE_GALLERY_PRODUCT_REQUEST';
export const DELETE_GALLERY_PRODUCT_SUCCESS = 'DELETE_GALLERY_PRODUCT_SUCCESS';
export const DELETE_GALLERY_PRODUCT_FAILURE = 'DELETE_GALLERY_PRODUCT_FAILURE';

export const GET_GALLERY_PRODUCTS_BY_CATEGORY_INIT = 'GET_GALLERY_PRODUCTS_BY_CATEGORY_INIT';
export const GET_GALLERY_PRODUCTS_BY_CATEGORY_REQUEST = 'GET_GALLERY_PRODUCTS_BY_CATEGORY_REQUEST';
export const GET_GALLERY_PRODUCTS_BY_CATEGORY_SUCCESS = 'GET_GALLERY_PRODUCTS_BY_CATEGORY_SUCCESS';
export const GET_GALLERY_PRODUCTS_BY_CATEGORY_FAILURE = 'GET_GALLERY_PRODUCTS_BY_CATEGORY_FAILURE';

const initialState = {
    loadingGalleryData:false,
    loadingGalleryDataSuccess:false,
    loadingGalleryDataFailure:null,
    galleryData:null,
    countGalleryData:-1,
    errorMessage:'',
    uploadingImageFiles:false,
    uploadingImageFilesSuccess:false,
    uploadingImageFilesFailure:null,
    uploadingDataOnServer:false,
    uploadingDataOnServerSuccess:false,
    uploadingDataOnServerFailure:null,
    uploadingDataOnServerData:null,
    getCountGalleryLoading:false,
    getCountGallerySuccess:false,
    getCountGalleryFailure:null,
    deleteGalleryProductLoading:false,
    deleteGalleryProductSuccess:false,
    deleteGalleryProductFailure:null,
    countGallery : 0,
};

const paintingsReducer = (state=initialState,action)=>{
    return produce(state,(draft)=>{
        switch(action.type){
            case DELETE_GALLERY_PRODUCT_INIT:{
                draft.deleteGalleryProductLoading=false;
                draft.deleteGalleryProductSuccess=false;
                draft.deleteGalleryProductFailure=null
                break;
            }
            case DELETE_GALLERY_PRODUCT_REQUEST:{
                draft.deleteGalleryProductLoading=true;
                draft.deleteGalleryProductSuccess=false;
                draft.deleteGalleryProductFailure=null;
                break;
            }
            case DELETE_GALLERY_PRODUCT_SUCCESS:{
                console.log(DELETE_GALLERY_PRODUCT_SUCCESS);
                draft.deleteGalleryProductLoading=false;
                draft.deleteGalleryProductSuccess=true;
                break;
            }
            case DELETE_GALLERY_PRODUCT_FAILURE:{
                draft.deleteGalleryProductLoading=false;
                draft.deleteGalleryProductFailure=action.data;
                break;
            }

            case GET_COUNT_GALLERY_INIT:{
                draft.getCountGalleryLoading=false;
                draft.getCountGallerySuccess=false;
                draft.getCountGalleryFailure=null;
                draft.countGallery=0;
                break;
            }
            case GET_COUNT_GALLERY_REQUEST:{
                draft.getCountGalleryLoading=true;
                draft.getCountGallerySuccess=false;
                draft.getCountGalleryFailure=null;
                break;
            }
            case GET_COUNT_GALLERY_SUCCESS:{
                draft.getCountGalleryLoading=false;
                draft.getCountGallerySuccess=true;
                draft.countGallery=action.data;
                console.log(action.data);
                break;
            }
            case GET_COUNT_GALLERY_FAILURE:{
                draft.getCountGalleryLoading=false;
                draft.getCountGalleryFailure=action.data;
                break;
            }
            /* case UPLOADING_DATA_INIT:{
                draft.uploadingDataOnServer=false;
                draft.uploadingDataOnServerSuccess=false;
                draft.uploadingDataOnServerFailure=null;
                draft.uploadingDataOnServerData=null;
                break;
            }
            case UPLOADING_DATA_REQUEST:{
                draft.uploadingDataOnServer = true;
                draft.uploadingDataOnServerSuccess=false;
                draft.uploadingDataOnServerFailure=null;
                break;
            }
            case UPLOADING_DATA_SUCCESS:{
                draft.uploadingDataOnServer=false;
                draft.uploadingDataOnServerSuccess=true;
                break;
            }
            case UPLOADING_DATA_FAILURE:{
                draft.uploadingDataOnServer=false;
                draft.uploadingDataOnServerFailure=action.data;
                break;
            } */
            case UPLOADING_IMAGE_FILES_INIT:{
                draft.uploadingImageFiles=false;
                draft.uploadingImageFilesSuccess=false;
                draft.uploadingImageFilesFailure=null;
                draft.uploadingImageFilesData=null;
                break;
            }       
            case  UPLOADING_IMAGE_FILES_REQUEST:{
                draft.uploadingImageFiles = true;
                draft.uploadingImageFilesSuccess=false;
                draft.uploadingImageFilesFailure = null;
                break;                
            }
            case UPLOADING_IMAGE_FILES_SUCCESS:{
                draft.uploadingImageFiles = false;
                draft.uploadingImageFilesSuccess=true;
                break;
            }
            case UPLOADING_IMAGE_FILES_FAILURE:{
                draft.uploadingImageFiles=false;
                draft.uploadingImageFilesFailure=action.data;
                break;
            }
            case INIT_PAINTINGS_STATE:{
                draft.loadingGalleryData=false;
                draft.loadingGalleryDataFailure=null;
                draft.loadingGalleryDataSuccess=false;
                draft.galleryData=null;
                draft.errorMessage='';
                draft.countGalleryData = -1;
                break;
            }
            case LOADING_GALLERY_DATA:{
                draft.errorMessage='';
                draft.loadingGalleryData=true;
                draft.loadingGalleryDataSuccess=false;
                break;
            }
            case LOAD_GALLERY_DATA_SUCCESS:{
                draft.loadingGalleryData=false;
                draft.loadingGalleryDataSuccess=true;
                draft.galleryData = action.galleryData;
                draft.countGalleryData = action.countGallery;
                if(action?.countGallery){draft.countGallery=action.countGallery;}
                break;
            }
            case LOAD_GALLERY_DATA_FAILURE:{
                draft.countGalleryData = -1;
                draft.loadingGalleryData=false;
                draft.loadingGalleryDataFailure=action.errorData;
                draft.errorMessage = action.errorMessage;
                break;
            }
            default:break;
        }
    });
}

export default paintingsReducer;