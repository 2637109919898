
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { createUserWallets } from '../../api/wallet';

import { USER_DASHBOARD, USER_LOGIN } from '../../constants/applicationUrls';
import { useAuth } from '../../contexts/AuthContext';

import Footer from '../Footer';
import Header from '../Header';

import './style.css';

function Forgotpwd(){
    const { currentUser, signup,resetPassword } = useAuth();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');    
    const [signupError, setSignupError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSignupError('');

        if (password !== confirmPassword)
        {
            return setSignupError("wrong email");
        }

        setLoading(true);

        resetPassword(email).then(async (response) => {
         
            history.push(USER_DASHBOARD);

        }).catch((error) => {
            setSignupError(error.message);
            setLoading(false);
        });

    }

    if(currentUser)
    {
        history.push(USER_DASHBOARD);
    }
  
    return (
        <>
            <Header />

            <div className="signup-area pt-100 pb-70">
                <div className="container">
                    <div className="row align-items-center">
                    
                        <div className="col-lg-12">
                            <div className="user-all-form">
                                <div className="contact-form">
                                    <h3 className="text-center">Reset My Account</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">

                                            <div className="col-lg-12 ">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="text" value={email} className="form-control" placeholder="example@gmail.com" onChange={(e) => setEmail(e.target.value)} required />
                                                </div>
                                            </div>
                                    

                                           

                                            {(signupError.length > 0) && (<div className="col-12">
                                                <div className="alert alert-danger text-center" role="alert">{signupError}</div>
                                            </div>)}
                                        
                                            <div className="col-lg-12 col-md-12 text-center pt-50">
                                                <button type="submit" className="default-btn bg-black" disabled={loading}>Send me the Reset Link</button>
                                            </div>
                                        
                                            <div className="col-12">
                                                <div className="sub-title">
                                                <span>Already have an account?</span>
                                                </div>
                                            </div>
                                        
                                            <div className="login-with-account">
                                                <ul>
                                                    <li><Link to={USER_LOGIN}>Login Here</Link></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Forgotpwd;

